<template>
<div>
  <v-data-table
    :headers="headers"
    :items="renewalList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <!-- <v-toolbar-title>Item Utility</v-toolbar-title> -->
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="my-2"
              @click="Dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
      </v-toolbar>
    </template>
        <template v-slot:[`item.DateStart`]="{ item }">
          {{formatDate(item.DateStart)}}
        </template>
        <template v-slot:[`item.DateEnd`]="{ item }">
          {{formatDate(item.DateEnd)}}
        </template>
        <template v-slot:[`item.MRCodes`]="{ item }">
          {{ item.MRCodes ? getMRCodes(item.MRCodes) : 'All' }}
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
  </v-data-table>
        <v-dialog
          v-model="Dialog"
          persistent
          max-width="500px"
          scrollable
        >
          <v-card>
            <v-card-title max>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">UPDATE RENEWAL SCHEDULE</span>
              <span class="subtitle-1 font-weight-bold" v-else>NEW RENEWAL SCHEDULE</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
            <v-card-text style="max-height: 900px">
              <v-form @submit.prevent ref="formRef">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-textarea
                      dense
                      v-model="formData.Description"
                      :rules="[formRules.required]"
                      counter
                      maxlength="250"
                        label="Description"
                        color="header"
                      ></v-textarea>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                    <v-menu
                      dense
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :return-value.sync="formData.DateStart"
                      transition="scale-transition"
                      offset-y
                      class="mb-4"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          dense
                          v-model="formData.DateStart"
                          :rules="[formRules.required]"
                          label="Date Start"
                          readonly
                          color="header"
                          v-bind="attrs"
                          v-on="on"
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="formData.DateStart"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="header"
                          @click="$refs.menu1.save(formData.DateStart)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-menu
                      dense
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="formData.DateEnd"
                      transition="scale-transition"
                      offset-y
                      class="mb-4"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          dense
                          v-model="formData.DateEnd"
                          :rules="[formRules.required]"
                          label="Date End"
                          readonly
                          color="header"
                          v-bind="attrs"
                          v-on="on"
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="formData.DateEnd"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="header"
                          @click="$refs.menu2.save(formData.DateEnd)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        v-model="formData.isSelected"
                        color="header"
                        label="Selected ICS/PAR Codes"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" v-if="formData.isSelected">
                <v-autocomplete
                  v-model="FiscalYearID_r"
                  :items="fiscalYearList"
                  auto-select-first
                  item-text="fiscalYear"
                  item-value="id"
                  color="header"
                  dense
                  label="Fiscal Year"
                  class="mt-2"
                >
                </v-autocomplete>
                </v-col>
                <v-col cols="12" v-if="formData.isSelected">
                  <v-spacer></v-spacer>
                  <v-btn
                  color="header darken-1"
                  outlined
                  small
                  class="mr-2"
                  @click="selectAll()"
                >
                  Select All
                </v-btn>
                <v-btn
                  color="grey darken-1"
                  outlined
                  small
                  @click="clearAll()"
                >
                  Clear All
                </v-btn>
                </v-col>
                <v-col cols="12" v-if="formData.isSelected">
                <v-autocomplete
                  v-model="formData.mrCodes"
                  :items="mrCodeList_filtered"
                  chips
                  item-value="MRCode"
                  item-text="MRCode"
                  :rules="[formRules.required, formData.mrCodes.length > 0]"
                  color="header"
                  label="ICS/PAR Codes"
                  multiple
                >
                </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                @click="save"
                class="white--text"
                :loading="isLoading"
              >
                Save
              </v-btn>
              <v-btn
              v-else
                color="blue darken-1"
                @click="update"
                class="white--text"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      amountErr: null,
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu1: false,
      menu2: false,
      menu3: false,
      formData: {
        Description: null,
        DateStart: null,
        DateEnd: null,
        isSelected: false,
        mrCodes: [],
      },
      Dialog: false,
      renewalList: [],
      headers: [
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'Description',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Start',
          sortable: false,
          value: 'DateStart',
          align: "center",
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date End',
          sortable: false,
          value: 'DateEnd',
          align: "center",
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'ICS/PAR Codes',
          align: 'start',
          sortable: false,
          value: 'MRCodes',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
       mrCodeList: [],
       fiscalYearList: [],
       FiscalYearID_r: 0,
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      FiscalYearID_r: {
        handler (data) {
          if(data > 0){
          this.mrCodeList_filtered = [];
          this.mrCodeList.forEach(item => {
                if(data == item.FiscalYearID){
                  this.mrCodeList_filtered.push(item);
                }
              });
          }
          else{
            this.mrCodeList_filtered = this.mrCodeList;
          }
        },
        deep: true,
      },
    },
    created () {
      this.getFiscalYear();
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      selectAll() {
        let budgets = [];
        this.mrCodeList_filtered.forEach(item => {
          budgets.push(item.MRCode)
        });
        this.formData.mrCodes = budgets;
      },
      clearAll() {
        this.formData.mrCodes = [];
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
              this.axiosCall(
              '/getRenewal',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.renewalList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
    editItem(data) {
      this.editItemId = data.RenewalID;
      this.formData.Description = data.Description;
      this.formData.DateStart = data.DateStart;
      this.formData.DateEnd = data.DateEnd;
      this.formData.isSelected = data.isSelected;
      this.formData.mrCodes = JSON.parse(data.MRCodes) || [];
      this.FiscalYearID_r = data.FiscalYearID;
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
            this.isLoading = true;

             let data = new FormData();
              data.append('Description', this.formData.Description);
              data.append('DateStart', this.formData.DateStart);
              data.append('DateEnd', this.formData.DateEnd);
              data.append('isSelected', this.formData.isSelected ? 1 : 0);
              data.append('MRCodes', this.formData.isSelected ? JSON.stringify(this.formData.mrCodes) : null);
              data.append('FiscalYearID', this.FiscalYearID_r);
                         this.axiosCall(
                        '/renewal/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
            this.isLoading = true;
             let data = new FormData();
              data.append('RenewalID', this.editItemId);
               data.append('Description', this.formData.Description);
              data.append('DateStart', this.formData.DateStart);
              data.append('DateEnd', this.formData.DateEnd);
              data.append('isSelected', this.formData.isSelected ? 1 : 0);
              data.append('MRCodes', this.formData.isSelected ? JSON.stringify(this.formData.mrCodes) : null);
              data.append('FiscalYearID', this.FiscalYearID_r);
                         this.axiosCall(
                        '/renewal/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      remove (item) {
        const index = this.formData.mrCodes.indexOf(item)
        if (index >= 0) this.formData.mrCodes.splice(index, 1)
      },
      getAllMRCode(){
        this.mrCodeList = [];
        this.mrCodeList_filtered = [];
             this.axiosCall(
              '/getAllMRCode',
              'POST',
              []
          )
          .then((res) => {
            this.mrCodeList = res.data.data.result;
              res.data.data.result.forEach(item => {
                if(this.FiscalYearID_r == item.FiscalYearID){
                  this.mrCodeList_filtered.push(item);
                }
              });
          })
      },
      getFiscalYear() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalYearList = [...res.data.data,{id: 0, fiscalYear: "All"}]
        this.FiscalYearID_r = res.data.data[0].id
        this.getAllMRCode();
      });
    },
    getMRCodes(data){
      let list = JSON.parse(data)
      if(list.length > 10){
        let budgets = list.slice(0, 10)
        return budgets.join(', ') + ", and " + (list.length - 10) + " more..."
      }
      else{
        return list.join(', ')
      }
    }
    }
  }
</script>