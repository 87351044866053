<template>
<div>
  <v-data-table
    :headers="headers"
    :items="prList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1 pt-2"
  >
        <template v-slot:top>
          <v-tabs color="green darken-2">
            <v-tab @click="tab = 1">
              <span v-if="$store.state.user.roleID == 5">For Verification</span>
              <span v-else>For Approval</span>
            </v-tab>
            <v-tab  @click="tab = 2">
              <span v-if="$store.state.user.roleID == 5">Verified</span>
              <span v-else>Approved</span>
              </v-tab>
            <v-tab @click="tab = 3">Pending</v-tab>
          </v-tabs>
        <v-row align="center" class="mx-1 my-3">
          <v-col cols="12" md="6" lg="4">
            <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search here..."
            color="green darken-2"
            class="pa-0"
            dense
            single-line
            hide-details
          ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
                    <!-----filter by office----->
          <v-autocomplete
            color="green"
            v-model="OfficeID"
            item-text="OfficeDescription"
            item-value="OfficeID"
            :items="office"
            @change="initialize()"
            dense
            label="Filter by office"
            hide-details
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
          <!-----filter by delivery unit----->
          <v-autocomplete
            color="green"
            v-model="DeliveryUnitID"
            item-text="DeliveryUnitDescription"
            item-value="DeliveryUnitID"
            :items="deliveryUnit"
            @change="initialize()"
            dense
            label="Filter by Delivery Unit"
            hide-details
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" lg="2">
          <!-----filter by pap----->
          <v-autocomplete
            color="green"
            v-model="PurposeID"
            item-text="PurposeDescription"
            item-value="PurposeID"
            :items="purpose"
            @change="initialize()"
            dense
            label="Filter by Program/ Activity/ Project"
            hide-details
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" lg="2">
          <!-----filter by fund source----->
          <v-autocomplete
            color="green"
            v-model="SpecificFundSourceID"
            item-text="SpecificFundSourceDescription"
            item-value="SpecificFundSourceID"
            :items="specificFundSource"
            @change="initialize()"
            dense
            label="Filter by Fund Source"
            hide-details
          >
          </v-autocomplete>
          </v-col>
      </v-row>
    </template>
      <template v-slot:[`item.PRCODE`]="{ item }">
        {{ item.isApproved == 1 ? item.PRCODE : item.PRCODETemp }}
      </template>
      <template v-slot:[`item.isDBM`]="{ item }">
          <span class="text-no-wrap" v-if="item.isDBM == 1"><small>DBM</small></span>
          <span class="text-no-wrap" v-if="item.isDBM == 0"><small>NON DBM</small></span>
      </template>
      <template v-slot:[`item.GrandTotal`]="{ item }">
        <span class="text-no-wrap">₱ {{formatPrice(item.GrandTotal)}}</span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
        color="green darken-2"
        dark
        outlined
        elevation="2"
         v-if="tab == 1 || (tab == 3 && item.PSUStatusID == 6)"
        small
        class="mr-2"
        @click="approveItem(item)"
      >
      <span v-if="$store.state.user.roleID == 5">Verify</span>
      <span v-else>Approve</span>
      </v-btn>
            <v-btn
        color="red darken-2"
        dark
        outlined
        elevation="2"
        class="mr-2"
        small
        v-if="tab == 1"
        @click="pendingItem(item)"
      >
        Pending
      </v-btn>
       <v-btn
        color="blue darken-2"
        dark
        outlined
        elevation="2"
        small
       @click="viewPR(item)"
      >
        View
      </v-btn>
        </span>
      </template>
  </v-data-table>
        <v-dialog
          v-model="dialogPending"
          persistent
          max-width="450px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">This Purchase Request is Pending</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="dialogPending = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
              <v-row>
              <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                     <v-textarea
                  color="green"
                    dense
                       v-model="remarks"
                     :rules="[formRules.required]"
                      label="Remarks"
                     counter
                     maxlength="500"
                    ></v-textarea>
        </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogPending = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                 @click="confirmReturn()"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogApprove" max-width="500px">
          <v-card>
            <v-card-title class="text-h6">Are you sure you want to approve this PR?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogApprove = false">Cancel</v-btn>
              <v-btn color="green darken-2" @click="confirmApprove()" text>Yes</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <prModlal :prData="prItem" />
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    components : {
      'prModlal' : () => import('./PRViewItem')
    },
    props: {
      fiscalYear: null,
    },
    data: () => ({
      OfficeID : 0,
      DeliveryUnitID: 0,
      PurposeID: 0,
      SpecificFundSourceID: 0,
      office : [],
      deliveryUnit: [],
      purpose: [],
      specificFundSource: [],
      tab: 1,
       searchText: "",
      searchTimeout : null,
      remarks: null,
      item: null,
      dialogPending: false,
      dialogApprove: false,
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
      options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      prList: [],
      prItem: [],

      headers: [
        { text: 'PR No.', value: 'PRCODE', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Fund', value: 'FundCode', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Budget Code', value: 'BudgetCode', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Purpose', value: 'PrPurpose', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Fund Source', value: 'SpecificFundSourceDescription', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: '', value: 'isDBM', sortable: false, align: "center", class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Amount', value: 'GrandTotal', align: "end", sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", }
      ],
    }),
    watch: {
      searchText : function(){
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
			this.initialize();
			}, 600);
		},
    OfficeID: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    DeliveryUnitID: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    PurposeID: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    SpecificFundSourceID: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      tab: {
        handler () {
          this.prList = [];
        this.initialize();
        },
        deep: true,
      },
      fiscalYear: {
        handler () {
        this.initialize();
        },
        deep: true,
      },
    },
    created () {
    },
    mounted() {
         this.getOffice();
         this.getDeliveryUnit();
         this.getPurpose();
         this.getSpecificFundSource();
          this.eventHub.$on('closePRItemodal', ()=>{
          this.initialize();
          this.prItem = {};
        });
    },
    beforeDestroy() {
         this.eventHub.$off('closePRItemodal');
    },
    methods: {
    getOffice() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllOffice", "POST", data).then((res) => {
        this.office = res.data.data.result;
        this.office.push({ OfficeID: 0, OfficeDescription: "All" });
        this.office.sort(function (a, b) {
          return a.OfficeID - b.OfficeID;
        });
      });
    },
    getDeliveryUnit() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllDeliveryUnit", "POST", data).then((res) => {
        this.deliveryUnit = res.data.data.result;
        this.deliveryUnit.push({ DeliveryUnitID: 0, DeliveryUnitDescription: "All" });
        this.deliveryUnit.sort(function (a, b) {
          return a.DeliveryUnitID - b.DeliveryUnitID;
        });
      });
    },
    getPurpose() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllPurpose", "POST", data).then((res) => {
        this.purpose = res.data.data.result;
        this.purpose.push({ PurposeID: 0, PurposeDescription: "All" });
        this.purpose.sort(function (a, b) {
          return a.PurposeID - b.PurposeID;
        });
      });
    },
    getSpecificFundSource() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllFundsource", "POST", data).then((res) => {
        this.specificFundSource = res.data.data.result;
        this.specificFundSource.push({ SpecificFundSourceID: 0, SpecificFundSourceDescription: "All" });
        this.specificFundSource.sort(function (a, b) {
          return a.SpecificFundSourceID - b.SpecificFundSourceID;
        });
      });
    },
      viewPR(item) {
        this.prItem = item;
        console.log(this.prItem)
      },
      confirmReturn() {
      if(this.$refs.formRef.validate()){
          let data = new FormData();
              data.append('Remarks', this.remarks)
              data.append('PRID', this.item.PRID);
              this.axiosCall(
              '/signatories/pr/pending',
              'POST',
              data
          )
          .then((res) => {
            console.log(res)
                this.dialogPending = false
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = 'success';
                this.fadeAwayMessage.message = 'PR Successfully Pending!';
                this.fadeAwayMessage.header = 'System Message';
                this.initialize();
          })
      }
      },
      confirmApprove() {
          let data = new FormData();
              data.append('PRID', this.item.PRID);
              this.axiosCall(
              '/signatories/pr/approve',
              'POST',
              data
          )
          .then((res) => {
            console.log(res)
                this.dialogApprove = false
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = 'success';
                this.fadeAwayMessage.message = 'PR Successfully Approved!';
                this.fadeAwayMessage.header = 'System Message';
                this.initialize();
          })
      },
           print (data) {
             window.open(this.apiUrl + "/pr/report/" + data.PRID);
          },
      pendingItem(data) {
        this.item = data;
          this.dialogPending = true
      },
      approveItem(data) {
        this.item = data;
          this.dialogApprove = true
      },
      initialize () {
        this.loading = true
        if(this.fiscalYear != null){
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
      if (this.$route.name == "RA") {
        data.append("RA", true);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("OfficeID", this.OfficeID);
      data.append("DeliveryUnitID", this.DeliveryUnitID);
      data.append("PurposeID", this.PurposeID);
      data.append("SpecificFundSourceID", this.SpecificFundSourceID);
      data.append("FiscalYearID", this.fiscalYear);
              this.axiosCall(
              '/signatories/pr',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.prList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
        }
      },
    }
  }
</script>   