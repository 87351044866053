<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="4">
        <HelpIAR />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HelpIAR from "./Help-PSU-IAR.vue";

export default {
  components: {
    HelpIAR,
  },
};
</script>
