<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">LIB Items</h2>
      <v-card class="mt-4">
      <LIBList/>
  </v-card>
  </div>
</template>

<script>
import LIBList from '@/components/Utils/LIBList.vue'
  export default {
    components: {
      LIBList
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>