<template>
<div>
  <v-data-table
    :headers="headers"
    :items="userList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="mr-2"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="my-2"
              @click="Dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="viewItem(item)">
          mdi-eye
        </v-icon>
        </span>
      </template>
  </v-data-table>
  <v-dialog v-model="editDialog"
          max-width="600px"
          persistent
        >
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green darken-2"
              dark
              rounded
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
              Add New Account
            </v-btn>
          </template> -->
          <v-card>
            <v-card-title>
              <span class="headline">User Account Details</span>
              <v-spacer></v-spacer>
              <v-btn
                icon
                color="secondary2"
                @click="editDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text>
                <v-form @submit.prevent ref="formRef">
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <v-text-field
                      v-model="formData.email"
                      label="Email Address"
                      color="header"
                      dense
                        :rules="[formRules.required, formRules.email, formRules.emaildnsc]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="formData.FirstName"
                       color="header"
                       :rules="[formRules.required]"
                       dense
                      label="First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="formData.MiddleName"
                       color="header"
                       :rules="[formRules.required]"
                      label="Middle Name"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                      v-model="formData.LastName"
                       :rules="[formRules.required]"
                      color="header"
                      label="Last Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                                v-model="formData.DesignationID"
                                :items="designation"
                                item-text="DesignationDescription"
                                item-value="DesignationID"
                                 :rules="[formRules.required]"
                                dense
                                color="header"
                                label="Designation"
                            ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                                v-model="formData.OfficeID"
                                :items="office"
                                item-text="OfficeDescription"
                                 :rules="[formRules.required]"
                                item-value="OfficeID"
                                color="header"
                                dense
                                label="Office">
                    </v-autocomplete>
                  </v-col>
                      <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                                v-model="formData.DeliveryUnitID"
                                :items="deliveryunit"
                                item-text="DeliveryUnitDescription"
                                 :rules="[formRules.required]"
                                item-value="DeliveryUnitID"
                                color="header"
                                dense
                                label="Deliver Unit">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <v-autocomplete
                                v-model="formData.roleID"
                                item-text="RoleDescription"
                                item-value="RoleID"
                                 :rules="[formRules.required]"
                                :items="roles"
                                color="header"
                                dense
                                label="Access Control"
                            ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col cols="12" class="text-subtitle-1">
                    Sub Access Controls:
                  </v-col>
                  <v-col cols="12" v-if="!formData.roleID" class="text-center">
                    <i>No access control selected</i>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="formData.roleID">
                  <v-col
                    cols="12"
                    md="6"
                  >
                   <v-checkbox
                   dense
                      v-model="formData.IsRA"
                      label="Recommending Approval"
                      color="header"
                    ></v-checkbox>
                  </v-col>
                    <v-col
                    cols="12"
                    md="6"
                  >
                    <v-checkbox
                    dense
                      v-model="formData.IsFM"
                      label="Fund Manager"
                      color="header"
                      v-if="formData.roleID != 2"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-checkbox
                    dense
                      v-model="formData.BACaccess"
                      label="BAC Access"
                      color="header"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="formData.roleID != 3"
                  >
                   <v-checkbox
                   dense
                      v-model="formData.IsPA"
                      color="header"
                      label="Person Accountable"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                   <v-checkbox
                   dense
                      v-model="formData.IsDAS"
                      color="header"
                      label="DAS Access"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                  <v-btn outlined class="mr-1" color="grey darken-2" @click="editDialog = false">Cancel</v-btn>
                  <v-btn dark color="green darken-1" :loading="isLoading" @click="save()"><v-icon class="mr-1">mdi-check-circle</v-icon> Save</v-btn>
            </v-card-actions>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="ViewUser = false"
              >
                Close
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
    <ViewUser :data="viewData" />
  </div>
</template>

<script>
  export default {
    components: {
      ViewUser: () => import("./ViewUser.vue"),
    },
    data: () => ({
      searchText: '',
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
      options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      formData: {},
      Dialog: false,
      userList: [],
      headers: [
        { text: 'Last Name', value: 'LastName', class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'First Name', value: 'FirstName', class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Middle Name', value: 'MiddleName', class: "green darken-2 white--text text-caption font-weight-bold", },
        {
          text: 'Email Address',
          align: 'start',
          value: 'email',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'Role', value: 'RoleDescription', class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Actions', value: 'Actions', sortable: false, width: '100', class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      viewData: [],
      editDialog: false,
      roles: [],
      designation: [],
      deliveryunit: [],
      office: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    created() {
      this.getUtilities();
    },
    mounted(){
      this.eventHub.$on('closeViewUserModal', ()=>{
        this.initialize();
      });
    },
    beforeDestroy() {
      this.eventHub.$off('closeViewUserModal');
    },
    methods: {
      getUtilities() {
      this.axiosCall(
              '/getPositionDesignationOfficeOutside',
              'GET'
          )
          .then((res) => {
              this.office = res.data.data.office
              this.deliveryunit = res.data.data.deliveryunit
              this.designation = res.data.data.designation
              this.roles = res.data.data.role
          })
		},
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
         data.append("searchText", this.searchText);
              this.axiosCall(
              '/getUserList',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.userList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
    editItem(data) {
      this.editItemId = data.UserID;
      this.formData = data;
      // console.log(this.formData)
      this.editDialog = true;
		},
    viewItem(data) {
      this.viewData = data;
		},
    save () {
        if(this.$refs.formRef.validate()){
          this.isLoading = true;
             let data = new FormData();
                data.append('data', JSON.stringify(this.formData));
                this.axiosCall(
                        '/account/save',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'User details successfully saved!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.editDialog = false;
                        this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
        }
      },
    }
  }
</script>