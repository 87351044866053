<template>
  <div>
    <v-card class="mx-1" max-width="344" elevation="2" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Help - PPMP Printing</div>
          <v-list-item-title class="text-h5 mb-1">
            How to Print PPPMP
          </v-list-item-title>
          <v-list-item-subtitle
            >A step-by-step instruction on How to Print your
            PPMP.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="green"
          rounded
          class="white--text items-center"
          @click="overlay = !overlay"
        >
          Learn How
          <v-icon right dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-card class="mx-1 relative" elevation="2" outlined color="white">
        <div class="scroll">
          <div
            class="
              text-overline
              px-4
              py-2
              black--text
              fixedElement
              white
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>help on PPMP Printing - How to Print your PPMP</div>
            <v-card-actions>
              <v-btn
                depressed
                color="green"
                rounded
                class="white--text items-center"
                @click="overlay = !overlay"
              >
                Close
                <v-icon right dark> mdi-close </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-list-item three-line>
            <v-list-item-content class="px-4">
              <!-- <v-list-item-title class="text-h5 mb-1 black--text">
              How to Create PR
            </v-list-item-title> -->
              <v-list-item-subtitle class="black--text"
                >1. Upon logging in, on the left side of the screen click on the
                PPMP Creation tab.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPprint1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. Upon clicking the tab, a list of your PPMP will show on the
                table. Click on the view button under the actions column to view
                your PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPprint2.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. Upon clicking the view button, a pop-up modal will show
                which will show you the details of your
                PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPprint3.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. To print your PPMP, Click on the “PRINT”
                button.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPprint4.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >5. Upon clicking the “PRINT” button, a new tab will open on
                your web browser which will be your PPMP Report
                view.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPprint5.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >6. You can save the PPMP as PDF or print it directly to your
                printer.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPprint6.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >7. You have successfully printed your PPMP. Repeat the process
                to print another PPMP.</v-list-item-subtitle
              >

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalPPMP: false,
      overlay: false,
    };
  },
  methods: {
    showPPMP() {
      this.modalPPMP = !this.modalPPMP;
    },
  },
};
</script>

<style scoped>
.papel {
  z-index: 2;
}
.scroll {
  width: 1000px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.fixedElement {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>