<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">Fiscal Year</h2>
      <v-card class="mt-4">
      <FiscalYear/>
  </v-card>
  </div>
</template>

<script>
import FiscalYear from '@/components/Utils/FiscalYear.vue'
  export default {
    components: {
      FiscalYear
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>