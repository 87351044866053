<template>
<div>
  <v-data-table
    :headers="headers"
    :items="fundList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <!-- <v-toolbar-title>Item Utility</v-toolbar-title> -->
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="my-2"
              @click="addDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Add New Fund
            </v-btn>
      </v-toolbar>
    </template>
        <template v-slot:[`item.Allocation`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Allocation || 0) }}</span>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Balance || 0) }}</span>
        </template>
    <template v-slot:[`item.Actions`]="{ item }">
      <!-- <v-icon
        small
        class="mr-2"
      >
        mdi-eye
      </v-icon> -->
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <!-- <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon> -->
    </template>
    <template v-slot:no-data>
      No Funds Created Yet
    </template>
  </v-data-table>
<!----------Add new Fund-------------------->
        <v-dialog
          v-model="addDialog"
          persistent
          max-width="350px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">Update Fund</span>
              <span class="subtitle-1 font-weight-bold" v-else>Allocate New Fund</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                    dense
                     v-model="formData.FundCode"
                     :rules="[formRules.required]"
                      label="Fund Code"
                    ></v-text-field>
                  </v-col>
                  <v-col
                     cols="12"
                    sm="12"
                    md="12"
                  >
                     <v-text-field
                     type="number"
                    dense
                     v-model="formData.Allocation"
                     :rules="[formRules.required, formRules.noNegativeNumberAllowed, formRules.numberRequired]"
                      label="Alloted Amount"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="formData.FiscalYearID"
                      :rules="[formRules.required]"
                      :items="fiscalyears"
                      auto-select-first
                      item-text="fiscalYear"
                      item-value="id"
                      color="header"
                      dense
                      label="Fiscal Year"
                      class="mt-2"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
                       <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                text
                @click="save"
                :loading="isLoading"
              >
                Add
              </v-btn>
              <v-btn
              v-else
                color="red darken-1"
                text
                @click="update"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      editItemId: null,
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu: false,
      formData: {
         Allocation: null,
         FundCode: null,
         FiscalYearID: null
      },
      addDialog: false,
      fundList: [],
      headers: [
        {
          text: 'Fund Code',
          align: 'start',
          sortable: false,
          value: 'FundCode',
           class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Year', value: 'fiscalYear',sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Allocated Amount', value: 'Allocation', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Available Balance', value: 'Balance', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      fiscalyears: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    created () {
      this.getFiscalYears();
      this.initialize();
    },
    methods: {
      editItem(data) {
      this.editItemId = data.FundID;
      this.formData.FundCode = data.FundCode;
      this.formData.Allocation = data.Allocation;
      this.formData.FiscalYearID = data.FiscalYearID;

      this.addDialog = true;
		},
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.addDialog = false;
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
              this.axiosCall(
              '/getAllFunds',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.fundList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
      save () {
         if(this.$refs.formRef.validate()){
                     this.isLoading = true;
             let data = new FormData();
              data.append('Allocation', this.formData.Allocation);
              data.append('FundCode', this.formData.FundCode);
              data.append('FiscalYearID', this.formData.FiscalYearID);
                         this.axiosCall(
                        '/addFund',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Fund Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addDialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = res.data.message;
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
              this.isLoading = true;
             let data = new FormData();
                data.append('FundID', this.editItemId);
               data.append('Allocation', this.formData.Allocation);
              data.append('FundCode', this.formData.FundCode);
              data.append('FiscalYearID', this.formData.FiscalYearID);
                         this.axiosCall(
                        '/updateFund',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Fund Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.editItemId = null;
                        this.addDialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = res.data.message;
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = res.data.data
      });
    },
    }
  }
</script>