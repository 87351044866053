<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ppmpList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-row class="mx-2" no-gutters>
          <v-col cols="12">
            <v-tabs v-model="app_status" class="mt-2" color="header">
              <v-tab>Pending</v-tab>
              <v-tab>Done</v-tab>
              <v-tab>Unpublished</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="12" sm="6">
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search for Budget Code"
            color="green darken-2"
            class="mb-3 mr-5"
            single-line
            hide-details
          ></v-text-field>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
            v-model="app_type"
            item-text="desc"
            item-value="id"
            :items="ppmptypes"
            hide-details
            class="ml-5"
            color="header"
            label="Filter by Types"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
            v-model="app_fund"
            item-text="FundCode"
            item-value="FundID"
            :items="funds"
            hide-details
            class="ml-5"
            color="header"
            label="Filter by Funds"
          >
          </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.Amount || 0) }}</span>
      </template>
      <template v-slot:[`item.BudgetDate`]="{ item }">
        {{ item.DateStart }} - {{ item.DateEnd }}
      </template>
      <template v-slot:[`item.DateApproved`]="{ item }">
        {{ formatDate(item.PresidentApprovedDate) }}
      </template>
      <template v-slot:[`item.PPMPStatusID`]="{ item }">
        <span v-if="item.PPMPStatusID == 12">Published</span>
      </template>
      <template v-slot:[`item.PPMPTypeID`]="{ item }">
        <span class="text-no-wrap" v-if="item.PPMPTypeID == 1"><small>DBM</small></span>
        <span class="text-no-wrap" v-if="item.PPMPTypeID ==2"><small>NON-DBM</small></span>
        <span class="text-no-wrap" v-if="item.PPMPTypeID == 3"><small>LIB</small></span>
      </template>
      <template v-slot:[`item.other_actions`]="{ item }">
        <v-btn
          x-small
          outlined
          color="header"
          dark
          class="mr-2"
          @click="setAsDone(item)"
          v-if="item.APPStatus == 1 || item.APPStatus == 3"
        >
          SET AS DONE
        </v-btn>
        <v-btn text color="header" small v-if="item.APPStatus == 2">
          <v-icon text="">mdi-check</v-icon> Done
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          x-small
          outlined
          color="blue darken-2"
          dark
          class="mr-2"
          @click="viewPPMP(item)"
        >
          VIEW
        </v-btn>
      </template>
    </v-data-table>
    <ppmpModlal :ppmpData="ppmpItem" />
    <v-dialog v-model="dialogDone" max-width="500px">
      <v-card>
        <v-card-title class="headline mt-5">Confirmation</v-card-title>
        <div class="mx-6">
          <strong>Are you sure you set this PPMP as done?</strong> <br />
          <b>Budget Code: </b> {{itemForDone.BudgetCode}}
        </div>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" outlined @click="dialogDone = false">Cancel</v-btn>
          <v-btn color="header darken-2 white--text" @click="confirmDone()"><v-icon>mdi-check</v-icon> Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    ppmpModlal: () => import("./PPMPAddItem"),
  },
  props: {
    fiscalYear: null,
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    addPPMPDialog: false,
    ppmpList: [],
    ppmpItem: [],

    headers: [
      {
        text: "Budget Code",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PPMP Description",
        value: "PPMPDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Budget",
        value: "Amount",
        sortable: false,
        align: "end",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Budget Date",
        value: "BudgetDate",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Date Approved",
        value: "DateApproved",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Status",
        value: "PPMPStatusID",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PPMP Type",
        value: "PPMPTypeID",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "",
        value: "other_actions",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    app_status: 0,
    funds: [{FundID: 0, FundCode: "ALL"}],
    ppmptypes: [
      {id: 0, desc: "ALL"},
      {id: 1, desc: "DBM"},
      {id: 2, desc: "NON-DBM"},
      {id: 3, desc: "LIB"},
      {id: 4, desc: "REVISED"},
    ],
    app_fund: 0,
    app_type: 0,
    itemForDone: [],
    dialogDone: false,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    app_status: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    app_fund: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    app_type: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.getFundList();
    this.eventHub.$on("closePPMPModal", () => {
      this.ppmpItem = {};
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closePPMPModal");
  },
  methods: {
    viewPPMP(item) {
      this.ppmpItem = item;
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("APPStatus", this.app_status + 1);
      data.append("FundID", this.app_fund);
      data.append("PPMPTypeID", this.app_type);
      this.axiosCall("/ppmp/approve/app", "POST", data).then((res) => {
        this.loading = false;
        this.ppmpList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getFundList() {
    this.axiosCall(
            '/get/getFundCodes',
            'GET'
        )
        .then((res) => {
          this.funds.push(...res.data.data);
        })
		},
    setAsDone(item){
      this.itemForDone = item;
      this.dialogDone = true;
    },
    confirmDone() {
      let data = new FormData();
      data.append("PPMPID", this.itemForDone.PPMPID);
      this.axiosCall(
              '/ppmp/approve/done',
              'POST',
              data
          )
          .then((res) => {
            if(res.status){
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "success";
              this.fadeAwayMessage.header = "System Message";
              this.fadeAwayMessage.message = "PPMP was successfully set as DONE.";
              this.dialogDone = false;
              this.initialize();
            }
            else{
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.header = "System Message";
              this.fadeAwayMessage.message = "Something went wrong!";
            }
          })
      },
  },
};
</script>