<template>
  <div class="DarkWhite pa-4">
    <v-row>
    <v-col cols="12" sm="6" md="8" lg="10">
        <h2 class="subheading green--text">Transaction Monitoring</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-autocomplete
          v-model="fiscalYear"
          :items="fiscalyears"
          auto-select-first
          item-text="fiscalYear"
          item-value="id"
          color="header"
          dense
          label="Fiscal Year"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div>
      <v-data-table
        :headers="headers"
        :items="budgetMonitoringData"
        :options.sync="options"
        :server-items-length="totalCount"
        :loading="loading"
        :footer-props="footerProps"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div class="mr-4">
              Search for a Code:
            </div>
            <v-text-field
              style="max-width: 300px"
              v-model="searchText"
              append-icon="mdi-magnify"
              placeholder="Search for a Code"
              color="green darken-2"
              class="mr-2"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    date_menu1: false,
    date_menu2: false,
    date_start: "",
    date_end: "",
    filter_pr: false,
    filter_po: false,
    filter_iar: false,
    checkbox: true,
    value: [0, 1, 5, 0, 0, 2, 3, 1, 0, 0, 2, 5],
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    addPPMPDialog: false,
    budgetMonitoringData: [],
    headers: [
      {
        text: "PPMP Budget Code",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      // {
      //   text: "PPMP Budget Date",
      //   align: "start",
      //   sortable: false,
      //   value: "BudgetCode",
      //   class: "green darken-2 white--text text-caption font-weight-bold",
      // },
      {
        text: "PPMP Description",
        value: "PPMPDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Code",
        value: "PR_CODE",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Date",
        value: "PRDateCreated",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PO Code",
        value: "POCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PO Date",
        value: "PODateCreated",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Date Delivered",
        align: "start",
        sortable: false,
        value: "DateDelivered",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "IAR Code",
        value: "IARCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "IAR Date",
        value: "IRARDateCreated",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Date Received",
        value: "DateReceived",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Status",
        value: "Status",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    fiscalYear: null,
    fiscalyears: [],
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    date_start: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    date_end: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    filter_pr: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    filter_po: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    filter_iar: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.getFiscalYears();
    this.eventHub.$on("closePPMPModal", () => {
      this.ppmpItem = {};
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closePPMPModal");
  },
  methods: {
    viewPPMP(item) {
      this.ppmpItem = item;
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      this.getBudgetMonitoring();
      }
    },
    getBudgetMonitoring() {
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("date_start", this.date_start);
      data.append("date_end", this.date_end);
      data.append("filter_pr", this.filter_pr ? 1 : 0);
      data.append("filter_po", this.filter_po ? 1 : 0);
      data.append("filter_iar", this.filter_iar ? 1 : 0);
      data.append("fiscalYear", this.fiscalYear);
      this.axiosCall("/dashboard/budgetMonitoring", "POST", data).then(
        (res) => {
          this.loading = false;
          this.budgetMonitoringData = res.data.data.result;
          this.totalCount = res.data.data.total;
          if (this.totalCount <= 10) {
            this.options.page = 1;
          }
        }
      );
    },
    printTransactions() {
      let pr = this.filter_pr ? 1 : 0;
      let po = this.filter_po ? 1 : 0;
      let iar = this.filter_iar ? 1 : 0;
      let date_start = this.date_start != "" ? this.date_start : 0;
      let date_end = this.date_end != "" ? this.date_end : 0;
      let searchText = this.searchText != "" ? this.searchText : 0;
      window.open(
        this.apiUrl +
          "/transaction/report/" +
          searchText +
          "/" +
          date_start +
          "/" +
          date_end +
          "/" +
          pr +
          "/" +
          po +
          "/" +
          iar
      );
    },
    resetFilter() {
      this.searchText = "";
      this.date_start = "";
      this.date_end = "";
      this.filter_pr = false;
      this.filter_po = false;
      this.filter_iar = false;
    },
    getFiscalYears() {
      this.fiscalyears = [{id: 0, fiscalYear: "All"}]
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    }, 
  },
};
</script>