<template>
  <div>
    <v-card class="mx-1" max-width="344" elevation="2" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Help - PSU Office</div>
          <v-list-item-title class="text-h5 mb-1">
            How to Create IAR
          </v-list-item-title>
          <v-list-item-subtitle
            >A step-by-step instruction on How to create
            IAR.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="green"
          rounded
          class="white--text items-center"
          @click="dialog = !dialog"
        >
          Learn How
          <v-icon right dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
<v-dialog v-model="dialog"
      width="1000">
      <v-card>
        <div class="scroll">
          <div
            class="
              text-overline
              px-4
              py-2
              black--text
              fixedElement
              white
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>help on psu office - How to create IAR</div>
            <v-card-actions>
              <v-btn
                depressed
                color="green"
                rounded
                class="white--text items-center"
                @click="dialog = !dialog"
              >
                Close
                <v-icon right dark> mdi-close </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-list-item three-line>
            <v-list-item-content class="px-4">
              <!-- <v-list-item-title class="text-h5 mb-1 black--text">
              How to Create PR
            </v-list-item-title> -->
              <v-list-item-subtitle class="black--text"
                >1. On the left side of the screen, click on the Approved PO
                tab.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. Upon clicking the Approved PO tab, a list of Approved PO
                will appear on the table.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR2.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. Click on the VIEW button to view the full details of the PO
                or click on the IAR button to create an IAR on the specific
                PO.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR3.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. After clicking the IAR button, a pop-up modal will show
                which contains the full details of the PO.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR4.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >5. To update the description of the item, click on the items
                description then a cursor pointer will
                appear.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR5.png"
                  height="auto"
                  width="800"
                />
              </div>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR5.1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >6. Click on the CANCEL button to cancel the editing or click
                SAVE to save the changes.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR6.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >7. Click the unit cost of the item to edit its unit
                cost.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR7.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >8. Click on the CANCEL button to cancel the editing or click
                SAVE to save the changes.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR8.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >9. If the delivery of the item is less than the quantity
                indicated on the PO, you can customize the quantity by clicking
                the quantity of the item then a pop-up text field in which you
                will encode the specific quantity that has been
                delivered..</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR9.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >10. After customizing the items details based on the delivery
                receipt or the invoice you have received, tick on the checkbox
                of the items you wanted to include on your
                IAR.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR10.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >11. After ticking the checkbox, the IAR button on the right
                side of the screen will now be available to click. Click on the
                IAR button and then a pop-up modal will show in which you will
                encode the details of your IAR.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR11.png"
                  height="auto"
                  width="800"
                />
              </div>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR11.1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >12. Encode the Invoice number on the text field, select on the
                date picker the date of the invoice, select on the date picker
                the date start of the inspection and the date end of the
                inspection, select on the drop down the name of the receiver of
                the specific IAR, tick on the checkbox if inspected, select on
                the radio button if the delivery is complete or partial, and
                lastly, select on the date picker the date of delivery of the
                said item. Click on the CANCEL button to cancel the creation or
                click CONTINUE to continue on creating the
                IAR.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR12.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >13. Upon clicking the CONTINUE button, you will be redirected
                to another page in which a finish IAR will
                show.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR13.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >14. You can review here the IAR, you can click the download
                button to download the file or the print button to print the
                file.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR14.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >15. You have successfully created an IAR.</v-list-item-subtitle
              >

              <v-list-item-subtitle class="black--text mt-8"
                >16. To check on the list of the created IAR’s, click on the IAR
                tab on the left side of the screen. A list of created IAR’s will
                appear on the Table.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR16.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >17. To view on the details of the IAR, click on the VIEW
                button, a pop-up modal will show.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR17.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >

              <div
                class="
                  text-overline
                  px-4
                  py-2
                  black--text
                  white
                  d-flex
                  justify-space-between
                  align-center
                "
              >
                <div>help on psu office - How to edit IAR</div>
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >1. On the left side of the screen, click on the IAR management
                tab to see the list of the IAR’s that has been created.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR18.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. Click on the VIEW button to view the full details of the
                IAR. Click on the edit IAR button to edit the said IAR.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR19.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. Upon clicking the edit IAR button, a pop-up button will
                appear in which you will encode the changes needed of the said
                IAR. Click on the specific fields that you needed to edit.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR20.png"
                  height="auto"
                  width="800"
                />
              </div>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/IAR21.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. After editing the IAR, click on the CANCEL button to cancel
                the editing or click on the CONFIRM button to confirm the
                editing.</v-list-item-subtitle
              >

              <v-list-item-subtitle class="black--text mt-8"
                >5. After clicking the confirm button, you now have successfully
                updated the details of the IAR.</v-list-item-subtitle
              >

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalPPMP: false,
      overlay: false,
      dialog: false,
    };
  },
  watch: {
    data: {
      handler() {
        this.dialog = true
      },
      deep: true,
    },
  },
  methods: {

  },
};
</script>

<style scoped>
.scroll {
  width: 1000px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.fixedElement {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>