<template>
<div>
  <v-data-table
    :headers="headers"
    :items="priceInjectionList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <!-- <v-toolbar-title>Item Utility</v-toolbar-title> -->
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="my-2"
              @click="Dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Items`]="{ item }">
        {{ item.Items ? getItemNames(JSON.parse(item.Items)) : 'All' }}
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.DateUpdated`]="{ item }">
        {{ formatDate(item.DateUpdated) }}
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
  </v-data-table>
        <v-dialog
          v-model="Dialog"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">Update Price Injection</span>
              <span class="subtitle-1 font-weight-bold" v-else>New Price Injection</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                    dense
                     v-model="formData.Description"
                     :rules="[formRules.required]"
                     counter
                     maxlength="250"
                      label="Description"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                     class="mb-5"
                  >
                  <span>Percentage</span>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-slider
                      v-model="formData.Percentage"
                      step=".1"
                      color="header"
                      thumb-label="always"
                      thumb-color="header"
                      track-color="header"
                    ></v-slider>
                    <v-text-field
                        dense
                        v-model="formData.Percentage"
                        :rules="[formRules.required]"
                        color="green"
                        type="number"
                      ></v-text-field>
                  </v-col>
                            <v-col cols="12">
                    <v-checkbox
                      v-model="formData.isSelected"
                      label="Selected Items"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" v-if="formData.isSelected">
               <v-autocomplete
              v-model="formData.Items"
              :items="itemList"
              chips
              item-text="ItemName"
              item-value="ItemID"
              :rules="[formRules.required]"
              color="blue-grey lighten-2"
              label="Items"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item.ItemName }}
                </v-chip>
              </template>
            </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                text
                @click="save"
                :loading="isLoading"
              >
                Save
              </v-btn>
              <v-btn
              v-else
                color="red darken-1"
                text
                @click="update"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      amountErr: null,
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
      options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu1: false,
      menu2: false,
      menu3: false,
      formData: {
        Description: null,
        Percentage: null,
        isSelected: false,
        Items: [],
      },
      Dialog: false,
      priceInjectionList: [],
      headers: [
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'Description',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Percentage',
          align: 'start',
          sortable: false,
          value: 'Percentage',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Items',
          align: 'start',
          sortable: false,
          value: 'Items',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Updated',
          align: 'start',
          sortable: false,
          value: 'DateUpdated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      itemList: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    created () {
      this.getAllItems();
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
              this.axiosCall(
              '/getPriceInjection',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.priceInjectionList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
    editItem(data) {
      this.editItemId = data.PriceInjectionID;
      this.formData.Description = data.Description;
      this.formData.Percentage = data.Percentage;
      this.formData.isSelected = data.isSelected;
      this.formData.Items = JSON.parse(data.Items);
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
             
             this.isLoading = true;
             let data = new FormData();
              data.append('Description', this.formData.Description);
              data.append('Percentage', this.formData.Percentage);
              data.append('isSelected', this.formData.isSelected ? 1 : 0);
              data.append('Items', this.formData.isSelected ? JSON.stringify(this.formData.Items) : null);
                         this.axiosCall(
                        '/priceInjection/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
              this.isLoading = true;
              let data = new FormData();
              data.append('PriceInjectionID', this.editItemId);
              data.append('Description', this.formData.Description);
              data.append('Percentage', this.formData.Percentage);
              data.append('isSelected', this.formData.isSelected ? 1 : 0);
              data.append('Items', this.formData.isSelected ? JSON.stringify(this.formData.Items) : null);
                         this.axiosCall(
                        '/priceInjection/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      remove (item) {
        const index = this.formData.Items.indexOf(item)
        if (index >= 0) this.formData.Items.splice(index, 1)
      },
      getAllItems(){
        this.budgetCodeList = [];
             this.axiosCall(
              '/priceInjection/getAllItems',
              'POST',
              []
          )
          .then((res) => {
              this.itemList = res.data.data.result
          })
      },
      getItemNames(data){
        let res = [];
        data.forEach(item => {
          this.itemList.forEach(item2 => {
            if(item2.ItemID == item){
              res.push(item2.ItemName);
            }
          });
        });
        return res.join(', ')
      }
    }
  }
</script>