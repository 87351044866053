<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="parList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-tabs v-model="status" color="header">
          <v-tab
            v-for="tab of status_tabs"
            :key="tab.id"
          >
          {{tab.desc}}
          </v-tab>
        </v-tabs>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search PAR number"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            color="green"
            v-model="userID"
            item-text="name"
            item-value="UserID"
            :items="userList"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By User"
          >
          </v-autocomplete>
          <v-autocomplete
            color="green"
            v-model="typeID"
            item-text="description"
            item-value="id"
            :items="doc_types"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By Type"
          >
          </v-autocomplete>
          <v-autocomplete
            color="green"
            v-model="remarksID"
            item-text="description"
            item-value="id"
            :items="remarksList"
            dense
            hide-details
            class="mr-2 mt-2"
            style="max-width:200px;"
            label="Filter By Remarks"
          >
          </v-autocomplete>
        </v-toolbar>
      </template>
      <template v-slot:[`item.PARCODE_old`]="{ item }">
        <span>{{ item.PARCODE_old ? item.PARCODE_old : 'N/A'}}</span>
      </template>
      <template v-slot:[`item.FundSource`]="{ item }">
        {{ item.FundCode }} - {{ item.SpecificFundSourceDescription }}
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.totalAmount) }}</span>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.Other_Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          color="header darken-2"
          dark
          outlined
          elevation="2"
          x-small
          class="mr-2"
          @click="approvePAR(item)"
          v-if="status == 0 || status == 1"
        >
          Approve
        </v-btn>
        <v-btn
          color="red darken-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="pendingPAR(item)"
          v-if="status == 0"
        >
          Pending
        </v-btn>
        </span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewPAR(item)"
          >
            View
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printPAR(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogPARType" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Please select PAR type:</v-card-title>
        <v-card-text>
          <v-row justify="center" class="mt-2">
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple</v-icon
              ><br />
              <v-btn color="green darken-2" @click="supply()" text
                >Supply</v-btn
              >
            </v-col>
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple-outline</v-icon
              ><br />
              <v-btn color="green darken-2" @click="accounting()" text
                >Accounting</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogPARType = false"
                >Cancel</v-btn
              >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approveDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to approve this Property Acknowledgement Receipt?
            <br /><br />
            <div>PAR #: <strong>{{approveData.PARCODE}}</strong></div>
            <div>Purpose: <strong>{{approveData.PARPurpose}}</strong></div>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="approveDialog = false">Cancel</v-btn>
            <v-btn color="header darken-2 white--text" @click="confirmApprovePAR()"><v-icon class="mr-1">mdi-check-circle</v-icon>Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="pendingDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to pending this Property Acknowledgement Receipt?
            <br /><br />
            <div>PAR #: <strong>{{pendingData.PARCODE}}</strong></div>
            <div>Purpose: <strong>{{pendingData.PARPurpose}}</strong></div>
            <br />
            <v-form ref="formRef">
            <v-textarea
              color="green"
                dense
                v-model="formData.Remarks"
                :rules="[formRules.required]"
                label="Remarks"
                counter
                outlined
                maxlength="500"
                ></v-textarea>
            </v-form>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="pendingDialog = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmPendingPAR()"><v-icon class="mr-1">mdi-clock-outline</v-icon>Pending</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <PARModal :parData="parData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    PARModal: () => import("./ViewPAR.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    parList: [],
    parData: [],
    headers: [
      {
          text: 'PAR CODE',
          align: 'start',
          sortable: false,
          value: 'PARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'OLD PAR CODE',
          align: 'start',
          sortable: false,
          value: 'PARCODE_old',
          width: 120,
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'PARPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Fund Source',
          align: 'start',
          sortable: false,
          value: 'FundSource',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: '',
          align: 'center',
          sortable: false,
          value: "Other_Actions",
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', width: '140', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      dialogPARType: false,
      parType: 1,
      itemToPrint: [],
      status_tabs: [
        {id: 1, desc: 'For Approval'},
        {id: 3, desc: 'Pending'},
        {id: 2, desc: 'Approved'},
        {id: 4, desc: 'Cancelled'},
      ],
      status: 0,
      approveDialog: false,
      pendingDialog: false,
      approveData: [],
      pendingData: [],
      formData: {
        Remarks: '',
      },
      remarksList: [
        {id: 0, description: "All"},
        {id: 1, description: "First"},
        {id: 2, description: "Renewed"},
        {id: 3, description: "Transferred"},
      ],
      remarksID: 0,
      userList: [],
      userID: 0,
      doc_types: [
        { id: 0, description: "All" },
        { id: 1, description: "Systemized" },
        { id: 2, description: "Old (Uploaded files)" },
      ],
      typeID: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    status: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    remarksID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    userID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    typeID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeViewPAR", () => {
      this.initialize();
    });
    this.getUsers();
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewPAR");
  },
  methods: {
    viewPAR(item) {
      this.parData = {...item, status_prop: this.status};
      // console.log(item)
    },
    printPAR(item) {
      window.open(this.apiUrl + "/par/report/" + item.PARID);
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("status", this.status + 1);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("Admin", 1);
      data.append("RemarksID", this.remarksID);
      data.append("UserID", this.userID);
      data.append("TypeID", this.typeID);
      this.axiosCall("/getPARListPerStatus", "POST", data).then((res) => {
        this.loading = false;
        this.parList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    approvePAR(data){
      this.approveData = data;
      this.approveDialog = true;
    },
    pendingPAR(data){
      this.pendingData = data;
      this.pendingDialog = true;
    },
    confirmApprovePAR(){
      let data = new FormData();
      data.append("PARID", this.approveData.PARID);
      data.append("Admin", 1);
      this.axiosCall("/par/approve", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "PAR was successfully approved.";
            this.fadeAwayMessage.header = "System Message";
            this.approveDialog = false;
            this.approveData = [];
            this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    confirmPendingPAR(){
      if(this.$refs.formRef.validate()){
      let data = new FormData();
      data.append("PARID", this.pendingData.PARID);
      data.append("Remarks", this.formData.Remarks);
      data.append("Admin", 1);
      this.axiosCall("/par/pending", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "PAR was successfully in pending status.";
            this.fadeAwayMessage.header = "System Message";
            this.pendingDialog = false;
             this.pendingData = [];
             this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
    getUsers() {
      this.axiosCall("/getAllPersonsAccountable", "GET").then((res) => {
        this.userList = [{UserID: 0, name: "All"},...res.data.data];
      });
    },
  },
};
</script>