<template>
<div>
    <v-dialog
      v-model="privacy_policy"
      persistent
      width="550"
    >
      <v-card>
        <v-card-title class="text-h5">
          Data Privacy Act of 2012
        </v-card-title>
        <v-card-text class="pp-content">
            <p>
            <b>Data Privacy Act of 2012</b>, I consent to the following terms and conditions on the collection, use, processing and disclosure of my personal data: I am aware that the <b>Davao del Norte State College</b> has collected and stored my personal data upon accomplishment of this form. These data include my full name, contact details like addresses, and landline/mobile numbers. I express my consent for the <b>Davao del Norte State College</b> to collect, store my personal information. I hereby affirm my right to be informed, object to processing, access, and rectify and to suspend or withdraw my personal data pursuant to the provisions of the <span class="ra-text" @click="goToRA10173()">RA 10173</span> and its implementing rules and regulations.
            </p>
            <p>
                By clicking the <b>Agree</b> button below, I warrant that I have read, understood all of the above provisions, and agreed with its full implementation.
            </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            class="white--text"
            outlined
            @click="disagree()"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            class="white--text"
            @click="privacy_policy = false"
          >
          <v-icon class="mr-1">mdi-thumb-up</v-icon>
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  <v-container v-if="done" fluid fill-height class="content">
    <v-row align="center" justify="center">
     <v-card
      class="d-flex justify-center align-center mt-10"
      flat
      height="500"
      tile
    >
      <v-card flat>
        <v-card-title>
         <v-img src="../assets/custom icons/in-review.png" height="400" width="200" contain>
                            </v-img>
        </v-card-title>
        <v-card-title  class="d-flex justify-center">Your Account is being Reviewed</v-card-title>
        <v-card-text class="d-flex justify-center">
          A confirmation message will be sent to your email shortly.
        </v-card-text>
        <v-card-text class="d-flex justify-center mt-5">
          <v-img src="../assets/PSMIS_LOGO.png" height="50" contain>
                            </v-img>
        </v-card-text>
      </v-card>
    </v-card>
    </v-row>
    </v-container>
  <v-container fluid v-else>
    <v-row class="justify-center">
      <v-row
        align="center"
        justify="center" 
        style="height:95vh"
        dense>
        <!-- SignUp Part1 -->
            <v-col v-if="isPart1" cols="12" class="lighten-2 fill-height d-flex flex-column justify-center align-center">
                <v-card flat tile class="white rounded-lg px-4" width="400px">
                    <v-card-title class="justify-center pt-7 px-7"><h2>Sign Up</h2></v-card-title>
                    <div class="d-flex justify-center text-caption mt-n3 grey--text lighten-1">Account Registration (1/3)</div>
                    <v-form @submit.prevent ref="part1Formref">
                    <v-row class="mx-5 pt-6">
                        <v-col
                            cols="12"
                            class="pa-0"
                            >
                            <v-text-field
                            color="green"
                                class="text-caption"
                                v-model="registerData.firstname"
                                 :rules="[formRules.required]" 
                                label="First Name"
                                prepend-inner-icon="mdi-account-outline"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0"
                            >
                            <v-text-field
                            color="green"
                                class="text-caption"
                                v-model="registerData.middlename"
                                label="Middle Name"
                                prepend-inner-icon="mdi-account-outline"
                                 :rules="[formRules.required]" 
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0"
                            >
                            <v-text-field
                            color="green"
                                class="text-caption"
                                v-model="registerData.lastname"
                                :rules="[formRules.required]" 
                                label="Last Name"
                                prepend-inner-icon="mdi-account-outline"
                            ></v-text-field>
                        </v-col>
                         <v-col
                            cols="12"
                            class="pa-0"
                            >
                            <v-text-field
                            color="green"
                                class="text-caption"
                                v-model="registerData.educQuali"
                                label="Educational Qualification"
                                prepend-inner-icon="mdi-book"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12">
                            <div class="d-flex justify-center py-2">
                                <v-btn
                                    class="log white--text rounded-pill py-2 px-8"
                                    @click="toPart2"
                                    >Next</v-btn>
                            </div>
                        </v-col>
                        <v-col
                            cols="12">
                                <div class="d-flex justify-center pt-8 pb-3 text-caption font-size-12">
                                Already have an account?<router-link to ="/">
                                <v-btn :ripple="false" color="header" text id="no-background-hover">
                                Sign In
                                </v-btn>
                                </router-link>
                            </div>
                        </v-col>
                    </v-row>
                    </v-form>
                </v-card>
            </v-col>
            <!-- SignUp Part2 -->
            <v-col v-if="isPart2" cols="12" class="lighten-2 fill-height d-flex flex-column justify-center align-center">
                <v-card flat tile class="white rounded-lg px-4" width="400px">
                    <v-card-title class="justify-center pt-7 px-7"><h2>Sign Up</h2></v-card-title>
                    <div class="d-flex justify-center text-caption mt-n3 grey--text lighten-1">Account Registration (2/3)</div>
                    <v-form @submit.prevent ref="part2Formref">
                    <v-row class="mx-5 pt-6">
                        <v-col
                            cols="12"
                            class="pa-2"
                            >
                            <v-autocomplete
                            color="green"
                              v-model="registerData.position"
                                :items="position"
                                item-text="PositionDescription"
                                item-value="PositionID"
                                dense
                                :rules="[formRules.required]" 
                                label="Position"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-2"
                            >
                            <v-autocomplete
                            color="green"
                              v-model="registerData.designation"
                                :items="designation"
                                item-text="DesignationDescription"
                                item-value="DesignationID"
                                dense
                                :rules="[formRules.required]" 
                                label="Designation"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-2"
                            >
                            <v-autocomplete
                            color="green"
                                 v-model="registerData.office"
                                :items="office"
                                item-text="OfficeDescription"
                                item-value="OfficeID"
                                dense
                                :rules="[formRules.required]" 
                                label="Office"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-2"
                            >
                            <v-autocomplete
                            color="green"
                                 v-model="registerData.deliveryunit"
                                :items="deliveryunit"
                                item-text="DeliveryUnitDescription"
                                item-value="DeliveryUnitID"
                                dense
                                :rules="[formRules.required]" 
                                label="Delivery Unit"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                            cols="12">
                            <div class="d-flex justify-center py-2">
                                <v-btn
                                    class="leg white--text rounded-pill py-2 px-8 mr-4"
                                    @click="toPart2"
                                    >Back</v-btn>
                                <v-btn
                                    class="log white--text rounded-pill py-2 px-8"
                                    @click="toPart3"
                                    >Next</v-btn>
                            </div>
                        </v-col>
                        <v-col
                            cols="12">
                                <div class="d-flex justify-center pt-8 pb-3 text-caption font-size-12">
                                Already have an account?<router-link to ="/">
                                <v-btn :ripple="false" color="header" text id="no-background-hover">
                                Sign In
                                </v-btn>
                                </router-link>
                            </div>
                        </v-col>
                    </v-row>
                    </v-form>
                </v-card>
            </v-col>
            <!-- SignUp Part3 -->
            <v-col v-if="isPart3" cols="12" class="lighten-2 fill-height d-flex flex-column justify-center align-center">
                <v-card flat tile class="white rounded-lg px-4" width="400px">
                    <v-card-title class="justify-center pt-7 px-7"><h2>Sign Up</h2></v-card-title>
                    <div class="d-flex justify-center text-caption mt-n3 grey--text lighten-1">Account Registration (3/3)</div>
                     <v-form @submit.prevent ref="part3Formref">
                    <v-row class="mx-5 pt-6">
                        <v-col
                            cols="12"
                            class="pa-0"
                            >
                            <v-text-field
                            color="green"
                                class="text-caption"
                                v-model="registerData.email"
                                :rules="[formRules.required, formRules.email, formRules.emaildnsc]"
                                label="Email address"
                                @change="checkemail()"
                                :error-messages="emailError"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0"
                            >
                            <v-text-field
                            color="green"
                                class="text-caption"
                                v-model="registerData.password"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[formRules.required, formRules.password]" 
                                :type="show1 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Enter your Password"
                                @click:append="show1 = !show1"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            class="pa-0"
                            >
                            <v-text-field
                            color="green"
                                class="text-caption"
                                v-model="registerData.password2"
                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="[formRules.required, formRules.confirmPassword(registerData.password2, registerData.password)]" 
                                :type="show2 ? 'text' : 'password'"
                                name="input-10-1"
                                label="Confirm your Password"
                                @click:append="show2 = !show2"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12">
                            <div class="d-flex justify-center py-2">
                                <v-btn
                                    class="leg white--text rounded-pill py-2 px-8 mr-4"
                                    @click="toPart3"
                                    :disabled="isLoading"
                                    >Back</v-btn>
                                <v-btn
                                    class="log white--text rounded-pill py-2 px-8"
                                    @click="toPart4(registerData)"
                                    :loading="isLoading"
                                    :disabled ="emailChecking"
                                    >Submit</v-btn>
                            </div>
                        </v-col>
                        <v-col
                            cols="12">
                             <div class="d-flex justify-center pt-8 pb-3 text-caption font-size-12">
                                Already have an account?<router-link to ="/">
                                <v-btn :ripple="false" color="header" text id="no-background-hover">
                                Sign In
                                </v-btn>
                                </router-link>
                            </div>
                        </v-col>
                    </v-row>
                     </v-form>
                </v-card>
            </v-col>
            <!-- SignUp Part4 -->
            <v-col v-if="isPart4" cols="12" class="lighten-2 fill-height d-flex flex-column justify-center align-center">
                <v-card flat tile class="white rounded-lg px-4" width="400px">
                    <v-card-title class="justify-center pt-7 px-7"><h2>Verify Your Account</h2></v-card-title>
                    <div class="d-flex justify-center text-caption mt-n3 grey--text lighten-1">OTP Confirmation</div>
                    <v-divider class="mx-4"></v-divider>
                    <div class="d-flex justify-center text-caption mt-2 px-5 grey--text lighten-1">
                        A Confirmation code has been sent to your email address. Please Enter  your OTP Code in the field below.
                    </div>
                    <v-row class="mx-5 pt-6">
                        <v-col
                            cols="12"
                            class="pa-0 mt-6 d-flex justify-center"
                            >
                            <v-otp-input
                                ref="otpInput"
                                input-classes="otp-input"
                                separator="-"
                                :num-inputs="4"
                                :should-auto-focus="true"
                                :is-input-num="true"
                                @on-complete="handleOnComplete"
                                />
                        </v-col>
                        <v-col
                            cols="12">
                            <div class="d-flex justify-center py-2 pt-9">
                                <v-btn
                                    class="log white--text rounded-pill py-2 px-8"
                                    :loading="isLoading"
                                    @click="confirmOtp()"
                                    >Confirm</v-btn>
                            </div>
                        </v-col>
                        <v-col
                            cols="12">
                             <div class="d-flex justify-center pt-8 pb-3 text-caption font-size-12">
                                Already have an account?<router-link to ="/">
                                <v-btn :ripple="false" color="header" text id="no-background-hover">
                                Sign In
                                </v-btn>
                                </router-link>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
      </v-row>
		<fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
    </v-row>
  </v-container>
</div>
</template>

<script>
  export default {
    name: 'Login',

    data: () => ({
    done: false,
    emailError: '',
    emailChecking: false,
     isLoading: false,
      isPart1: true,
      isPart2: false,
      isPart3: false,
      isPart4: false,
      registerData: {
          firstname: null,
          educQuali: '',
          middlename: null,
          lastname: null,
          password: null,
          password2: null,
          otp: null,
          email: null,
          designation: null,
          office: null,
          deliveryunit: null
      },
      position: [],
      designation: [],
      office: [],
      deliveryunit: [],
      valid: false,
      show1: false,
      show2: false,
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
    privacy_policy: false,
    }),
    mounted () {
        this.getPositionDesignationOffice();
        if(this.$route.params.email){
            this.registerData.email = this.$route.params.email;
            this.isPart1 = false;
            this.isPart4 = true;
            this.privacy_policy = false;
        }
        else{
            this.privacy_policy = true;
        }
    },
    methods: {
        confirmOtp () {
            this.isLoading = true;
             let data = new FormData();
                data.append('otp', this.registerData.otp);
                data.append('email', this.registerData.email);
                this.axiosCall(
                        '/account/verify',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                            this.done = true;
                            let that = this;
                            setTimeout(function(){  that.$router.replace({path: '/'}); }, 5000);

                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Invalid OTP';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
        },
        checkemail () {
                this.emailChecking = true;
                let data = new FormData();
                data.append('email', this.registerData.email);
                this.axiosCall(
                        '/checkEmailExist',
                        'POST',
                        data
                    )
                    .then((res) => {
                        console.log(res.data)
                        if(res.data.status) {
                            this.emailError = '';
                        }else {
                            this.emailError = 'Email Already Exist!';
                        }
                        this.emailChecking = false
                    })
        },
        toPart1 () {
            this.isPart1 = !this.isPart1
            this.isPart2 = !this.isPart2
        },
        toPart2 () {
            if(this.isPart1){
                if(this.$refs.part1Formref.validate()){
                    this.isPart1 = !this.isPart1
                    this.isPart2 = !this.isPart2
                }
            }else {
                this.isPart1 = !this.isPart1
                this.isPart2 = !this.isPart2
            }
        },
        toPart3 () {
            if(this.isPart2){
                 if(this.$refs.part2Formref.validate()){
                    this.isPart2 = !this.isPart2
                    this.isPart3 = !this.isPart3
                 }
            }else{
            this.isPart2 = !this.isPart2
            this.isPart3 = !this.isPart3
            }
        },
        toPart4 (val) {
            if(this.isPart3){
                 if(this.$refs.part3Formref.validate() && this.emailError.length < 1){
                     this.isLoading = true;
                let data = new FormData();
                data.append('email', val.email);
                data.append('firstname', val.firstname);
                data.append('middlename', val.middlename);
                data.append('lastname', val.lastname);
                data.append('password', val.password);
                data.append('educQuali', val.educQuali);
                data.append('position', val.position);
                data.append('designation', val.designation);
                data.append('office', val.office);
                data.append('deliveryunit', val.deliveryunit);
                this.axiosCall(
                        '/register',
                        'POST',
                        data
                    )
                    .then((res) => {
                        if (res.data.status){
                        this.isLoading = false
                        this.isPart3 = !this.isPart3
                        this.isPart4 = !this.isPart4
                        }
                    })
                 }
            }else{
             this.isPart3 = !this.isPart3
             this.isPart4 = !this.isPart4
            }
        },
        handleOnComplete(value) {
            console.log('OTP completed: ', value);
            this.registerData.otp = value;
        },
        handleClearInput() {
            this.$refs.otpInput.clearInput();
        },
        getPositionDesignationOffice() {
                this.axiosCall(
                        '/getPositionDesignationOfficeOutside',
                        'GET'
                    )
                    .then((res) => {
                        this.office = res.data.data.office;
                        this.position = res.data.data.position;
                        this.designation = res.data.data.designation;
                        this.deliveryunit = res.data.data.deliveryunit;
                    })
		},
        goToRA10173(){
            window.open("https://www.officialgazette.gov.ph/2012/08/15/republic-act-no-10173/");
        },
        disagree(){
            this.$router.push('/');
        }
    }
  }
</script>

<style scoped>
.back {
  background: url('../assets/thon.png')
    no-repeat center center fixed !important;
    background-color: rgb(49, 112, 49);
  background-size: cover;
  overflow: hidden !important;
}
.content {
    /* background: url('../assets/BG_3rd_Layer.png')
    no-repeat center center fixed !important; */
    background: url('../assets/BG_1st_Layer.png') no-repeat center fixed, linear-gradient(0deg, rgba(0,115,52,1) 0%, rgba(46,92,7,1) 100%);
    /* background: rgb(0,115,52);
    background: linear-gradient(90deg, rgba(0,115,52,1) 0%, rgba(108,125,11,1) 100%); */
    background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--ntp-one-google-bar-height));
    min-width: fit-content;
    position: relative;
    z-index: 1;
}
.content {
    background: url('../assets/thon.png')
    no-repeat center center fixed !important;
    background-color: rgb(49, 112, 49);
    background-repeat:round;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--ntp-one-google-bar-height));
    min-width: fit-content;
    position: relative;
    z-index: 1;
}
:host {
    --ntp-theme-shortcut-background-color: rgb(229, 231, 232);
    --ntp-theme-text-color: var(--google-grey-800);
    --ntp-theme-text-shadow: none;
    --ntp-one-google-bar-height: 56px;
    --ntp-search-box-width: 337px;
}
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.log {
    background: #048b2c;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #769c32, #048b2c);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #769c32, #048b2c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.leg {
    background: #808080;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #34411f, #808080);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #34411f, #808080); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.otp-input {
	width: 40px;
	height: 40px;
	padding: 5px;
	margin: 0 10px;
	font-size: 20px;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	text-align: center;
}

.otp-input.error {
	border: 1px solid red !important;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.v-text-field label{
    font-size: 10pt;
  }
  .hh {
  height: 90vh;
}
#no-background-hover{
  text-transform: unset !important;
  font-family: roboto !important;
  margin-top: -9px !important;
  padding: 0 !important;
  font-weight: normal !important;

}
#no-background-hover::before {
   background-color: transparent !important;
}
.pp-content{
    text-align: justify !important;
}
.ra-text{
    text-decoration: underline !important;
    color: #43a047 !important;
    font-weight: bold !important;
    cursor: pointer !important;
}
</style>
