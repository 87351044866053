<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="rsmiList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-tabs v-model="status" color="header">
          <v-tab
            v-for="tab of status_tabs"
            :key="tab.id"
          >
          {{tab.desc}}
          </v-tab>
        </v-tabs>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search RS number"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.Other_Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          color="header darken-2"
          dark
          outlined
          elevation="2"
          x-small
          class="mr-2"
          @click="approveRSMI(item)"
          v-if="status == 0 || status == 1"
        >
          Verify
        </v-btn>
        <v-btn
          color="red darken-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="pendingRSMI(item)"
          v-if="status == 0"
        >
          Pending
        </v-btn>
        </span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewRSMI(item)"
          >
            View
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printRSMI(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="approveDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to approve this Report of Supplies and Materials Issued?
            <br /><br />
            <div>RSMI #: <strong>{{approveData.RSMICODE}}</strong></div>
            <!-- <div>Created By: <strong>{{approveData.CreatedBy}}</strong></div> -->
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="approveDialog = false">Cancel</v-btn>
            <v-btn color="header darken-2 white--text" @click="confirmApproveRSMI()"><v-icon class="mr-1">mdi-check-circle</v-icon>Verify</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="pendingDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to pending this Report of Supplies and Materials Issued?
            <br /><br />
            <div>RSMI #: <strong>{{pendingData.RSMICODE}}</strong></div>
            <!-- <div>Created By: <strong>{{pendingData.CreatedBy}}</strong></div> -->
            <br />
            <v-form ref="formRef">
            <v-textarea
              color="green"
                dense
                v-model="formData.Remarks"
                :rules="[formRules.required]"
                label="Remarks"
                counter
                outlined
                maxlength="500"
                ></v-textarea>
            </v-form>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="pendingDialog = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmPendingRSMI()"><v-icon class="mr-1">mdi-clock-outline</v-icon>Pending</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <ViewRSMIModal :rsmiData="rsmiData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    ViewRSMIModal: () => import("../Supply/ViewRSMI.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    rsmiList: [],
    rsmiData: [],
    headers: [
      {
          text: 'RSMI CODE',
          align: 'start',
          sortable: false,
          value: 'RSMICODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Fund Code',
          align: 'center',
          sortable: false,
          value: 'FundYear',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'Description',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: '# of RIS',
          align: 'center',
          sortable: false,
          value: 'RISCount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'Other_Actions',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      status_tabs: [
        {id: 1, desc: 'For Approval'},
        {id: 3, desc: 'Pending'},
        {id: 2, desc: 'Approved'},
        {id: 4, desc: 'Cancelled'},
      ],
      status: 0,
      approveDialog: false,
      pendingDialog: false,
      approveData: [],
      pendingData: [],
      formData: {
        Remarks: '',
      },
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    status: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeViewRSMI", () => {
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewRSMI");
  },
  methods: {
    viewRSMI(item) {
      this.rsmiData = {...item, status_prop: this.status};
    },
    printRSMI(item) {
      this.link = this.apiUrl + "/rsmi/report/" + item.RSMIID;
      window.open(this.link);
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("status", this.status + 1);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      this.axiosCall("/getRSMIListPerStatus", "POST", data).then((res) => {
        this.loading = false;
        this.rsmiList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    approveRSMI(data){
      this.approveData = data;
      this.approveDialog = true;
    },
    pendingRSMI(data){
      this.pendingData = data;
      this.pendingDialog = true;
    },
    confirmApproveRSMI(){
      let data = new FormData();
      data.append("RSMIID", this.approveData.RSMIID);
      this.axiosCall("/rsmi/approve", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RSMI was successfully approved.";
            this.fadeAwayMessage.header = "System Message";
            this.approveDialog = false;
            this.approveData = [];
            this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    confirmPendingRSMI(){
      if(this.$refs.formRef.validate()){
      let data = new FormData();
      data.append("RSMIID", this.pendingData.RSMIID);
      data.append("Remarks", this.formData.Remarks);
      this.axiosCall("/rsmi/pending", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RSMI was successfully in pending status.";
            this.fadeAwayMessage.header = "System Message";
            this.pendingDialog = false;
             this.pendingData = [];
             this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
  },
};
</script>