<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="prList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-tabs
            v-model="tabs"
            color="header"
            v-if="$store.state.user.roleID == 5"
          >
            <v-tab>
              Verified
            </v-tab>
            <v-tab>
              Pending
            </v-tab>
            <v-tab>
              For Canvass
            </v-tab>
            <v-tab>
              For Recanvass
            </v-tab>
          </v-tabs>
        <v-toolbar flat>
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search for PR Code/Purpose"
            color="green darken-2"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            color="green"
            v-model="OfficeID"
            item-text="OfficeDescription"
            item-value="OfficeID"
            :items="office"
            @change="initialize()"
            dense
            hide-details
            class="mt-2"
            label="Filter by office"
          >
          </v-autocomplete>
        </v-toolbar>
      </template>

      <template v-slot:[`item.PRCODE`]="{ item }">
       {{ item.isApproved == 1 ? item.PRCODE : item.PRCODETemp }}
      </template>
      <template v-slot:[`item.BudgetCode`]="{ item }">
        {{ item.isParent == 1 ? 'Consolidated' : item.BudgetCode }}
      </template>
      <template v-slot:[`item.GrandTotal`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.GrandTotal) }}</span>
      </template>
      <template v-slot:[`item.other_actions`]="{ item }">
          <span class="text-no-wrap" v-if="item.PSUStatusID != 6">
            <v-btn
                v-if="$store.state.user.roleID == 5 && !item.isDBM"
                @click="rfq(item)"
                x-small
                outlined
                color="blue darken-2"
                dark
                class="mr-2"
              >
                RFQ
              </v-btn>
              <!-- <v-btn
                v-if="$store.state.user.BACaccess == 1 && item.isParent == 0"
                x-small
                color="orange darken-2"
                disabled
                outlined
                class="mr-2"
              >
              <v-icon x-small>mdi-check</v-icon>
                AOQ
              </v-btn> -->
              <v-btn
                v-if="$store.state.user.BACaccess == 1 && item.isParent == 0 && item.PSUStatusID == 7 && item.IsCanvassed == 1"
                @click="aoq(item)"
                x-small
                outlined
                color="orange darken-2"
                dark
                class="mr-2"
              >
                AOQ
              </v-btn>

              <v-btn
                v-if="$store.state.user.roleID == 5 && item.isDBM"
                x-small
                outlined
                color="red darken-2"
                dark
                class="mr-2"
                @click="apr(item)"
              >
                APR
              </v-btn>

              <v-btn
                v-if="$store.state.user.roleID == 5 && item.PSUStatusID == 7 && item.IsCanvassed == 1"
                x-small
                outlined
                color="green darken-2"
                dark
                class="mr-2"
                @click="po(item)"
              >
                PO
              </v-btn>
              <v-btn
                v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 15"
                x-small
                outlined
                color="amber accent-4"
                dark
                class="mr-2"
                @click="iar(item)"
              >
                IAR
            </v-btn>
          </span>
      </template>
      <!-- <template v-slot:[`item.Canvassed`]="{ item }">
          <v-icon v-if="item.isApproved == 1 && item.PRstatus == 1" color="header">mdi-check</v-icon>
        </template> -->
      <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            x-small
            outlined
            color="blue darken-2"
            dark
            class="mr-2"
            @click="viewPR(item)"
          >
            VIEW
          </v-btn>
      </template>
    </v-data-table>
    <prModlal :prData="prItem" />
    <poModlal :prData="poItem" />
    <iarModlal :prData="iarItem" />
    <canvasModlal :canvasData="prItem1" />
    <v-dialog v-model="rfqDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline">Request for Quotation</v-card-title>
            <div class="mx-6">
              <v-form @submit.prevent ref="formRefRFQ">
              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="rfqFormData.RFQType" row :disabled="!switchRFQ" >
                    <v-radio label="Lot Bid" value="1" color="header"></v-radio>
                    <v-radio label="Item Bid" value="2" color="header"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="rfqFormData.dodID"
                    :disabled="!switchRFQ"
                    item-text="dodDescription"
                    item-value="dodID"
                    :rules="[formRules.required]"
                    :items="dodList"
                    color="header"
                    dense
                    class="mb-4"
                    label="Delivery Period"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              </v-form>
            </div>
            <v-card-actions class="mt-5">
              <v-switch
                    v-model="switchRFQ"
                    v-if="showEditRFQ"
                    inset
                    class="mb-4"
                    hide-details
                    color="header"
                    label="Edit Mode"
                  ></v-switch>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="rfqDialog = false">Cancel</v-btn>
              <v-btn color="blue darken-2 white--text" @click="confirmGenerateRFQ()" :loading="isLoading"><v-icon>mdi-printer</v-icon>Print</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    prModlal: () => import("./PRViewItem"),
    poModlal: () => import("./AddPO"),
    iarModlal: () => import("./AddOtherIAR"),
    canvasModlal: () => import("./PSUCanvas"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    dialogConfirm: false,
    OfficeID: 0,
    office: [],
    dialogLoading: false,
    dialogconfirmDelete: false,
    deleteitemid: null,
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    editItemId: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    menu: false,
    menu1: false,
    formData: {
      PPMPDescription: null,
      isDBM: null,
      BudgetID: null,
      Amount: null,
    },
    rfqDialog: false,
    rfqFormData: {
      RFQType: null,
      dodID: null
    },
    rfqData: [],
    dodList: [],
    switchRFQ: false,
    addPPMPDialog: false,
    showEditRFQ: false,
    prList: [],
    budgetList: [],
    prItem: [],
    prItem1: [],
    poItem: [],
    iarItem: [],
    headers: [
      {
        text: "PR No.",
        align: "start",
        sortable: false,
        value: "PRCODE",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Budget Code",
        value: "BudgetCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Purpose",
        value: "PrPurpose",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Date",
        value: "DateCreated",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Grand Total",
        value: "GrandTotal",
        sortable: false,
        align: "end",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "",
        value: "other_actions",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      // {
      //   text: "Canvassed",
      //   value: "Canvassed",
      //   sortable: false,
      //   align: "center",
      //   class: "green darken-2 white--text text-caption font-weight-bold",
      // },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
        class:
          "green darken-2 white--text text-caption font-weight-bold d-flex justify-center align-center",
      },
    ],
    tabs: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    tabs: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  created() {
    this.getDOD();
  },
  mounted() {
    this.eventHub.$on("closePOModal", () => {
      this.poItem = {};
      this.initialize();
    });
    this.eventHub.$on("closePRItemodal", () => {
      this.prItem = {};
      this.initialize();
    });
    this.eventHub.$on("closePRItemodal1", () => {
      this.prItem1 = {};
      this.initialize();
    });
    this.eventHub.$on("closeCanvasModal", () => {
      this.initialize();
    });
    let data = new FormData();
    data.append("page", 1);
    data.append("itemsPerPage", 1000000);
    this.axiosCall("/getAllOffice", "POST", data).then((res) => {
      this.office = res.data.data.result;
      this.office.push({ OfficeID: 0, OfficeDescription: "All" });
      this.office.sort(function (a, b) {
        return a.OfficeID - b.OfficeID;
      });
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closePRItemodal");
    this.eventHub.$off("closePRItemodal1");
    this.eventHub.$off("closeCanvasModal");
  },
  methods: {
    po(data) {
      this.poItem = data;
    },
    iar(data) {
      this.iarItem = data;
      console.log(data)
    },
    deletePR() {
      this.dialogLoading = true;
      let data = new FormData();
      data.append("PRID", this.deleteitemid);
      this.axiosCall("/pr/delete", "POST", data).then(() => {
        this.dialogLoading = false;
        this.dialogconfirmDelete = false;
        this.deleteitemid = null;
        this.initialize();
      });
    },
    deleteItem(data) {
      this.deleteitemid = data.PRID;
      if (this.deleteitemid) {
        this.dialogconfirmDelete = true;
      }
    },
    apr(data) {
      window.open(this.apiUrl + "/pr/apr/" + data.PRID);
    },
    rfq(data) {
      this.rfqData = data;
      this.rfqDialog = true;
      if(data.RFQType != null && data.dodID != null){
        this.rfqFormData.RFQType = data.RFQType.toString()
        this.rfqFormData.dodID = data.dodID
        this.showEditRFQ = true;
        this.switchRFQ = false;
      }
      else{
         this.showEditRFQ = false;
        this.switchRFQ = true;
      }
      // window.open(this.apiUrl + "/pr/rfq/" + data.PRID);
    },
    confirmGenerateRFQ(){
      if(this.$refs.formRefRFQ.validate()){
        this.isLoading = true;
        let data = new FormData();
        data.append('PRID', this.rfqData.PRID);
        data.append('RFQType', this.rfqFormData.RFQType);
        data.append('dodID', this.rfqFormData.dodID);
                    this.axiosCall(
                  '/pr/generateRFQ',
                  'POST',
                  data
              )
              .then((res) => {
                  this.isLoading = false;
                  if(res.data.status) {
                  let recanvass = 0;
                  if(this.tabs == 3){
                    recanvass = 1;
                  }
                  window.open(this.apiUrl + "/pr/rfq/" + recanvass + '/' + res.data.data);
                  this.$refs.formRefRFQ.reset();
                  this.rfqDialog = false;
                  this.initialize();
                  }else {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'error';
                  this.fadeAwayMessage.message = res.data.message;
                  this.fadeAwayMessage.header = 'System Message';
                  }
              })
         }
         else{
          this.isLoading = false;
         }
    },
    print(data) {
      window.open(this.apiUrl + "/pr/report/" + data.PRID);
    },
    viewPR(item) {
      this.prItem = item;
    },
    aoq(item) {
      this.prItem1 = item;
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("OfficeID", this.OfficeID);
      data.append("FiscalYearID", this.fiscalYear);
      if(this.$store.state.user.roleID == 5){
      data.append("tab", this.tabs);
      }
      this.axiosCall("/get/approved/pr", "POST", data).then((res) => {
        this.loading = false;
        this.prList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getDOD(){
      this.axiosCall("/get/all/POUtil", "POST", []).then((res) => {
        this.dodList = res.data.data.dod;
      });
    }
  },
};
</script>