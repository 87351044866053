<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ppmpList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-tabs color="green darken-2" class="mb-2">
          <v-tab @click="tab = 1">For Approval</v-tab>
          <v-tab @click="tab = 2">Approved</v-tab>
          <v-tab @click="tab = 3">Pending</v-tab>
        </v-tabs>
        <v-row align="center" class="mx-1 my-3">
          <v-col cols="12" md="6" lg="4">
          <!-----search for budget code----->
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search for Budget Code"
            color="green darken-2"
            single-line
            class="pa-0"
            hide-details
          ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
          <!-----filter by office----->
          <v-autocomplete
            color="green"
            v-model="OfficeID"
            item-text="OfficeDescription"
            item-value="OfficeID"
            :items="office"
            @change="initialize()"
            dense
            hide-details
            label="Filter by office"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
          <!-----filter by delivery unit----->
          <v-autocomplete
            color="green"
            v-model="DeliveryUnitID"
            item-text="DeliveryUnitDescription"
            item-value="DeliveryUnitID"
            :items="deliveryUnit"
            @change="initialize()"
            dense
            hide-details
            label="Filter by Delivery Unit"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" lg="2">
          <!-----filter by pap----->
          <v-autocomplete
            color="green"
            v-model="PurposeID"
            item-text="PurposeDescription"
            item-value="PurposeID"
            :items="purpose"
            @change="initialize()"
            dense
            hide-details
            label="Filter by Program/ Activity/ Project"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" lg="2">
          <!-----filter by fund source----->
          <v-autocomplete
            color="green"
            v-model="SpecificFundSourceID"
            item-text="SpecificFundSourceDescription"
            item-value="SpecificFundSourceID"
            :items="specificFundSource"
            @change="initialize()"
            dense
            hide-details
            label="Filter by Fund Source"
          >
          </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.BudgetCode`]="{ item }">
          <span class="text-no-wrap">{{ item.BudgetCode }}</span>
        </template>
        <template v-slot:[`item.Amount`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Amount || 0) }}</span>
        </template>
        <template v-slot:[`item.BudgetDate`]="{ item }">
          {{ item.DateStart }} - {{ item.DateEnd }}
        </template>
        <template v-slot:[`item.PPMPTypeID`]="{ item }">
          <span class="text-no-wrap" v-if="item.PPMPTypeID == 1"><small>DBM</small></span>
          <span class="text-no-wrap" v-if="item.PPMPTypeID == 2"><small>NON DBM</small></span>
          <span class="text-no-wrap" v-if="item.PPMPTypeID == 3"><small>LIB</small></span>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <span class="text-no-wrap">
          <v-btn
            color="green darken-2"
            dark
            outlined
            elevation="2"
            v-if="tab == 1"
            small
            class="mr-2"
            @click="approveItem(item)"
          >
            Approve
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            class="mr-2"
            small
            v-if="tab == 1"
            @click="pendingItem(item)"
          >
            Pending
          </v-btn>
          <v-btn
            color="blue darken-2"
            dark
            outlined
            elevation="2"
            small
            @click="viewPPMP(item)"
          >
            View
          </v-btn>
          </span>
        </template>
    </v-data-table>
    <v-dialog v-model="dialogPending" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="headline">Pending PPMP</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="dialogPending = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form @submit.prevent ref="formRef">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    color="green"
                    dense
                    v-model="remarks"
                    :rules="[formRules.required]"
                    label="Remarks"
                    counter
                    maxlength="500"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPending = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" text @click="confirmReturn()">
            Return PPMP
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogApprove" max-width="500px">
      <v-card>
        <v-card-title class="text-h6"
          >Are you sure you want to approve this PPMP?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-2" text @click="dialogApprove = false"
            >Cancel</v-btn
          >
          <v-btn color="green darken-2" @click="confirmApprove()" text
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ppmpModlal :ppmpData="ppmpItem" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    ppmpModlal: () => import("./PPMPViewItem"),
  },
  props:{
    fiscalYear: null,
  },
  data: () => ({
    OfficeID: 0,
    DeliveryUnitID: 0,
    PurposeID: 0,
    SpecificFundSourceID: 0,
    office : [],
    deliveryUnit: [],
    purpose: [],
    specificFundSource: [],
    searchText: "",
    searchTimeout: null,
    tab: 1,
    remarks: null,
    item: null,
    dialogPending: false,
    dialogApprove: false,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    ppmpList: [],
    ppmpItem: [],

    headers: [
      {
        text: "Budget Code",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PPMP Description",
        value: "PPMPDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Amount Allocation",
        value: "Amount",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Budget Date",
        value: "BudgetDate",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PPMP Type",
        value: "PPMPTypeID",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "Actions",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    OfficeID: function () {
        this.initialize();
    },
    DeliveryUnitID: function () {
        this.initialize();
    },
    PurposeID: function () {
        this.initialize();
    },
    SpecificFundSourceID: function () {
        this.initialize();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    tab: {
      handler() {
        this.ppmpList = [];
        this.initialize();
      },
      deep: true,
    },
fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.eventHub.$on("closePPMPModal", () => {
      this.initialize();
      this.ppmpItem = {};
    });
    this.getOffice();
    this.getDeliveryUnit();
    this.getPurpose();
    this.getSpecificFundSource();
    this.eventHub.$on("closePRItemodal", () => {
      this.initialize();
      this.prItem = {};
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closePPMPModal");
  },
  methods: {
    getOffice() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllOffice", "POST", data).then((res) => {
        this.office = res.data.data.result;
        this.office.push({ OfficeID: 0, OfficeDescription: "All" });
        this.office.sort(function (a, b) {
          return a.OfficeID - b.OfficeID;
        });
      });
    },
    getDeliveryUnit() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllDeliveryUnit", "POST", data).then((res) => {
        this.deliveryUnit = res.data.data.result;
        this.deliveryUnit.push({ DeliveryUnitID: 0, DeliveryUnitDescription: "All" });
        this.deliveryUnit.sort(function (a, b) {
          return a.DeliveryUnitID - b.DeliveryUnitID;
        });
      });
    },
    getPurpose() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllPurpose", "POST", data).then((res) => {
        this.purpose = res.data.data.result;
        this.purpose.push({ PurposeID: 0, PurposeDescription: "All" });
        this.purpose.sort(function (a, b) {
          return a.PurposeID - b.PurposeID;
        });
      });
    },
    getSpecificFundSource() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllFundsource", "POST", data).then((res) => {
        this.specificFundSource = res.data.data.result;
        this.specificFundSource.push({ SpecificFundSourceID: 0, SpecificFundSourceDescription: "All" });
        this.specificFundSource.sort(function (a, b) {
          return a.SpecificFundSourceID - b.SpecificFundSourceID;
        });
      });
    },
    viewPPMP(item) {
      this.ppmpItem = {...item, RA: true};
    },
    confirmReturn() {
      if (this.$refs.formRef.validate()) {
        let data = new FormData();
        data.append("Remarks", this.remarks);
        data.append("PPMPID", this.item.PPMPID);
        data.append("RA", true);
        this.axiosCall("/signatories/PPMP/pending", "POST", data).then(
          (res) => {
            console.log(res);
            this.dialogPending = false;
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "PPMP Successfully Pending!";
            this.fadeAwayMessage.header = "System Message";
            this.initialize();
          }
        );
      }
    },
    confirmApprove() {
      let data = new FormData();
      data.append("PPMPID", this.item.PPMPID);
      data.append("RA", true);
      this.axiosCall("/signatories/PPMP/approve", "POST", data).then((res) => {
        console.log(res);
        this.dialogApprove = false;
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "success";
        this.fadeAwayMessage.message = "PPMP Successfully Approved!";
        this.fadeAwayMessage.header = "System Message";
        this.initialize();
      });
    },
    print(data) {
      window.open(
        this.apiUrl + "/ppmp/report/" + data.PPMPID + "/" + data.BudgetID
      );
    },
    pendingItem(data) {
      let role = this.$store.state.user;
      if (role.roleID == 7) {
        this.remarks = data.BudgetRemarks;
      }
      if (role.roleID == 8) {
        this.remarks = data.PresidentRemarks;
      }
      if (role.roleID == 9) {
        this.remarks = data.FinanceRemarks;
      }
      if (role.roleID == 10) {
        this.remarks = data.RecommendingApprovalRemarks;
      }
      this.item = data;
      this.dialogPending = true;
    },
    approveItem(data) {
      this.item = data;
      this.dialogApprove = true;
    },
    initialize() {
      this.ppmpList = [];
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("RA", true);
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("OfficeID", this.OfficeID);
      data.append("DeliveryUnitID", this.DeliveryUnitID);
      data.append("PurposeID", this.PurposeID);
      data.append("SpecificFundSourceID", this.SpecificFundSourceID);
      data.append("FiscalYearID", this.fiscalYear);
      this.axiosCall("/signatories/PPMP", "POST", data).then((res) => {
        this.loading = false;
        this.ppmpList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    save() {
      if (this.$refs.formRef.validate() && !this.amountErr) {
        this.isLoading = true;
        let data = new FormData();
        data.append("Amount", this.formData.Amount);
        data.append("isDBM", this.formData.isDBM);
        data.append("BudgetID", this.formData.BudgetID);
        data.append("PPMPDescription", this.formData.PPMPDescription);
        this.axiosCall("/PPMP/add", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "PPMP Successfully Added!";
            this.fadeAwayMessage.header = "System Message";
            this.$refs.formRef.reset();
            this.addPPMPDialog = false;
            this.initialize();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong try again!";
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
  },
};
</script>   