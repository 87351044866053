<template>
  <div class="DarkWhite pa-4 ">
      <h2 class="subheading green--text">Payment Term</h2>
      <v-card class="mt-4">
      <PSUPaymentTermUtility/>
  </v-card>
  </div>
</template>

<script>
import PSUPaymentTermUtility from '../components/Utils/PSUPaymentTermUtility.vue'
  export default {
    components: {
      PSUPaymentTermUtility
    },
    data: () => ({
    }),
  }
</script>


<style scoped>
</style>