<template>
  <div class="px-2">
    <v-data-table
      :headers="headers"
      :items="DataList"
      :loading="loading"
      class="elevation-0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-subtitle-1 font-weight-bold"
            >Delivery Term List</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-2"
            dark
            outlined
            elevation="2"
            class="my-2"
            @click="addDialog = true"
          >
            <v-icon>mdi-plus</v-icon>
            New Delivery Term
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="addDialog" persistent max-width="350px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-bold" v-if="editItemId"
            >Update Delivery Term</span
          >
          <span class="subtitle-1 font-weight-bold" v-else>
            New Delivery Term</span
          >
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form @submit.prevent ref="formRef">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    dense
                    label="Description"
                    color="header"
                    v-model="formData.dtDescription"
                    :rules="[formRules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancel()"> Cancel </v-btn>
          <v-btn
            v-if="!editItemId"
            color="green darken-1"
            text
            @click="save"
            :loading="isLoading"
          >
            Add
          </v-btn>
          <v-btn
            v-else
            color="green darken-1"
            text
            @click="update"
            :loading="isLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  data: () => ({
    editItemId: null,
    loading: true,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    formData: {
      dtDescription: null,
    },
    addDialog: false,
    DataList: [],
    headers: [
      {
        text: "Description",
        value: "dtDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        class:
          "green darken-2 white--text text-caption font-weight-bold d-flex justify-center align-center",
      },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    editItem(data) {
      this.editItemId = data.dtID;
      this.formData.dtDescription = data.dtDescription;
      this.addDialog = true;
    },
    cancel() {
      this.editItemId = null;
      this.$refs.formRef.reset();
      this.addDialog = false;
    },
    initialize() {
      this.loading = true;
      let data = new FormData();
      this.axiosCall("/get/all/deliveryterm", "POST", data).then((res) => {
        this.loading = false;
        this.DataList = res.data.data;
      });
    },
    save() {
      if (this.$refs.formRef.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("dtDescription", this.formData.dtDescription);
        this.axiosCall("/add/deliveryterm", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Delivery Term Successfully Added!";
            this.fadeAwayMessage.header = "System Message";
            this.$refs.formRef.reset();
            this.addDialog = false;
            this.initialize();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    update() {
      if (this.$refs.formRef.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("dtID", this.editItemId);
        data.append("dtDescription", this.formData.dtDescription);
        this.axiosCall("/edit/deliveryterm", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message =
              "Delivery Term Successfully Updated!";
            this.fadeAwayMessage.header = "System Message";
            this.$refs.formRef.reset();
            this.editItemId = null;
            this.addDialog = false;
            this.initialize();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
  },
};
</script>