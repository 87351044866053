<template>
<div>
  <v-data-table
    :headers="headers"
    :items="fiscalList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="my-2 mr-2"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="my-2"
              @click="Dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
      </v-toolbar>
    </template>
        <template v-slot:[`item.enable`]="{ item }">
           <div class="d-flex justify-center">
          <v-switch
            v-model="item.enable"
            inset
            color="header"
            hide-details
            class="ma-0"
            @change="enableTransaction(item)"
          ></v-switch>
           </div>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
  </v-data-table>
        <v-dialog
          v-model="Dialog"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">Update Fiscal Year</span>
              <span class="subtitle-1 font-weight-bold" v-else>New Fiscal Year</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                    dense
                     v-model="formData.fiscalYear"
                     :rules="[formRules.required]"
                      label="Fiscal Year"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                text
                @click="save"
                :loading="isLoading"
              >
                Save
              </v-btn>
              <v-btn
              v-else
                color="red darken-1"
                text
                @click="update"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      searchText: '',
      amountErr: null,
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu1: false,
      menu2: false,
      formData: {
        FiscalYear: null,
      },
      Dialog: false,
      fiscalList: [],
      headers: [
        {
          text: 'Fiscal Year',
          align: 'start',
          sortable: false,
          value: 'fiscalYear',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Allow Transactions',
          sortable: false,
          align: "center",
          value: 'enable',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, width: '100', class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
         data.append("searchText", this.searchText);
              this.axiosCall(
              '/getFiscalYear',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.fiscalList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
    editItem(data) {
      this.editItemId = data.id;
      this.formData.fiscalYear = data.fiscalYear;
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate()){
             this.isLoading = true;
             let data = new FormData();
              data.append('fiscalYear', this.formData.fiscalYear);
                         this.axiosCall(
                        '/fiscalYear/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
              this.isLoading = true;
             let data = new FormData();
              data.append('id', this.editItemId);
              data.append('fiscalYear', this.formData.fiscalYear);
                         this.axiosCall(
                        '/fiscalYear/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      enableTransaction(item) {
        let data = new FormData();
        data.append('id', item.id);
        data.append('enable', item.enable ? 1 : 0);
                    this.axiosCall(
                  '/fiscalYear/enable',
                  'POST',
                  data
              )
              .then((res) => {
                  this.isLoading = false;
                  if(res.data.status) {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'success';
                  this.fadeAwayMessage.message = item.enable ? 'Transaction successfully enabled!' : 'Transaction successfully disabled!';
                  this.fadeAwayMessage.header = 'System Message';
                  }else {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'error';
                  this.fadeAwayMessage.message = 'Something went wrong. Try again!';
                  this.fadeAwayMessage.header = 'System Message';
                  }
              })
      }
    }
  }
</script>