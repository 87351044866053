<template>
  <div>
    <v-container fluid fill-height class="content" v-if="!this.token">
      <v-row align="center" justify="center">
        <v-card
              elevation="3"
              class="rounded-lg screen-lower"
              style="overflow: hidden"
            >
          <v-row no-gutters dense>
            <v-col
            cols="12"
            md="6"
            class="hidden-sm-and-down"
            >
            <v-img src="../assets/PSMIS_POSTER.png" height="520"> </v-img>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card-title class="justify-center pa-4 pt-8">
                <h2>
                  <a class="green--text text--darken-4">Sign In</a>
                  <!-- <a class="grey--text text-h6">| Welcome Back</a> -->
                </h2>
              </v-card-title>
              <v-form @submit.prevent ref="Formref">
                <v-row class="mx-5 pt-4">
                  <v-col cols="12" class="pa-0 px-4">
                    <v-text-field
                      color="green"
                      class="font-size-14"
                      v-model="email"
                      :rules="[
                        formRules.required,
                        formRules.email,
                        formRules.emaildnsc,
                      ]"
                      label="Email address"
                      @keyup.enter="dologin()"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pa-0 px-4">
                    <v-text-field
                      color="green"
                      class="font-size-14"
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[formRules.required]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Password"
                      @keyup.enter="dologin()"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                    <div class="text-center">
                      <v-btn
                        class="text-caption"
                        color="header"
                        text
                        @click="forgotpass()"
                      >
                        Forgot Password?
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-center py-7">
                      <v-btn
                        class="
                          log
                          white--text
                          rounded-pill
                          py-2
                          px-10
                          font-size-14
                        "
                        :loading="isLoading"
                        @click="dologin()"
                        >Login</v-btn
                      >
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-center font-size-14">
                      Don't have an account?
                      <router-link to="/register">
                       <v-btn :ripple="false" color="header" text id="no-background-hover">Register</v-btn>
                      </router-link
                      >
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex justify-center mb-5">
                      <img src="../assets/dnsc logo.png" width="55" height="50" class="ma-1 mr-0">
                      <img src="../assets/Bagong_Pilipinas.png" width="50" height="50" class="ma-1">
                      <img src="../assets/ISO.png" width="80" height="50" class="ma-1">
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
        <fade-away-message-component
          displayType="variation2"
          v-model="fadeAwayMessage.show"
          :message="fadeAwayMessage.message"
          :header="fadeAwayMessage.header"
          :top="fadeAwayMessage.top"
          :type="fadeAwayMessage.type"
        ></fade-away-message-component>
      </v-row>
    </v-container>

    <!------In-Review-------->
    <v-container fluid fill-height v-else>
      <v-row align="center" justify="center">
      <v-card
        class="d-flex justify-center rounded-lg align-center"
        flat
        height="500"
        tile
        elevation="4"
      >
        <v-card flat>
          <v-card-title>
            <v-img
              src="../assets/custom icons/in-review.png"
              height="400"
              width="200"
              contain
            >
            </v-img>
          </v-card-title>
          <v-card-title class="d-flex justify-center"
            >Your Account is being Reviewed</v-card-title
          >
          <v-card-text class="d-flex justify-center">
            A confirmation message will be sent to your email shortly.
          </v-card-text>
          <v-card-text class="d-flex justify-center mt-5">
            <v-img src="../assets/PSMIS_LOGO.png" height="50" contain> </v-img>
          </v-card-text>
        </v-card>
      </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  mounted() {
    if (this.$store.state.user) {
      if (!this.$store.state.user.roleID) {
        this.deleteToken();
        setTimeout(function () {
          location.reload();
        }, 5000);
      }
    }
  },
  methods: {
    forgotpass() {
      this.$router.replace({ path: "/forgot-password/" });
    },
    dologin() {
      if (this.$refs.Formref.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("email", this.email);
        data.append("password", this.password);
        this.axiosCall("/login", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            localStorage.setItem("token", res.data.data.data.user.token);
            location.reload();
          } else {
            if (res.data.message === "otp") {
              this.$router.replace({ path: "/register/" + this.email });
            } else {
              this.fadeAwayMessage.show = true;
              this.fadeAwayMessage.type = "error";
              this.fadeAwayMessage.message = res.data.message;
              this.fadeAwayMessage.header = "System Message";
            }
          }
        });
      }
    },
  },
  data: () => ({
    isLoading: false,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    email: "",
    password: "",
    show1: false,
  }),
};
</script>

<style scoped>
.back {
  /* background: url('../assets/thon.png')
    no-repeat center center fixed !important;
    background-color: rgb(49, 112, 49); */
  background-color: rgb(49, 112, 49);
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.content {
  /* background: url('../assets/BG_3rd_Layer.png')
    no-repeat center center fixed !important; */
  background: url("../assets/BG_1st_Layer.png") no-repeat center fixed,
    linear-gradient(0deg, rgba(0, 115, 52, 1) 0%, rgba(46, 92, 7, 1) 100%);
  /* background: rgb(0,115,52);
    background: linear-gradient(90deg, rgba(0,115,52,1) 0%, rgba(108,125,11,1) 100%); */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px;
}
:host {
  --ntp-theme-shortcut-background-color: rgb(229, 231, 232);
  --ntp-theme-text-color: var(--google-grey-800);
  --ntp-theme-text-shadow: none;
  --ntp-one-google-bar-height: 56px;
  --ntp-search-box-width: 337px;
}
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hh {
  height: 90vh;
}
.log {
  background: #048b2c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #769c32,
    #048b2c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #769c32,
    #048b2c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
#no-background-hover{
  text-transform: unset !important;
  font-family: roboto !important;
  margin-top: -7px !important;
  padding: 0 !important;
  font-weight: normal !important;

}
#no-background-hover::before {
   background-color: transparent !important;
}
</style>
