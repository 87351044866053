<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">Positions</h2>
      <v-card class="mt-4">
      <Position/>
  </v-card>
  </div>
</template>

<script>
import Position from '@/components/Utils/Position.vue'
  export default {
    components: {
      Position
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>