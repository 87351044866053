<template>
<div>
  <v-data-table
    :headers="headers"
    :items="LIBList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="my-2 mr-2"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="my-2"
              @click="Dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
      </v-toolbar>
    </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
  </v-data-table>
        <v-dialog
          v-model="Dialog"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">Update LIB Item</span>
              <span class="subtitle-1 font-weight-bold" v-else>New LIB Item</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                  <v-autocomplete
                    v-model="formData.CategoryID"
                    color="green"
                    item-text="CategoryDescription"
                    item-value="CategoryID"
                    :rules="[formRules.required]"
                    :items="LIBCategories"
                    dense
                    class="mb-5"
                    label="Category"
                  >
                  </v-autocomplete>
                    <v-textarea
                    dense
                     v-model="formData.ItemName"
                     :rules="[formRules.required]"
                     counter
                     maxlength="250"
                      label="Item Name"
                    ></v-textarea>
                  </v-col>
                  
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                text
                @click="save"
                :loading="isLoading"
              >
                Save
              </v-btn>
              <v-btn
              v-else
                color="red darken-1"
                text
                @click="update"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      searchText: '',
      amountErr: null,
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu1: false,
      menu2: false,
      formData: {
        ItemName: null,
      },
      Dialog: false,
      LIBList: [],
      headers: [
        {
          text: 'Item Name',
          align: 'start',
          sortable: false,
          value: 'ItemName',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Category',
          align: 'start',
          sortable: false,
          value: 'CategoryDescription',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      LIBCategories: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    mounted(){
      this.getLIBCategories();
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
         data.append("searchText", this.searchText);
              this.axiosCall(
              '/getLIBItems',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.LIBList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
    editItem(data) {
      this.editItemId = data.ItemID;
      this.formData.ItemName = data.ItemName;
      this.formData.CategoryID = data.CategoryID;
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
              this.isLoading = true;
             let data = new FormData();
              data.append('ItemName', this.formData.ItemName);
              data.append('CategoryID', this.formData.CategoryID);
                         this.axiosCall(
                        '/LIBItems/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
              this.isLoading = true;
             let data = new FormData();
              data.append('ItemID', this.editItemId);
               data.append('ItemName', this.formData.ItemName);
               data.append('CategoryID', this.formData.CategoryID);
                         this.axiosCall(
                        '/LIBItems/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
    getLIBCategories(){
      this.axiosCall("/get/LIBCategories", "GET").then((res) => {
        this.LIBCategories = res.data.data;
      });
    }
    }
  }
</script>