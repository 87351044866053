<template>
  <div>
    <v-card class="mx-1" max-width="344" elevation="2" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Help - PR Creation</div>
          <v-list-item-title class="text-h5 mb-1">
            How to Create PR
          </v-list-item-title>
          <v-list-item-subtitle
            >A step-by-step instruction on How to Create your
            PR.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="green"
          rounded
          class="white--text items-center"
          @click="overlay = !overlay"
        >
          Learn How
          <v-icon right dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-card class="mx-1 relative" elevation="2" outlined color="white">
        <div class="scroll">
          <div
            class="
              text-overline
              px-4
              py-2
              black--text
              fixedElement
              white
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>help on PR Creation - How to Create PR</div>
            <v-card-actions>
              <v-btn
                depressed
                color="green"
                rounded
                class="white--text items-center"
                @click="overlay = !overlay"
              >
                Close
                <v-icon right dark> mdi-close </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-list-item three-line>
            <v-list-item-content class="px-4">
              <!-- <v-list-item-title class="text-h5 mb-1 black--text">
              How to Create PR
            </v-list-item-title> -->
              <v-list-item-subtitle class="black--text"
                >1. On the left side of the screen, click on the PPMP Creation
                Tab. Upon Clicking the tab, a list of your PPMP’s will be shown
                on the table.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. On the table, a “PR” button will appear on your PPMP if your
                PPMP is already approved by the president.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR2.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. To review the Approval Tracker of your PPMP, click on the
                view button or the “Eye Icon” under the actions
                column.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR3.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. Upon clicking the view button, a pop-up modal will appear
                which contains the full details of your PPMP including the
                approval tracker.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR4.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >5. To close the modal, click on the close button located at the
                top right side of the screen.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR5.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >6. Upon closing the modal, your PPMP list will appear on the
                screen.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR6.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >7. To create a Purchase Request, click on the PR button beside
                the view button under the actions column.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR7.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >8. Upon clicking the PR button, a pop-up modal will appear
                which has fields to select a category, a PR button and a table
                which will consist of your selected items to be
                PR.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR8.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >9. Click on the select category dropdown menu and select a
                category. These categories that appear here on the list are the
                categories of the items that have been added from your
                PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR9.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >10. Select a category from the list. Upon selecting a category,
                a list of the items under its category will appear on the
                table.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR10.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >11. Select an item by clicking the checkbox on the left side of
                the item name. Upon clicking the checkbox of the item, the
                Purchase Request button will be available to click for you to
                continue your PR.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR11.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >12. Upon clicking the Purchase Request button, a pop-up modal
                will appear and will ask you to input the purpose of this PR you
                are creating.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR12.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >13. To continue, click the “ADD” button or “CANCEL” to close
                the modal.</v-list-item-subtitle
              >

              <v-list-item-subtitle class="black--text mt-8"
                >14. After Clicking the “ADD” button, close the modal by
                clicking the close icon button on the top ride side of the
                screen.</v-list-item-subtitle
              >
              <div class="px-4">
                <img
                  src="@/assets/help images/PR13.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >15. After closing the modal, you will be returned to your PPMP
                list.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR14.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >16. On the left side of the screen, click on the Purchase
                Request Tab. Upon clicking the tab, a list of PR will appear on
                the table.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR15.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >17. To view your PR, click on the eye button or click the print
                button to print your PR.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR16.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >18. Upon clicking the view button, a pop-up modal will appear
                which contains the full details of your
                PR.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PR17.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >19. You have successfully created your Purchase Request. To add
                new PR, repeat the Steps from item number 7 to item number
                18.</v-list-item-subtitle
              >

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalPPMP: false,
      overlay: false,
    };
  },
  methods: {
    showPPMP() {
      this.modalPPMP = !this.modalPPMP;
    },
  },
};
</script>

<style scoped>
.papel {
  z-index: 2;
}
.scroll {
  width: 1000px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.fixedElement {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>