<template>
  <div class="px-5">
  <v-row>
    <v-col cols="12" class="flex-items mt-3">
      <v-text-field
      v-model="searchText"
      append-icon="mdi-magnify"
      label="Search"
      color="green darken-2"
      outlined
      dense
      hide-details
    ></v-text-field>
    <v-spacer></v-spacer>
    <v-autocomplete
      color="green"
      v-model="CategoryTypeID"
      item-text="name"
      item-value="id"
      :items="categoryTypeList"
      dense
      hide-details
      outlined
      label="Filter By Type"
    >
    </v-autocomplete>
    
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="3" class="flex-items">
          <span class="pr-2">Show</span>
          <span>
            <v-select
            dense
            outlined
            hide-details
            v-model="options.itemsPerPage"
            style="max-width: 90px;"
            class="rounded-lg"
            :items="perPageChoices">
        </v-select>
          </span>
          <span class="pl-2">
            Entries
          </span>
        </v-col>
        <v-col cols="12" md="6">
          <v-pagination
              v-model="options.page"
              class="my-4"
              color="header"
              :length="Math.ceil(paginationData.totalCount / paginationData.perPage) || 0"
              :total-visible="7"
            ></v-pagination>
        </v-col>
        <v-col cols="12" md="3" class="flex-items">
          <span>Showing {{ paginationData.pageStart }} to {{ paginationData.pageEnd }} of {{ paginationData.totalCount }} entries</span>
        </v-col>
      </v-row>
      </v-col>
    <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in itemList" :key="index">
    <v-card
    class="mx-auto"
    max-width="320"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="mb-4">
          <b>₱ {{formatPrice(parseFloat(item.UnitCost) + parseFloat(item.UnitCost * (item.Percentage / 100)))}}</b>
        </div>
        <v-list-item-title class="text-subtitle-1 mb-1">
          {{item.ItemName}}
        </v-list-item-title>
        <v-list-item-subtitle>{{item.ItemDescription}}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"
        color="grey"
      >
       <v-img src="../../assets/pms-item-placeholder.jpg"></v-img>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        rounded
        text
        @click="moreDetails(item)"
      >
        More Details
      </v-btn>
      <v-spacer></v-spacer>
      <v-chip
      color="red darken-2"
      text-color="white"
      class="mr-1"
      x-small
      v-if="item.Percentage"
    >
      +{{item.Percentage}}%
    </v-chip>
      <v-chip
      color="header"
      text-color="white"
      x-small
    >
      {{item.CategoryTypeID == 1 ? 'DBM' : item.CategoryTypeID == 2 ? 'NON-DBM' : item.CategoryTypeID == 3 ? 'LIB' : 'Uncategorized'}}
    </v-chip>
    </v-card-actions>
  </v-card>
    </v-col>
    <v-col v-if="itemList.length == 0" cols="12" align="center" class="my-7">No Items Available</v-col>
<v-col cols="12">
      <v-row>
        <v-col cols="12" md="3" class="flex-items">
          <span class="pr-2">Show</span>
          <span>
            <v-select
            dense
            outlined
            hide-details
            v-model="options.itemsPerPage"
            style="max-width: 90px;"
            class="rounded-lg"
            :items="perPageChoices">
        </v-select>
          </span>
          <span class="pl-2">
            Entries
          </span>
        </v-col>
        <v-col cols="12" md="6">
          <v-pagination
              v-model="options.page"
              class="my-4"
              color="header"
              :length="Math.ceil(paginationData.totalCount / paginationData.perPage) || 0"
              :total-visible="7"
            ></v-pagination>
        </v-col>
        <v-col cols="12" md="3" class="flex-items">
          <span>Showing {{ paginationData.pageStart }} to {{ paginationData.pageEnd }} of {{ paginationData.totalCount }} entries</span>
        </v-col>
      </v-row>
      </v-col>
  </v-row>

<v-dialog
      v-model="moreDetailsDialog"
      width="700"
    >

      <v-card>
    <v-img
      height="200"
      src="../../assets/pms-item-placeholder.jpg"
      class="align-end text-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
    <div class="ma-3">
      <v-chip
      color="red darken-2"
      text-color="white"
      class="mr-1"
      v-if="itemData.Percentage"
    >
      +{{itemData.Percentage}}%
    </v-chip>
      <v-chip
      color="header"
      text-color="white"
    >
      {{itemData.CategoryTypeID == 1 ? 'DBM' : itemData.CategoryTypeID == 2 ? 'NON-DBM' : itemData.CategoryTypeID == 3 ? 'LIB' : 'Uncategorized'}}
    </v-chip>
    </div>
    </v-img>

    <v-card-title>{{itemData.ItemName}}</v-card-title>

    <v-card-text>

        <div>
          Actual Price: <b>₱ {{formatPrice(itemData.UnitCost)}}</b>
        </div>
        <div>
          Current Price: <b>₱ {{formatPrice(parseFloat(itemData.UnitCost) + parseFloat(itemData.UnitCost * (itemData.Percentage / 100)))}}</b>
        </div>

      <div class="mt-4 text-subtitle-1">
        Description:
      </div>

      <div>{{itemData.ItemDescription || 'No Description'}}</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title class="font-size-16">Other Details:</v-card-title>

    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" md="4">Unit Measure:</v-col>
        <v-col cols="12" md="8"><b>{{itemData.UnitMeasureDescription}}</b></v-col>
        <v-col cols="12" md="4">Category:</v-col>
        <v-col cols="12" md="8"><b>{{itemData.CategoryDescription}}</b></v-col>
        <v-col cols="12" md="4">Classification:</v-col>
        <v-col cols="12" md="8"><b v-if="itemData.ClassificationID">{{itemData.ClassificationDescription}}</b><b v-else>None</b></v-col>
        <v-col cols="12" md="4">Stock/Property Code:</v-col>
        <v-col cols="12" md="8"><b v-if="itemData.ClassificationID">{{'###-' + itemData.Code}}{{itemData.Stock ? '-' + itemData.Stock : itemData.Property ? '-' + itemData.Property : '-####'}}</b><b v-else>None</b></v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="header"
        text
        @click="moreDetailsDialog = false"
      >
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    searchText: "",
    listView: true,
    editItemId: null,
    loading: false,
    perPageChoices: [
          {text:'5' , value: 5},
          {text:'10' , value: 10},
          {text:'20' , value: 20},
          {text:'50' , value: 50},
          {text:'100' , value: 100},
          {text:'250' , value: 250},
          {text:'500' , value: 500},
      ],
    options: {
      itemsPerPage: 50,
      page: 1,
    },
    isLoading: false,
    itemList: [],
    categoryTypeList: [],
    CategoryTypeID: 0,
    paginationData: [],
    moreDetailsDialog: false,
    itemData: [],
  }),
  watch: {
    options: {
        handler () {
         this.initialize();
        },
        deep: true,
    },
    searchText: function () {
        this.paginationData = [];
        this.options.page = 1;
        this.initialize();
    },
    CategoryTypeID: function () {
        this.paginationData = [];
        this.options.page = 1;
        this.initialize();
    },
  },
  created() {
    this.initialize();
    this.getClassification();
  },
  methods: {
    getClassification() {
      this.axiosCall("/getCategoryTypes", "GET").then((res) => {
        this.categoryTypeList = this.prepend({id: 0, name: 'All'},res.data.data.categoryType);
      });
    },
    prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
    },
    initialize() {
      this.loading = true;
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("CategoryTypeID", this.CategoryTypeID);
      this.axiosCall("/get/allItems", "POST", data).then((res) => {
        this.loading = false;
        this.itemList = res.data.data.result;
        this.paginationData = res.data.data.pagination_data;
        if (this.paginationData.totalCount <= 10) {
          this.options.page = 1;
        }
        else{
          this.options.page = res.data.data.pagination_data.page;
        }
      });
    },
    moreDetails(data){
      this.itemData = data;
      this.moreDetailsDialog = true;
    }
  },
};
</script>