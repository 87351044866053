<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="prList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search for PR purpose"
            color="green darken-2"
            class="mb-2 mr-2"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-2"
            dark
            outlined
            elevation="2"
            class="mb-2"
            @click="dialogNEW = true"
          >
            <v-icon>mdi-plus</v-icon>
            Create New
          </v-btn>
        </v-toolbar>
      </template>
        <template v-slot:[`item.PRCODE`]="{ item }">
          {{ item.isApproved == 1 ? item.PRCODE : item.PRCODETemp }}
        </template>
        <template v-slot:[`item.FundCode`]="{ item }">
          {{ item.FundCode  }} - {{ item.fiscalYear }}
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <span class="text-no-wrap">
            <v-btn
                color="green darken-1"
                outlined
                x-small
                class="mx-2"
                @click="viewPRs(item)"
              >
                PR List
              </v-btn>
              <v-icon small class="mr-2" @click="viewPR(item)">
                visibility
              </v-icon>
              <v-icon
                small
                class="mr-2"
                color="red accent-4"
                @click="print(item)"
              >
                mdi-printer
              </v-icon>
              <v-btn
                color="red darken-1"
                outlined
                x-small
                class="mx-2"
                @click="apr(item)"
              >
                APR
              </v-btn>
              <v-btn
                color="blue darken-1"
                outlined
                x-small
                class="mx-2"
              >
                PR
              </v-btn>
          </span>
        </template>
    </v-data-table>
    <prModlal :prData="prItem" />
    <CreateNew :prData="prItem2" :dialogMenu="dialogNEW" />
    <PRs :prData="prItem3" :dialogMenu="dialogPRs" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    prModlal: () => import("./PRViewItem"),
    CreateNew: () => import("./VPViewPRs"),
    PRs: () => import("./VPViewPRList"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    dialogNEW: false,
    dialogPRs: false,
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    editItemId: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    menu: false,
    menu1: false,
    formData: {
      PPMPDescription: null,
      isDBM: null,
      BudgetID: null,
      Amount: null,
    },
    addPPMPDialog: false,
    prList: [],
    budgetList: [],
    prItem: [],
    prItem2: [],
    prItem3: [],

    headers: [
      {
        text: "PR No.",
        align: "start",
        sortable: false,
        value: "PRCODE",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Purpose",
        value: "PrPurpose",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Date",
        value: "DateCreated",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Fund Code",
        value: "FundCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "Actions",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.eventHub.$on("closeModalCons", () => {
      this.prData = {};
      this.dialogNEW = false;
      this.initialize();
    });
    this.eventHub.$on("closePRItemodal1", () => {
      this.prData = {};
      this.initialize();
    });
    this.eventHub.$on("closeModalCons1", () => {
      this.prData = {};
      this.dialogPRs = false;
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeModalCons");
    this.eventHub.$off("closeModalCons1");
    this.eventHub.$off("closePRItemodal1");
  },
  methods: {
    viewPRs(item) {
      this.prItem3 = item;
      this.dialogPRs = true;
    },
    print(data) {
      window.open(this.apiUrl + "/pr/report/" + data.PRID);
    },
    viewPR(item) {
      this.prItem = item;
      console.log(this.prItem);
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("fiscalYear", this.fiscalYear);
      this.axiosCall("/get/consolidated/pr", "POST", data).then((res) => {
        this.loading = false;
        this.prList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    apr(data) {
      window.open(this.apiUrl + "/pr/apr/" + data.PRID);
    },
  },
};
</script>