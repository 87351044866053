<template>
  <div class="px-2">
    <v-data-table
      :headers="headers"
      :items="categoryList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-0"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-subtitle-1 font-weight-bold"
            >Category List</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Category"
          color="green"
          single-line
          hide-details
        ></v-text-field> -->
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-2"
            dark
            outlined
            elevation="2"
            class="mt-2"
            @click="addDialog = true"
          >
            <v-icon>mdi-plus</v-icon>
            Add New Category
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="addDialog" persistent max-width="350px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-bold" v-if="editItemId"
            >Update Category</span
          >
          <span class="subtitle-1 font-weight-bold" v-else> New Category</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form @submit.prevent ref="formRef">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    color="green"
                    dense
                    v-model="formData.Description"
                    :rules="[formRules.required]"
                    label="Category Description"
                    counter
                    maxlength="250"
                  ></v-textarea>
                  <v-checkbox
                    style="width: 50px"
                    v-model="formData.isDBM"
                    label="DBM"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancel()"> Cancel </v-btn>
          <v-btn
            v-if="!editItemId"
            color="green darken-1"
            text
            @click="save"
            :loading="isLoading"
          >
            Add
          </v-btn>
          <v-btn
            v-else
            color="green darken-1"
            text
            @click="update"
            :loading="isLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  data: () => ({
    editItemId: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    formData: {
      Description: null,
      isDBM: null,
    },
    addDialog: false,
    categoryList: [],
    headers: [
      {
        text: "Category Description",
        value: "CategoryDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        class:
          "green darken-2 white--text text-caption font-weight-bold d-flex justify-center align-center",
      },
    ],
  }),
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  created() {
    // this.initialize();
  },
  methods: {
    editItem(data) {
      this.editItemId = data.CategoryID;
      this.formData.Description = data.CategoryDescription;
      this.formData.isDBM = data.isDBM;
      this.addDialog = true;
    },
    cancel() {
      this.editItemId = null;
      this.$refs.formRef.reset();
      this.addDialog = false;
    },
    initialize() {
      this.loading = true;
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      this.axiosCall("/getAllCategory", "POST", data).then((res) => {
        this.loading = false;
        this.categoryList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
    },
    save() {
      if (this.$refs.formRef.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("Description", this.formData.Description);
        data.append("isDBM", this.formData.isDBM);
        this.axiosCall("/addCategory", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Category Successfully Added!";
            this.fadeAwayMessage.header = "System Message";
            this.$refs.formRef.reset();
            this.addDialog = false;
            this.initialize();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    update() {
      if (this.$refs.formRef.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("CategoryID", this.editItemId);
        data.append("Description", this.formData.Description);
        data.append("isDBM", this.formData.isDBM);
        this.axiosCall("/updateCategory", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Category Successfully Updated!";
            this.fadeAwayMessage.header = "System Message";
            this.$refs.formRef.reset();
            this.editItemId = null;
            this.addDialog = false;
            this.initialize();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
  },
};
</script>