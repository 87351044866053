<template>
  <nav>
    <div class="navbar-content">
    <v-app-bar
      app
      color="DarkWhite"
      absolute
      class="rounded-tr-xl pa-0 app-bar-style"
      flat
      elevate-on-scroll
    >
      <v-toolbar-title class="text-uppercase">
        <v-btn
        class="hidden-md-and-up mr-1"
        @click.stop="drawer = !drawer"
        icon
        ><v-icon>mdi-menu</v-icon></v-btn>
        <v-avatar size="45" class="mr-1">
          <v-img src="../../assets/PSMIS_LOGO.png" alt=""> </v-img>
          <!-- <v-img width="45" src="../../assets/PSMIS_LOGO.png" alt="" class="drawer-img"> </v-img> -->
        </v-avatar>
        <!-- <b v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 3  || $store.state.user.roleID == 15  || $store.state.user.roleID == 16  || $store.state.user.roleID == 17"> Supply Information System</b>
        <b v-else> Procurement Management System</b> -->
        <b>Procurement and Supply Management Information System</b>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="py-2">
        <v-autocomplete
          style="max-width: 250px"
          flat
          rounded
          v-model="searchModule"
          color="secondary2"
          class="nav_search hidden-md-and-down"
          prepend-inner-icon="mdi-magnify"
          append-icon=""
          density="comfortable"
          background-color="secondary1"
          placeholder="Search module"
          menu-icon=""
          single-line
          solo
          hide-details
          item-props
          item-text="title"
          item-value="route"
          :items="ModuleItems"
        >
        <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title class="text-center pa-4">
            <v-row class="ma-3" justify="center">
            <v-img max-width="120" src="../../assets/search-module.png"></v-img>
            </v-row>
            No 
            <strong>Module</strong> Found
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar
          color="header"
          class="text-h5 font-weight-light white--text"
        >
          <v-icon color="white">{{item.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{item.title}}</v-list-item-title>
          <v-list-item-subtitle>{{item.RoleDescription}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon v-if="item.RoleDescription" color="grey">mdi-view-module</v-icon>
          <v-icon v-else color="grey">mdi-view-module-outline</v-icon>
        </v-list-item-action>
      </template>
        </v-autocomplete>
      </v-toolbar-items>
      <v-toolbar-items class="py-2">
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-toolbar-title
              align-self-center
              class="secondary1 rounded-xl ml-4 pl-4 d-flex align-center"
            >
              <v-btn elevation="0" icon small class="hidden-lg-and-up mr-1" @click="dialogSearch = true">
                  <v-icon color="secondary2" dark>mdi-magnify</v-icon>
              </v-btn>
              <!-- <v-btn elevation="0" icon small  class="mr-2">
                <v-badge offset-y="14" offset-x="10" color="red" content="6">
                  <v-icon color="secondary2" dark>mdi-bullhorn-variant</v-icon>
                </v-badge>
              </v-btn> -->
              <v-menu
                offset-y
                max-width="550" min-width="400" max-height="600"
                class="pt-10"
                transition="slide-y-transition"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    color="red"
                    dot
                    overlap
                    :value="announcements.length > 0 && !announcement_clicked"
                  >
                  <v-btn
                  elevation="0"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="announcement_clicked = true"
                  >
                    <v-icon color="secondary2" dark>mdi-bullhorn-variant</v-icon>
                  </v-btn>
                  </v-badge>
                </template>

                <v-card max-height="550px">
                  <v-card-title class="pt-2" style="height: 40px">
                    <span class="mr-5">Announcements</span>
                    <v-spacer></v-spacer>
                    <v-btn-toggle
                      v-model="toggleActive"
                      divided
                      variant="outlined"
                    >
                      <v-btn small>All</v-btn>
                      <v-btn small>Active</v-btn>
                      <v-btn small>Inactive</v-btn>
                    </v-btn-toggle>
                  </v-card-title>
                  <v-card-text class="ma-0 pa-0">
                    <v-list three-line v-if="announcements.length > 0">
                  <v-list-item-group>
                    <template v-for="(item, index) in announcements">
                      <v-list-item :key="'announce'+index" exact-active-class="header" @click="viewAnnouncement(item)">
                        <template v-slot:default="{ active }">
                          <v-list-item-avatar>
                            <v-icon
                              class="header lighten-1"
                              color="white"
                              v-if="item.AnnouncementTypeID == 1"
                            >
                              mdi-consolidate
                            </v-icon>
                            <v-icon
                              class="header lighten-1"
                              color="white"
                              v-if="item.AnnouncementTypeID == 2"
                            >
                              mdi-file-document-refresh-outline
                            </v-icon>
                            <v-icon
                              class="header lighten-1"
                              color="white"
                              v-if="item.AnnouncementTypeID == 3"
                            >
                              mdi-autorenew
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{item.title}}</v-list-item-title>

                            <!-- <v-list-item-subtitle
                              class="text--primary"
                              v-text="item.headline"
                            ></v-list-item-subtitle> -->

                            <v-list-item-subtitle>{{item.details.Description}}</v-list-item-subtitle>
                            <v-list-item-subtitle><b>{{formatDate(item.details.DateStart)}} - {{formatDate(item.details.DateEnd)}}</b>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="d-none">{{active}}</v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text>
                              <span v-if="item.action >= 511000">
                                {{Math.floor(item.action/511000)}} yr/s ago
                              </span>
                              <span v-else-if="item.action >= 9800">
                                {{Math.floor(item.action/9800)}} wk/s ago
                              </span>
                              <span v-else-if="item.action >= 1400">
                                {{Math.floor(item.action/1400)}} day/s ago
                              </span>
                              <span v-else-if="item.action >= 60">
                                {{Math.floor(item.action/60)}} hr/s ago
                              </span>
                              <span v-else-if="item.action >= 1">
                                {{item.action}} min/s ago
                              </span>
                              <span v-else>
                                Just now
                              </span>
                            </v-list-item-action-text>

                            <v-icon
                            v-if="item.details.isActive == 1"
                              color="header"
                              small
                            >
                              mdi-newspaper
                            </v-icon>
                            <v-icon
                            v-else
                              color="grey lighten-1"
                              small
                            >
                              mdi-newspaper
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>

                      <v-divider
                        v-if="index < announcements.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
                <v-list v-else class="d-flex align-center flex-column py-5">No announcements</v-list>
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-menu
                offset-y
                max-width="550"  min-width="400" max-height="600"
                transition="slide-y-transition"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-badge
                    color="red"
                    overlap
                    :value="notif_unseen > 0 && !notif_clicked"
                    :content="notif_unseen"
                  >
                  <v-btn
                  elevation="0"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mx-1"
                  @click="notif_clicked = true"
                  >
                    <v-icon color="secondary2" dark>mdi-bell</v-icon>
                  </v-btn>
                  </v-badge>
                </template>

                <v-card max-height="550px">
                  <v-card-title class="pt-1" style="height: 35px">
                    Notifications
                  </v-card-title>
                  <v-card-text class="ma-0 pa-0">
                    <v-list three-line v-if="notifications.length > 0">
                  <v-list-item-group>
                    <template v-for="(item, index) in notifications">
                      <v-list-item :key="'notif' + index" @click="viewDetails(item)">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>{{item.title}}</v-list-item-title>

                            <!-- <v-list-item-subtitle
                              class="text--primary"
                              v-text="item.headline"
                            ></v-list-item-subtitle> -->

                            <v-list-item-subtitle>{{item.subtitle}} <span v-if="item.details.PPMPTypeID == 1">(DBM)</span>
                            <span v-if="item.details.PPMPTypeID == 2">(Non-DBM)</span>
                            <span v-if="item.details.PPMPTypeID == 3">(LIB)</span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle><b>{{"Code: " + item.details.Code}}</b></v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-list-item-action-text>
                              <span v-if="item.action >= 511000">
                                {{Math.floor(item.action/511000)}} yr/s ago
                              </span>
                              <span v-else-if="item.action >= 9800">
                                {{Math.floor(item.action/9800)}} wk/s ago
                              </span>
                              <span v-else-if="item.action >= 1400">
                                {{Math.floor(item.action/1400)}} day/s ago
                              </span>
                              <span v-else-if="item.action >= 60">
                                {{Math.floor(item.action/60)}} hr/s ago
                              </span>
                              <span v-else-if="item.action >= 1">
                                {{item.action}} min/s ago
                              </span>
                              <span v-else>
                                Just now
                              </span>
                            </v-list-item-action-text>

                            <v-icon
                              v-if="item.IsSeen == 1"
                              color="grey lighten-1"
                              small
                            >
                              mdi-checkbox-multiple-blank-circle-outline
                            </v-icon>

                            <v-icon
                              v-else-if="item.IsSeen == 0"
                              color="green darken-3"
                              small
                            >
                              mdi-checkbox-multiple-blank-circle
                            </v-icon>
                          </v-list-item-action>
                        </template>
                      </v-list-item>

                      <v-divider
                        v-if="index < notifications.length - 1"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
                <v-list v-else class="d-flex align-center flex-column py-5">No notifications</v-list>
                  <v-row justify="center" class="ma-0 pa-0 mb-2" v-if="notifications.length > 0">
                    <v-btn v-if="notifications.length < notif_total" class="elevation-0" color="transparent" :loading="loading_notif" @click="showMoreNotif()">show more</v-btn>
                    <span v-else>---------- No more data ----------</span>
                  </v-row>
                  </v-card-text>
                </v-card>
                
              </v-menu>
              <v-btn text v-on="on" class="nav_btns">
                <v-icon left>mdi-chevron-down</v-icon>
                <span
                  >{{ $store.state.user.FirstName }}
                  {{ $store.state.user.LastName.charAt(0) }}.</span
                >
                <v-avatar size="30">
                  <v-icon dark> mdi-account-circle </v-icon>
                </v-avatar>
              </v-btn>
            </v-toolbar-title>
          </template>
          <v-list dense class="px-2" transition="slide-y-transition">
            <v-list-item-group color="green darken-2">
              <v-list-item router :to="'/' + BaseUrl + '/announcements'">
                <v-list-item-icon class="mr-2">
                  <v-icon
                    small
                    class="font-weight-light"
                  >mdi-bullhorn-variant</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-light"
                  >Announcements</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item router :to="'/' + BaseUrl + '/settings'">
                <v-list-item-icon class="mr-2">
                  <v-icon
                    small
                    class="font-weight-light"
                  >mdi-cog</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-light"
                  >Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="darkMode">
                  <v-list-item-icon class="mr-2">
                    <v-icon small class="tabs_font font-weight-light"
                      >mdi-switch</v-icon>
                  </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="tabs_font font-weight-light">
                    Switch Theme
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logout()">
                <v-list-item-icon class="mr-2">
                  <v-icon
                    small
                    color="red darken-2"
                    class="font-weight-light"
                  >mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="font-weight-light"
                  >Sign Out</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
  </div>
  <div>
    <PPMPModal :ppmpData="ppmpData" />
    <PRModal :prData="prData" />
    <POModal :poData="poData" />
    <IARModal :poData="iarData" />
    <RISModal :risData="risData" />
    <ICSModal :icsData="icsData" />
    <PARModal :parData="parData" />
    <RSModal :rsData="rsData" />
    <AnnouncementModal :data="announcementData" />
    <v-dialog
      v-model="dialogEsign"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5">
           Reminder!
        </v-card-title>

        <v-card-text class="text-justify">
          As an integral part of our <strong>Procurement and Supply Management Information System</strong>, uploading your <strong>E-signature</strong> is essential for documents such as but not limited to <strong>PPMP</strong>, <strong>PR</strong>, and <strong>Issuance Slip</strong>. Rest assured, the system incorporates security measures to safeguard the confidentiality and integrity of your information throughout its lifecycle within our system.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            outlined
            class="elevation-0 white--text"
            @click="remindLater()"
          >
            Remind Me Later
          </v-btn>
          <v-btn
            color="header"
            class="elevation-0 white--text"
            router :to="'/' + BaseUrl + '/settings'"
            @click="dialogEsign = false"
          >
            Go To Settings
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSearch"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
           Search Module
           <v-spacer></v-spacer>
           <v-btn icon @click="dialogSearch = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>

        <v-card-text>
          <v-autocomplete
          flat
          rounded
          v-model="searchModule"
          color="secondary2"
          class="nav_search"
          prepend-inner-icon="mdi-magnify"
          append-icon=""
          density="comfortable"
          background-color="secondary1"
          placeholder="Search module"
          menu-icon=""
          single-line
          solo
          hide-details
          item-props
          item-text="title"
          item-value="route"
          :items="ModuleItems"
        >
        <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title class="text-center pa-4">
            <v-row class="ma-3" justify="center">
            <v-img max-width="120" src="../../assets/search-module.png"></v-img>
            </v-row>
            No 
            <strong>Module</strong> Found
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-avatar
          color="header"
          class="text-h5 font-weight-light white--text"
        >
          <v-icon color="white">{{item.icon}}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{item.title}}</v-list-item-title>
          <v-list-item-subtitle>{{item.RoleDescription}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon v-if="item.RoleDescription" color="grey">mdi-view-module</v-icon>
          <v-icon v-else color="grey">mdi-view-module-outline</v-icon>
        </v-list-item-action>
      </template>
        </v-autocomplete>
        </v-card-text>
      </v-card>
    </v-dialog>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      app
      absolute
      class="DarkWhite rounded-tl-xl pb-lg-10 pb-md-5 pb-sm-0 scroll-style"
      :permanent="$vuetify.breakpoint.mdAndUp"
      hide-overlay
    >
      <v-toolbar dense flat color="transparent" class="hidden-md-and-up">
        <v-spacer></v-spacer>
        <v-btn dense icon class="mt-2" @click="drawer = !drawer">
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="hidden-sm-and-down mb-5"></div>
        <v-list align="center">
          <v-hover>
          <template v-slot:default="{ hover }">
          <v-card class="rounded-circle elevation-3" width="100" height="100">
          <v-img
            :src="image"
            class="rounded-circle"
            width="100" height="100"
          ></v-img>
          <v-fade-transition>
          <v-overlay
            v-if="hover"
            absolute
            color="header"
          >
            <v-btn icon x-large dark router :to="'/' + BaseUrl + '/settings'"><v-icon>mdi-account-edit-outline</v-icon></v-btn>
          </v-overlay>
        </v-fade-transition>
          </v-card>
        </template>
        </v-hover>
        </v-list>
        <v-list
          :class="toggleMini ? 'd-none' : 'd-flex'"
          class="justify-center"
          align="center"
        >
          <span
            class="
              secondary2--text
              text-subtitle-2
              text-uppercase
            "
          >
            {{ $store.state.user.FirstName }} {{ $store.state.user.LastName }} 
          </span>
        </v-list>
        
      <!-- <v-list dense class="px-3">
        <v-list-item-group color="green darken-2">
          <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            :to="link.route"
          >
            <v-list-item-icon class="mr-2">
              <v-icon class="tabs_font_icon">{{link.icon}}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                class="tabs_font_text"
              >{{link.text}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list> -->
      <v-list nav dense class="sidebar">
          <div v-for="(link, i) in links" :key="i">
          <span v-if="link.RoleType == 2 && links[i-1].RoleType != link.RoleType" :key="link.RoleType">
            <span class="text-body-2 mx-1">Other Modules</span>
            <v-divider class="mx-1 mb-1"></v-divider>
          </span>
          <v-list-item
            v-if="!link.subLinks "
            :key="link.title"
            router :to="'/'+ BaseUrl + link.route"
            color="green darken-2"
            class="mx-2 my-1"
          >
            <v-list-item-icon class="mr-5">
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title  class="item-title">{{ link.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            
            <v-list-item
            v-else-if="link.subLinks && link.IsExtract"
            v-for="sublink in link.subLinks"
            :key="sublink.title"
            router :to="'/'+ BaseUrl + sublink.route"
            color="green darken-2"
            class="mx-2 my-1"
          >
            <v-list-item-icon class="mr-5">
              <v-icon>{{ sublink.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title  class="item-title">{{ sublink.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-list-group
            v-else
            :key="link.title"
            no-action
            :prepend-icon="link.icon"
            :value="false"
            color="green darken-2"
            class="mx-2"
        >
            <template v-slot:activator>
              <v-list-item-title>{{ link.title }}</v-list-item-title>
             </template>
            <div class="sub-item">
            <v-list-item
                v-for="sublink in link.subLinks"
                router :to="'/'+ BaseUrl + sublink.route"
                :key="sublink.title"
                color="green darken-2"
                class="my-1"
            >
                <v-list-item-icon class="mr-5">
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ sublink.title }}</v-list-item-title>

            </v-list-item>
            </div>
        </v-list-group>
          </div>
        </v-list>
        <span v-if="links.length == 0">
        <v-skeleton-loader
          type="list-item-avatar"
          max-width="220"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar"
          max-width="200"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar"
          max-width="180"
        ></v-skeleton-loader>
         <v-skeleton-loader
          type="list-item-avatar"
          max-width="250"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar"
          max-width="230"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar"
          max-width="190"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="divider"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar"
        ></v-skeleton-loader>
        <v-skeleton-loader
          type="list-item-avatar"
          max-width="220"
        ></v-skeleton-loader>
        </span>
      <div class="hidden-sm-and-down mt-5"></div>
      <v-toolbar dense flat color="transparent" class="hidden-md-and-up">
      </v-toolbar>
    </v-navigation-drawer>
  </nav>
</template>
<script>
export default {
  components: {
    PPMPModal: () => import("../Utils/PPMPAddItem"),
    PRModal: () => import("../Utils/PRViewItem"),
    POModal: () => import("../PO-Components/PSUViewPOItem.vue"),
    IARModal: () => import("../IAR/ViewIAR.vue"),
    RISModal: () => import("../Supply/ViewRIS.vue"),
    ICSModal: () => import("../Supply/ViewICS.vue"),
    PARModal: () => import("../Supply/ViewPAR.vue"),
    RSModal: () => import("../Supply/ViewRS.vue"),
    AnnouncementModal: () => import("../Utils/ViewAnnouncement.vue"),
  },
  data: () => ({
    drawer: true,
    overlay: false,
    toggleMini: false,
    links: [],
    notifications: [],
    announcements: [],
      image: require("../../assets/avatar-placeholder.png"),
      BaseUrl: '',
      fm_links: [
        {
          icon: "mdi-cash-marker",
          title: "Allocated Budgets",
          route: "/allocated-budget",
        },
        {
          icon: "mdi-view-dashboard-edit",
          title: "PPMP and LIB List",
          route: "/ppmp-lib-list",
        },
        {
          icon: "mdi-file-outline",
          title: "Purchase Request",
          route: "/purchaserequest",
        },
        {
          icon: "mdi-file-check-outline",
          title: "Purchase Orders",
          route: "/user-polist",
        }],
      ra_links: [{
            icon: "mdi-file-multiple",
            title: "Recommending Approval",
            route: "/ra-ppmp",
          }],
      bac_links: [{
            icon: "mdi-file-check-outline",
            title: "Approved PPMP",
            route: "/pres-approved-ppmp",
          },
          {
          icon: "mdi-file-check-outline",
          title: "APP Management",
          route: "/bac-app",
          },
          {
          icon: "mdi-file-check-outline",
          title: "PR Management",
          route: "/psu-prlist",
          },
          {
          icon: "mdi-file-check-outline",
          title: "AOQ Management",
          route: "/bac-aoq",
          }],
      help_links: [
        { icon: "mdi-help", title: "Help", route: "/help" },
      ],
      ppmpData: [],
      prData: [],
      poData: [],
      iarData: [],
      risData: [],
      icsData: [],
      parData: [],
      rsData: [],
      notif_total: 0,
      notif_unseen: 0,
      notif_clicked: false,
      loading_notif: false,
      announcementData: [],
      toggleActive: 1,
      announcement_clicked: false,
      dialogEsign: false,
      ModuleItems: [],
      searchModule: '',
      dialogSearch: false
  }),
  watch: {
    ppmpData: {
      handler() {},
      deep: true,
    },
    prData: {
      handler() {},
      deep: true,
    },
    poData: {
      handler() {},
      deep: true,
    },
    toggleActive: {
      handler() {
        this.getAnnouncements();
      },
      deep: true,
    },
    searchModule: {
      handler(data) {
      if(data != null && data != ''){
      this.$router.push("/" + this.$store.state.user.BaseUrl + data)
      this.dialogSearch = false
      }
      },
      deep: true,
    },
  },
  created: function () {
    if(!this.$vuetify.breakpoint.mdAndUp){
      this.drawer = false
    }
    this.$vuetify.theme.dark = this.$store.state.theme != null ? this.$store.state.theme : this.$vuetify.theme.dark
    
    let role = this.$store.state.user;
    this.BaseUrl = this.$store.state.user.BaseUrl;
    this.getUserModules();
    this.getProfilePic(role.ProfilePic);
    this.getNotifications();
    this.getAnnouncements();
    let user = this.$store.state.user;
    if(user.EsignRequired == 1){
    this.getEsignature(user.UserID);
    }
  },
  mounted(){
    this.eventHub.$on('reloadSideBar', ()=>{
       this.getProfilePic(this.$store.state.user.ProfilePic);
    });
    this.eventHub.$on('closeViewAnnouncement', ()=>{
       this.getAnnouncements();
    });
    let modules = JSON.parse(JSON.stringify(this.$store.state.module))
    let modules_final = [];
    modules.forEach(item => {
      if(item.subLinks){
        modules_final.push({ divider: true },
        { header: item.title + " (" + item.RoleDescription + ")" },{ divider: true },...item.subLinks)
      }
      else{
        modules_final.push(item)
      }
    });
    this.ModuleItems = modules_final
  },
  beforeDestroy() {
    this.eventHub.$off('reloadSideBar');
    this.eventHub.$off("closeViewAnnouncement");
  },
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.dispatch("setTheme", this.$vuetify.theme.dark);
    },
    getProfilePic(filename){
        let data = new FormData();
        data.append('ProfilePic', filename || '');
              this.axiosCall(
            '/getProfilePic',
            'POST',
            data
        )
        .then((res) => {
            if(res.data.status) {
              this.image = res.data.data
            }else {
              this.image = require("../../assets/avatar-placeholder.png")
            }
        })
    },
    getUserModules() {
      this.axiosCall("/getModulePerRoleForMenu", "GET").then((res) => {
        this.links = res.data.data;
      });
    },
    getNotifications() {
      let offset = this.notifications.length;
      this.axiosCall("/getNotificationList/" + offset, "GET").then((res) => {
        this.notifications.push(...res.data.data.result);
        this.notif_total = res.data.data.total;
        this.notif_unseen = res.data.data.unseen;
        this.loading_notif = false;
      });
    },
    showMoreNotif(){
      this.loading_notif = true;
      this.getNotifications();
    },
    getAnnouncements() {
      let status = this.toggleActive;
      this.axiosCall("/getAnnouncementList/" + status, "GET").then((res) => {
        this.announcements = res.data.data;
      });
    },
    viewDetails(data){
      if(data.EventID == 1){
        this.$router.push("/" + this.BaseUrl + "/allocated-budget")
      }
      else if(data.EventID == 2 || data.EventID == 3 || data.EventID == 4 || data.EventID == 5){
        this.ppmpData = data.details;
      }
      else if(data.EventID == 6 || data.EventID == 7 || data.EventID == 8 || data.EventID == 9){
        this.prData = data.details;
      }
      else if(data.EventID == 10 || data.EventID == 11 || data.EventID == 12 || data.EventID == 13){
        this.poData = data.details;
      }
      else if(data.EventID == 14 || data.EventID == 15 || data.EventID == 16 || data.EventID == 17){
        this.iarData = data.details;
      }
      else if(data.EventID == 18 || data.EventID == 19 || data.EventID == 20 || data.EventID == 21){
        this.risData = data.details;
      }
      else if(data.EventID == 22 || data.EventID == 23 || data.EventID == 24 || data.EventID == 25){
        this.icsData = data.details;
      }
      else if(data.EventID == 26 || data.EventID == 27 || data.EventID == 28 || data.EventID == 29){
        this.parData = data.details;
      }
      else if(data.EventID == 30 || data.EventID == 31 || data.EventID == 32 || data.EventID == 33){
        this.rsData = data.details;
      }
      if(data.IsSeen == 0){
        this.seen(data.id);
      }
    },
    seen(id){
      let data = new FormData();
      data.append('id', id);
      this.axiosCall("/notification/seen", "POST", data).then((res) => {
        if(res.data.status){
          this.notifications.forEach(item => {
            if(item.id == id){
              item.IsSeen = 1;
            }
          });
        }
      });
    },
    viewAnnouncement(data){
      this.announcementData = data;
    },
    getEsignature(UserID){
        let data = new FormData();
        data.append('UserID', UserID || '');
              this.axiosCall(
            '/getEsign',
            'POST',
            data
        )
        .then((res) => {
            if(res.data.status) {
              if(res.data.data){
                this.dialogEsign = false;
              }
              else{
                if(this.$store.state.reminder){
                let d1 = new Date();
                let d2 = new Date(this.$store.state.reminder)
                if(d1.getTime() >= d2.getTime()){
                  this.dialogEsign = true;
                }
                else{
                  this.dialogEsign = false;
                }
                }
                else{
                  this.dialogEsign = true;
                }
              }
            }
            else{
              this.dialogEsign = true;
            }
        })
    },
    remindLater(){
      var date = new Date();
      date.setDate(date.getDate() + 1);
      this.$store.dispatch("setReminder", date);
      // console.log(this.$store.state.reminder)
      this.dialogEsign = false;
    }
  },
};
</script>
<style scoped>
.border {
  border-left: 4px solid #0ba518;
}
.v-avatar .outlined {
  border-color: #43a047;
  border-width: 4px;
}
.v-list-item {
  background-color: transparent;
}
.v-list-item--active {
  background-color: #e8f5e9 !important;
  /* border-radius: 10px; */
}
.tabs_font {
  font-size: 11t !important;
}
.tabs_font_text {
  font-size: 9pt !important;
}
.tabs_font_icon {
  font-size: 14pt !important;
}
.v-menu__content {
  top: 60px !important;
}
.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  width: 400px !important;
}
.nav_btns::before {
  color: transparent;
}

.nav_btns:hover {
  color: #757575;
}
.v-input__control > .v-input__slot,
.nav_search {
  width: 450px !important;
}
.row-pointer {
  cursor: pointer;
}
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
/* .ab-style{ 
  -webkit-appearance: none;
  -moz-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.4) !important;
  -webkit-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.4) !important;
} */
.sub-item {
  border-radius: 5px;
  background: rgba(229, 229, 229, 0.3);
}
/* .drawer-img {
  width: 270px;
  border: 4px solid #2d5d09;
} */

</style>