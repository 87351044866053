<template>
<div>
  <v-data-table
    :headers="headers"
    :items="announcementList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="mb-2 mr-2"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          small
          color="header"
          outlined
          class="white--text"
          @click="viewDetails(item)">
          More details
        </v-btn>
        </span>
      </template>
  </v-data-table>
  <AnnouncementModal :data="announcementData" />
  </div>
</template>

<script>
  export default {
    components: {
      AnnouncementModal: () => import("../Utils/ViewAnnouncement.vue"),
    },
    data: () => ({
      searchText: '',
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
      options: {},
      isLoading: false,
      Dialog: false,
      announcementList: [],
      announcementData: [],
      headers: [
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'title',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'subtitle',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, width: '100', class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    mounted() {
      this.eventHub.$on("closeViewAnnouncement", () => {
      this.initialize();
    });
    },
    beforeDestroy() {
    this.eventHub.$off("closeViewAnnouncement");
  },
    methods: {
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
         data.append("searchText", this.searchText);
              this.axiosCall(
              '/getAnnouncements',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.announcementList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
      viewDetails(data){
      this.announcementData = data;
    }
    }
  }
</script>