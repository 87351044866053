<template>
  <div>
    <v-card class="mx-1" max-width="344" elevation="2" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Help - PR Printing</div>
          <v-list-item-title class="text-h5 mb-1">
            How to Print PR
          </v-list-item-title>
          <v-list-item-subtitle
            >A step-by-step instruction on How to Print your
            PR.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="green"
          rounded
          class="white--text items-center"
          @click="overlay = !overlay"
        >
          Learn How
          <v-icon right dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-card class="mx-1 relative" elevation="2" outlined color="white">
        <div class="scroll">
          <div
            class="
              text-overline
              px-4
              py-2
              black--text
              fixedElement
              white
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>help on PR Printing - How to Print your PR</div>
            <v-card-actions>
              <v-btn
                depressed
                color="green"
                rounded
                class="white--text items-center"
                @click="overlay = !overlay"
              >
                Close
                <v-icon right dark> mdi-close </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-list-item three-line>
            <v-list-item-content class="px-4">
              <!-- <v-list-item-title class="text-h5 mb-1 black--text">
              How to Create PR
            </v-list-item-title> -->
              <v-list-item-subtitle class="black--text"
                >1. Upon logging in, click on the “Purchase Request” tab on the
                left side of the screen.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PRprinting1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. Upon clicking the “Purchase Request” tab, a list of your
                PR’s will show on the table. You can click the view button under
                the actions column if you want to review it or click on the
                printer icon to print your PR.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PRprinting2.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. Upon clicking the view button, a pop-up modal will show
                which has the full details of your Purchase
                Request.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PRprinting3.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. Click the “PRINT” button to continue.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PRprinting4.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >5. Upon clicking the print button, a new browser tab will open
                and will redirect you to your PR Report
                View.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PRprinting5.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >6. You can save the PPMP as PDF or print it directly to your
                printer.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PRprinting6.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >7. You have successfully printed your PR. Repeat the process to
                print another PR.</v-list-item-subtitle
              >

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalPPMP: false,
      overlay: false,
    };
  },
  methods: {
    showPPMP() {
      this.modalPPMP = !this.modalPPMP;
    },
  },
};
</script>

<style scoped>
.papel {
  z-index: 2;
}
.scroll {
  width: 1000px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.fixedElement {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>