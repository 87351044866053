<template>
<div>
  <v-data-table
    :headers="headers"
    :items="prList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
         <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
        style="max-width: 300px;"
        v-model="searchText"
        append-icon="mdi-magnify"
        placeholder="Search for PR Code/Purpose"
        color="green darken-2"
          class="mt-2"
        single-line
        hide-details
      ></v-text-field>
      </v-toolbar>
    </template>
        <template v-slot:[`item.PRCODE`]="{ item }">
          {{ item.isApproved == 1 && item.isDBM == 0 ? item.PRCODE : item.PRCODETemp }}
        </template>
        <template v-slot:[`item.ApprovalStatus`]="{ item }">
          <v-tooltip top v-if="item.PSUStatusID == 7 && item.IsCanvassed == 1 && item.POexist == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                style="font-size: 12px;"
                color="header"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
              Utilized
              </v-chip>
            </template>
            <span>Some/All items in PR are utilized</span>
        </v-tooltip>
          <v-tooltip top v-else-if="item.isPending">
            <template v-slot:activator="{ on, attrs }">
            <v-chip
                class="ma-2"
                color="red"
                style="font-size: 12px;"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                Pending
              </v-chip>
            </template>
            <span>Pending By Finance/President</span>
        </v-tooltip>
        <v-tooltip top v-else-if="item.PSUStatusID == 7 && item.IsCanvassed == 1">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                style="font-size: 12px;"
                color="header"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
              For Canvass
              </v-chip>
            </template>
            <span>Set For Canvass By PSU</span>
        </v-tooltip>
        <v-tooltip top v-else-if="item.PSUStatusID == 6 && item.IsCanvassed == 0">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                style="font-size: 12px;"
                color="orange darken-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
                >
                Pending
              </v-chip>
            </template>
            <span>Pending By PSU</span>
        </v-tooltip>
        <v-tooltip top v-else-if="item.PSUStatusID == 7 && item.IsCanvassed == 0">
            <template v-slot:activator="{ on, attrs }">
            <v-chip
              style="font-size: 12px;"
              color="blue darken-2"
              text-color="white"
              v-bind="attrs"
              v-on="on"
            >
              Received
            </v-chip>
        </template>
            <span>Received By PSU</span>
        </v-tooltip>
        <v-tooltip top v-else-if="item.isApproved">
            <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              color="green "
              style="font-size: 12px;"
              text-color="white"
              v-bind="attrs"
              v-on="on"
            >
              Approved
            </v-chip>
        </template>
            <span>Approved By Finance&President</span>
        </v-tooltip>
            <v-chip
            v-else
              class="ma-2"
              style="font-size: 12px;"
                >
              On Process
            </v-chip>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <span class="text-no-wrap">
            <v-icon
                small
                class="mr-2"
                   @click="viewPR(item)">
                visibility
              </v-icon>
              <v-icon
                small
                class="mr-2"
                color="green accent-4"
                @click="print(item)">
                mdi-printer
              </v-icon>
                <v-icon
                small
                color="red"
                class="mr-2"
                :disabled ="item.isApproved == 1"
                @click="deleteItem(item)">
                mdi-delete
              </v-icon>
          </span>
        </template>
  </v-data-table>
  <v-dialog v-model="dialogconfirmDelete" persistent style="position:relative;" max-width="320">
    <v-card class="px-6 py-5">
      <div class="font-size-18 mb-4 font-2 text-h6 px-0" style="line-height: 1.3em">
        Are you sure you want to remove this purchase request?
        </div>
        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn
          text
          class="px-4" 
          @click="dialogconfirmDelete = false; deleteitemid = null;">
          Cancel
          </v-btn>
          <v-btn 
          class="px-4"
          color="error"
          :loading="dialogLoading"
         @click="deletePR()">
          Remove
          </v-btn>
          </v-card-actions>
          </v-card>
		</v-dialog>
        <prModlal :prData="prItem" />
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    components : {
      'prModlal' : () => import('./PRViewItem')
    },
    props: {
      fiscalYear: null
    },
    data: () => ({
      dialogLoading: false,
      dialogconfirmDelete: false,
      deleteitemid : null,
      searchText: "",
      searchTimeout : null,
      amountErr: null,
      editItemId: null,
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu: false,
      menu1: false,
      formData: {
         PPMPDescription: null,
         isDBM: null,
         BudgetID: null,
         Amount: null
      },
      addPPMPDialog: false,
      prList: [],
      budgetList: [],
      prItem: [],

      headers: [
        {
          text: 'PR No.',
          align: 'start',
          sortable: false,
          value: 'PRCODE',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        {
          text: 'Budget Code',
          align: 'start',
          sortable: false,
          value: 'BudgetCode',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'PR Purpose', value: 'PrPurpose', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'PR Date', value: 'DateCreated', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Status', value: 'ApprovalStatus', sortable: false, align: "center", class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Actions', value: 'Actions', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", }
      ],
    }),
    watch: {
    searchText : function(){
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
			this.initialize();
			}, 600);
		},
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      fiscalYear: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    created () {

    },
    mounted() {
        this.eventHub.$on('closePRItemodal', ()=>{
          this.prItem = {};
          this.initialize();
        });
    },
    beforeDestroy() {
         this.eventHub.$off('closePRItemodal');
    },
    methods: {
      deletePR () {
           this.dialogLoading = true;
         let data = new FormData();
          data.append('PRID', this.deleteitemid);
              this.axiosCall(
              '/pr/delete',
              'POST',
              data
          )
          .then(() => {
            this.dialogLoading = false;
            this.dialogconfirmDelete = false;
            this.deleteitemid = null;
            this.initialize();
          })
      },
      deleteItem (data){
        this.deleteitemid = data.PRID;
        if(this.deleteitemid){
          this.dialogconfirmDelete = true;
        }
      },
        print (data) {
             window.open(this.apiUrl + "/pr/report/" + data.PRID);
          },
      viewPR(item) {
        this.prItem = item;
        console.log(this.prItem)
      },
      initialize () {
        this.loading = true
        if(this.fiscalYear != null){
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
           data.append('searchText', this.searchText);
           data.append('FiscalYearID', this.fiscalYear);
              this.axiosCall(
              '/get/pr',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.prList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
        }
      },

    }
  }
</script>