<template>
  <div>
    <v-card
      class="mb-5"
    >
        <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
             v-model="OfficeID"
            item-text="OfficeDescription"
            item-value="OfficeID"
            :items="office"
            hide-details
            color="header"
            label="Filter by Office"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
            v-model="DeliveryUnitID"
            item-text="DeliveryUnitDescription"
            item-value="DeliveryUnitID"
            :items="deliveryUnit"
            hide-details
            color="header"
            label="Filter by Delivery Unit"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
            v-model="PurposeID"
            item-text="PurposeDescription"
            item-value="PurposeID"
            :items="purpose"
            hide-details
            color="header"
            label="Filter by Program/Activity/Project"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
            v-model="SpecificFundSourceID"
            item-text="SpecificFundSourceDescription"
            item-value="SpecificFundSourceID"
            :items="specificFundSource"
            hide-details
            color="header"
            label="Filter by Fund Source"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-menu
              dense
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="DateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  dense
                  v-model="DateRange"
                  label="Date Range"
                  color="header"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-5"
                  hide-details
                  clearable
                >
                </v-combobox>
              </template>
              <v-date-picker
                v-model="DateRange"
                no-title
                scrollable
                range
                color="header"
                :max="new Date().toISOString().substr(0, 10)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="header" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn
                  class="white--text"
                  color="header"
                  @click="$refs.menu1.save(DateRange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="ppmpList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat class="mx-1">
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search for Budget Code"
            color="green darken-2"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <vue-json-to-csv 
          :json-data="itemListForCSV"
          :labels="{ 
            BudgetCode: { title: 'BudgetCode' },
            PPMPDescription: { title: 'Description' },
            Amount: { title: 'Budget' },
            BudgetDate: { title: 'Budget Date' },
            PresidentApprovedDate: { title: 'Date Approved' },
            PPMPStatusID: { title: 'Status (11=Not yet Published; 12=Published)' },
            PPMPTypeID: { title: 'Type (1=DBM; 2=NDBM; 3=LIB)' },
            }"
          csv-title="Approved PPMPs">
            <v-btn
              outlined
              color="green darken-2"
              dark
              :loading="loadingCSV"
            >
              <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              CSV
            </v-btn>
          </vue-json-to-csv>
        </v-toolbar>
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.Amount || 0) }}</span>
      </template>
      <template v-slot:[`item.BudgetDate`]="{ item }">
        {{ item.DateStart }} - {{ item.DateEnd }}
      </template>
      <template v-slot:[`item.DateApproved`]="{ item }">
        {{ formatDate(item.PresidentApprovedDate) }}
      </template>
      <template v-slot:[`item.PPMPStatusID`]="{ item }">
        <span v-if="item.PPMPStatusID == 12">Published</span>
      </template>
      <template v-slot:[`item.PPMPTypeID`]="{ item }">
        <span class="text-no-wrap" v-if="item.PPMPTypeID == 1"><small>DBM</small></span>
        <span class="text-no-wrap" v-if="item.PPMPTypeID ==2"><small>NON-DBM</small></span>
        <span class="text-no-wrap" v-if="item.PPMPTypeID == 3"><small>LIB</small></span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          x-small
          outlined
          color="blue darken-2"
          dark
          class="mr-2"
          @click="viewPPMP(item)"
        >
          VIEW
        </v-btn>
      </template>
    </v-data-table>
    <ppmpModlal :ppmpData="ppmpItem" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    ppmpModlal: () => import("./PPMPAddItem"),
  },
  props: {
    fiscalYear: null,
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    addPPMPDialog: false,
    ppmpList: [],
    ppmpItem: [],

    headers: [
      {
        text: "Budget Code",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PPMP Description",
        value: "PPMPDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Budget",
        value: "Amount",
        sortable: false,
        align: "end",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Budget Date",
        value: "BudgetDate",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Date Approved",
        value: "DateApproved",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Status",
        value: "PPMPStatusID",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: " ",
        value: "PPMPTypeID",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    itemListForCSV: [],
    loadingCSV: true,
    OfficeID: 0,
    DeliveryUnitID: 0,
    PurposeID: 0,
    SpecificFundSourceID: 0,
    office : [],
    deliveryUnit: [],
    purpose: [],
    specificFundSource: [],
    DateRange: null,
    menu1: false,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  OfficeID: function () {
        this.initialize();
    },
    DeliveryUnitID: function () {
        this.initialize();
    },
    PurposeID: function () {
        this.initialize();
    },
    SpecificFundSourceID: function () {
        this.initialize();
    },
    DateRange: function () {
        this.initialize();
    },
  },
  created() {
    this.getOffice();
    this.getDeliveryUnit();
    this.getPurpose();
    this.getSpecificFundSource();
  },
  mounted() {
    this.eventHub.$on("closePPMPModal", () => {
      this.ppmpItem = {};
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closePPMPModal");
  },
  methods: {
    viewPPMP(item) {
      this.ppmpItem = item;
    },
    initialize() {
      this.loading = true;
      this.loadingCSV = true;
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("OfficeID", this.OfficeID);
      data.append("DeliveryUnitID", this.DeliveryUnitID);
      data.append("PurposeID", this.PurposeID);
      data.append("SpecificFundSourceID", this.SpecificFundSourceID);
      data.append("DateRange", this.DateRange && this.DateRange.length > 1 ? this.DateRange : '');
      this.axiosCall("/ppmp/approve/president", "POST", data).then((res) => {
        this.loading = false;
        this.ppmpList = res.data.data.result;
        this.itemListForCSV = res.data.data.result;
        this.loadingCSV = false;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
    },
    getOffice() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllOffice", "POST", data).then((res) => {
        this.office = res.data.data.result;
        this.office.push({ OfficeID: 0, OfficeDescription: "All" });
        this.office.sort(function (a, b) {
          return a.OfficeID - b.OfficeID;
        });
      });
    },
    getDeliveryUnit() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllDeliveryUnit", "POST", data).then((res) => {
        this.deliveryUnit = res.data.data.result;
        this.deliveryUnit.push({ DeliveryUnitID: 0, DeliveryUnitDescription: "All" });
        this.deliveryUnit.sort(function (a, b) {
          return a.DeliveryUnitID - b.DeliveryUnitID;
        });
      });
    },
    getPurpose() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllPurpose", "POST", data).then((res) => {
        this.purpose = res.data.data.result;
        this.purpose.push({ PurposeID: 0, PurposeDescription: "All" });
        this.purpose.sort(function (a, b) {
          return a.PurposeID - b.PurposeID;
        });
      });
    },
    getSpecificFundSource() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllFundsource", "POST", data).then((res) => {
        this.specificFundSource = res.data.data.result;
        this.specificFundSource.push({ SpecificFundSourceID: 0, SpecificFundSourceDescription: "All" });
        this.specificFundSource.sort(function (a, b) {
          return a.SpecificFundSourceID - b.SpecificFundSourceID;
        });
      });
    },
  },
};
</script>