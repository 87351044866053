<template>
  <div class="DarkWhite pa-4">
      <h2 class="subheading green--text">Fund Creation</h2>
      <v-card class="mt-4">
      <FundUtility/>
  </v-card>
  </div>
</template>

<script>
import FundUtility from '../components/Utils/FundUtility.vue'
  export default {
    components: {
      FundUtility
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>