<template>
  <div>
    <v-card class="mx-1" max-width="344" elevation="2" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Help - PPMP A pproval</div>
          <v-list-item-title class="text-h5 mb-1">
            How to Approve PPMP
          </v-list-item-title>
          <v-list-item-subtitle
            >A step-by-step instruction on How to Approve
            PPMP.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="green"
          rounded
          class="white--text items-center"
          @click="overlay = !overlay"
        >
          Learn How
          <v-icon right dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-card class="mx-1 relative" elevation="2" outlined color="white">
        <div class="scroll">
          <div
            class="
              text-overline
              px-4
              py-2
              black--text
              fixedElement
              white
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>help on PPMP Approval - How to Approve PPMP</div>
            <v-card-actions>
              <v-btn
                depressed
                color="green"
                rounded
                class="white--text items-center"
                @click="overlay = !overlay"
              >
                Close
                <v-icon right dark> mdi-close </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-list-item three-line>
            <v-list-item-content class="px-4">
              <!-- <v-list-item-title class="text-h5 mb-1 black--text">
              How to Create PR
            </v-list-item-title> -->
              <v-list-item-subtitle class="black--text"
                >1. On the left side of the screen, click on the “PPMP Approval”
                tab.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. Upon clicking the PPMP Approval tab, a table will show which
                consist the PPMP’s of all fund managers to be approved by the
                budget officer.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP2.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. On top part of the table, there are three (3) different tabs
                which can show you the PPMP’s that needed to approve (FOR
                APPROVAL), the PPMP’s that are approved (APPROVED), and the
                PPMP’s that you have returned to the user as pending
                (PENDING).</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP3.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. On the right side of the table under the actions column,
                there are three (3) different buttons which are “APPROVE”,
                “PENDING” and “VIEW”.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP4.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >5. To approve a PPMP, click on the “APPROVE” button. A pop-up
                modal will show and will ask you confirm your decision. Click
                “YES” to approve the PPMP or “CANCEL” to cancel the
                operation.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP5.png"
                  height="auto"
                  width="800"
                />
              </div>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP6.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >6. To return the PPMP, click on the “PENDING” button. A pop-up
                modal will show and will ask you to input your pending remarks.
                Click “Return PPMP” to pending the request or “CANCEL” to cancel
                the operation.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP7.png"
                  height="auto"
                  width="800"
                />
              </div>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP8.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >7. To view the PPMP, click on the “VIEW” button. A pop-up modal
                will show which contains the full details of the
                PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP9.png"
                  height="auto"
                  width="800"
                />
              </div>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP10.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >8. As the budget officer, you can lock the PPMP creation of all
                fund managers. In this case, all the fund managers can’t create
                or edit their existing PPMP’s. To lock the PPMP Creation, click
                on this switch.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP11.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >9. To close the modal, click on the close button on the top
                right side of the screen.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/BPPMP12.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalPPMP: false,
      overlay: false,
    };
  },
  methods: {
    showPPMP() {
      this.modalPPMP = !this.modalPPMP;
    },
  },
};
</script>

<style scoped>
.papel {
  z-index: 2;
}
.scroll {
  width: 1000px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.fixedElement {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>