<template>
  <div class="DarkWhite pa-4">
    <v-row row no-gutters>
      <v-col cols="12" sm="6" md="8" lg="10">
        <h2 class="green--text">Dashboard</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-autocomplete
          v-model="fiscalYear"
          :items="fiscalyears"
          auto-select-first
          item-text="fiscalYear"
          item-value="id"
          color="header"
          dense
          label="Fiscal Year"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div v-if="roles.length > 1" class="mb-5">
    <v-autocomplete
          :items="roles"
          label="View As"
          outlined
          dense
          item-text="RoleDescription"
          item-value="RoleID"
          v-model="db_role"
          color="header"
          hide-details
          auto-select-first
        ></v-autocomplete>
  </div>
    <div v-if="db_role == 1">
      <Administrator :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 2 || db_role == 11">
      <FundManager :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 3 || db_role == 13">
      <PersonAccountable :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 4">
      <VicePresident :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 5">
      <PSUOfficer :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 6">
      <SupplyOfficer :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 7">
      <BudgetOfficer :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 8">
      <President :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 9">
      <FinanceDirector :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 10">
      <RecommendingApproval :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 12">
      <BACAccess :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 14">
      <DASAccess :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 15">
      <Inspectorate :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 16">
      <ReleasingOfficer :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 17">
      <RTROfficer :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 18">
      <APManager :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 20">
      <SupplyOfficer :fiscalYear="fiscalYear" />
    </div>
    <div v-if="db_role == 21">
      <COA :fiscalYear="fiscalYear" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
      Administrator : () => import('../components/Dashboards/Administrator.vue'),
      FundManager : () => import('../components/Dashboards/FundManager.vue'),
      PersonAccountable : () => import('../components/Dashboards/PersonAccountable.vue'),
      VicePresident : () => import('../components/Dashboards/VicePresident.vue'),
      PSUOfficer : () => import('../components/Dashboards/PSUOfficer.vue'),
      SupplyOfficer : () => import('../components/Dashboards/SupplyOfficer.vue'),
      BudgetOfficer : () => import('../components/Dashboards/BudgetOfficer.vue'),
      President : () => import('../components/Dashboards/President.vue'),
      FinanceDirector : () => import('../components/Dashboards/FinanceDirector.vue'),
      RecommendingApproval : () => import('../components/Dashboards/RecommendingApproval.vue'),
      BACAccess : () => import('../components/Dashboards/BACAccess.vue'),
      DASAccess : () => import('../components/Dashboards/DASAccess.vue'),
      Inspectorate : () => import('../components/Dashboards/Inspectorate.vue'),
      ReleasingOfficer : () => import('../components/Dashboards/ReleasingOfficer.vue'),
      RTROfficer : () => import('../components/Dashboards/RTROfficer.vue'),
      APManager : () => import('../components/Dashboards/APManager.vue'),
      COA : () => import('../components/Dashboards/COA.vue')
    },
  data: () => ({
    fiscalYear: null,
    fiscalyears: [],
    db_role: null,
    roles: [],
  }),
  watch: {
    fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
      },
      deep: true,
    },
  },
  mounted() {
    this.getFiscalYears();
    this.setRoles();
  },
  methods: {
    setRoles(){
      let user = this.$store.state.user;
      let roles = [user.roleID];
      if(user.IsRA ==  1){
        roles.push(10)
      }
      if(user.IsFM ==  1){
        roles.push(11)
      }
      if(user.BACaccess ==  1){
        roles.push(12)
      }
      if(user.IsPA ==  1){
        roles.push(13)
      }
      if(user.IsDAS ==  1){
        roles.push(14)
      }
      let data = new FormData();
      data.append('roles', JSON.stringify(roles))
      this.axiosCall("/dashboard/getRolesByID", "POST", data).then((res) => {
          this.roles = res.data.data;
          if(this.roles.length > 0){
            this.db_role = this.roles[0].RoleID;
          }
        }
      );
    },
    getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"},...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
  },
};
</script>