<template>
  <div class="DarkWhite pa-4 ">
    <v-row>
    <v-col cols="12" sm="6">
      <h2 class="subheading green--text" v-if="tab == 0">Account Settings</h2>
      <h2 class="subheading green--text" v-if="tab == 1">Other Settings</h2>
      </v-col>
      <v-col cols="12" sm="6">
      <v-tabs
      v-model="tab"
      right
      max-width="200"
      color="header"
      background-color="transparent"
      class="mb-3 mt-n2"
    >
    <v-tabs-slider></v-tabs-slider>

      <v-tab>
        <v-icon class="mr-2">mdi-account-cog</v-icon>
        Account
      </v-tab>

      <v-tab>
        <v-icon class="mr-2">mdi-tune-variant</v-icon>
        Others
      </v-tab>
    </v-tabs>
    </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card class="elevation-2">
          <v-card-text>
              <v-row justify="center">
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  class="d-flex justify-center"
                >
                  <v-card class="mt-4 text-center elevation-0">
                    <v-card-text>
                    <v-hover>
                      <template v-slot:default="{ hover }">
                      <v-card class="rounded-circle elevation-6" width="128" height="128" style="left: 50%; transform: translateX(-50%)">
                          <v-img
                            class="rounded-circle"
                            :src="formData.imageUrl"
                            width="128" height="128"
                          >
                          </v-img>
                          <v-fade-transition>
                            <v-overlay
                              v-if="hover"
                              absolute
                              color="header"
                            >
                              <v-file-input
                              accept="image/*"
                              v-model="image"
                              @change="onFileChange"
                              id="fileInput"
                              style="display: none;"
                            >
                            </v-file-input>
                            <v-btn icon x-large dark onclick="document.getElementById('fileInput').click()"><v-icon>mdi-account-edit-outline</v-icon></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                            </v-card>
                          </template>
                          </v-hover>
                    </v-card-text>
                    <v-card-text class="text-center">
                      <h4 class="text-h5 mb-3 text--primary text-uppercase">
                        {{ $store.state.user.FirstName }} {{ $store.state.user.LastName }}
                      </h4>

                      <div class="text--secondary">
                      {{data.email}}
                      </div>
                      <v-list dense class="text-left mt-2">
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-account-lock-open</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content left>
                              <v-list-item-title>{{role}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon v-if="formData.IsRA == 1">mdi-account-lock-open</v-icon>
                              <v-icon v-else>mdi-account-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Recommending Approval</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="formData.roleID != 2">
                            <v-list-item-icon>
                              <v-icon v-if="formData.IsFM == 1">mdi-account-lock-open</v-icon>
                              <v-icon v-else>mdi-account-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Fund Manager</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon v-if="formData.BACaccess == 1">mdi-account-lock-open</v-icon>
                              <v-icon v-else>mdi-account-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>BAC Access</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="formData.roleID != 3">
                            <v-list-item-icon>
                              <v-icon v-if="formData.IsPA == 1">mdi-account-lock-open</v-icon>
                              <v-icon v-else>mdi-account-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Person Accountable</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                      </v-list>
                      <!-- <v-btn
                        class="mr-0"
                        color="primary"
                        min-width="100"
                        rounded
                      >
                        Follow
                      </v-btn> -->
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  lg="8"
                  md="8"
                >
                  <v-card flat>
                    <v-card-text>
                    <v-form ref="FormRef1">
                        <v-row>
                          <v-col cols="12">
                            <span class="text-subtitle-1">Personal Information</span>
                            <v-divider></v-divider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-text-field
                              color="green"
                              label="First Name"
                              :rules="[formRules.required]"
                              v-model="formData.FirstName"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-text-field
                              color="green"
                              label="Middle Name"
                              :rules="[formRules.required]"
                              v-model="formData.MiddleName"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-text-field
                              color="green"
                              label="Last Name"
                              :rules="[formRules.required]"
                              v-model="formData.LastName"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-text-field
                              color="green"
                              label="Educational Attainment"
                              v-model="formData.EducQuali"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-autocomplete
                              v-model="formData.PositionID"
                              item-text="PositionDescription"
                              item-value="PositionID"
                              :rules="[formRules.required]"
                              :items="positions"
                              color="header"
                              label="Position">
                            </v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-autocomplete
                              v-model="formData.DesignationID"
                              item-text="DesignationDescription"
                              item-value="DesignationID"
                              :rules="[formRules.required]"
                              :items="designations"
                              color="header"
                              label="Designation">
                            </v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-autocomplete
                              v-model="formData.OfficeID"
                              item-text="OfficeDescription"
                              item-value="OfficeID"
                              :rules="[formRules.required]"
                              :items="offices"
                              color="header"
                              label="Office">
                            </v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-autocomplete
                              v-model="formData.DeliveryUnitID"
                              item-text="DeliveryUnitDescription"
                              item-value="DeliveryUnitID"
                              :rules="[formRules.required]"
                              :items="deliveryunits"
                              color="header"
                              label="Delivery Unit">
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" md="8" class="pt-0">
                            <v-textarea
                              v-model="formData.About"
                              color="green"
                              label="About Me"
                            />
                          </v-col>
                          <v-col cols="12" md="4">
                            <div class="flex w-full h-screen items-center justify-center text-center">
                                    <div class="pa-2 dd-container" style="height: auto;" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                                      <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" accept="image/*"/>
                                      <label for="assetsFieldHandle">
                                        <div>
                                          Upload your E-Sign here
                                        </div>
                                      <div class="mt-4 d-flex align-center justify-center">
                                          <v-img v-if="formData.sign != null" :src="formData.sign"></v-img>
                                          <v-icon class="mt-3 mb-8" v-else x-large>mdi-cloud-upload-outline</v-icon>
                                      </div>
                                      </label>
                                    </div>
                                  </div>
                              </v-col>
                          <!-- <v-col cols="12">
                            <span class="text-subtitle-1">Credentials</span>
                            <v-divider></v-divider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="12"
                          >
                            <v-text-field
                              color="green"
                              label="Email Address"
                              :rules="[formRules.required, formRules.email, formRules.emaildnsc,]"
                              v-model="formData.email"
                            />
                          </v-col> -->

                          <!-- <v-col
                            cols="12"
                            md="6"
                            class="flex-items"
                          >
                            <v-text-field
                              :rules="[formRules.required, formRules.password]"
                              color="green"
                              name="input-10-1"
                              label="Password"
                              hint="Note: Click update to confirmly change the password"
                              disabled
                              hide-details
                              v-model="formData.password"
                              counter
                              class="pa-0 ma-0 mt-n1"
                            ></v-text-field>
                            <v-btn
                              color="header"
                              outlined
                              @click="changePass"
                              small
                              class=""
                            >
                              Change
                            </v-btn>
                          </v-col> -->

                          <v-col
                            cols="12"
                            class="text-right"
                          >
                          <v-btn
                              color="red darken-3"
                              dark
                              outlined
                              class="mr-2"
                              @click="changePass"
                            >
                              Change Password
                            </v-btn>

                            <v-btn
                              color="header"
                              dark
                              @click="update"
                              :loading="isLoading"
                            >
                              Update Profile
                            </v-btn>
                          </v-col>
                        </v-row>
                    </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
          </v-card-text>
          </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            This Panel is still under development. :-)
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="dialogChangePass" max-width="400px">
          <v-card>
            <v-card-title class="headline mt-5">Change Password</v-card-title>
            <div class="mx-6">
              <v-form ref="ChangePassRef">
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[formRules.required]" 
                    :type="show1 ? 'text' : 'password'"
                    color="green"
                    name="input-10-1"
                    label="Current Password"
                    hint="At least 8 characters"
                    v-model="formData.current_password"
                    counter
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[formRules.required, formRules.password]" 
                    :type="show2 ? 'text' : 'password'"
                    color="green"
                    name="input-10-1"
                    label="New Password"
                    hint="At least 8 characters"
                    v-model="formData.new_password"
                    counter
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[formRules.required, formRules.password, formRules.confirmPassword(formData.confirm_password, formData.new_password)]" 
                    :type="show3 ? 'text' : 'password'"
                    color="green"
                    name="input-10-1"
                    label="Confirm Password"
                    hint="At least 8 characters"
                    v-model="formData.confirm_password"
                    counter
                    @click:append="show3 = !show3"
                  ></v-text-field>
                </v-col>
              </v-row>
              </v-form>
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogChangePass = false"
              >Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmChangePass()"><v-icon>mdi-check-circle-outline</v-icon> Submit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tab: 0,
      data: [],
      fadeAwayMessage : {
      show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
      },
      formData: {
        FirstName: null,
        MiddleName: null,
        LastName: null,
        EducQuali: null,
        PositionID: null,
        DesignationID: null,
        OfficeID: null,
        DeliveryUnitID: null,
        About: null,
        email: null,
        password: null,
        current_password: null,
        new_password: null,
        confirm_password: null,
        roleID:null,
        IsRA: null,
        IsFM: null,
        IsPA: null,
        BACaccess: null,
        imageUrl: require("../assets/avatar-placeholder.png"),
        sign: null,
      },
      offices: [],
      positions: [],
      designations: [],
      deliveryunits: [],
      roles: [],
      role: null,
      dialogChangePass: false,
      show1: false,
      show2: false,
      show3: false,
      image: undefined,
      isLoading: false,
      filelist: [],
    }
  },
  watch: {
      tab: {
      handler() {
      },
      deep: true,
    },
    },
  mounted(){
    this.initialize()
    this.getUtilities();
  },
  methods: {
    initialize(){
      this.data = this.$store.state.user;
      let data = this.$store.state.user;
      this.getProfilePic(data.ProfilePic);
      this.getEsignature(data.UserID);
      this.formData.FirstName = data.FirstName
      this.formData.MiddleName = data.MiddleName
      this.formData.LastName = data.LastName
      this.formData.EducQuali = data.EducQuali
      this.formData.PositionID = data.PositionID
      this.formData.DesignationID = data.DesignationID
      this.formData.OfficeID = data.OfficeID
      this.formData.DeliveryUnitID = data.DeliveryUnitID
      this.formData.About = data.About
      this.formData.email = data.email
      this.formData.password = data.password
      this.formData.roleID = data.roleID
      this.formData.IsRA = data.IsRA
      this.formData.BACaccess = data.BACaccess
      this.formData.IsFM = data.IsFM
      this.formData.IsPA = data.IsPA
      this.formData.EducQuali = data.EducQuali
    },
    getUtilities(){
      this.axiosCall("/getPositionDesignationOfficeOutside", "GET").then((res) => {
        this.positions = res.data.data.position;
        this.offices = res.data.data.office;
        this.designations = res.data.data.designation;
        this.deliveryunits = res.data.data.deliveryunit;
        this.roles = res.data.data.role;
        this.roles.forEach(item => {
          if(item.RoleID == this.formData.roleID){
            this.role = item.RoleDescription;
          }
        });
      });
    },
    update () {
    if(this.$refs.FormRef1.validate()){
        this.isLoading = true;
        let data = new FormData();
        data.append('UserID', this.data.UserID);
        data.append('FirstName', this.formData.FirstName);
        data.append('MiddleName', this.formData.MiddleName);
        data.append('LastName', this.formData.LastName);
        data.append('EducQuali', this.formData.EducQuali);
        data.append('PositionID', this.formData.PositionID);
        data.append('DesignationID', this.formData.DesignationID);
        data.append('OfficeID', this.formData.OfficeID);
        data.append('DeliveryUnitID', this.formData.DeliveryUnitID);
        data.append('About', this.formData.About || '');
        data.append('email', this.formData.email);
        data.append('sign', this.formData.sign ? this.formData.sign.split(',')[1] : '');
                    this.axiosCall(
                  '/account/update',
                  'POST',
                  data
              )
              .then((res) => {
                  this.isLoading = false;
                  if(res.data.status) {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'success';
                  this.fadeAwayMessage.message = 'Successfully Updated!';
                  this.fadeAwayMessage.header = 'System Message';
                  this.uploadFiles();
                  }else {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'error';
                  this.fadeAwayMessage.message = res.data.message;
                  this.fadeAwayMessage.header = 'System Message';
                  }
              })
        }
    },
    uploadFiles(){
        let data = new FormData();
        data.append('files[]', this.image || ''); 
        data.append('prev_file', this.data.ProfilePic || ''); 
        this.axiosCall(
                '/upload',
                'POST',
                data
            )
            .then((res) => {
                if (res.data.status){
                  this.formData.imageUrl = ""
                }
                else{
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = 'error';
                this.fadeAwayMessage.message = res.data.message;
                this.fadeAwayMessage.header = 'System Message';
                }
                this.isLoading = false;
                this.getUser();
            })
        },
    getUser(){
      this.axiosCall("/getUser", "GET")
          .then((res) => {
            if (res.data) {
              this.$store.dispatch("setUser", res.data.data);
              this.initialize();
              this.eventHub.$emit("reloadSideBar", false);
            }
          });
    },
    changePass(){
      this.dialogChangePass = true;
    },
    confirmChangePass(){
      if(this.$refs.ChangePassRef.validate()){
        let data = new FormData();
        data.append('UserID', this.data.UserID);
        data.append('CurPass', this.formData.current_password);
        data.append('NewPass', this.formData.new_password);
        data.append('ConfPass', this.formData.confirm_password);
                    this.axiosCall(
                  '/account/changePassword',
                  'POST',
                  data
              )
              .then((res) => {
                  this.isLoading = false;
                  if(res.data.status) {
                  this.dialogChangePass = false
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'success';
                  this.fadeAwayMessage.message = 'Password was successfully changed.';
                  this.fadeAwayMessage.header = 'System Message';
                  }
                  // else {
                  // this.fadeAwayMessage.show = true;
                  // this.fadeAwayMessage.type = 'error';
                  // this.fadeAwayMessage.message = res.data.message;
                  // this.fadeAwayMessage.header = 'System Message';
                  // }
              })
      }
    },
    createImage(file) {
    const reader = new FileReader();

    reader.onload = e => {
      this.formData.imageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
    },
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.createImage(file);
    },
    getProfilePic(filename){
        let data = new FormData();
        data.append('ProfilePic', filename || '');
              this.axiosCall(
            '/getProfilePic',
            'POST',
            data
        )
        .then((res) => {
            if(res.data.status) {
              this.formData.imageUrl = res.data.data
            }else {
              this.formData.imageUrl = require("../assets/avatar-placeholder.png")
            }
        })
    },
    getEsignature(UserID){
        let data = new FormData();
        data.append('UserID', UserID || '');
              this.axiosCall(
            '/getEsign',
            'POST',
            data
        )
        .then((res) => {
            if(res.data.status && res.data.data) {
              this.formData.sign = res.data.data
            }
            // else {
            //   this.formData.imageUrl = "https://www.pngkit.com/png/full/302-3022217_roger-berry-avatar-placeholder.png"
            // }
        })
    },
    onChange() {
    if(this.$refs.file.files.length > 1){
      this.fadeAwayMessage.show = true;
      this.fadeAwayMessage.type = "error";
      this.fadeAwayMessage.header = "System Message";
      this.fadeAwayMessage.message = "Only one E-signature is allowed.";
      this.filelist = [];
    }
    else{
      this.filelist = [...this.$refs.file.files];
      this.createESign(this.filelist[0]);
    }
    },
    createESign(file) {
    const reader = new FileReader();

    reader.onload = e => {
      console.log(e.target)
      this.formData.sign = e.target.result;
    };
    reader.readAsDataURL(file);
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      if(event.dataTransfer.files.length > 1){
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Only one E-signature is allowed.";
      }
      else{
        this.onChange();
      }
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
  }
}
</script>

<style scoped>
.dd-container {
      background-color: white !important;
      border: 3px dashed green !important;
    }
</style>