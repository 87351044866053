var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ppmpList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-2"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"append-icon":"mdi-magnify","placeholder":"Search for Budget Code","color":"green darken-2","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1)]},proxy:true},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.Amount || 0)))])]}},{key:"item.BudgetDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.DateStart)+" - "+_vm._s(item.DateEnd)+" ")]}},{key:"item.DateApproved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.PresidentApprovedDate))+" ")]}},{key:"item.PPMPStatusID",fn:function(ref){
var item = ref.item;
return [(item.PPMPStatusID == 12)?_c('span',[_vm._v("Published")]):_vm._e()]}},{key:"item.PPMPTypeID",fn:function(ref){
var item = ref.item;
return [(item.PPMPTypeID == 1)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("DBM")])]):_vm._e(),(item.PPMPTypeID ==2)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("NON-DBM")])]):_vm._e(),(item.PPMPTypeID == 3)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("LIB")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"blue darken-2","dark":""},on:{"click":function($event){return _vm.viewPPMP(item)}}},[_vm._v(" VIEW ")])]}}],null,true)}),_c('ppmpModlal',{attrs:{"ppmpData":_vm.ppmpItem}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }