<template>
  <v-app>
    <!-- <div class="d-flex justify-center">
      <Announcement class="above"/>
    </div> -->
    <Navbar/>
    <v-main class="DarkWhite main-content">
      <div class="main-container">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '../components/Navbar/Navbar'
// import Announcement from "@/components/Announcement/Announcement.vue"
export default {
  name: 'App',
  components: {
    Navbar,
    // Announcement
  },
  data: () => ({
  }),
};
</script>

<style>
.above{
  position: absolute;
  height: 80px;
  top: -20px;
  z-index: 10;
  cursor: pointer;
}
</style>