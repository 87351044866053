<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="icsList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-tabs v-model="status" color="header">
          <v-tab
            v-for="tab of status_tabs"
            :key="tab.id"
          >
          {{tab.desc}}
          </v-tab>
        </v-tabs>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search ICS number"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.FundSource`]="{ item }">
        {{ item.FundCode }} - {{ item.SpecificFundSourceDescription }}
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.totalAmount) }}</span>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.Other_Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          color="header darken-2"
          dark
          outlined
          elevation="2"
          x-small
          class="mr-2"
          @click="approveICS(item)"
          v-if="status == 0 || status == 1"
        >
          Approve
        </v-btn>
        <v-btn
          color="red darken-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="pendingICS(item)"
          v-if="status == 0"
        >
          Pending
        </v-btn>
        </span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewICS(item)"
          >
            View
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printICS(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogICSType" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Please select ICS type:</v-card-title>
        <v-card-text>
          <v-row justify="center" class="mt-2">
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple</v-icon
              ><br />
              <v-btn color="green darken-2" @click="supply()" text
                >Supply</v-btn
              >
            </v-col>
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple-outline</v-icon
              ><br />
              <v-btn color="green darken-2" @click="accounting()" text
                >Accounting</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogICSType = false"
                >Cancel</v-btn
              >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approveDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to approve this Inventory Custodian Slip?
            <br /><br />
            <div>ICS #: <strong>{{approveData.ICSCODE}}</strong></div>
            <div>Purpose: <strong>{{approveData.ICSPurpose}}</strong></div>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="approveDialog = false">Cancel</v-btn>
            <v-btn color="header darken-2 white--text" @click="confirmApproveICS()"><v-icon class="mr-1">mdi-check-circle</v-icon>Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="pendingDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to pending this Inventory Custodian Slip?
            <br /><br />
            <div>ICS #: <strong>{{pendingData.ICSCODE}}</strong></div>
            <div>Purpose: <strong>{{pendingData.ICSPurpose}}</strong></div>
            <br />
            <v-form ref="formRef">
            <v-textarea
              color="green"
                dense
                v-model="formData.Remarks"
                :rules="[formRules.required]"
                label="Remarks"
                counter
                outlined
                maxlength="500"
                ></v-textarea>
            </v-form>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="pendingDialog = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmPendingICS()"><v-icon class="mr-1">mdi-clock-outline</v-icon>Pending</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <ICSModal :icsData="icsData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    ICSModal: () => import("./ViewICS.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    icsList: [],
    icsData: [],
    headers: [
      {
          text: 'ICS CODE',
          align: 'start',
          sortable: false,
          value: 'ICSCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'ICSPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Fund Source',
          align: 'start',
          sortable: false,
          value: 'FundSource',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: '',
          align: 'center',
          sortable: false,
          value: "Other_Actions",
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', width: '140', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      dialogICSType: false,
      icsType: 1,
      itemToPrint: [],
      status_tabs: [
        {id: 1, desc: 'For Approval'},
        {id: 3, desc: 'Pending'},
        {id: 2, desc: 'Approved'},
        {id: 4, desc: 'Cancelled'},
      ],
      status: 0,
      approveDialog: false,
      pendingDialog: false,
      approveData: [],
      pendingData: [],
      formData: {
        Remarks: '',
      }
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    status: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeViewICS", () => {
      this.initialize();
    });

  },
  beforeDestroy() {
    this.eventHub.$off("closeViewICS");
  },
  methods: {
    viewICS(item) {
      this.icsData = {...item, status_prop: this.status};
    },
    printICS(item) {
      window.open(this.apiUrl + "/ics/report/" + item.ICSID);
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("status", this.status + 1);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      this.axiosCall("/getICSListPerStatus", "POST", data).then((res) => {
        this.loading = false;
        this.icsList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    approveICS(data){
      this.approveData = data;
      this.approveDialog = true;
    },
    pendingICS(data){
      this.pendingData = data;
      this.pendingDialog = true;
    },
    confirmApproveICS(){
      let data = new FormData();
      data.append("ICSID", this.approveData.ICSID);
      this.axiosCall("/ics/approve", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "ICS was successfully approved.";
            this.fadeAwayMessage.header = "System Message";
            this.approveDialog = false;
            this.approveData = [];
            this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    confirmPendingICS(){
      if(this.$refs.formRef.validate()){
      let data = new FormData();
      data.append("ICSID", this.pendingData.ICSID);
      data.append("Remarks", this.formData.Remarks);
      this.axiosCall("/ics/pending", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "ICS was successfully in pending status.";
            this.fadeAwayMessage.header = "System Message";
            this.pendingDialog = false;
             this.pendingData = [];
             this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
  },
};
</script>