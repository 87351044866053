<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ppmpList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-row class="mx-2">
          <v-col cols="12" sm="6" md="4">
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search for Budget Code"
            color="green darken-2"
            class="mb-3"
            single-line
            hide-details
          ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.Amount || 0) }}</span>
      </template>
      <template v-slot:[`item.BudgetDate`]="{ item }">
        {{ item.DateStart }} - {{ item.DateEnd }}
      </template>
      <template v-slot:[`item.DateApproved`]="{ item }">
        {{ formatDate(item.PresidentApprovedDate) }}
      </template>
      <template v-slot:[`item.PPMPStatusID`]="{ item }">
        <span v-if="item.PPMPStatusID == 12">Published</span>
      </template>
      <template v-slot:[`item.PPMPTypeID`]="{ item }">
        <span class="text-no-wrap" v-if="item.PPMPTypeID == 1"><small>DBM</small></span>
        <span class="text-no-wrap" v-if="item.PPMPTypeID ==2"><small>NON-DBM</small></span>
        <span class="text-no-wrap" v-if="item.PPMPTypeID == 3"><small>LIB</small></span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          x-small
          outlined
          color="blue darken-2"
          dark
          class="mr-2"
          @click="viewPPMP(item)"
        >
          VIEW
        </v-btn>
      </template>
    </v-data-table>
    <RevisedItemModal :ppmpData="ppmpItem" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    RevisedItemModal: () => import("./BudgetRevisedItem"),
  },
  props: {
    fiscalYear: null,
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    addPPMPDialog: false,
    ppmpList: [],
    ppmpItem: [],

    headers: [
      {
        text: "Budget Code",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PPMP Description",
        value: "PPMPDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Budget",
        value: "Amount",
        sortable: false,
        align: "end",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Budget Date",
        value: "BudgetDate",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Date Approved",
        value: "DateApproved",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Status",
        value: "PPMPStatusID",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: " ",
        value: "PPMPTypeID",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeRevisedPPMPItemModal", () => {
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeRevisedPPMPItemModal");
  },
  methods: {
    viewPPMP(item) {
      this.ppmpItem = item;
    },
    initialize() {
      this.loading = true;
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      this.axiosCall("/ppmp/revised", "POST", data).then((res) => {
        this.loading = false;
        this.ppmpList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
    },
  },
};
</script>