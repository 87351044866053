<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">Budget Allotment Types</h2>
      <v-card class="mt-4">
      <BudgetAllotment/>
  </v-card>
  </div>
</template>

<script>
import BudgetAllotment from '@/components/Utils/BudgetAllotment.vue'
  export default {
    components: {
      BudgetAllotment
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>