<template>
  <div>
    <v-card class="mx-1" max-width="344" elevation="2" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Help - PPMP Creation</div>
          <v-list-item-title class="text-h5 mb-1">
            How to Create PPMP
          </v-list-item-title>
          <v-list-item-subtitle
            >A step-by-step instruction on How to Create your
            PPMP.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="green"
          rounded
          class="white--text items-center"
          @click="overlay1 = !overlay1"
        >
          Learn How
          <v-icon right dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay1">
      <v-card class="mx-1 relative" elevation="2" outlined color="white">
        <div class="scroll">
          <div
            class="
              text-overline
              px-4
              py-2
              black--text
              fixedElement
              white
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>help on PPMP Creation - How to Create PPMP</div>
            <v-card-actions>
              <v-btn
                depressed
                color="green"
                rounded
                class="white--text items-center"
                @click="overlay1 = !overlay1"
              >
                Close
                <v-icon right dark> mdi-close </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-list-item three-line>
            <v-list-item-content class="px-4">
              <!-- <v-list-item-title class="text-h5 mb-1 black--text">
              How to Create PR
            </v-list-item-title> -->
              <v-list-item-subtitle class="black--text"
                >1. On the left side of the screen, click on the PPMP Creation
                Tab. Upon clicking the tab, a list of your PPMP’s will show on
                the table.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. On the right side of the screen, on top of the table, click
                on the “Create PPMP” button.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP2.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. Upon clicking the button, a pop-up modal will show and will
                ask you to input your PPMP Description, select the budget on the
                dropdown menu and select the recommending approval of your
                PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP3.png"
                  height="auto"
                  width="800"
                />
                <img
                  src="@/assets/help images/PPMP4.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. Click “ADD” to continue or “CANCEL” to close the
                modal.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP5.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >5. Upon clicking the ”ADD” button, your created PPMP will be
                available and will be shown on the table. Take note that the
                “FIRST” PPMP that you have created is automatically will be a
                “DBM” or for Common Use Supply Only.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP6.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >6. To view the full details of your PPMP, click the “EYE
                Button” located on the right side of the
                screen.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP7.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >7. Upon clicking the view button, a pop-up modal will show
                which has the full details of your PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP8.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >8. You can see the description of your PPMP, your total and
                available balance, approval tracker, PPMP Details, and your PPMP
                Item list.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP9.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >9. To add an Item on your PPMP, click on the “ADD ITEM”
                button.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP10.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >10. Upon clicking on the “ADD ITEM” button, a pop-up will show
                with an autocomplete dropdown field where you will encode or
                search your desired item to be added. Below you will see an
                input box field where you will input the quantity of the item
                you selected.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP11.png"
                  height="auto"
                  width="800"
                />
                <img
                  src="@/assets/help images/PPMP12.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >11. Click on the “ADD ITEM” to continue or click “CANCEL” to
                close the modal.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP13.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >12. Upon clicking the “ADD ITEM” button, your added item will
                be shown on your Item List below.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP14.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >13. If your Created PPMP is for “DBM or Common Use Items” only,
                you need to check your available balance and use all of it
                before you publish your PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP15.png"
                  height="auto"
                  width="800"
                />
              </div>

              <div class="black--text mt-8">
                14. In case that you have already selected your desired items
                and its quantity and you notice that you still have an available
                balance of Php 12.00 for example as shown in the figure below,
                then to accumulate all the remaining balance you must add an
                item equivalent to your remaining balance. And if you wish to
                proceed without accumulating your remaining balance, you can
                just click the “PUBLISH” button to continue. Take note that your
                remaining balance will be automatically become your Non-DBM or
                Non-Common Use PPMP.
              </div>
              >
              <div class="px-4">
                <img
                  src="@/assets/help images/PPMP16.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >15. To continue with your DBM PPMP, click “PUBLISH” button to
                publish your PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP17.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >16. Upon clicking the “PUBLISH” button, the modal will
                automatically close and will appear the list of your
                PPMP’s.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP18.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >17. As you can see on the list, the budget of the PPMP is now
                determined and the status will appear as “PUBLISHED”. You have
                created your first and DBM PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP19.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >18. Click on the view button to see the full details of your
                published PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP20.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >19. Here is your published PPMP as shown
                below.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP21.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >20. You can now create your new PPMP as your “NON-DBM PPMP”
                with your remaining balance from your “DBM PPMP”. Click on the
                “CREATE PPMP” button to create new PPMP</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP22.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >21. A pop-up will show and will ask you to input the details of
                your second PPMP which is your “NON DBM
                PPMP”.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP23.png"
                  height="auto"
                  width="800"
                />
                <img
                  src="@/assets/help images/PPMP24.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >22. Click the “ADD” button to continue or “CANCEL” to close the
                modal.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP25.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >23. Upon clicking the “ADD” button, a list will show containing
                your PPMP’s. You can see on the Status column that your Non-DBM
                is still on process.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP26.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >24. Click on the View button or the “EYE” icon to view your
                PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP27.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >25. Upon clicking the view button, a pop-up will show with your
                PPMP Details.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP28.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >26. As you can see on your PPMP Balance, you still have a 12
                peso remaining balance in which you accumulate from your DBM
                PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP29.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >27. Click the “ADD ITEM” button to add items on your NON-DBM
                PPMP. (Repeat the process from item number 9 to item number
                12)</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP30.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >28. Upon clicking the “ADD ITEM” button, a pop-will show and
                will ask you to input or select your schedule month for the
                specific item. Click “SAVE” to continue or “CANCEL” to close the
                modal.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP31.png"
                  height="auto"
                  width="800"
                />
                <img
                  src="@/assets/help images/PPMP32.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >29. Upon clicking the “SAVE” button, go back to Step number 10
                to step number 12.</v-list-item-subtitle
              >

              <v-list-item-subtitle class="black--text mt-8"
                >30. If you have added your final items to your list, and you
                notice that you still have an available balance (for example on
                the figure below), you can add a “CONTINGENCY” item and use all
                your remaining available balance and input is as the quantity of
                the item “CONTINGENCY”.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP33.png"
                  height="auto"
                  width="800"
                />
                <img
                  src="@/assets/help images/PPMP34.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >31. Then click the “ADD” button to continue or “CANCE” to close
                the modal.</v-list-item-subtitle
              >

              <v-list-item-subtitle class="black--text mt-8"
                >32. Upon clicking the “SAVE” button, you can see now that your
                remaining balance is accumulated as contingency and is added on
                the PPMP Item list. You can also now click the “PUBLISH” button
                to publish this PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP35.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >33. Upon clicking the “PUBLISH” button, you will now have
                redirected to your PPMP Lists and you will also see the total
                budget of your NON-DBM PPMP and its status. You will also notice
                that this PPMP is NON-DBM because it doesn’t have the DBM
                identifier.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP36.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >34. You now have successfully created your PPMP. You can view
                your PPMP’s Approval Tracker for you to be updated about the
                Approval Level of your PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMP37.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalPPMP: false,
      overlay1: false,
    };
  },
  methods: {
    showPPMP() {
      this.modalPPMP = !this.modalPPMP;
    },
  },
};
</script>

<style scoped>
.papel {
  z-index: 2;
}
.scroll {
  width: 1000px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.fixedElement {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>