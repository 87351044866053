<template>
<div>
  <v-data-table
    :headers="headers"
    :items="budgetList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
    color="header"
  >
         <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
        style="max-width: 300px;"
        v-model="searchText"
        append-icon="mdi-magnify"
        placeholder="Search for Budget Code"
        color="green darken-2"
          class="mt-2"
        single-line
        hide-details
      ></v-text-field>
      </v-toolbar>
    </template>
    <!-- <template v-slot:progress>
      <v-progress-linear color="header lighten-2" indeterminate></v-progress-linear>
    </template> -->
        <template v-slot:[`item.Amount`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Amount) }}</span>
        </template>
        <template v-slot:[`item.Balance`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Balance) }}</span>
        </template>
        <template v-slot:[`item.BudgetUsed`]="{ item }">
          <span :color="(((item.Amount - item.Balance) / item.Amount) * 100).toFixed(2) < '100' 
            ? 'error' : 'header'">{{ (((item.Amount - item.Balance) / item.Amount) * 100).toFixed(2) + '%' }}</span>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <span class="text-no-wrap">
          <v-btn
                @click="createNewPPMP(item)"
                color="header"
                class="mr-1 white--text"
                dense
                small
                v-if="item.Balance == item.Amount"
              >
                <v-icon class="mr-2" >mdi-plus</v-icon>
                CREATE PPMP
              </v-btn>
              <v-btn
                @click="createNewPPMP(item)"
                color="warning"
                class="mr-1 white--text"
                dense
                outlined
                small
                v-if="item.Balance != item.Amount && item.Balance > 0"
              >
                <v-icon class="mr-2" >mdi-pencil</v-icon>
                EDIT PPMP
              </v-btn>
              <v-btn
                @click="createNewPPMP(item)"
                color="header"
                class="mr-1 white--text"
                dense
                outlined
                small
                v-if="item.Balance <= 0"
              >
                <v-icon class="mr-2" >mdi-eye</v-icon>
                VIEW PPMP
              </v-btn>
              <!-- <v-btn
                @click="view(item)"
                color="header"
                outlined
                small
              >
                <v-icon class="mr-1">mdi-list-status</v-icon>
                Status
              </v-btn> -->
              </span>
        </template>
  </v-data-table>
        <CreateRevisedPPMPModal :data="budgetData" />
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    components : {
      'CreateRevisedPPMPModal' : () => import('./CreateRevisedPPMP')
    },
    props: {
      fiscalYear: null,
    },
    data: () => ({
      searchText: "",
      searchTimeout : null,
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
      options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      formData: {},
      budgetList: [],
      budgetData: [],
      headers: [
        {
          text: 'Budget Code',
          align: 'start',
          sortable: false,
          value: 'BudgetCode',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'Specific Fund Source', value: 'SpecificFundSourceDescription', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Total Budget', value: 'Amount', align: 'end', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Balance', value: 'Balance', align: 'end', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Budget Date Start', value: 'DateStart', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Budget Date End', value: 'DateEnd', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Budget Used (%)', value: 'BudgetUsed', align:'center', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Actions', value: 'Actions', sortable: false, align: 'center', class: "green darken-2 white--text text-caption font-weight-bold", }
      ],
    }),
    watch: {
    searchText : function(){
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
			this.initialize();
			}, 600);
		},
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      fiscalYear: {
      handler () {
        this.initialize();
      },
      deep: true,
      },
    },
    created () {
    },
    mounted() {
        this.eventHub.$on('closeCreateRevisedPPMPModal', ()=>{
          this.initialize();
        });
    },
    beforeDestroy() {
         this.eventHub.$off('closeCreateRevisedPPMPModal');
    },
    methods: {
      initialize() {
        this.loading = true
        if(this.fiscalYear != null){
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
           data.append('searchText', this.searchText);
           data.append('FiscalYearID', this.fiscalYear);
              this.axiosCall(
              '/get/revisedBudgets',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.budgetList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
        }
      },
      createNewPPMP(item){
        this.budgetData = item;
      }
    }
  }
</script>