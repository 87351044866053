<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">User Modules</h2>
      <v-card class="mt-4">
      <UserModules/>
  </v-card>
  </div>
</template>

<script>
import UserModules from '@/components/Utils/UserModules.vue'
  export default {
    components: {
      UserModules
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>