<template>
<div>
  <v-card v-if="budgetList.length > 0 || QuarterID != 0" class="mb-7">
  <v-data-table
    :headers="headers"
    :items="budgetList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
      flat>
      <h2 class="mei-title mt-4">Pending Budget</h2>
      </v-toolbar>
      <v-toolbar
        flat
      >
        <v-text-field
        v-model="searchText"
        append-icon="mdi-magnify"
        label="Search"
        color="green darken-2"
        single-line
        hide-details
      ></v-text-field>
        <v-spacer></v-spacer>
        <v-autocomplete
            v-model="QuarterID"
            item-text="description"
            item-value="id"
            :items="quarters"
            hide-details
            color="header"
            label="Quarter"
            style="max-width: 180px"
            class="mr-2"
          >
          </v-autocomplete>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Amount`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Amount || 0) }}</span>
      </template>
      <template v-slot:[`item.PerBudgetRecord`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.PerBudgetRecord || 0) }}</span>
      </template>
      <template v-slot:[`item.BudgetUtilized`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.AmountNDBM_PO) + parseFloat(item.AmountNDBM_OBL) + parseFloat(item.AmountDBM_PR) + parseFloat(item.AmountLIB_OBL)) }}</span>
      </template>
      <template v-slot:[`item.Status`]="{ item }">
          <v-chip color="green darken-2" dark small v-if="item.SubmittedBy > 0">Submitted</v-chip>
          <v-chip color="amber darken-2" dark small v-else>Pending</v-chip>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn color="green darken-2" class="white--text mr-2" outlined x-small @click="submitItem(item)">
          Submit
          </v-btn>
          <v-btn color="blue darken-2" class="white--text mr-2" outlined x-small @click="viewItem(item)">
          view
          </v-btn>
        </span>
      </template>
  </v-data-table>
   </v-card>

   <v-card v-else class="mb-7">
      <h2 class="mei-pending-title text-center py-5">No Pending Budget for M&E Instrument</h2>
   </v-card>

  <BudgetMAndEUser :fiscalYear="fiscalYear" v-if="showBMEU" />

   <v-dialog
          v-model="submitMEIDialog"
          persistent
          max-width="700px"
          scrollable
        >
          <v-card>
            <v-card-title>
              <span>Submit M&E Instrument</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
            <v-card-text style="max-height: 900px;">
              <v-form @submit.prevent ref="formRef">
              <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card
                    class="pa-3"
                    color="grey lighten-3"
                    flat
                  >
                  <v-card-text class="pa-0 ma-0">
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                    <strong class="text-no-wrap">Quarter: </strong>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <span v-if="submitData.Quarter">{{submitData.Quarter}}</span>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <strong class="text-no-wrap">Budget Code: </strong>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <span>{{submitData.BudgetCode}}</span>
                  </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Purpose: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{submitData.PurposeDescription}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Remarks: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{submitData.Remarks}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Actual Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <strong v-if="submitData.Amount">{{formatPrice(submitData.Amount) }}</strong>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Per Budget Record: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <strong v-if="submitData.PerBudgetRecord">{{formatPrice(submitData.PerBudgetRecord) }}</strong>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Utilized Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <strong v-if="submitData.AmountNDBM_PO">{{formatPrice(parseFloat(submitData.AmountNDBM_PO) + parseFloat(submitData.AmountNDBM_OBL) + parseFloat(submitData.AmountDBM_PR) + parseFloat(submitData.AmountLIB_OBL)) }}</strong>
                    </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                      cols="12"
                    >
          <div class="text-subtitle-2 mb-2">
            <v-icon small color="primary" class="mr-1 mb-1">mdi-information-variant-circle</v-icon>Provide justification of your current level of BURs
          </div>
          <v-textarea
          dense
          v-model="formData.Justification"
          color="header"
          :rules="[formRules.required]"
          outlined
            label="Justification">
          </v-textarea>
          <div class="text-subtitle-2 mb-2 mt-2">
            <v-icon small color="primary" class="mr-1 mb-1">mdi-information-variant-circle</v-icon>Enumerate you plans to ensure 100% utilization of budget
          </div>
          <v-textarea
          dense
          v-model="formData.CatchUpPlan"
          color="header"
          :rules="[formRules.required]"
          outlined
            label="Catch-Up Plan">
          </v-textarea>
          </v-col>
              </v-row>
              </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                outlined
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn
              v-if="editItemId"
                color="blue darken-2"
                class="white--text"
                @click="update"
                :loading="isLoading"
              >
              <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
                Update
              </v-btn>
              <v-btn
                color="green darken-2"
                class="white--text"
                @click="submit"
                :loading="isLoading"
              >
              <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <ViewMAndE :meiData="viewData" />
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
import BudgetMAndEUser from './BudgetMAndEUser.vue'
import ViewMAndE from './ViewMAndE.vue'
  export default {
    components: {
      BudgetMAndEUser,
      ViewMAndE
    },
    props: {
      fiscalYear: null,
    },
    data: () => ({
      searchText: "",
      searchTimeout : null,
      amountErr: null,
      amountErr2: null,
      editItemId: null,
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu: false,
      menu1: false,
      formData: {
         Justification: null,
         CatchUpPlan: null,
      },
      submitMEIDialog: false,
      budgetList: [],
      fundList: [],
      office: [],
      deliveryunit: [],
      specificfundsourceList: [],
      purposeList: [],
      allotmentList: [],
      users: [],
      headers: [
        { text: 'Quarter', value: 'Quarter', sortable: false, align: 'center', class: "green darken-2 white--text text-caption font-weight-bold" },
        {
          text: 'Budget Code',
          align: 'start',
          sortable: false,
          value: 'BudgetCode',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'Amount', value: 'Amount', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Per Budget Record', value: 'PerBudgetRecord', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Amount (Utilized)', value: 'BudgetUtilized', align: 'end', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Remarks', value: 'Remarks', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" }
      ],
      itemListForCSV: [],
      loadingCSV: true,
      OfficeID: 0,
      DeliveryUnitID: 0,
      PurposeID: 0,
      SpecificFundSourceID: 0,
      UserID: 0,
      officeList : [],
      deliveryUnit: [],
      purpose: [],
      specificFundSource: [],
      DateRange: null,
      menu2: false,
      budgetsForRevision: [],
      savings: 0,
      budget: [],
      submitData: {},
      Status: 0,
      statuses: [
        {id: 0, description: 'All'},
        {id: 1, description: 'Pending'},
        {id: 2, description: 'Submitted'},
      ],
      quarters: [
        { id: 0, description: 'All' },
        { id: 1, description: 'First' },
        { id: 2, description: 'Second' },
        { id: 3, description: 'Third' },
        { id: 4, description: 'Fourth' },
      ],
      QuarterID: 0,
      showBMEU: true,
      viewData: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText : function(){
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
			this.initialize();
			}, 600);
		},
    fiscalYear: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    QuarterID: {
        handler () {
         this.initialize();
        },
      },
    },
    created() {
      this.initialize();
    },  
    mounted () {
      this.eventHub.$on("closeViewMAndE", () => {
        this.initialize();
      });
    },
    beforeDestroy() {
      this.eventHub.$off("closeViewMAndE");
    },
    methods: {
      print(data) {
        window.open(this.apiUrl + "/mei/report/" + data.MEIID);
      },
      viewItem(data){
        this.viewData = data;
      },
      submitItem(item){
        this.submitData = item;
        this.submitMEIDialog = true;
      },
      cancel() {
        this.editItemId = null;
        this.transferItemId = null;
        this.$refs.formRef.reset();
        this.submitMEIDialog = false;
      },
      initialize () {
        if(this.fiscalYear != null){
        this.loading = true;
        this.loadingCSV = true;
        this.showBMEU = false;
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
          data.append('searchText', this.searchText);
          data.append('FiscalYearID', this.fiscalYear);
          data.append("QuarterID", this.QuarterID);
          data.append("UserID", this.$store.state.user.UserID);
              this.axiosCall(
              '/getAllBudgetMAndEByUserPending',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false;
            this.loadingCSV = false;
              this.budgetList = res.data.data.result;
              this.itemListForCSV = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
            this.showBMEU = true;
            //this.getBudgetList();
          })
        }
      },
    //   getBudgetTypeAndOffice() {
    //   this.axiosCall(
    //           '/getOfficeAndOthersForCreateUpdate',
    //           'GET'
    //       )
    //       .then((res) => {
    //           this.office = res.data.data.office;
    //           this.fundList = res.data.data.fund;
    //           this.specificfundsourceList = res.data.data.specificfundsource;
    //           this.purposeList = res.data.data.purpose;
    //           this.allotmentList = res.data.data.allotmenttype;
    //           this.deliveryunit = res.data.data.deliveryunit;
    //           this.users = [{UserID: 0, name: 'All'}, ...res.data.data.users];
    //       })
		// },
      submit () {
         if(this.$refs.formRef.validate()){
          this.isLoading = true;
          let data = new FormData();
          data.append('MEIID', this.submitData.MEIID);
          data.append('Justification', this.formData.Justification);
          data.append('CatchUpPlan', this.formData.CatchUpPlan);
                      this.axiosCall(
                    '/mei/submit',
                    'POST',
                    data
                )
                .then((res) => {
                    this.isLoading = false;
                    if(res.data.status) {
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'success';
                    this.fadeAwayMessage.message = 'Budget For M&E was successfully submitted!';
                    this.fadeAwayMessage.header = 'System Message';
                    this.cancel();
                    this.initialize();
                    }else {
                    this.fadeAwayMessage.show = true;
                    this.fadeAwayMessage.type = 'error';
                    this.fadeAwayMessage.message = 'Something went wrong try again!';
                    this.fadeAwayMessage.header = 'System Message';
                    }
                })
         }
      },
    }
  }
</script>
<style scoped>
.mei-title {
  color: #777 !important;
}
.mei-pending-title {
  color: #999 !important;
}
</style>