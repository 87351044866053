var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.prList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-2",staticStyle:{"max-width":"300px"},attrs:{"append-icon":"mdi-magnify","placeholder":"Search for PR Code/Purpose","color":"green darken-2","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)]},proxy:true},{key:"item.PRCODE",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isApproved == 1 && item.isDBM == 0 ? item.PRCODE : item.PRCODETemp)+" ")]}},{key:"item.ApprovalStatus",fn:function(ref){
var item = ref.item;
return [(item.PSUStatusID == 7 && item.IsCanvassed == 1 && item.POexist == 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"font-size":"12px"},attrs:{"color":"header","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" Utilized ")])]}}],null,true)},[_c('span',[_vm._v("Some/All items in PR are utilized")])]):(item.isPending)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",staticStyle:{"font-size":"12px"},attrs:{"color":"red","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" Pending ")])]}}],null,true)},[_c('span',[_vm._v("Pending By Finance/President")])]):(item.PSUStatusID == 7 && item.IsCanvassed == 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"font-size":"12px"},attrs:{"color":"header","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" For Canvass ")])]}}],null,true)},[_c('span',[_vm._v("Set For Canvass By PSU")])]):(item.PSUStatusID == 6 && item.IsCanvassed == 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"font-size":"12px"},attrs:{"color":"orange darken-2","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" Pending ")])]}}],null,true)},[_c('span',[_vm._v("Pending By PSU")])]):(item.PSUStatusID == 7 && item.IsCanvassed == 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticStyle:{"font-size":"12px"},attrs:{"color":"blue darken-2","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" Received ")])]}}],null,true)},[_c('span',[_vm._v("Received By PSU")])]):(item.isApproved)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",staticStyle:{"font-size":"12px"},attrs:{"color":"green ","text-color":"white"}},'v-chip',attrs,false),on),[_vm._v(" Approved ")])]}}],null,true)},[_c('span',[_vm._v("Approved By Finance&President")])]):_c('v-chip',{staticClass:"ma-2",staticStyle:{"font-size":"12px"}},[_vm._v(" On Process ")])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewPR(item)}}},[_vm._v(" visibility ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"green accent-4"},on:{"click":function($event){return _vm.print(item)}}},[_vm._v(" mdi-printer ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"red","disabled":item.isApproved == 1},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),_c('v-dialog',{staticStyle:{"position":"relative"},attrs:{"persistent":"","max-width":"320"},model:{value:(_vm.dialogconfirmDelete),callback:function ($$v) {_vm.dialogconfirmDelete=$$v},expression:"dialogconfirmDelete"}},[_c('v-card',{staticClass:"px-6 py-5"},[_c('div',{staticClass:"font-size-18 mb-4 font-2 text-h6 px-0",staticStyle:{"line-height":"1.3em"}},[_vm._v(" Are you sure you want to remove this purchase request? ")]),_c('v-card-actions',{staticClass:"px-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-4",attrs:{"text":""},on:{"click":function($event){_vm.dialogconfirmDelete = false; _vm.deleteitemid = null;}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"px-4",attrs:{"color":"error","loading":_vm.dialogLoading},on:{"click":function($event){return _vm.deletePR()}}},[_vm._v(" Remove ")])],1)],1)],1),_c('prModlal',{attrs:{"prData":_vm.prItem}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }