import { render, staticRenderFns } from "./AllocatedBudgets.vue?vue&type=template&id=7bb2f706&scoped=true&"
import script from "./AllocatedBudgets.vue?vue&type=script&lang=js&"
export * from "./AllocatedBudgets.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bb2f706",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VCol,VRow})
