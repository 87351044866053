<template>
<div>
  <v-tabs v-model="access" color="header" class="mb-1">
     <v-tab
      v-for="tab of access_tabs"
      :key="tab.id"
      :disabled="editing"
     >
     {{tab.desc}}
     </v-tab>
   </v-tabs>
          <v-toolbar flat color="header" class="white--text" dark>
            <v-toolbar-title>{{roles[tab_selected] ? roles[tab_selected].RoleDescription : ''}} Modules</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="action('edit')" v-if="!editing">
              <v-icon>sort</v-icon>
            </v-btn>
            <span v-if="user_modules.length > 0">
            <v-btn text @click="action('save')" v-if="editing">
              Save
            </v-btn>
            <v-btn text @click="action('undo')" v-if="editing">
              Undo
            </v-btn>
            </span>
            <span v-else>
              <v-btn icon @click="editing = false" v-if="editing">
                <v-icon>close</v-icon>
              </v-btn>
            </span>
          </v-toolbar>
          <v-tabs vertical color="header" class="vertical-tabs" v-model="tab_selected">
      <v-tab v-for="(item, index) in roles" :key="index" :disabled="roles[tab_selected].RoleID != item.RoleID && editing">
        <v-icon left>
          mdi-account
        </v-icon>
        {{item.RoleDescription}}
      </v-tab>
      <v-tab-item v-for="(item, index) in roles" :key="index">
        <v-toolbar flat>
            <v-autocomplete
              v-model="formData.module"
              item-text="title"
              item-value="id"
              :items="modules"
              outlined
              hide-details
              dense
              color="header" class="mr-3"
              :disabled="!editing"
              label="Select Modules">
            </v-autocomplete>
            <v-btn color="header" @click="addModule()" :disabled="!editing"><v-icon class="white--text">mdi-plus</v-icon></v-btn>
          </v-toolbar>
          <v-list two-line class="py-0" v-if="user_modules.length > 0">
            <v-list-item-group>
            <draggable :options="options" v-model="user_modules">
              <template v-for="(item, index) in user_modules">
                <v-list-item :key="roles[index] ? roles[index].RoleDescription : '' + index" v-if="!item.subLinks">
                  <v-list-item-avatar tile>
                    <v-icon large>{{item.icon}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                    <v-list-item-subtitle v-if="access == 0">/{{roles[tab_selected].BaseUrl + item.route}}</v-list-item-subtitle>
                     <v-list-item-subtitle v-else>{{item.route}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar color="grey lighter-5">
                    <span style="user-select:none;">{{ index+1 }}</span>
                  </v-list-item-avatar>
                  <v-list-item-action v-if="editing" class="ml-8">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                        <v-checkbox v-model="item.IsExtract" color="header" hide-details></v-checkbox>
                        </span>
                      </template>
                      <span>Extract</span>
                    </v-tooltip>
                  </v-list-item-action>
                    <v-list-item-action v-if="editing">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="remove(index)" icon v-bind="attrs" v-on="on" color="red"><v-icon>delete</v-icon></v-btn>
                      </template>
                      <span>Remove</span>
                    </v-tooltip>
                  </v-list-item-action>

                </v-list-item>
                <v-list-group
                v-else
            :key="item.title"
            no-action
            :value="false"
            :append-icon="null"
        >
            <template v-slot:activator>
              <v-list-item-avatar tile>
                    <v-icon large>{{item.icon}}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{item.title}}</v-list-item-title>
                    <v-list-item-subtitle v-if="access == 0">/{{roles[tab_selected].BaseUrl + item.route}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else>{{item.route}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-avatar color="grey lighter-5">
                    <span style="user-select:none; color: black;">{{ index+1 }}</span>
                  </v-list-item-avatar>
                  <v-list-item-action v-if="editing" class="ml-8">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                        <v-checkbox v-model="item.IsExtract" color="header" hide-details></v-checkbox>
                        </span>
                      </template>
                      <span>Extract</span>
                    </v-tooltip>
                  </v-list-item-action>
                    <v-list-item-action v-if="editing">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="remove(index)" icon v-bind="attrs" v-on="on" color="red"><v-icon>delete</v-icon></v-btn>
                      </template>
                      <span>Remove</span>
                    </v-tooltip>
                  </v-list-item-action>
             </template>
            <div class="sub-item">
            <v-list-item
                v-for="sublink in item.subLinks"
                :key="sublink.title"
                class="pl-15"
            >
                <v-list-item-avatar>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ sublink.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="access == 0">/{{roles[tab_selected].BaseUrl + sublink.route}}</v-list-item-subtitle>
                <v-list-item-subtitle v-else>{{sublink.route}}</v-list-item-subtitle>
            </v-list-item>
            </div>
        </v-list-group>
              </template>
                <!-- <v-divider
            v-if="index < user_modules.length - 1"
            :key="index"
          ></v-divider> -->
            </draggable>
            </v-list-item-group>
          </v-list>
          <v-row v-else>
          <v-col>
            <v-img src="../../assets/no-module.jpg" max-width="250px" alt="no module available" class="ml-auto mr-auto"></v-img>
          </v-col>
        </v-row>  
      </v-tab-item>
      </v-tabs>
    <v-dialog v-model="dialogConfirm" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="subheader">Confirmation</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="dialogConfirm = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text>
            <span v-if="confirm_type === 'undo'">Are you sure you want to undo the action?</span>
            <span v-if="confirm_type === 'save'">Are you sure you want to save the user modules?</span>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" outlined @click="dialogConfirm = false">
          Cancel
          </v-btn>
          <v-btn
            color="green darken-3"
            :loading="isLoading"
            class="white--text"
            @click="confirmSave()"
            v-if="confirm_type === 'save'"
          >
          <v-icon>mdi-check-circle</v-icon>
            Save
          </v-btn>
          <v-btn
            color="red darken-3"
            :loading="isLoading"
            class="white--text"
            @click="confirmUndo()"
            v-if="confirm_type === 'undo'"
          >
          <v-icon>undo</v-icon>
            Undo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      searchText: '',
      loading: true,
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      roles: [],
      roles_temp: [],
      before: null,
      editing: false,
      modules: [],
      modules_temp: [],
      user_modules: [],
      user_modules_deleted: [],
      tab_selected: 0,
      formData: {
        module: null,
      },
      confirm_type: '',
      dialogConfirm: false,
      access_tabs: [
        {id: 1, desc: 'Main Access'},
        {id: 2, desc: 'Sub Access'},
      ],
      access: 0,
      }),
    watch: {
      tab_selected: {
        handler (data) {
         this.getUserModules(this.roles[data].RoleID)
        },
        deep: true,
      },
      access: {
        handler (data) {
          let result = [];
         this.roles_temp.forEach(item => {
          if(item.RoleType == parseInt(data) + 1){
            result.push(item)
          }
         });
         this.roles = result;
         this.tab_selected = 0;
         this.getUserModules(this.roles[0].RoleID);
        },
        deep: true,
      },
      user_modules: {
        handler (data) {
          this.modules = JSON.parse(JSON.stringify(this.modules_temp))
          this.modules.forEach(item => {
            data.forEach(item2 => {
              if (item.id == item2.id && item2.ModuleID == null){
                item.disabled = true
              }
              else if(item.id != null && item.id == item2.ModuleID){
                item.disabled = true;
              }
            })
          });
        },
        deep: true,
      },
    },
    computed: {
    options () {
      return {
        disabled: !this.editing
      }
    }
  },
    created () {
      this.getRoles();
      this.getModules();
    },
    methods: {
    getModules() {
      this.axiosCall("/getModuleList", "GET").then((res) => {
        res.data.data.forEach(item => {
          item.IsExtract = 0
        });
        this.modules = res.data.data;
        this.modules_temp = res.data.data;
      });
    },
    getRoles() {
      this.axiosCall("/getRoleList", "GET").then((res) => {
        this.roles_temp = res.data.data;
        let result = [];
        this.roles_temp.forEach(item => {
          if(item.RoleType == parseInt(this.access) + 1){
            result.push(item)
          }
         });
         this.roles = result;
        this.getUserModules(this.roles[0].RoleID);
      });
    },
    getUserModules(role) {
      this.axiosCall("/getModulePerRole/"+role, "GET").then((res) => {
        this.user_modules = res.data.data;
      });
    },
    addModule(){
      if(this.formData.module){
        this.modules.forEach(item => {
          if(item.id == this.formData.module){
            this.user_modules.push(item);
          }
        });
        this.formData.module = null;
      }
    },
    action (e) {
      this.confirm_type = e;
      if (e === 'edit'){
        this.before = Object.assign([],this.user_modules);
        this.editing = !this.editing
      } 
      if (e === 'undo'){
        if(JSON.stringify(this.before) == JSON.stringify(this.user_modules)){
        this.editing = !this.editing
        }
        else{
          this.dialogConfirm = true;
        }
      }
      if(e === 'save'){
        this.dialogConfirm = true;
      }
      },
      remove (i) {
        this.user_modules_deleted.push(this.user_modules[i])
        this.$delete(this.user_modules, i)
      },
      confirmUndo(){
        this.user_modules = this.before
        this.user_modules_deleted = [];
        this.dialogConfirm = false;
        this.editing = !this.editing
      },
      confirmSave(){
         if(this.roles[this.tab_selected].RoleID){
            this.isLoading = true;
            let data = new FormData();
            data.append('Modules', JSON.stringify(this.user_modules));
            data.append('Modules_deleted', JSON.stringify(this.user_modules_deleted));
            data.append('RoleID', this.roles[this.tab_selected].RoleID);
            this.axiosCall(
                  '/moduleuser/save',
                  'POST',
                  data
              )
              .then((res) => {
                  this.isLoading = false;
                  if(res.data.status) {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'success';
                  this.fadeAwayMessage.message = this.roles[this.tab_selected].RoleDescription + ' Module was successfully added!';
                  this.fadeAwayMessage.header = 'System Message';
                  this.dialogConfirm = false;
                  this.editing = !this.editing
                  }else {
                  this.fadeAwayMessage.show = true;
                  this.fadeAwayMessage.type = 'error';
                  this.fadeAwayMessage.message = 'Something went wrong try again!';
                  this.fadeAwayMessage.header = 'System Message';
                  }
              })
         }
      },
    }
  }
</script>
<style scoped>
div.vertical-tabs [role="tab"] {
  justify-content: flex-start;
}
.sub-item {
  background: rgba(229, 229, 229, 0.3);
}
</style>