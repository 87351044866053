<template>
  <div class="DarkWhite pa-4 ">
      <h2 class="subheading green--text">Suppliers</h2>
      <v-card class="mt-4">
      <PSUCompanyUtility/>
  </v-card>
  </div>
</template>

<script>
import PSUCompanyUtility from '../components/Utils/PSUCompanyUtility.vue'
  export default {
    components: {
      PSUCompanyUtility
    },
    data: () => ({
    }),
  }
</script>


<style scoped>
</style>