<template>
<div>
  <v-data-table
    :headers="headers"
    :items="consolidationScheduleList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <!-- <v-toolbar-title>Item Utility</v-toolbar-title> -->
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="mb-2"
              @click="Dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
      </v-toolbar>
    </template>
      <template v-slot:[`item.DateStart`]="{ item }">
        {{ formatDate(item.DateStart) }}
      </template>
      <template v-slot:[`item.DateEnd`]="{ item }">
        {{ formatDate(item.DateEnd) }}
      </template>
      <template v-slot:[`item.BudgetCodes`]="{ item }">
        {{ item.BudgetCodes ? JSON.parse(item.BudgetCodes).join(', ') : 'All' }}
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
  </v-data-table>
        <v-dialog
          v-model="Dialog"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">Update Schedule</span>
              <span class="subtitle-1 font-weight-bold" v-else>New Schedule</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-textarea
                    dense
                     v-model="formData.Description"
                     :rules="[formRules.required]"
                     counter
                     maxlength="250"
                      label="Description"
                    ></v-textarea>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                   <v-menu
                    dense
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateStart"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateStart"
                        :rules="[formRules.required]"
                        label="Date Start"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateStart"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(formData.DateStart)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-menu
                    dense
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="formData.DateEnd"
                    transition="scale-transition"
                    offset-y
                    class="mb-4"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="formData.DateEnd"
                        :rules="[formRules.required]"
                        label="Date End"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker
                      v-model="formData.DateEnd"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(formData.DateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="formData.isSelected"
                      label="Selected Budget Codes"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" v-if="formData.isSelected">
               <v-autocomplete
              v-model="formData.budgetCodes"
              :items="budgetCodeList"
              chips
              :rules="[formRules.required, formData.budgetCodes.length > 0]"
              color="blue-grey lighten-2"
              label="Budget Codes"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="remove(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                text
                @click="save"
                :loading="isLoading"
              >
                Save
              </v-btn>
              <v-btn
              v-else
                color="red darken-1"
                text
                @click="update"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      amountErr: null,
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu1: false,
      menu2: false,
      menu3: false,
      formData: {
        Description: null,
        DateStart: null,
        DateEnd: null,
        isSelected: false,
        budgetCodes: []
      },
      Dialog: false,
      consolidationScheduleList: [],
      headers: [
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'Description',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Start',
          align: 'start',
          sortable: false,
          value: 'DateStart',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date End',
          align: 'start',
          sortable: false,
          value: 'DateEnd',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Budget Codes',
          align: 'start',
          sortable: false,
          value: 'BudgetCodes',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
       budgetCodeList: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    created () {
      this.getAllBudgetCode();
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
              this.axiosCall(
              '/getConsolidationSchedule',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.consolidationScheduleList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
    editItem(data) {
      this.editItemId = data.ConsolidationSchedID;
      this.formData.Description = data.Description;
      this.formData.DateStart = data.DateStart;
      this.formData.DateEnd = data.DateEnd;
      this.formData.isSelected = data.isSelected;
      this.formData.budgetCodes = JSON.parse(data.BudgetCodes);
      console.log(this.formData.budgetCodes)
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
                     this.isLoading = true;
             let data = new FormData();
              data.append('Description', this.formData.Description);
              data.append('DateStart', this.formData.DateStart);
              data.append('DateEnd', this.formData.DateEnd);
              data.append('isSelected', this.formData.isSelected ? 1 : 0);
              data.append('BudgetCodes', this.formData.isSelected ? JSON.stringify(this.formData.budgetCodes) : null);
                         this.axiosCall(
                        '/consolidationSchedule/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
                     this.isLoading = true;
             let data = new FormData();
              data.append('ConsolidationSchedID', this.editItemId);
               data.append('Description', this.formData.Description);
              data.append('DateStart', this.formData.DateStart);
              data.append('DateEnd', this.formData.DateEnd);
              data.append('isSelected', this.formData.isSelected ? 1 : 0);
              data.append('BudgetCodes', this.formData.isSelected ? JSON.stringify(this.formData.budgetCodes) : null);
                         this.axiosCall(
                        '/consolidationSchedule/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      remove (item) {
        const index = this.formData.budgetCodes.indexOf(item)
        if (index >= 0) this.formData.budgetCodes.splice(index, 1)
      },
      getAllBudgetCode(){
        this.budgetCodeList = [];
             this.axiosCall(
              '/getAllBudgetCode',
              'POST',
              []
          )
          .then((res) => {
              res.data.data.result.forEach(item => {
              this.budgetCodeList.push(item.BudgetCode);
              });
          })
      }
    }
  }
</script>