<template>
  <div class="DarkWhite pa-4">
    <v-row>
    <v-col cols="12" sm="6" md="8" lg="10">
        <h2 class="subheading green--text">Inspection and Acceptance Report List</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-autocomplete
          v-model="fiscalYear"
          :items="fiscalyears"
          auto-select-first
          item-text="fiscalYear"
          item-value="id"
          color="header"
          dense
          label="Fiscal Year"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="iarList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search IAR/PO number"
            color="green darken-2"
            class="mr-2"
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
          v-model="SupplierID"
          :items="suppliers"
          auto-select-first
          style="max-width: 300px"
          item-text="CompanyName"
          item-value="SupplierID"
          color="header"
          class="mx-2"
          dense
          label="Supplier"
          hide-details
          >
          </v-autocomplete>
          <v-autocomplete
          v-model="RISStatusID"
          v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 16 || $store.state.user.roleID == 20"
          :items="ris_status"
          auto-select-first
          style="max-width: 300px"
          item-text="description"
          item-value="id"
          color="header"
          class="mx-2"
          dense
          label="RIS Status"
          hide-details
          >
          </v-autocomplete>
        </v-toolbar>
      </template>

      <template v-slot:[`item.GrandTotal`]="{ item }">
        ₱ {{ formatPrice(item.GrandTotal) }}
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.other_actions`]="{ item }">
        <v-btn
          color="amber darken-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="ris(item)"
          v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 16 || $store.state.user.roleID == 20"
        >
          RIS
        </v-btn>
        <!-- <v-btn color="amber darken-2"
          x-small
          text
          v-if="item.IsIssued"
          >
            <span>
              <v-icon small color="amber darken-2">mdi-check</v-icon> RIS
            </span>
          </v-btn> -->
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          color="blue darken-2"
          class="mr-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="IAR(item)"
        >
          View
        </v-btn>
        <v-btn
          color="red darken-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="viewIAR(item)"
        >
          Print
        </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogIARVersion" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Please select IAR version:</v-card-title>
        <v-card-actions>
          <v-row justify="center">
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple</v-icon
              ><br />
              <v-btn color="green darken-2" @click="oldIAR()" text
                >Old version</v-btn
              >
            </v-col>
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple-outline</v-icon
              ><br />
              <v-btn color="green darken-2" @click="newIAR()" text
                >New version</v-btn
              >
            </v-col>
            <v-col align="end" cols="12">
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogIARVersion = false"
                >Cancel</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <IARModal :poData="poItem1" />
    <ProcessIARModal :processData="dispenseData" />
    <CreateRISModal :risData="risData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    IARModal: () => import("../../components/IAR/ViewIAR.vue"),
    ProcessIARModal: () => import("../../components/IAR/ProcessIAR.vue"),
    CreateRISModal: () => import("../../components/Supply/CreateRIS.vue"),
  },
  data: () => ({
    tab: 1,
    searchText: "",
    searchTimeout: null,
    item: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    iarList: [],
    poItem1: [],
    dispenseData: [],
    dialogIARVersion: false,
    link: "",
    headers: [
      {
        text: "IAR No.",
        value: "IARCODE",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PO No.",
        value: "POCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Supplier",
        value: "CompanyName",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Amount",
        value: "GrandTotal",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Date Created",
        value: "DateCreated",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Status",
        value: "Status",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "",
        value: "other_actions",
        align: "center",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    fiscalYear: null,
    fiscalyears: [],
    risData: [],
    suppliers: [],
    SupplierID: 0,
    ris_status: [
      {id: 0, description: "All"},
      {id: 1, description: "Pending"},
      {id: 2, description: "Partial"},
      {id: 3, description: "Completed"},
    ],
    RISStatusID: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
        this.initialize();
      },
      deep: true,
    },
    SupplierID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    RISStatusID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
    created() {},
  mounted() {
    this.eventHub.$on("closeProcessIAR", () => {
      this.initialize();
    });
    this.eventHub.$on("closeViewIAR", () => {
      this.initialize();
    });
    this.eventHub.$on("closeCreateRIS", () => {
      this.initialize();
    });
    this.getFiscalYears()
    this.getSuppliers();
  },
  beforeDestroy() {
    this.eventHub.$off("closeProcessIAR");
    this.eventHub.$off("closeViewIAR");
    this.eventHub.$off("closeCreateRIS");
  },
  methods: {
    IAR(item) {
      this.poItem1 = item;
    },
    viewIAR(item) {
      this.dialogIARVersion = true;
      this.link = this.apiUrl + "/iar/report/" + item.IARID;
    },
    dispense(item) {
      this.dispenseData = item;
    },
    ris(item){
      this.risData = item
    },
    oldIAR() {
      window.open(this.link + "/old");
      this.dialogIARVersion = false;
    },
    newIAR() {
      window.open(this.link + "/new");
      this.dialogIARVersion = false;
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("SupplierID", this.SupplierID);
      data.append("RISStatusID", this.RISStatusID);
      this.axiosCall("/iar/list/get", "POST", data).then((res) => {
        this.loading = false;
        this.iarList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
    getSuppliers() {
      let data = new FormData();
      data.append("searchText", '');
      this.axiosCall("/get/all/supplier", "POST", data).then((res) => {
        this.suppliers = [{SupplierID: 0, CompanyName: "All"},...res.data.data]
      });
    },
  },
};
</script>