<template>
  <div class="px-2">
    <!-- <v-tabs light>
      <v-tabs-slider color="amber accent-4"></v-tabs-slider>
    <v-tab class="text-caption font-weight-medium grey--text text--darken-2" @click="viewList()">
      <v-icon class="mr-1">mdi-view-list</v-icon>
      List View</v-tab>
    <v-tab class="text-caption font-weight-bold grey--text text--darken-2" @click="viewList()">
      <v-icon class="mr-1">mdi-dots-grid</v-icon>
      Grid View</v-tab>
  </v-tabs> -->
    <v-data-table
      :headers="headers"
      :items="itemList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-0"
      v-if="listView"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="mb-2 mr-2"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            color="green"
            v-model="ClassificationID"
            item-text="ClassificationDescription"
            item-value="ClassificationID"
            :items="classificationListFiltering"
            dense
            hide-details
            class="mr-3"
            label="Filter By Class"
          >
          </v-autocomplete>
          <v-btn
            color="green darken-2"
            dark
            outlined
            elevation="2"
            class="mb-2"
            @click="addDialog = true"
            v-if="$store.state.user.roleID != 2"
          >
            <v-icon>mdi-plus</v-icon>
            Add New Item
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.UnitCost`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
      </template>
      <template v-slot:[`item.StockProperty`]="{ item }">
        {{ item.Stock ? 'Stock' : item.Property ? 'Property': '' }}
      </template>
      <template v-slot:[`item.Percentage`]="{ item }">
        {{ item.Percentage || '0.00' }}%
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <v-tooltip top v-if="item.IsFrequentUpdate">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-4 orange--text"
            >
              error_outline
            </v-icon>
          </template>
          <span>Marked For Frequent Update</span>
        </v-tooltip>
        <span class="mr-8 orange--text" v-else> &nbsp; </span>
        <span class="text-no-wrap">
        <v-icon small class="mr-2" color="blue darken-2" @click="editItem(item)" v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 5 || $store.state.user.roleID == 20">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" color="red darken-2" @click="deleteItem(item)" v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 16 || $store.state.user.roleID == 20">
          mdi-delete
        </v-icon>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="addDialog" persistent scrollable max-width="1300px">
      <v-card>
        <v-card-title>
          <span class="text-h5 font-weight-bold" v-if="editItemId"
            >Update Item</span
          >
          <span class="text-h5 font-weight-bold" v-else>New Item</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text style="max-height: 900px;">
            <v-row>
              <v-col cols="12" md="6">
              <v-form @submit.prevent ref="formRef">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        color="green"
                        v-model="formData.CategoryID"
                        item-text="CategoryDescription"
                        item-value="CategoryID"
                        :rules="[formRules.required]"
                        :items="categoryList"
                        dense
                        label="Category"
                        @change="searchItem()"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="formData.CategoryID == 15">
                      <v-autocomplete
                        color="green"
                        v-model="formData.SubCategoryID"
                        item-text="SubCategoryDescription"
                        item-value="SubCategoryID"
                        :rules="[formRules.required]"
                        :items="subCategoryList"
                        dense
                        label="Sub Category"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        color="green"
                        dense
                        v-model="formData.ItemName"
                        :rules="[formRules.required, formRules.firstCharMustLetter]"
                        label="Item Name"
                        @keyup="getCode(formData.ClassificationID); searchItem();"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        color="green"
                        dense
                        v-model="formData.ItemDescription"
                        label="Item Description"
                        counter
                        maxlength="2000"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        color="green"
                        v-model="formData.UnitMeasure"
                        item-text="UnitMeasureDescription"
                        item-value="UnitMeasureID"
                        :rules="[formRules.required]"
                        :items="unitmeasureList"
                        dense
                        label="Unit Measure"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        color="green"
                        dense
                        v-model="formData.UnitCost"
                        :rules="[formRules.required]"
                        @change="changeUnitCost(formData.UnitCost)"
                        label="Unit Cost"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="formData.UnitCost">
                      <v-autocomplete
                        color="green"
                        v-model="formData.ClassificationID"
                        item-text="ClassificationDescription"
                        item-value="ClassificationID"
                        :items="classificationListFiltered"
                        @change="getCode(formData.ClassificationID)"
                        dense
                        clearable
                        :label="formData.UnitCost < 50000 ? 'Classification (Stock)' : 'Classification (Property)'"
                      >
                      </v-autocomplete>
                      <div v-if="formData.UnitCost < 50000">
                      <span>Stock Number</span>
                      <v-text-field
                        color="green"
                        dense
                        :value="formData.Stock"
                        :prefix='"###-" + classCode + "-" + this.formData.ItemName.charAt(0).toUpperCase() + "-"'
                        readonly
                      ></v-text-field>
                      </div>
                      <div v-if="formData.UnitCost >= 50000">
                      <span>Property Number</span>
                      <v-text-field
                        color="green"
                        dense
                        :value="formData.Property"
                        :prefix='"###-" + classCode + "-" + this.formData.ItemName.charAt(0).toUpperCase() + "-"'
                        readonly
                      ></v-text-field>
                      </div>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          color="green"
                          v-model="formData.PriceInjectionID"
                          item-text="Percentage"
                          item-value="PriceInjectionID"
                          :items="priceInjectionList"
                          clearable
                          dense
                          label="Price Increase"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                      <v-checkbox
                        v-model="formData.IsFrequentUpdate"
                        label="Mark Item For Frequent Update"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
              </v-col>
              <v-col cols="12" md="6">
                <span class="subtitle-1 font-weight-bold">Related Items</span>
                <v-alert
                text
                  dense
                  color="header"
                  icon="mdi-help-circle"
                  border="left"
                >
                  <div class="text-h6">
                    Other related items will be displayed here...
                  </div>
                  <div>the relation of the inputted item to the other items will be based on the "Item Name", "Item Description", and "Category".</div>
                </v-alert>
                <div class="mt-10" v-if="relatedItems.length == 0">
                <div class="text-subtitle-1 d-flex justify-center">
                  <v-img src="../../assets/no-module.jpg" max-width="250px" alt="no module available" class="ml-auto mr-auto"></v-img>
                </div>
                <div class="text-subtitle-1 d-flex justify-center">
                  No data available
                </div>
                </div>
                <v-list three-line>
                <template v-for="(item, index) in relatedItems">
                  <v-subheader
                    v-if="item.header"
                    :key="index"
                  >
                  {{item.header}}
                  </v-subheader>

                  <v-list-item
                    :key="index"
                  >
                    <v-list-item-avatar>
                      <v-img src="../../assets/pms-item-placeholder.jpg"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{item.ItemName}}</v-list-item-title>
                      <v-list-item-subtitle>{{item.ItemDescription}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                            <v-list-item-action-text>
                              <v-chip
                              color="header lighten-1"
                              dark
                              x-small
                            >
                              {{item.CategoryTypeID == 1 ? 'DBM' : item.CategoryTypeID == 2 ? 'NON-DBM' : item.CategoryTypeID == 3 ? 'LIB' : 'Uncategorized'}}
                            </v-chip>
                            </v-list-item-action-text>
                            <span class="subtitle-1 text-no-wrap">
                              ₱ {{formatPrice(parseFloat(item.UnitCost) + parseFloat(item.UnitCost * (item.Percentage / 100)))}}
                            </span>
                            
                          </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list>
              <div v-if="relatedItems.length > 0" class="d-flex justify-center">
                  <v-pagination
                      v-model="options2.page"
                      class="my-4"
                      color="header"
                      :length="Math.ceil(paginationData.totalCount / paginationData.perPage)"
                      :total-visible="7"
                    ></v-pagination>
              </div>
              </v-col>
            </v-row>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="cancel()"> Cancel </v-btn>
          <v-btn
            v-if="!editItemId"
            color="green darken-1"
            dark
            @click="save"
            :loading="isLoading"
          >
          <v-icon class="mr-1">mdi-plus-circle</v-icon>
            Add
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            dark
            @click="update"
            :loading="isLoading"
          >
          <v-icon class="mr-1">mdi-pencil-circle-outline</v-icon>
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline mt-5">Confirmation</v-card-title>
          <div class="mx-6">
            <strong>Are you sure you want to delete this item?</strong> <br /> <br />
            <b>Item Name: </b> {{itemToDelete.ItemName}}<br />
            <b>Item Description: </b> {{itemToDelete.ItemDescription || "No decription"}}
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="dialogDelete = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmDeleteItem()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  data: () => ({
    searchText: "",
    listView: true,
    editItemId: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    formData: {
      ItemName: null,
      CategoryID: null,
      ItemDescription: null,
      UnitMeasure: null,
      UnitCost: null,
      ClassificationID: null,
      Stock: null,
      Property: null,
      IsFrequentUpdate: null,
      PriceInjectionID: null,
      CategoryTypeID: null
    },
    addDialog: false,
    itemList: [],
    priceInjectionList: [],
    categoryList: [],
    unitmeasureList: [],
    subCategoryList: [],
    classificationList: [],
    classificationListFiltered: [],
    classCode: '####',
    classType: null,
    ClassificationID: 0,
    classificationListFiltering: [],
    headers: [
      {
        text: "Category",
        align: "start",
        value: "CategoryDescription",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Item Name",
        value: "ItemName",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Item Description",
        value: "ItemDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Unit Cost",
        value: "UnitCost",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Classification",
        value: "Code",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Stock/Property",
        value: "StockProperty",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Price Increase",
        value: "Percentage",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Created By",
        value: "CreatedBy",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Updated By",
        value: "UpdatedBy",
        sortable: false,
        align: "center",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Action",
        value: "Actions",
        sortable: false,
        align: "center",
        class:
          "green darken-2 white--text text-caption font-weight-bold d-flex justify-center align-center",
      },
    ],
    relatedItems: [],
    options2: {
      itemsPerPage: 5,
      page: 1,
    },
    paginationData: [],
    itemToDelete: [],
    dialogDelete: false,
  }),
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    ClassificationID: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options2: {
        handler () {
         this.searchItem();
        },
        deep: true,
    },
  },
  created() {
    // this.initialize();
    this.getPriceInjection();
    this.getCategory();
    this.getSubCategory();
    this.getClassification();
  },
  methods: {
    changeUnitCost(amount){
      this.classificationListFiltered = [];
      // this.formData.ClassificationID = null;
      this.classCode = "####";
      if(amount >= 50000){
        this.classificationList.forEach(item => {
          if(item.ClassificationType == 2){
            this.classificationListFiltered.push(item)
          }
        });
        this.formData.Stock = '';
        this.classType = 2;
      }
      else{
        this.classificationList.forEach(item => {
          if(item.ClassificationType == 1){
            this.classificationListFiltered.push(item)
          }
        });
        this.formData.Property = '';
        this.classType = 1;
      }
    },
    getCode(id){
      if(id){
        let desc = "####";
        this.classificationList.forEach(item => {
           if(item.ClassificationID == id){
            desc = item.Code
          }
        });
        this.classCode = desc
        let item_name = this.formData.ItemName.charAt(0);
        let data = new FormData();
        data.append("ClassificationID", id);
        data.append("ItemNameChar", item_name);
        data.append("ItemID", this.editItemId);
        this.axiosCall("/getItemSequence", "POST", data).then((res) => {
          if(this.classType == 1){
            this.formData.Stock = res.data.data;
          }
          else if(this.classType == 2){
            this.formData.Property = res.data.data;
          }
        });
      }
      else{
        this.classCode = '####';
        this.formData.Stock = '';
        this.formData.Property = '';
      }
    },
    viewList() {
      this.listView = !this.listView;
    },
    getPriceInjection() {
      this.axiosCall("/getPriceInjectionList", "GET").then((res) => {
        this.priceInjectionList = res.data.data;
      });
    },
    getCategory() {
      this.axiosCall("/getCategory", "GET").then((res) => {
        this.categoryList = res.data.data.category;
        this.unitmeasureList = res.data.data.unitmeasure;
      });
    },
    getClassification() {
      this.axiosCall("/getClassification", "GET").then((res) => {
        this.classificationList = res.data.data.classification;
        this.classificationListFiltering = this.prepend({ClassificationID: 0, ClassificationDescription: 'All'},res.data.data.classification);
      });
    },
    prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
    },
    getSubCategory() {
      this.axiosCall("/getSubCategory", "GET").then((res) => {
        this.subCategoryList = res.data.data.subCategory;
      });
    },
    editItem(data) {
      this.changeUnitCost(data.UnitCost)
      this.editItemId = data.ItemID;
      this.formData.CategoryID = data.CategoryID;
      this.formData.ItemName = data.ItemName;
      this.formData.UnitCost = data.UnitCost;
      this.formData.UnitMeasure = data.UnitMeasureID;
      this.formData.ItemDescription = data.ItemDescription;
      this.formData.ClassificationID = data.ClassificationID;
      this.formData.Stock = data.Stock;
      this.formData.Property = data.Property;
      this.formData.IsFrequentUpdate = data.IsFrequentUpdate;
      this.formData.PriceInjectionID = data.PriceInjectionID;
      this.getCode(data.ClassificationID)
      this.addDialog = true;
    },
    cancel() {
      this.editItemId = null;
      this.relatedItems = [];
      this.$refs.formRef.reset();
      this.addDialog = false;
    },
    initialize() {
      this.loading = true;
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("ClassificationID", this.ClassificationID);
      this.axiosCall("/getAllItem", "POST", data).then((res) => {
        this.loading = false;
        this.itemList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
    },
    save() {
      if (this.$refs.formRef.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("ItemName", this.formData.ItemName);
        data.append("CategoryID", this.formData.CategoryID);
        data.append("ItemDescription", this.formData.ItemDescription);
        data.append("UnitMeasure", this.formData.UnitMeasure);
        data.append("UnitCost", this.formData.UnitCost);
        data.append("ClassificationID", this.formData.ClassificationID);
        data.append("Stock", this.formData.Stock);
        data.append("Property", this.formData.Property);
        data.append("IsFrequentUpdate", this.formData.IsFrequentUpdate);
        data.append("PriceInjectionID", this.formData.PriceInjectionID);

        this.axiosCall("/addItem", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Item Successfully Added!";
            this.fadeAwayMessage.header = "System Message";
            this.$refs.formRef.reset();
            this.addDialog = false;
            this.initialize();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    update() {
      if (this.$refs.formRef.validate()) {
        this.isLoading = true;
        let data = new FormData();
        data.append("ItemID", this.editItemId);
        data.append("CategoryID", this.formData.CategoryID);
        data.append("ItemName", this.formData.ItemName);
        data.append("ItemDescription", this.formData.ItemDescription);
        data.append("UnitMeasure", this.formData.UnitMeasure);
        data.append("UnitCost", this.formData.UnitCost);
        data.append("ClassificationID", this.formData.ClassificationID);
        data.append("Stock", this.formData.Stock);
        data.append("Property", this.formData.Property);
        data.append("IsFrequentUpdate", this.formData.IsFrequentUpdate);
        data.append("PriceInjectionID", this.formData.PriceInjectionID);
        this.axiosCall("/updateItem", "POST", data).then((res) => {
          this.isLoading = false;
          if (res.data.status) {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Item Successfully Updated!";
            this.fadeAwayMessage.header = "System Message";
            this.$refs.formRef.reset();
            this.editItemId = null;
            this.addDialog = false;
            this.initialize();
          } else {
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
        });
      }
    },
    searchItem(){
      if(this.formData.ItemName != '' && this.formData.CategoryID != null){
        let data = new FormData();
      if (this.options2.page) {
        data.append("page", this.options2.page);
        data.append("itemsPerPage", this.options2.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 5);
      }
      data.append("searchText", this.formData.ItemName);
      data.append("CategoryTypeID", this.CategoryTypeID);
      if(this.editItemId){
      data.append("UpdateID", this.editItemId);
      }
      this.axiosCall("/get/allItems", "POST", data).then((res) => {
        this.relatedItems = res.data.data.result;
        this.paginationData = res.data.data.pagination_data;
        if (this.paginationData.totalCount <= 5) {
          this.options2.page = 1;
        }
        else{
          this.options2.page = res.data.data.pagination_data.page;
        }
      });
      }
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.dialogDelete = true;
    },
    confirmDeleteItem(){
        let data = new FormData();
        data.append("ItemID", this.itemToDelete.ItemID);
        this.axiosCall("/deleteItem", "POST", data).then((res) => {
          if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "Item was successfully deleted!";
            this.fadeAwayMessage.header = "System Message";
            this.itemToDelete = [];
            this.dialogDelete = false;
            this.initialize();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = res.data.message;
            this.fadeAwayMessage.header = "System Message";
          }
          
        });
    },
  },
};
</script>
<style scoped>
/* .v-btn--icon.v-size--default {
    height: 30px !important;
    width: 30px !important;
    background-color: #43A047 !important;
    color: white !important;
} */
.space {
  padding: 0px !important;
}
.v-list .kani {
  line-height: normal !important;
}
</style>