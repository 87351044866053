var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.AOQLists,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"append-icon":"mdi-magnify","label":"Search","color":"green darken-2","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.CanvassNo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.PRCODE.substring(3, item.PRCODE.length))+" ")]}},{key:"item.AOQTypeID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.AOQTypeID == 2 ? 'Lot Bid' : 'Item Bid')+" ")]}},{key:"item.DateReceived",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateReceived))+" ")]}},{key:"item.ClosingDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.ClosingDate) || "N/A")+" ")]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"header","dense":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.aoq(item)}}},[_vm._v(" View ")]),_c('v-btn',{staticClass:"mr-1 white--text",attrs:{"color":"red darken-3","dense":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.print(item)}}},[_vm._v(" Print ")])],1)]}}],null,true)}),_c('canvasModlal',{attrs:{"canvasData":_vm.prItem}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }