<template>
  <div class="DarkWhite pa-4">
      <h2 class="subheading green--text">Office/ Committee</h2>

      <v-card class="mt-4">
      <BudgetOfficeCommitteeUtility/>
  </v-card>
  </div>
</template>

<script>
import BudgetOfficeCommitteeUtility from '@/components/Utils/BudgetOfficeCommitteeUtility.vue'
  export default {
    components: {
      BudgetOfficeCommitteeUtility
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>