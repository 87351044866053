<template>
  <div class="DarkWhite pa-4">
    <v-row>
    <v-col cols="12" sm="6" md="8" lg="10">
        <h2 class="subheading green--text">Purchase Order List</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-autocomplete
          v-model="fiscalYear"
          :items="fiscalyears"
          auto-select-first
          item-text="fiscalYear"
          item-value="id"
          color="header"
          dense
          label="Fiscal Year"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="poList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search PO number"
            color="green darken-2"
            class="mr-2"
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
              v-model="UserID"
              color="green"
              item-text="name"
              item-value="UserID"
              :items="users"
              dense
              outlined
              hide-details
              v-if="$store.state.user.roleID != 2 && $store.state.user.roleID != 3"
              label="Users">
          </v-autocomplete>
        </v-toolbar>
      </template>
      <template v-slot:[`item.GrandTotal`]="{ item }">
        <span class="text-no-wrap">₱ {{formatPrice(item.GrandTotal)}}</span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
                color="blue darken-2"
                dark
                outlined
                elevation="2"
                small
                @click="viewPO(item)"
              >
                View
              </v-btn>
              <!-- <v-btn
                color="green darken-2"
                dark
                outlined
                elevation="2"
                class="ml-3"
                small
                @click="IAR(item)"
                v-if="currentRoleID == 6 || currentRoleID == 15"
              >
                IAR
              </v-btn> -->
        </span>
      </template>
    </v-data-table>
    <poModlal :poData="poItem" />
    <!-- <iarModlal :poData="poItem1" /> -->
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    poModlal: () => import("../../components/PO-Components/PSUViewPOItem.vue"),
    // iarModlal: () => import("../../components/IAR/AddIAR.vue"),
  },
  data: () => ({
    tab: 1,
    searchText: "",
    searchTimeout: null,
    remarks: null,
    item: null,
    dialogPending: false,
    dialogApprove: false,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    poList: [],
    poItem: [],
    poItem1: [],
    currentRoleID: null,
    headers: [
      {
        text: "PO Number",
        value: "POCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Number",
        value: "PRCODE",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Supplier",
        value: "CompanyName",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Supplier Address",
        value: "CompanyAddress",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Grand Total",
        value: "GrandTotal",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "Actions",
        align: "center",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    fiscalYear: null,
    fiscalyears: [],
    users: [],
    UserID: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    tab: {
      handler() {
        this.poList = [];
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
        this.initialize();
      },
      deep: true,
    },
    UserID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.currentRoleID = this.$store.state.user.roleID;
    this.eventHub.$on("closePOItemodal", () => {
      this.initialize();
      this.poItem = {};
    });
    this.eventHub.$on("closeIAR", () => {
      this.initialize();
      this.poItem1 = {};
    });
    this.getFiscalYears()
  },
  beforeDestroy() {
    this.eventHub.$off("closePOItemodal");
    this.eventHub.$off("closeIAR");
  },
  methods: {
    viewPO(item) {
      this.poItem = item;
    },
    IAR(item) {
      this.poItem1 = item;
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("OfficeID", 0);
      data.append("DeliveryUnitID", 0);
      data.append("PurposeID", 0);
      data.append("SpecificFundSourceID", 0);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("UserID", this.UserID);
      this.axiosCall("/signatories/po", "POST", data).then((res) => {
        this.loading = false;
        this.poList = res.data.data.result;
        this.users = [{UserID: 0, name: "All"},...res.data.data.users];
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
  },
};
</script>