<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">Sub Modules</h2>
      <v-card class="mt-4">
      <ModuleSubs/>
  </v-card>
  </div>
</template>

<script>
import ModuleSubs from '@/components/Utils/ModuleSubs.vue'
  export default {
    components: {
      ModuleSubs
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>