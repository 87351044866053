<template>
  <div class="pa-4" style="position: relative">
    <h2 class="subheading green--text">Help for Recommending Approval</h2>
    <HelpUser class="larawan" />
    <img
      src="@/assets/Consulting-rafiki.svg"
      class="bg"
      position="right"
      height="450"
      width="auto"
    />
  </div>
</template>

<script>
import HelpUser from "@/components/Utils/Help-RA.vue";
export default {
  components: {
    HelpUser,
  },
  data: () => ({}),
};
</script>

<style scoped>
.bg {
  border-style: none;
  position: absolute;
  right: 10px;
  top: 160px;
  opacity: 0.5;
  z-index: 1;
}
.larawan {
  z-index: 2;
  opacity: 1;
}
</style>