<template>
  <div style="position: relative">
    <v-row class="mt-4 larawan">
      <v-col cols="4">
        <HelpUserPPMP />
      </v-col>

      <v-col cols="4">
        <HelpUserPR />
      </v-col>

      <v-col cols="4">
        <HelpUserPPMPPRinting />
      </v-col>

      <v-col cols="4">
        <HelpUserPRprinting />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HelpUserPPMP from "./Help-User-PPMP.vue";
import HelpUserPR from "./Help-User-PR.vue";
import HelpUserPPMPPRinting from "./Help-User-PPMP-Printing.vue";
import HelpUserPRprinting from "./Help-User-PR-Printing.vue";
export default {
  components: {
    HelpUserPPMP,
    HelpUserPR,
    HelpUserPPMPPRinting,
    HelpUserPRprinting,
  },
};
</script>