<template>
  <div class="DarkWhite pa-4 ">
    <v-row>
      <v-col cols="12" sm="6" md="8" lg="10" xl="11">
        <h2 class="subheading green--text">List of Items</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" xl="1">
        <v-autocomplete
          v-model="view"
          :items="views"
          auto-select-first
          item-text="description"
          item-value="id"
          color="header"
          dense
          label="Views"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
      <v-card>
      <PSUitemList v-if="view == 1" />
      <PSUitemList2 v-if="view == 2" />
      </v-card>
  </div>
</template>

<script>
import PSUitemList from '../components/Utils/PSUitemList.vue'
import PSUitemList2 from '../components/Utils/PSUitemList2.vue'
  export default {
    components: {
      PSUitemList,
      PSUitemList2
    },
    data: () => ({
      views: [
        {
          id: 1,
          description: 'Grid View'
        },
        {
          id: 2,
          description: 'List View'
        }
      ],
      view: 1
    }),
      watch: {
      view: {
        handler() {
        },
        deep: true,
      },
    }
  }
</script>

<style scoped>
</style>