<template>
<div>
  <v-data-table
    :headers="headers"
    :items="itemList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="mb-2 mr-2"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="my-2"
              @click="Dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
  </v-data-table>
        <v-dialog
          v-model="Dialog"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">Update Module</span>
              <span class="subtitle-1 font-weight-bold" v-else>New Module</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="formData.ModuleID"
                      item-text="title"
                      item-value="id"
                      :rules="[formRules.required]"
                      :items="modules"
                      color="header"
                      label="Modules">
                      </v-autocomplete>
                  </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.title"
                    :rules="[formRules.required]"
                    dense
                    label="Title"
                    color="header"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.icon"
                    :rules="[formRules.required]"
                    dense
                    label="Icon"
                    color="header"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" class="flex-items" v-if="formData.icon">
                  <strong>Selected Icon:</strong>
                  <v-icon x-large class="ml-5" color="#239FAB">{{ formData.icon }}</v-icon>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.route"
                    :rules="[formRules.required]"
                    label="Route"
                    color="header">
                  </v-text-field>
                </v-col>
            </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                text
                @click="save"
                :loading="isLoading"
              >
                Save
              </v-btn>
              <v-btn
              v-else
                color="blue darken-1"
                text
                @click="update"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      searchText: '',
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
      options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      formData: {
        title: null,
        icon: null,
        route: null
      },
      Dialog: false,
      itemList: [],
      headers: [
        {
          text: 'Module',
          align: 'start',
          sortable: false,
          value: 'Module',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Title',
          align: 'start',
          sortable: false,
          value: 'title',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Icon',
          align: 'start',
          sortable: false,
          value: 'icon',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Route',
          align: 'start',
          sortable: false,
          value: 'route',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', sortable: false, width: '100', class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      modules: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    created () {
      this.initialize();
      this.getModules();
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
         data.append("searchText", this.searchText);
              this.axiosCall(
              '/getSubModules',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.itemList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
      getModules() {
      this.axiosCall("/getModuleList", "GET").then((res) => {
        this.modules = res.data.data;
      });
    },
    editItem(data) {
      this.editItemId = data.id;
      this.formData.ModuleID = data.ModuleID;
      this.formData.title = data.title;
      this.formData.icon = data.icon;
      this.formData.route = data.route;
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate()){
             
             this.isLoading = true;
             let data = new FormData();
              data.append('ModuleID', this.formData.ModuleID);
              data.append('title', this.formData.title);
              data.append('icon', this.formData.icon);
              data.append('route', this.formData.route);
                         this.axiosCall(
                        '/modulesub/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Sub Module was successfully added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
              this.isLoading = true;
              let data = new FormData();
              data.append('id', this.editItemId);
              data.append('ModuleID', this.formData.ModuleID);
              data.append('title', this.formData.title);
              data.append('icon', this.formData.icon);
              data.append('route', this.formData.route);
                         this.axiosCall(
                        '/modulesub/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Sub Module was successfully updated!!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
    }
  }
</script>