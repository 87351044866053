<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">Consolidation Schedules</h2>
      <v-card class="mt-4">
      <ConsolidationSchedule/>
  </v-card>
  </div>
</template>

<script>
import ConsolidationSchedule from '@/components/Utils/ConsolidationSchedule.vue'
  export default {
    components: {
      ConsolidationSchedule
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>