<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">Modules</h2>
      <v-card class="mt-4">
      <Modules/>
  </v-card>
  </div>
</template>

<script>
import Modules from '@/components/Utils/Modules.vue'
  export default {
    components: {
      Modules
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>