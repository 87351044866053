var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DarkWhite"},[_c('div',[_c('v-autocomplete',{attrs:{"items":_vm.mrtypes,"label":"Select MR Type","outlined":"","item-text":"desc","item-value":"id","dense":"","color":"header"},model:{value:(_vm.mrtype),callback:function ($$v) {_vm.mrtype=$$v},expression:"mrtype"}})],1),(_vm.mrtype == 1)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_ics,"items":_vm.icsList,"options":_vm.options1,"server-items-length":_vm.totalCount1,"loading":_vm.loading1,"footer-props":_vm.footerProps1},on:{"update:options":function($event){_vm.options1=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"my-1 pt-1",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-3",staticStyle:{"max-width":"350px"},attrs:{"append-icon":"mdi-magnify","placeholder":"Search ICS number","color":"green darken-2","dense":"","hide-details":""},model:{value:(_vm.searchText1),callback:function ($$v) {_vm.searchText1=$$v},expression:"searchText1"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"header","dark":""},on:{"click":function($event){return _vm.addICS()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-folder-multiple-plus")]),_vm._v(" Add ICS & References ")],1)],1)]},proxy:true},{key:"item.FundSource",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.FundCode)+" - "+_vm._s(item.SpecificFundSourceDescription)+" ")]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.totalAmount)))])]}},{key:"item.Remarks",fn:function(ref){
var item = ref.item;
return [(item.RenewID)?_c('span',[_vm._v("Renewed")]):(item.TransferID)?_c('span',[_vm._v("Transferred")]):_c('span',[_vm._v("First")])]}},{key:"item.DateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateCreated))+" ")]}},{key:"item.DispenseTypeID",fn:function(ref){
var item = ref.item;
return [(item.DispenseTypeID == 1)?_c('span',[_vm._v("Distributed")]):_vm._e(),(item.DispenseTypeID == 2)?_c('span',[_vm._v("Shared")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.viewICS(item)}}},[_vm._v(" View ")]),(item.IsCancelled == 0)?_c('v-btn',{attrs:{"color":"red darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.printICS(item)}}},[_vm._v(" Print ")]):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.mrtype == 2)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_par,"items":_vm.parList,"options":_vm.options2,"server-items-length":_vm.totalCount2,"loading":_vm.loading2,"footer-props":_vm.footerProps2},on:{"update:options":function($event){_vm.options2=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"my-1 pt-1",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-2",staticStyle:{"max-width":"350px"},attrs:{"append-icon":"mdi-magnify","placeholder":"Search PAR number","color":"green darken-2","dense":"","hide-details":""},model:{value:(_vm.searchText2),callback:function ($$v) {_vm.searchText2=$$v},expression:"searchText2"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"header","dark":""},on:{"click":function($event){return _vm.addPAR()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-folder-multiple-plus")]),_vm._v(" Add PAR & References ")],1)],1)]},proxy:true},{key:"item.FundSource",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.FundCode)+" - "+_vm._s(item.SpecificFundSourceDescription)+" ")]}},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.totalAmount)))])]}},{key:"item.Remarks",fn:function(ref){
var item = ref.item;
return [(item.RenewID)?_c('span',[_vm._v("Renewed")]):(item.TransferID)?_c('span',[_vm._v("Transferred")]):_c('span',[_vm._v("First")])]}},{key:"item.DateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.DateCreated))+" ")]}},{key:"item.DispenseTypeID",fn:function(ref){
var item = ref.item;
return [(item.DispenseTypeID == 1)?_c('span',[_vm._v("Distributed")]):_vm._e(),(item.DispenseTypeID == 2)?_c('span',[_vm._v("Shared")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.viewPAR(item)}}},[_vm._v(" View ")]),(item.IsCancelled == 0)?_c('v-btn',{attrs:{"color":"red darken-2","dark":"","outlined":"","elevation":"2","x-small":""},on:{"click":function($event){return _vm.printPAR(item)}}},[_vm._v(" Print ")]):_vm._e()],1)]}}],null,true)}):_vm._e(),_c('CreateRIA',{attrs:{"riaData":_vm.riaData}}),_c('ICSModal',{attrs:{"icsData":_vm.icsData}}),_c('PARModal',{attrs:{"parData":_vm.parData}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }