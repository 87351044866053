<template>
  <div class="DarkWhite pa-4">
    <v-row>
    <v-col cols="12" sm="6" md="8" lg="10">
        <h2 class="subheading green--text">Purchase Order List</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-autocomplete
          v-model="fiscalYear"
          :items="fiscalyears"
          auto-select-first
          item-text="fiscalYear"
          item-value="id"
          color="header"
          dense
          label="Fiscal Year"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="poList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search PO/PR number"
            color="green darken-2"
            class="mb-3 mt-3"
            dense
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
                v-model="BudgetID"
                color="green"
                item-text="BudgetCode"
                item-value="BudgetID"
                style="max-width: 300px"
                :items="budgetCodeList"
                dense
                hide-details
                class="mr-2"
                label="Budget Codes">
            </v-autocomplete>
            <vue-json-to-csv 
          :json-data="itemListForCSV"
          :labels="{ 
            POCode: { title: 'PO Number' },
            PRCODE: { title: 'PR Number' },
            CompanyName: { title: 'Supplier' },
            CompanyAddress: { title: 'Supplier Address' },
            GrandTotal: { title: 'Grand Total' }
            }"
          csv-title="Purchase Orders">
            <v-btn
              outlined
              color="green darken-2"
              class="mr-2"
              dark
              :loading="loadingCSV"
            >
              <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              CSV
            </v-btn>
          </vue-json-to-csv>
        </v-toolbar>
      </template>
      <template v-slot:[`item.GrandTotal`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.GrandTotal) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <span class="text-no-wrap">
              <v-btn
                color="blue darken-2"
                dark
                outlined
                elevation="2"
                x-small
                @click="viewPO(item)"
              >
                View
              </v-btn>
              <v-btn
                color="green darken-2"
                dark
                outlined
                elevation="2"
                class="ml-2"
                x-small
                @click="IAR(item)"
                v-if="$store.state.user.roleID == 6 || $store.state.user.roleID == 15"
              >
                IAR
              </v-btn>
              <v-btn
                color="red darken-2"
                dark
                outlined
                elevation="2"
                class="ml-2"
                x-small
                @click="LCB(item)"
                v-if="$store.state.user.roleID == 5"
              >
                LCB
              </v-btn>
              <v-btn
                color="amber darken-4"
                dark
                outlined
                elevation="2"
                class="ml-2"
                x-small
                @click="waive(item)"
                v-if="$store.state.user.BACaccess == 1"
              >
                Waive
              </v-btn>
        </span>
      </template>
    </v-data-table>
    <poModlal :poData="poItem" />
    <iarModlal :poData="poItem1" />
    <lcbModal :poData="poItem2" />
    <waiveModal :poData="poItem3" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    poModlal: () => import("../../components/PO-Components/PSUViewPOItem.vue"),
    iarModlal: () => import("../../components/IAR/AddIAR.vue"),
    lcbModal: () => import("../../components/PO-Components/AddLCB.vue"),
    waiveModal: () => import("../../components/PO-Components/WaivePO.vue"),
  },
  data: () => ({
    tab: 1,
    searchText: "",
    searchTimeout: null,
    remarks: null,
    item: null,
    dialogPending: false,
    dialogApprove: false,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    poList: [],
    poItem: [],
    poItem1: [],
    poItem2: [],
    poItem3: [],
    headers: [
      {
        text: "PO Number",
        value: "POCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Number",
        value: "PRCODE",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Supplier",
        value: "CompanyName",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Supplier Address",
        value: "CompanyAddress",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Grand Total",
        value: "GrandTotal",
        sortable: false,
        align: 'end',
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
        width: 160,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    fiscalYear: null,
    fiscalyears: [],
    itemListForCSV: [],
    loadingCSV: false,
    budgetCodeList: [
      { BudgetID: 0, BudgetCode: 'All'},
    ],
    BudgetID: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    tab: {
      handler() {
        this.poList = [];
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
        this.initialize()
      },
      deep: true,
    },
    BudgetID: {
      handler() {
        this.initialize()
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closePOItemodal", () => {
      this.initialize();
      this.poItem = {};
    });
    this.eventHub.$on("closeIAR", () => {
      this.initialize();
      this.poItem1 = {};
    });
    this.getFiscalYears();
    this.getAllBudgetCode();
  },
  beforeDestroy() {
    this.eventHub.$off("closePOItemodal");
    this.eventHub.$off("closeIAR");
  },
  methods: {
    viewPO(item) {
      this.poItem = item;
    },
    IAR(item) {
      this.poItem1 = item;
    },
    LCB(item) {
      this.poItem2 = item;
    },
    waive(item) {
      this.poItem3 = item;
    },
    initialize() {
      this.loading = true;
      this.loadingCSV = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("OfficeID", 0);
      data.append("DeliveryUnitID", 0);
      data.append("PurposeID", 0);
      data.append("SpecificFundSourceID", 0);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("BudgetID", this.BudgetID);
      this.axiosCall("/signatories/po", "POST", data).then((res) => {
        this.loading = false;
        this.loadingCSV = false;
        this.poList = res.data.data.result;
        this.totalCount = res.data.data.total;
        this.itemListForCSV = res.data.data.result
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    getAllBudgetCode(){
        this.budgetCodeList = [{ BudgetID: 0, BudgetCode: 'All'}];
        this.axiosCall(
              '/get/allBudgetList',
              'GET'
          )
          .then((res) => {
            this.budgetCodeList = [{ BudgetID: 0, BudgetCode: 'All'},...res.data.data]
          })
      },
    getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
  },
};
</script>