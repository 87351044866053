<template>
  <div class="DarkWhite">
    <v-data-table
      :headers="headers"
      :items="rsList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-tabs v-model="status" color="header">
          <v-tab
            v-for="tab of status_tabs"
            :key="tab.id"
          >
          {{tab.desc}}
          </v-tab>
        </v-tabs>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search RS number"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.DateReturned`]="{ item }">
        {{ formatDate(item.DateReturned) }}
      </template>
      <template v-slot:[`item.Other_Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn
          color="header darken-2"
          dark
          outlined
          elevation="2"
          x-small
          class="mr-2"
          @click="approveRS(item)"
          v-if="status == 0 || status == 1"
        >
          Approve
        </v-btn>
        <v-btn
          color="red darken-2"
          dark
          outlined
          elevation="2"
          x-small
          @click="pendingRS(item)"
          v-if="status == 0"
        >
          Pending
        </v-btn>
        </span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewRS(item)"
          >
            View
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printRS(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="approveDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to approve this Return Slip?
            <br /><br />
            <div>RS #: <strong>{{approveData.RSCODE}}</strong></div>
            <div>Returned By: <strong>{{approveData.ReturnBy}}</strong></div>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="approveDialog = false">Cancel</v-btn>
            <v-btn color="header darken-2 white--text" @click="confirmApproveRS()"><v-icon class="mr-1">mdi-check-circle</v-icon>Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="pendingDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to pending this Return Slip?
            <br /><br />
            <div>RS #: <strong>{{pendingData.RSCODE}}</strong></div>
            <div>Returned By: <strong>{{pendingData.ReturnBy}}</strong></div>
            <br />
            <v-form ref="formRef">
            <v-textarea
              color="green"
                dense
                v-model="formData.Remarks"
                :rules="[formRules.required]"
                label="Remarks"
                counter
                outlined
                maxlength="500"
                ></v-textarea>
            </v-form>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="pendingDialog = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmPendingRS()"><v-icon class="mr-1">mdi-clock-outline</v-icon>Pending</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <RSModal :rsData="rsData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    RSModal: () => import("./ViewRS.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    rsList: [],
    rsData: [],
    headers: [
      {
          text: 'RS CODE',
          align: 'start',
          sortable: false,
          value: 'RSCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'ICS/PAR CODE',
          align: 'start',
          sortable: false,
          value: 'ICSPARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Returned By',
          align: 'start',
          sortable: false,
          value: 'ReturnBy',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Returned',
          align: 'start',
          sortable: false,
          value: 'DateReturned',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'Other_Actions',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      status_tabs: [
        {id: 1, desc: 'For Approval'},
        {id: 3, desc: 'Pending'},
        {id: 2, desc: 'Approved'},
        {id: 4, desc: 'Cancelled'},
      ],
      status: 0,
      approveDialog: false,
      pendingDialog: false,
      dialogRISType: false,
      approveData: [],
      pendingData: [],
      formData: {
        Remarks: '',
      }
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    status: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeViewRS", () => {
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewRS");
  },
  methods: {
    viewRS(item) {
      this.rsData = {...item, status_prop: this.status};
    },
    printRS(item) {
      this.link = this.apiUrl + "/rs/report/" + item.RSID;
      window.open(this.link);
    },
    initialize() {
      this.loading = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("status", this.status + 1);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      this.axiosCall("/getRSListPerStatus", "POST", data).then((res) => {
        this.loading = false;
        this.rsList = res.data.data.result;
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    approveRS(data){
      this.approveData = data;
      this.approveDialog = true;
    },
    pendingRS(data){
      this.pendingData = data;
      this.pendingDialog = true;
    },
    confirmApproveRS(){
      let data = new FormData();
      data.append("RSID", this.approveData.RSID);
      this.axiosCall("/rs/approve", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RS was successfully approved.";
            this.fadeAwayMessage.header = "System Message";
            this.approveDialog = false;
            this.approveData = [];
            this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    confirmPendingRS(){
      if(this.$refs.formRef.validate()){
      let data = new FormData();
      data.append("RSID", this.pendingData.RSID);
      data.append("Remarks", this.formData.Remarks);
      this.axiosCall("/rs/pending", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RS was successfully in pending status.";
            this.fadeAwayMessage.header = "System Message";
            this.pendingDialog = false;
             this.pendingData = [];
             this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
  },
};
</script>