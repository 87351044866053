<template>
<div>
  <v-card
      class="mb-5"
    >
        <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
             v-model="OfficeID"
            item-text="OfficeDescription"
            item-value="OfficeID"
            :items="officeList"
            hide-details
            color="header"
            label="Filter by Office"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
            v-model="DeliveryUnitID"
            item-text="DeliveryUnitDescription"
            item-value="DeliveryUnitID"
            :items="deliveryUnit"
            hide-details
            color="header"
            label="Filter by Delivery Unit"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
            v-model="PurposeID"
            item-text="PurposeDescription"
            item-value="PurposeID"
            :items="purpose"
            hide-details
            color="header"
            label="Filter by Program/Activity/Project"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-autocomplete
            v-model="SpecificFundSourceID"
            item-text="SpecificFundSourceDescription"
            item-value="SpecificFundSourceID"
            :items="specificFundSource"
            hide-details
            color="header"
            label="Filter by Fund Source"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-menu
              dense
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="DateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  dense
                  v-model="DateRange"
                  label="Date Range"
                  color="header"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-5"
                  hide-details
                  clearable
                >
                </v-combobox>
              </template>
              <v-date-picker
                v-model="DateRange"
                no-title
                scrollable
                range
                color="header"
                :max="new Date().toISOString().substr(0, 10)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="header" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  class="white--text"
                  color="header"
                  @click="$refs.menu2.save(DateRange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        </v-card-text>
    </v-card>
  <v-card>
  <v-data-table
    :headers="headers"
    :items="budgetList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        class="mx-1 mt-2"
      >
        <v-text-field
        v-model="searchText"
        append-icon="mdi-magnify"
        label="Search"
        color="green darken-2"
        single-line
        hide-details
      ></v-text-field>
        <v-spacer></v-spacer>
        <vue-json-to-csv 
          :json-data="itemListForCSV"
          :labels="{ 
            BudgetCode: { title: 'Budget Code' },
            FundCode: { title: 'Fund Code' },
            OfficeDescription: { title: 'Office' },
            DeliveryUnitDescription: { title: 'Delivery Unit' },
            Amount: { title: 'Amount' },
            DateStart: { title: 'Budget Date Start' },
            DateEnd: { title: 'Budget Date End' },
            DateCreated: { title: 'Budget Date Created' },
            }"
          csv-title="List of Budgets">
            <v-btn
              outlined
              color="green darken-2"
              dark
              :loading="loadingCSV"
            >
              <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              CSV
            </v-btn>
          </vue-json-to-csv>
            <v-btn
              color="green darken-2"
              class="ml-2"
              dark
              @click="addBudgetDialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Add New Budget
            </v-btn>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Amount`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.Amount || 0) }}</span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
        <v-btn color="red darken-2" class="white--text mr-1" outlined x-small @click="transferItem(item)" v-if="checkForTransfer(item.BudgetCode) && item.ParentBudgetID == null">
          transfer
          </v-btn>
          <v-btn color="blue darken-2" class="white--text" outlined x-small @click="editItem(item)">
          edit
          </v-btn>
        </span>
      </template>
  </v-data-table>
  </v-card>
        <v-dialog
          v-model="addBudgetDialog"
          persistent
          max-width="550px"
          scrollable
        >
          <v-card>
            <v-card-title>
              <span v-if="transferItemId">TRANSFER BUDGET</span>
              <span v-else-if="editItemId">UPDATE BUDGET</span>
              <span v-else>ADD BUDGET</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
            <v-card-text style="max-height: 900px;">
              <v-form @submit.prevent ref="formRef">
              <v-container>
              <v-row v-if="transferItemId">
                <v-col cols="12">
                  <v-card
                    class="pa-3"
                    color="grey lighten-3"
                    flat
                  >
                  <v-card-text class="pa-0 ma-0">
                  <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <strong class="text-no-wrap">Budget Code: </strong>
                  </v-col>
                  <v-col cols="12" sm="8">
                    {{formData.BudgetCode}}
                  </v-col>
                  </v-row>
                  <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <strong class="text-no-wrap">Fund Source: </strong>
                  </v-col>
                  <v-col cols="12" sm="8">
                    {{formData.FundCode + '-' + formData.SpecificFundSourceDescription}}
                  </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong class="text-no-wrap">Current Budget: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      ₱ {{formatPrice(formData.Amount)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong class="text-no-wrap">Current Assignee: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{formData.Assignee}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Office: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{formData.OfficeDescription}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Allotment Type: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{formData.AllotmentTypeDescription}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Delivery Unit: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{formData.DeliveryUnitDescription}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Budget Date: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{formatDate(formData.BudgetDate) }} - {{formatDate(formData.BudgetDateEnd) }}
                    </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >

          <v-autocomplete
            v-model="formData.UserID_new"
            item-text="name"
            item-value="UserID"
              :rules="[formRules.required]"
            :items="users"
            dense
            class="mb-4"
            color="header"
            label="New Assignee">
        </v-autocomplete>

          <v-autocomplete
          v-model="formData.OfficeID_new"
          item-text="OfficeDescription"
          item-value="OfficeID"
            :rules="[formRules.required]"
          :items="office"
          dense
          class="mb-4"
          color="header"
          label="Office">
        </v-autocomplete>

          <!-- <v-autocomplete
                    v-model="formData.DeliveryUnitID"
                    item-text="DeliveryUnitDescription"
                    item-value="DeliveryUnitID"
                      :rules="[formRules.required]"
                    :items="deliveryunit"
                    dense
                    class="mb-4"
                    color="header"
                    label="Delivery Unit">
        </v-autocomplete> -->

          <v-autocomplete
            v-model="formData.PurposeID_new"
            item-text="PurposeDescription"
            item-value="PurposeID"
              :rules="[formRules.required]"
            :items="purposeList"
            dense
            class="mb-4"
            color="header"
            label="Purpose">
        </v-autocomplete>

        <v-text-field
          dense
          v-model="formData.Amount_new"
          :rules="[formRules.required, formRules.noNegativeNumberAllowed, formRules.numberRequired]"
          :error-messages="amountErr2"
          @input="checkSavings(savings, formData.Amount_new)"
          type = "number"
          color="header"
          class="mb-4"
            label="Amount">
          </v-text-field>

          </v-col>
              </v-row>
              <v-row v-else>
              <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
            <v-text-field
            dense
              v-model="formData.BudgetCode"
              :rules="[formRules.required]"
              label="Budget Code"
              color="header"
              class="mb-4"
            ></v-text-field>

            <v-autocomplete
                      v-model="formData.FundID"
                      item-text="FundCode"
                      item-value="FundID"
                        :rules="[formRules.required]"
                      :items="fundList"
                      @change="checkAmount()"
                      dense
                      class="mb-4"
                      color="header"
                      label="Fund Code">
          </v-autocomplete>

          <v-autocomplete
                      v-model="formData.SpecificFundSourceID"
                      item-text="SpecificFundSourceDescription"
                      item-value="SpecificFundSourceID"
                        :rules="[formRules.required]"
                      :items="specificfundsourceList"
                      dense
                      class="mb-4"
                      color="header"
                      label="Specific Fund Source">
          </v-autocomplete>

          <v-autocomplete
                    v-model="formData.AllotmentTypeID"
                    item-text="AllotmentTypeDescription"
                    item-value="AllotmentTypeID"
                      :rules="[formRules.required]"
                    :items="allotmentList"
                    dense
                    class="mb-4"
                    color="header"
                    label="Allotment Type">
        </v-autocomplete>

        <v-autocomplete
                  v-model="formData.UserID"
                  item-text="name"
                  item-value="UserID"
                    :rules="[formRules.required]"
                  :items="users"
                  dense
                  class="mb-4"
                  color="header"
                  label="Assignee">
      </v-autocomplete>

        <v-autocomplete
                  v-model="formData.OfficeID"
                  item-text="OfficeDescription"
                  item-value="OfficeID"
                    :rules="[formRules.required]"
                  :items="office"
                  dense
                  class="mb-4"
                  color="header"
                  label="Office">
      </v-autocomplete>

        <v-autocomplete
                  v-model="formData.DeliveryUnitID"
                  item-text="DeliveryUnitDescription"
                  item-value="DeliveryUnitID"
                    :rules="[formRules.required]"
                  :items="deliveryunit"
                  dense
                  class="mb-4"
                  color="header"
                  label="Delivery Unit">
      </v-autocomplete>

        <v-autocomplete
          v-model="formData.PurposeID"
          item-text="PurposeDescription"
          item-value="PurposeID"
            :rules="[formRules.required]"
          :items="purposeList"
          dense
          class="mb-4"
          color="header"
          label="Purpose">
      </v-autocomplete>
      <v-menu
        dense
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="formData.BudgetDate"
        transition="scale-transition"
        offset-y
        class="mb-4"
        min-width="auto"
      >
        <template  v-slot:activator="{ on, attrs }">
          <v-combobox
           v-bind="attrs"
            v-on="on"
           dense
            v-model="formData.BudgetDate"
            :rules="[formRules.required, formRules.dateNotEqual(formData.BudgetDate, formData.BudgetDateEnd)]"
            label="Budget Date Start"
            color="header"
            readonly
          ></v-combobox>
        </template>
        <v-date-picker
          v-model="formData.BudgetDate"
          :max="formData.BudgetDateEnd"
          no-title
          color="header"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="grey darken-2"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="header"
            @click="$refs.menu.save(formData.BudgetDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-menu
         dense
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="formData.BudgetDateEnd"
        transition="scale-transition"
        offset-y
        class="mb-4"
        min-width="auto"
      >
        <template  v-slot:activator="{ on, attrs }">
          <v-combobox
           dense
            v-model="formData.BudgetDateEnd"
             :rules="[formRules.required, formRules.dateNotEqual(formData.BudgetDate, formData.BudgetDateEnd)]"
            label="Budget Date End"
            color="header"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-combobox>
        </template>
        <v-date-picker
          v-model="formData.BudgetDateEnd"
            :min="formData.BudgetDate"
          no-title
          scrollable
           color="header"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="grey darken-2"
            @click="menu1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="header"
            @click="$refs.menu1.save(formData.BudgetDateEnd)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-text-field
                     dense
                     v-model="formData.Amount"
                     :rules="[formRules.required, formRules.noNegativeNumberAllowed, formRules.numberRequired]"
                     :error-messages="amountErr"
                     @input="checkAmount()"
                     type = "number"
                     class="mb-4"
                     color="header"
                      label="Amount">
        </v-text-field>

        </v-col>
              </v-row>
              </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <span v-if="transferItemId">Savings: <strong>₱ {{ formatPrice(savings) }}</strong></span>
              <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                outlined
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId && !transferItemId"
                color="green darken-2"
                class="white--text"
                @click="save"
                :loading="isLoading"
              >
              <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
                Submit
              </v-btn>
              <v-btn
              v-if="transferItemId"
                color="red darken-2"
                class="white--text"
                @click="transfer"
                :loading="isLoading"
              >
              <v-icon class="mr-1">mdi-transfer</v-icon>
                Transfer
              </v-btn>
              <v-btn
              v-if="editItemId"
                color="blue darken-2"
                class="white--text"
                @click="update"
                :loading="isLoading"
              >
              <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    props: {
      fiscalYear: null,
    },
    data: () => ({
      searchText: "",
      searchTimeout : null,
      amountErr: null,
      amountErr2: null,
      editItemId: null,
      transferItemId: null,
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu: false,
      menu1: false,
      formData: {
         FundID: null,
         BudgetDate: null,
         BudgetDateEnd: null,
         PurposeID: null,
         SpecificFundSourceID: null,
         Amount: null,
         OfficeID: null,
         BudgetCode: null,
         AllotmentTypeID: null,
         DeliveryUnit: null,
         UserID: null
      },
      addBudgetDialog: false,
      budgetList: [],
      fundList: [],
      office: [],
      deliveryunit: [],
      specificfundsourceList: [],
      purposeList: [],
      allotmentList: [],
      users: [],
      headers: [
        {
          text: 'Budget Code',
          align: 'start',
          sortable: false,
          value: 'BudgetCode',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'Fund', value: 'FundCode', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Office', value: 'OfficeDescription', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Delivery Unit', value: 'DeliveryUnitDescription', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Amount', value: 'Amount', align: "end", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Budget Date Start', value: 'DateStart', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Budget Date End', value: 'DateEnd', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" }
      ],
      itemListForCSV: [],
      loadingCSV: true,
      OfficeID: 0,
      DeliveryUnitID: 0,
      PurposeID: 0,
      SpecificFundSourceID: 0,
      officeList : [],
      deliveryUnit: [],
      purpose: [],
      specificFundSource: [],
      DateRange: null,
      menu2: false,
      budgetsForRevision: [],
      savings: 0,
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText : function(){
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
			this.initialize();
			}, 600);
		},
    fiscalYear: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    OfficeID: function () {
        this.initialize();
    },
    DeliveryUnitID: function () {
        this.initialize();
    },
    PurposeID: function () {
        this.initialize();
    },
    SpecificFundSourceID: function () {
        this.initialize();
    },
    DateRange: function () {
        this.initialize();
    },
    },
    created () {
      // this.initialize();
      this.getBudgetTypeAndOffice();
      this.getOffice();
      this.getDeliveryUnit();
      this.getPurpose();
      this.getSpecificFundSource();
      this.getBudgetsForRevision();
    },
    methods: {
      checkAmount() {
        if(this.formData.FundID && this.formData.Amount){
          this.isLoading = true;
          let data = new FormData();
          data.append('fundID', this.formData.FundID);
          data.append('Amount', this.formData.Amount);
          this.axiosCall(
              '/checkFund',
              'POST',
              data
          )
          .then((res) => {
            let that = this;
          setTimeout(function(){  that.isLoading = false}, 500);
            if(res.data.data === true){
              this.amountErr = "Insufficient Funds";
            }else{
               this.amountErr = null;
            }
          })
        }else{
          this.amountErr = null;
        }
      },
      cancel() {
        this.editItemId = null;
        this.transferItemId = null;
        this.$refs.formRef.reset();
        this.addBudgetDialog = false;
      },
      initialize () {
        this.loading = true;
        this.loadingCSV = true;
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
          data.append('searchText', this.searchText);
          data.append('FiscalYearID', this.fiscalYear);
          data.append("OfficeID", this.OfficeID);
          data.append("DeliveryUnitID", this.DeliveryUnitID);
          data.append("PurposeID", this.PurposeID);
          data.append("SpecificFundSourceID", this.SpecificFundSourceID);
          data.append("DateRange", this.DateRange && this.DateRange.length > 1 ? this.DateRange : '');
              this.axiosCall(
              '/getAllBudget',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false;
            this.loadingCSV = false;
              this.budgetList = res.data.data.result;
              this.itemListForCSV = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
              if(this.totalCount <= 10){
                this.options.page = 1;
              }

          })
      },
      getBudgetTypeAndOffice() {
      this.axiosCall(
              '/getOfficeAndOthersForCreateUpdate',
              'GET'
          )
          .then((res) => {
              this.office = res.data.data.office;
              this.fundList = res.data.data.fund;
              this.specificfundsourceList = res.data.data.specificfundsource;
              this.purposeList = res.data.data.purpose;
              this.allotmentList = res.data.data.allotmenttype;
              this.deliveryunit = res.data.data.deliveryunit;
              this.users = res.data.data.users;
          })
		},
    editItem(data) {
      this.transferItemId = null;
      this.editItemId = data.BudgetID;
      this.formData.FundID = data.FundID;
      this.formData.BudgetDate = data.BudgetDate;
      this.formData.BudgetDateEnd = data.BudgetDateEnd;
      this.formData.PurposeID = data.PurposeID;
      this.formData.SpecificFundSourceID = data.SpecificFundSourceID;
      this.formData.Amount = data.Amount;
      this.formData.OfficeID = data.OfficeID;
      this.formData.BudgetCode = data.BudgetCode;
      this.formData.AllotmentTypeID = data.AllotmentTypeID;
      this.formData.DeliveryUnitID = data.DeliveryUnitID;
      this.formData.UserID = data.UserID;
      this.addBudgetDialog = true;
		},
    transferItem(data) {
      this.editItemId = null;
      this.transferItemId = data.BudgetID;
      this.formData.FundID = data.FundID;
      this.formData.SpecificFundSourceID = data.SpecificFundSourceID;
      this.formData.BudgetDate = data.BudgetDate;
      this.formData.BudgetDateEnd = data.BudgetDateEnd;
      this.formData.PurposeID = data.PurposeID;
      this.formData.Amount = data.Amount;
      this.formData.OfficeID = data.OfficeID;
      this.formData.BudgetCode = data.BudgetCode;
      this.formData.AllotmentTypeID = data.AllotmentTypeID;
      this.formData.DeliveryUnitID = data.DeliveryUnitID;
      this.formData.UserID = data.UserID;
      this.formData.FundCode = data.FundCode;
      this.formData.SpecificFundSourceDescription = data.SpecificFundSourceDescription;
      this.formData.OfficeDescription = data.OfficeDescription;
      this.formData.AllotmentTypeDescription = data.AllotmentTypeDescription;
      this.formData.DeliveryUnitDescription = data.DeliveryUnitDescription;
      this.formData.Assignee = this.users.find((user) => user.UserID == data.UserID).name;
      this.formData.UserID_new = null;
      this.formData.OfficeID_new = null;
      this.formData.PurposeID_new = null;
      this.formData.Amount_new = null;
      this.getSavings();
      this.addBudgetDialog = true;
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
                     this.isLoading = true;
             let data = new FormData();
              data.append('FundID', this.formData.FundID);
              data.append('BudgetDate', this.formData.BudgetDate);
              data.append('BudgetDateEnd', this.formData.BudgetDateEnd);
              data.append('PurposeID', this.formData.PurposeID);
              data.append('SpecificFundSourceID', this.formData.SpecificFundSourceID);
              data.append('Amount', this.formData.Amount);
              data.append('OfficeID', this.formData.OfficeID);
              data.append('BudgetCode', this.formData.BudgetCode);
              data.append('AllotmentTypeID', this.formData.AllotmentTypeID);
              data.append('DeliveryUnitID', this.formData.DeliveryUnitID);
              data.append('UserID', this.formData.UserID);
                         this.axiosCall(
                        '/budget/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Budget Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addBudgetDialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
                     this.isLoading = true;
             let data = new FormData();
              data.append('BudgetID', this.editItemId);
              data.append('FundID', this.formData.FundID);
              data.append('BudgetDate', this.formData.BudgetDate);
              data.append('BudgetDateEnd', this.formData.BudgetDateEnd);
              data.append('PurposeID', this.formData.PurposeID);
              data.append('SpecificFundSourceID', this.formData.SpecificFundSourceID);
              data.append('Amount', this.formData.Amount);
              data.append('OfficeID', this.formData.OfficeID);
              data.append('BudgetCode', this.formData.BudgetCode);
              data.append('AllotmentTypeID', this.formData.AllotmentTypeID);
              data.append('DeliveryUnitID', this.formData.DeliveryUnitID);
              data.append('UserID', this.formData.UserID);
                         this.axiosCall(
                        '/budget/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Budget Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addBudgetDialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      transfer () {
         if(this.$refs.formRef.validate() && !this.amountErr2){
              this.isLoading = true;
             let data = new FormData();
              data.append('FundID', this.formData.FundID);
              data.append('BudgetDate', this.formData.BudgetDate);
              data.append('BudgetDateEnd', this.formData.BudgetDateEnd);
              data.append('PurposeID', this.formData.PurposeID_new);
              data.append('SpecificFundSourceID', this.formData.SpecificFundSourceID);
              data.append('Amount', this.formData.Amount_new);
              data.append('OfficeID', this.formData.OfficeID_new);
              data.append('BudgetCode', this.formData.BudgetCode + '-T');
              data.append('AllotmentTypeID', this.formData.AllotmentTypeID);
              data.append('DeliveryUnitID', this.formData.DeliveryUnitID);
              data.append('UserID', this.formData.UserID_new);
              data.append('ParentBudgetID', this.transferItemId);
                         this.axiosCall(
                        '/budget/transfer',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Budget Successfully Transferred!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addBudgetDialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      getOffice() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllOffice", "POST", data).then((res) => {
        this.officeList = res.data.data.result;
        this.officeList.push({ OfficeID: 0, OfficeDescription: "All" });
        this.officeList.sort(function (a, b) {
          return a.OfficeID - b.OfficeID;
        });
      });
    },
    getDeliveryUnit() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllDeliveryUnit", "POST", data).then((res) => {
        this.deliveryUnit = res.data.data.result;
        this.deliveryUnit.push({ DeliveryUnitID: 0, DeliveryUnitDescription: "All" });
        this.deliveryUnit.sort(function (a, b) {
          return a.DeliveryUnitID - b.DeliveryUnitID;
        });
      });
    },
    getPurpose() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllPurpose", "POST", data).then((res) => {
        this.purpose = res.data.data.result;
        this.purpose.push({ PurposeID: 0, PurposeDescription: "All" });
        this.purpose.sort(function (a, b) {
          return a.PurposeID - b.PurposeID;
        });
      });
    },
    getSpecificFundSource() {
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 1000000);
      this.axiosCall("/getAllAllFundsource", "POST", data).then((res) => {
        this.specificFundSource = res.data.data.result;
        this.specificFundSource.push({ SpecificFundSourceID: 0, SpecificFundSourceDescription: "All" });
        this.specificFundSource.sort(function (a, b) {
          return a.SpecificFundSourceID - b.SpecificFundSourceID;
        });
      });
    },
    getBudgetsForRevision() {
      this.axiosCall("/getBudgetsForRevision", "GET").then((res) => {
        res.data.data.result.forEach(item => {
          this.budgetsForRevision.push(...JSON.parse(item.BudgetCodes))
        });
      });
    },
    checkForTransfer(BudgetCode){
      return this.budgetsForRevision.includes(BudgetCode);
    },
    getSavings() {
        if(this.transferItemId){
          let data = new FormData();
          data.append('BudgetID', this.transferItemId);
          data.append('Amount', this.formData.Amount_new);
          this.axiosCall(
              '/getSavings',
              'POST',
              data
          )
          .then((res) => {
            this.savings = res.data.data
            
          })
        }
      },
      checkSavings(savings, amount) {
        if(savings >= amount){
          this.amountErr2 = null;
        }
        else{
          this.amountErr2 = 'Insuficient Savings!';
        }
      },
    }
  }
</script>