<template>
  <div class="DarkWhite pa-4">
    <v-row>
    <v-col cols="12" sm="6" md="8" lg="10">
        <h2 class="subheading green--text">Budget Monitoring</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-autocomplete
          v-model="fiscalYear"
          :items="fiscalyears"
          auto-select-first
          item-text="fiscalYear"
          item-value="id"
          color="header"
          dense
          label="Fiscal Year"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div>
      <v-card
      :loading="filterLoading"
      class="mb-5"
    >
    <template slot="progress">
        <v-progress-linear color="header" indeterminate></v-progress-linear>
    </template>
        <v-card-text>
        <v-row >
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
            v-model="FundID"
            color="green"
            item-text="FundCode"
            item-value="FundID"
            :items="funds"
            :disabled="tab != 0"
            hide-details
            label="Fund Code">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              dense
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="DateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="DateRange"
                  label="Date Range (Utilization)"
                  color="header"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  clearable
                >
                </v-combobox>
              </template>
              <v-date-picker
                v-model="DateRange"
                no-title
                scrollable
                range
                color="header"
              >
                <v-spacer></v-spacer>
                <v-btn text color="header" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn
                  class="white--text"
                  color="header"
                  @click="$refs.menu1.save(DateRange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="3">
            <v-menu
              dense
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="DateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  v-model="DateRangeText2"
                  label="Date Range (Non-Proc)"
                  color="header"
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                  clearable
                >
                </v-combobox>
              </template>
              <v-date-picker
                v-model="DateRange2"
                no-title
                scrollable
                range
                color="header"
              >
                <v-spacer></v-spacer>
                <v-btn text color="header" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn
                  class="white--text"
                  color="header"
                  @click="$refs.menu2.save(DateRange2)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col> -->
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
            v-model="UserID"
            item-text="name"
            item-value="UserID"
            :items="users"
            hide-details
            color="header"
            disabled
            label="Filter by User"
          >
          </v-autocomplete>
          </v-col>
        </v-row>
        </v-card-text>
    </v-card>
      <v-data-table
        :headers="headers"
        :items="utilizationData"
        :options.sync="options"
        :server-items-length="totalCount"
        :loading="loading"
        :footer-props="footerProps"
        class="elevation-1"
      >
        <template v-slot:top>
                <v-tabs
                  v-model="tab"
                  color="header"
                  v-if="$store.state.user.roleID != 2"
                  dense
                >
                  <v-tabs-slider color="header"></v-tabs-slider>

                  <v-tab
                    v-for="item in tab_items"
                    :key="item"
                    color="header"
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>
            <v-row class="mx-1 align-center">
            <v-col cols="12" md="5">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              :placeholder="tab == 1 ? 'Search for Fund Code': 'Search for Budget Code'"
              color="green darken-2"
              class="mb-md-4"
              hide-details
            ></v-text-field>
            </v-col>
            <v-col cols="12" md="7" class="flex-items justify-md-end mb-4 mb-lg-0">
            <v-btn
              outlined
              color="red darken-2"
              dark
              @click="print()"
              class="mr-2"
            >
              <v-icon>print</v-icon>
              Print
            </v-btn>
            <vue-json-to-csv 
                    :json-data="utilizationData"
                    :labels="csvLabel"
                    csv-title="BudgetUtilization">
                <v-btn
                  outlined
                  color="green darken-2"
                  dark
                >
                  <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                  CSV
                </v-btn>
              </vue-json-to-csv>
            </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.AllocatedFund`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.AllocatedFund || 0) }}</span>
        </template>
        <template v-slot:[`item.AllocatedBudget`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.AllocatedBudget || 0) }}</span>
        </template>
        <template v-slot:[`item.PRs`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.PRs || 0) }}</span>
        </template>
        <template v-slot:[`item.POs`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.POs || 0) }}</span>
        </template>
        <template v-slot:[`item.IARs`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.IARs || 0) }}</span>
        </template>
        <template v-slot:[`item.NonProc`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.NonProc || 0) }}</span>
        </template>
        <template v-slot:[`item.UnrequestedBudget`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.UnrequestedBudget || 0) }}</span>
        </template>
        <template v-slot:[`item.UnutilizedBudget`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.UnutilizedBudget || 0) }}</span>
        </template> 
        <template v-slot:[`item.ObligationBUR`]="{ item }">
          <span class="text-no-wrap">{{ formatPrice(item.ObligationBUR || 0) }}</span>
        </template>
        <template v-slot:[`item.DisbursementBUR`]="{ item }">
          <span class="text-no-wrap">{{ formatPrice(item.DisbursementBUR || 0) }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    labels_budget: [],
    value_budget: [],
    labels_ppmp: [],
    value_ppmp: [],
    total_budget_count: 0,
    total_ppmp_count: 0,
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    footerProps: { "items-per-page-options": [10, 20, 50, 100, 500, -1] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    addPPMPDialog: false,
    utilizationData: [],
    headers_specific: [
      {
        text: "Budget Code",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Allocated Budget",
        align: "end",
        sortable: false,
        value: "AllocatedBudget",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PRs",
        align: "end",
        sortable: false,
        value: "PRs",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "POs",
        align: "end",
        sortable: false,
        value: "POs",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "IARs",
        align: "end",
        sortable: false,
        value: "IARs",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Non-Proc Obligation",
        align: "end",
        sortable: false,
        value: "NonProc",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Unrequested Budget",
        value: "UnrequestedBudget",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Unutilized Budget",
        value: "UnutilizedBudget",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Obligation BUR",
        value: "ObligationBUR",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Disbursement BUR",
        align: "end",
        sortable: false,
        value: "DisbursementBUR",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    headers_overall: [
      {
        text: "Fund Code",
        align: "start",
        sortable: false,
        value: "FundCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Allocated Fund",
        align: "end",
        sortable: false,
        value: "AllocatedFund",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Allocated Budget",
        align: "end",
        sortable: false,
        value: "AllocatedBudget",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PRs",
        align: "end",
        sortable: false,
        value: "PRs",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "POs",
        align: "end",
        sortable: false,
        value: "POs",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "IARs",
        align: "end",
        sortable: false,
        value: "IARs",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Non-Proc Obligation",
        align: "end",
        sortable: false,
        value: "NonProc",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Unrequested Budget",
        value: "UnrequestedBudget",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Unutilized Budget",
        value: "UnutilizedBudget",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Obligation BUR",
        value: "ObligationBUR",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Disbursement BUR",
        align: "end",
        sortable: false,
        value: "DisbursementBUR",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    headers: [],
    tab_items: ['Specifics','Overall'],
    tab: 0,
    funds: [{FundID: 0, FundCode: 'ALL'}],
    FundID: 0,
    DateRange: null,
    DateRange2: [],
    menu1: false,
    menu2: false,
    users: [],
    UserID: 0,
    csvLabel_specifics: {
        BudgetCode: { title: 'Budget Code' },
        AllocatedBudget: { title: 'Allocated Budget' },
        PRs: { title: 'PRs' },
        POs: { title: 'POs' },
        IARs: { title: 'IARs' },
        NonProc: { title: 'Non-Proc Obligation' },
        UnrequestedBudget: { title: 'Unrequested Budget' },
        UnutilizedAmount: { title: 'Unutilized Amount' },
        ObligationBUR: { title: 'Obligation BUR' },
        DisbursementBUR: { title: 'Disbursement BUR' },
        },
    csvLabel_overall: { 
        FundCode: { title: 'Fund Code' },
        AllocatedFund: { title: 'Allocated Fund' },
        AllocatedBudget: { title: 'Allocated Budget' },
        PRs: { title: 'PRs' },
        POs: { title: 'POs' },
        IARs: { title: 'IARs' },
        NonProc: { title: 'Non-Proc Obligation' },
        UnrequestedBudget: { title: 'Unrequested Budget' },
        UnutilizedBudget: { title: 'Unutilized Budget' },
        ObligationBUR: { title: 'Obligation BUR' },
        DisbursementBUR: { title: 'Disbursement BUR' },
        },
    csvLabel: {
        BudgetCode: { title: 'Budget Code' },
        AllocatedBudget: { title: 'Allocated Budget' },
        PRs: { title: 'PRs' },
        POs: { title: 'POs' },
        IARs: { title: 'IARs' },
        NonProc: { title: 'Non-Proc Obligation' },
        UnrequestedBudget: { title: 'Unrequested Budget' },
        UnutilizedBudget: { title: 'Unutilized Budget' },
        ObligationBUR: { title: 'Obligation BUR' },
        DisbursementBUR: { title: 'Disbursement BUR' },
        },
    fiscalYear: null,
    fiscalyears: [],
    filterLoading: false,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    tab: {
      handler(data) {
        if(data == 1){
          this.headers = this.headers_overall;
          this.csvLabel = this.csvLabel_overall;
        }
        else{
          this.headers = this.headers_specific;
          this.csvLabel = this.csvLabel_specifics;
        }
        this.searchText = '';
        this.initialize();
      },
      deep: true,
    },
    FundID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
        this.initialize();
      },
      deep: true,
    },
    DateRange: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  computed: {
      DateRangeText () {
        this.initialize();
        return this.DateRange.join(' ~ ')
      },
      DateRangeText2 () {
        return this.DateRange2.join(' ~ ')
      },
    },
  created(){
    this.getFundList();
    this.headers = this.headers_specific;
  },
  mounted() {
    this.eventHub.$on("closePPMPModal", () => {
      this.ppmpItem = {};
      this.initialize();
    });
    this.getFiscalYears()
  },
  beforeDestroy() {
    this.eventHub.$off("closePPMPModal");
  },
  methods: {
    viewPPMP(item) {
      this.ppmpItem = item;
    },
    initialize() {
      this.loading = true;
      this.utilizationData = [];
      if(this.fiscalYear != null){
        this.getFundUtilization();
      }
    },
    getFundUtilization() {
      let data = new FormData();
      let url = '';
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      if(this.tab == 1){
        url = '/dashboard/fundUtilization';
      }
      else{
        data.append("fundID", this.FundID);
        url = '/dashboard/budgetUtilization';
      }
      this.axiosCall(url, "POST", data).then(
        (res) => {
          this.loading = false;
          this.utilizationData = res.data.data.result;
          this.totalCount = res.data.data.total;
          if(this.DateRange && this.DateRange.length == 2){
            const startDate = new Date(this.DateRange[0]);
            const endDate = new Date(this.DateRange[1]);
            endDate.setDate(endDate.getDate() + 1);
            this.utilizationData.forEach(item => {
              let total_du = 0;
              if(item.DateUtilized != null){
              let data_du = JSON.parse(item.DateUtilized)
              data_du.forEach(item2 => {
                let date = new Date(item2.date);
                if(date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()){
                  total_du = total_du + parseFloat(item2.amount)
                }
              });
              }
              let total_di = 0;
              if(item.DateInspected != null){
                let data_di = JSON.parse(item.DateInspected)
              data_di.forEach(item2 => {
                let date = new Date(item2.date);
                if(date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()){
                  total_di = total_di + parseFloat(item2.amount)
                }
              });
              }
              let total_do = 0;
              if(item.DateObligated != null){
                let data_do = JSON.parse(item.DateObligated)
              data_do.forEach(item2 => {
                let date = new Date(item2.date);
                if(date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime()){
                  total_do = total_do + parseFloat(item2.amount)
                }
              });
              }
              item.POs = total_du;
              item.IARs = total_di;
              item.NonProc = total_do;
              item.UnrequestedBudget = parseFloat(item.AllocatedBudget) - (parseFloat(item.PRs) + parseFloat(item.NonProc))
              item.UnutilizedBudget = parseFloat(item.AllocatedBudget) - (parseFloat(item.POs) + parseFloat(item.NonProc))
              item.ObligationBUR = (parseFloat(item.POs) + parseFloat(item.NonProc)) / parseFloat(item.AllocatedBudget)
              item.DisbursementBUR = (parseFloat(item.IARs) + parseFloat(item.NonProc)) / (parseFloat(item.POs) + parseFloat(item.NonProc))
            });
          }
          if (this.totalCount <= 10) {
            this.options.page = 1;
          }
        }
      );
    },
    print(){
      let searchText = this.searchText != '' ? this.searchText : 0;
      window.open(this.apiUrl + "/transaction/budgetUtilization/" + searchText);
    },
    getFundList() {
    this.axiosCall(
            '/get/getFundCodes',
            'GET'
        )
        .then((res) => {
          this.funds.push(...res.data.data);
        })
		},
    getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
  },
};
</script>

<style scoped>
.Offset {
  top: -20px !important;
  position: relative !important;
}
.v-sheet--offset {
  top: -15px;
  position: relative;
}
</style>