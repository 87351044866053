<template>
<div>
  <v-data-table
    :headers="headers"
    :items="AOQLists"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="mt-2"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
      <template v-slot:[`item.CanvassNo`]="{ item }">
        {{ item.PRCODE.substring(3, item.PRCODE.length) }}
      </template>
      <template v-slot:[`item.AOQTypeID`]="{ item }">
        {{ item.AOQTypeID == 2 ? 'Lot Bid' : 'Item Bid' }}
      </template>
      <template v-slot:[`item.DateReceived`]="{ item }">
        {{ formatDate(item.DateReceived) }}
      </template>
      <template v-slot:[`item.ClosingDate`]="{ item }">
        {{ formatDate(item.ClosingDate) || "N/A" }}
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            @click="aoq(item)"
            color="header"
            class="mr-1"
            dense
            outlined
            x-small
          >
            View
          </v-btn>
          <v-btn
            @click="print(item)"
            color="red darken-3"
            class="mr-1 white--text"
            dense
            outlined
            x-small
          >
            Print
          </v-btn>
        </span>
      </template>
  </v-data-table>
      <canvasModlal :canvasData="prItem" />
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    components: {
    canvasModlal: () => import("./PSUCanvasEdit"),
    },
    props: {
    fiscalYear: null,
    },
    data: () => ({
      searchText: '',
      amountErr: null,
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu1: false,
      menu2: false,
      formData: {
        ItemName: null,
      },
      Dialog: false,
      AOQLists: [],
      headers: [
        // {
        //   text: 'Canvass No.',
        //   align: 'start',
        //   sortable: false,
        //   value: 'CanvassNo',
        //   class: "green darken-2 white--text text-caption font-weight-bold"
        // },
        {
          text: 'PR Code',
          align: 'start',
          sortable: false,
          value: 'PRCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Type of Bidding',
          align: 'start',
          sortable: false,
          value: 'AOQTypeID',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Mode of Procurement',
          align: 'start',
          sortable: false,
          value: 'mopDescription',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Received from PSU (RFQ)',
          align: 'center',
          sortable: false,
          value: 'DateReceived',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Closing date',
          align: 'center',
          sortable: false,
          value: 'ClosingDate',
          class: "green darken-2 white--text text-caption font-weight-bold" 
        },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      prItem: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    },
    mounted(){
      this.getLIBCategories();
      this.eventHub.$on("closeCanvasModal", () => {
      this.initialize();
    });
    },
    beforeDestroy() {
    this.eventHub.$off("closeCanvasModal");
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      aoq(item) {
      this.prItem = item;
    },
      initialize () {
        if(this.fiscalYear != null){
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
         data.append("searchText", this.searchText);
         data.append("FiscalYearID", this.fiscalYear);
              this.axiosCall(
              '/getAOQ',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.AOQLists = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      }
      },
    editItem(data) {
      this.editItemId = data.ItemID;
      this.formData.ItemName = data.ItemName;
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
              this.isLoading = true;
             let data = new FormData();
              data.append('ItemName', this.formData.ItemName);
              data.append('CategoryID', this.formData.CategoryID);
                         this.axiosCall(
                        '/LIBItems/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
              this.isLoading = true;
             let data = new FormData();
              data.append('ItemID', this.editItemId);
               data.append('ItemName', this.formData.ItemName);
               data.append('CategoryID', this.formData.CategoryID);
                         this.axiosCall(
                        '/LIBItems/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                        
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
    getLIBCategories(){
      this.axiosCall("/get/LIBCategories", "GET").then((res) => {
        this.LIBCategories = res.data.data;
      });
    },
    print(data){
      window.open(this.apiUrl + "/pr/aoq/" + data.PRID + "/" + data.AOQID);
    }
    }
  }
</script>