<template>
<div class="DarkWhite pa-4 ">
    <h2 class="subheading green--text">User Account Verification</h2>
    <!-- <v-breadcrumbs :items="items"></v-breadcrumbs> -->
  <v-data-table
    :headers="headers"
    :items="users"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <!-- <v-toolbar-title>User Account Verification</v-toolbar-title> -->
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
     <v-tooltip bottom>
         <template v-slot:activator="{ on, attrs }">
        <v-icon
        small
        class="mr-2"
        v-bind="attrs"
        v-on="on"
        @click="viewDetails(item)"
      >
        mdi-eye
      </v-icon>
         </template>
      <span>View</span>
    </v-tooltip>
    </template>
    <template v-slot:no-data>
      <span>No New Users</span>
    </template>
  </v-data-table>
   <v-dialog
          v-model="ViewUser"
          max-width="600px"
          persistent
          scrollable
        >
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green darken-2"
              dark
              rounded
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
              Add New Account
            </v-btn>
          </template> -->
          <v-card>
            <v-card-title>
              <span class="headline">User Account Details</span>
              <v-spacer></v-spacer>
              <v-btn
                icon
                color="secondary2"
                @click="ViewUser = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text style="max-height: 900px;">
                <v-form @submit.prevent ref="formRef">
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <v-text-field
                      v-model="userDetails.email"
                      label="Email Address"
                      color="header"
                      dense
                      readonly
                        :rules="[formRules.required, formRules.email, formRules.emaildnsc]"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="userDetails.FirstName"
                       readonly
                       color="header"
                       :rules="[formRules.required]"
                       dense
                      label="First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="userDetails.MiddleName"
                       readonly
                       color="header"
                       :rules="[formRules.required]"
                      label="Middle Name"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                    dense
                      v-model="userDetails.LastName"
                       :rules="[formRules.required]"
                      readonly
                      color="header"
                      label="Last Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                                v-model="userDetails.DesignationID"
                                :items="designation"
                                item-text="DesignationDescription"
                                item-value="DesignationID"
                                 :rules="[formRules.required]"
                                dense
                                color="header"
                                label="Designation"
                                readonly
                            ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                                v-model="userDetails.OfficeID"
                                :items="office"
                                item-text="OfficeDescription"
                                 :rules="[formRules.required]"
                                item-value="OfficeID"
                                readonly
                                color="header"
                                dense
                                label="Office">
                    </v-autocomplete>
                  </v-col>
                      <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                                v-model="userDetails.DeliveryUnitID"
                                :items="deliveryunit"
                                item-text="DeliveryUnitDescription"
                                 :rules="[formRules.required]"
                                item-value="DeliveryUnitID"
                                readonly
                                color="header"
                                dense
                                label="Deliver Unit">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                  >
                    <v-autocomplete
                                v-model="userDetails.roleID"
                                item-text="RoleDescription"
                                item-value="RoleID"
                                 :rules="[formRules.required]"
                                :items="roles"
                                color="header"
                                dense
                                label="Access Control"
                            ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col cols="12" class="text-subtitle-1">
                    Sub Access Controls:
                  </v-col>
                  <v-col cols="12" v-if="!userDetails.roleID" class="text-center">
                    <i>No access control selected</i>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="userDetails.roleID">
                  <v-col
                    cols="12"
                    md="6"
                  >
                   <v-checkbox
                   dense
                      color="header"
                      v-model="userDetails.IsRA"
                      label="Recommending Approval"
                    ></v-checkbox>
                  </v-col>
                    <v-col
                    cols="12"
                    md="6"
                  >
                    <v-checkbox
                    dense
                      v-model="userDetails.IsFM"
                      label="Fund Manager"
                      color="header"
                      v-if="userDetails.roleID != 2"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-checkbox
                    dense
                      v-model="userDetails.BACaccess"
                      label="BAC Access"
                      color="header"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-if="userDetails.roleID != 3"
                  >
                   <v-checkbox
                   dense
                      v-model="userDetails.IsPA"
                      color="header"
                      label="Person Accountable"
                    ></v-checkbox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                   <v-checkbox
                   dense
                      v-model="userDetails.IsDAS"
                      color="header"
                      label="DAS Access"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
                  <v-btn text class="mr-2" color="error" @click="ViewUser = false">Decline</v-btn>
                  <v-btn text color="green darken-1" :loading="isLoading" @click="save()" >Confirm</v-btn>
            </v-card-actions>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="ViewUser = false"
              >
                Close
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-dialog>
        <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
</div>
</template>

<script>
  export default {
    data: () => ({
       footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
    isLoading: false,
      ViewUser: false,
      roles: [],
      designation: [],
      deliveryunit: [],
      office: [],
      userDetails: {
         BACaccess: false,
         IsRA: false,
         IsFM: false,
         IsPA: false,
         IsDAS: false,
      },
      headers: [
        {
          text: 'Email Address',
          align: 'start',
          value: 'email',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'First Name', value: 'FirstName', class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Middle Name', value: 'MiddleName', class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Last Name', value: 'LastName', class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Actions', value: 'actions', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold", }
      ],
      users: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      }
    },
    computed: {
    },
    created () {
      this.getPositionDesignationOffice();
      // this.initialize()
    },

    methods: {
    getPositionDesignationOffice() {
      this.axiosCall(
              '/getPositionDesignationOffice',
              'GET'
          )
          .then((res) => {
              this.office = res.data.data.office
              this.deliveryunit = res.data.data.deliveryunit
              this.designation = res.data.data.designation
              this.roles = res.data.data.roles
          })
		},
      initialize () {
          this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
          this.axiosCall(
          '/getAllUsers',
            'POST',
            data)
          .then((res) => {
              this.loading = false
              this.users = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
            })
      },

      viewDetails (item) {
        this.userDetails = item
        this.userDetails.BACaccess = item.BACaccess ? true : false;
        this.userDetails.IsRA = item.IsRA == 1 ? true : false;
        this.userDetails.IsFM = item.IsFM == 1 ? true : false;
        this.userDetails.IsPA = item.IsPA == 1 ? true : false;
        this.userDetails.IsDAS = item.IsDAS == 1 ? true : false;
        this.ViewUser = true;
      },
      save () {
        if(this.$refs.formRef.validate()){
          this.isLoading = true;
             let data = new FormData();
                data.append('data', JSON.stringify(this.userDetails));
                this.axiosCall(
                        '/account/validate',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'User Authorized Successfully!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.ViewUser = false;
                        this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
        }
      }
    }
  }
</script>

<style scoped>
</style>
