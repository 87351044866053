<template>
<div class="DarkWhite">
    <!-- <h2 class="subheading green--text">Help</h2> -->
    <BudgetHelp v-if="user.roleID == 7" />
    <FinanceHelp v-if="user.roleID == 8 || user.roleID == 9" />
    <PSUHelp v-if="user.roleID == 5" />
    <FundManagerHelp v-if="user.roleID == 2 || user.IsFM == 1" />
    <RAHelp v-if="user.IsRA == 1" />
  </div>
</template>

<script>
import FundManagerHelp from './Help/Help-User.vue';
import BudgetHelp from './Help/Help-Budget.vue';
import FinanceHelp from './Help/Help-Finance.vue';
import PSUHelp from './Help/Help-PSU.vue';
import RAHelp from './Help/Help-RA.vue';
  export default {
    components: {
        FundManagerHelp,
        BudgetHelp,
        FinanceHelp,
        PSUHelp,
        RAHelp,
    },
    data: () => ({
        user: null,
    }),
    created(){
        this.user = this.$store.state.user;
    }
  }
</script>

<style scoped>
</style>