<template>
<div>
  <v-tabs
    v-model="tab"
    color="header"
    v-if="revisionData"
  >
    <v-tabs-slider color="header"></v-tabs-slider>

    <v-tab
      v-for="item in tab_items"
      :key="item"
      color="header"
    >
      {{ item }}
    </v-tab>
  </v-tabs>
  <div>
    <v-data-table
    :headers="headers"
    :items="ppmpList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
        <v-row class="mx-1 pb-2 align-center">
            <v-col cols="12" md="5">
              <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              placeholder="Search here..."
              color="green darken-2"
              class="pa-0"
              single-line
              hide-details
            ></v-text-field>
            </v-col>
            <v-col cols="12" md="7" class="flex-items justify-md-end mb-4 mb-md-0">
              <v-autocomplete
                  v-model="BudgetID"
                  color="green"
                  item-text="BudgetCode"
                  item-value="BudgetID"
                  :items="budgetCodeList"
                  dense
                  outlined
                  hide-details
                  class="mr-2"
                  label="Budget Codes">
              </v-autocomplete>
              <v-autocomplete
                  v-model="PPMPTypeID"
                  :disabled="tab == 1"
                  color="green"
                  item-text="Description"
                  item-value="PPMPTypeID"
                  :items="ppmptype"
                  dense
                  outlined
                  hide-details
                  label="PPMP Type">
              </v-autocomplete>
            </v-col>
          </v-row>
    </template>
        <template v-slot:[`item.Amount`]="{ item }">
          <span class="text-no-wrap">₱ {{formatPrice(item.Amount || 0)}}</span>
        </template>
        <template v-slot:[`item.BudgetDate`]="{ item }">
          {{ item.DateStart }} - {{ item.DateEnd }}
        </template>
        <template v-slot:[`item.PPMPTypeID`]="{ item }">
            <span class="text-no-wrap" v-if="tab == 0 && item.PPMPTypeID == 1"><small>DBM</small></span>
            <span class="text-no-wrap" v-if="tab == 0 && item.PPMPTypeID == 2"><small>NON-DBM</small></span>
            <span class="text-no-wrap" v-if="tab == 0 && item.PPMPTypeID == 3"><small>LIB</small></span>
            <span class="text-no-wrap" v-if="tab == 1"><small>NON-DBM & LIB</small></span>
        </template>
        <template v-slot:[`item.PPMPStatusID`]="{ item }">
            <span v-if="item.PPMPStatusID == 11"> On Process</span>
            <span v-if="item.PPMPStatusID == 12"> Published</span>
        </template>
        <template v-slot:[`item.Other_Actions`]="{ item }">
            <span class="text-no-wrap">
            <v-btn
            v-if="item.BudgetStatusID == 7 && item.FinanceStatusID == 7 && item.PresidentStatusID == 7 && tab == 0 && item.PPMPTypeID != 3 && item.enable == 1"
              x-small
               outlined
              color="red darken-2"
              dark
              class="mr-2"
             @click="PR(item)">
              PR
              </v-btn>
            <v-btn
            v-if="tab"
              x-small
               outlined
              color="green darken-2"
              dark
              class="mr-2"
             @click="revise(item)">
              Revise
              </v-btn>
            </span>
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <span class="text-no-wrap">
               <v-icon
                class="mr-2"
                v-if="!tab"
                   @click="viewPPMP(item)">
                mdi-eye
              </v-icon>
              <v-icon
                class="mr-2"
                v-if="tab"
                   @click="viewRevisedPPMP(item)">
                mdi-eye
              </v-icon>
              <v-icon
              v-if="item.PPMPStatusID == 11"
                class="mr-2"
                @click="editItem(item)">
                mdi-pencil-box-outline
              </v-icon>
          </span>
        </template>
  </v-data-table>
        <v-dialog
          v-model="addPPMPDialog"
          persistent
          max-width="450px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">Update PPMP</span>
              <span class="subtitle-1 font-weight-bold" v-else>Create PPMP</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
              <v-row>
              <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
            <v-text-field
            dense
              v-model="formData.PPMPDescription"
              :rules="[formRules.required]"
              label="PPMP Description"
              color="green"
              class="mb-4"
            ></v-text-field>
            <v-autocomplete
            v-if="!editItemId"
                      v-model="formData.BudgetID"
                      color="green"
                      item-text="BudgetCode"
                      item-value="BudgetID"
                      :rules="[formRules.required]"
                      :items="budgetList"
                      dense
                      class="mb-4"
                      label="Budget">

                 <template v-slot:selection="data">
                  {{ data.item.BudgetCode }} (₱ {{formatPrice(data.item.Amount)}})
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.BudgetCode}}</v-list-item-title>
                    <v-list-item-subtitle>₱ {{formatPrice(data.item.Amount)}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
          </v-autocomplete>

                 <v-autocomplete
                      v-model="formData.approvalID"
                      color="green"
                      item-text="FirstName"
                      item-value="UserID"
                      :rules="[formRules.required]"
                      :items="approvalList"
                      dense
                      class="mb-4"
                      label="Recommending Approval">

                 <template v-slot:selection="data">
                 {{data.item.FirstName}} {{data.item.MiddleName}} {{data.item.LastName}} {{data.item.EducQuali}} 
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{data.item.FirstName}} {{data.item.MiddleName}} {{data.item.LastName}} {{data.item.EducQuali}} </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
          </v-autocomplete>
        </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                text
                @click="save"
                :loading="isLoading"
              >
                Add
              </v-btn>
              <v-btn
              v-else
                color="red darken-1"
                text
                @click="update"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
        <ppmpModlal :ppmpData="ppmpItem" />
        <ppmpModlalPR :ppmpData="ppmpItemPR" />
        <ppmpModlalRevisedItem :ppmpData="ppmpItemRevision" />
        <ppmpModlalRevision :ppmpData="ppmpRevision" />
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    components : {
      'ppmpModlal' : () => import('./PPMPAddItem'),
      'ppmpModlalPR' : () => import('./PPMPAddPR'),
      'ppmpModlalRevisedItem' : () => import('./PPMPViewRevised'),
      'ppmpModlalRevision' : () => import('./PPMPRevision'),
    },
    props: {
      fiscalYear: null,
    },
    data: () => ({
      tab: 0,
      tab_items: ['ppmplist', 'revision'],
      searchText: "",
      searchTimeout : null,
      amountErr: null,
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu: false,
      menu1: false,
      formData: {
         PPMPDescription: null,
         BudgetID: null,
         approvalID: null
      },
      addPPMPDialog: false,
      ppmpList: [],
      budgetList: [],
      approvalList: [],
      ppmpItem: [],
      ppmpItemPR: [],
      ppmpItemRevision: [],
      ppmpRevision: [],
      revisionData: [],
      budgetsForRevision: [],
      headers: [
        {
          text: 'Budget Code',
          align: 'start',
          sortable: false,
          value: 'BudgetCode',
          class: "green darken-2 white--text text-caption font-weight-bold",
        },
        { text: 'PPMP Description', value: 'PPMPDescription', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Budget', value: 'Amount', align: "end", sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Budget Date', value: 'BudgetDate', align: "center", sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'PPMP Type', value: 'PPMPTypeID', align: 'center', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Status', value: 'PPMPStatusID', align: 'center', sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: '', value: 'Other_Actions', align: "center", sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false,class: "green darken-2 white--text text-caption font-weight-bold", }
      ],
    ppmptype: [
      { PPMPTypeID: 0, Description: 'All'},
      { PPMPTypeID: 1, Description: 'DBM'},
      { PPMPTypeID: 2, Description: 'Non-DBM'},
      { PPMPTypeID: 3, Description: 'LIB'},
    ],
    PPMPTypeID: 0,
    budgetCodeList: [
      { BudgetID: 0, BudgetCode: 'All'},
    ],
    BudgetID: 0,
    }),
    watch: {
    searchText : function(){
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
			this.initialize();
			}, 600);
		},
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      tab: {
        handler () {
        this.ppmpList = [];
        this.totalCount = 0;
        this.initialize();
        },
        deep: true,
      },
      PPMPTypeID: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      BudgetID: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      fiscalYear: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    created () {
      // this.initialize();
      this.getBudgetList();
      this.getApprovalList();
      this.checkRevision();
      this.getBudgetListByUserID();
    },
    mounted() {
        this.eventHub.$on('closePPMPModal', ()=>{
          this.ppmpItem = {};
           this.initialize();
          this.getBudgetList();
           this.getApprovalList();

        });
      this.eventHub.$on('closePPMPModalPR', ()=>{
          this.ppmpItemPR = {};
           this.initialize();
          this.getBudgetList();
           this.getApprovalList();
        });
      this.eventHub.$on('closePPMPModalRevision', ()=>{
          this.ppmpItemRevision = {};
          this.ppmpRevision = {};
           this.initialize();
          this.getBudgetList();
           this.getApprovalList();
        });
    },
    beforeDestroy() {
         this.eventHub.$off('closePPMPModal');
         this.eventHub.$off('closePPMPModalPR');
         this.eventHub.$off('closePPMPModalRevision');
    },
    methods: {
      viewPPMP(item) {
        this.ppmpItem = item;
      },
      viewRevisedPPMP(item) {
        this.ppmpItemRevision = item;
      },
      PR(item) {
        this.ppmpItemPR = item;
      },
      revise(item) {
        this.ppmpRevision = item;
      },
      checkAmount() {
        if(this.formData.BudgetID && this.formData.Amount){
          this.isLoading = true;
          let data = new FormData();
          data.append('BudgetID', this.formData.BudgetID);
          data.append('Amount', this.formData.Amount);
          this.axiosCall(
              '/ppmp/budget/check/balance',
              'POST',
              data
          )
          .then((res) => {
            let that = this;
          setTimeout(function(){  that.isLoading = false}, 500);
            if(res.data.data === true){
              this.amountErr = "Insufficient Budget";
            }else{
               this.amountErr = null;
            }
          })
        }else{
          this.amountErr = null;
        }
      },
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.addPPMPDialog = false;
      },
      initialize () {
        this.loading = true
        if(this.fiscalYear != null){
         let data = new FormData();
         let url = '';
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
          data.append('searchText', this.searchText);
          data.append('FiscalYearID', this.fiscalYear);
          if(this.PPMPTypeID > 0){
            data.append('PPMPTypeID', this.PPMPTypeID);
          }
          if(this.BudgetID > 0){
            data.append('BudgetID', this.BudgetID);
          }
          data.append('revision', this.tab);
          if(this.revisionData){
          data.append('budgetCodes', JSON.stringify(this.budgetsForRevision) || '');
          }
          url = this.tab ? '/get/PPMPRevision' : '/get/PPMPNew';
              this.axiosCall(
              url,
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.ppmpList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
        }
      },
      getBudgetList() {
      this.axiosCall(
              '/get/Budget/List',
              'GET'
          )
          .then((res) => {
            this.budgetList = res.data.data;
          })
		},
    getBudgetListByUserID() {
      this.axiosCall(
              '/get/budgetListByUserID',
              'GET'
          )
          .then((res) => {
            this.budgetCodeList.push(...res.data.data);
          })
		},
    getApprovalList() {
      this.axiosCall(
              '/get/approval/List',
              'GET'
          )
          .then((res) => {
            this.approvalList = res.data.data;
          })
		},
    editItem(data) {
      this.editItemId = data.PPMPID;
              this.formData.Amount = data.Amount;
              this.formData.isDBM = data.isDBM;
              this.formData.BudgetID = data.BudgetID;
              this.formData.approvalID = data.RecommendingApprovalID
              this.formData.PPMPDescription = data.PPMPDescription;
              this.addPPMPDialog = true;
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
            this.isLoading = true;
             let data = new FormData();
              data.append('BudgetID', this.formData.BudgetID);
              data.append('PPMPDescription', this.formData.PPMPDescription);
              data.append('approvalID', this.formData.approvalID);
                         this.axiosCall(
                        '/PPMP/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'PPMP Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addPPMPDialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate() && !this.amountErr){
                     this.isLoading = true;
             let data = new FormData();
              data.append('PPMPID', this.editItemId);
              data.append('PPMPDescription', this.formData.PPMPDescription);
              data.append('approvalID', this.formData.approvalID)
                         this.axiosCall(
                        '/PPMP/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'PPMP Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.addPPMPDialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      checkRevision(){
        this.budgetsForRevision = [];
        this.revisionData = null;
             this.axiosCall(
              '/getRevisionDate',
              'POST',
              []
          )
          .then((res) => {
              this.revisionData = res.data.data.result;
              this.revisionData.forEach(item => {
                this.budgetsForRevision.push(...JSON.parse(item.BudgetCodes))
              });
          })
      }
    }
  }
</script>