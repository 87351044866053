import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import "./assets/global.css"
import OtpInput from "@bachdgvn/vue-otp-input";
import mixins from './mixins.js'
import store from './store';
import VueJsonToCsv from 'vue-json-to-csv'
import draggable from 'vuedraggable'
const FadeAwayMessage = () => import("./components/Utils/FadeAwayMessage");
Vue.config.productionTip = false
Vue.mixin(mixins)

Vue.component("v-otp-input", OtpInput);
Vue.component("vue-json-to-csv", VueJsonToCsv);
Vue.component('fade-away-message-component', FadeAwayMessage)
Vue.component("draggable", draggable);
new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')