<template>
<div>
  <v-data-table
    :headers="headers"
    :items="unitConversionList"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="mb-2 mr-2"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>
       
            <v-btn
              color="green darken-2"
              dark
              outlined
              elevation="2"
              class="my-2"
              @click="Dialog = true"
            >
              <v-icon>mdi-plus</v-icon>
              Create New
            </v-btn>
      </v-toolbar>
    </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-icon
            small
            color="blue darken-2"
            class="mr-2"
            @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <!-- <v-icon
            small
            color="red darken-2"
            @click="deleteItem(item)">
            mdi-delete
          </v-icon> -->
        </span>
      </template>
  </v-data-table>
        <v-dialog
          v-model="Dialog"
          persistent
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold" v-if="editItemId">Update Unit Conversion</span>
              <span class="subtitle-1 font-weight-bold" v-else>New Unit Conversion</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="cancel()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      dense
                      v-model="formData.description"
                      :rules="[formRules.required]"
                      counter
                      maxlength="250"
                      rows="3"
                      color="header"
                      label="Description"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      v-model="formData.factor"
                      :rules="[formRules.required]"
                      label="Factor"
                      color="header"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="formData.From"
                      :items="unitmeasures1"
                      item-text="UnitMeasureDescription"
                      item-value="UnitMeasureID"
                      color="header"
                      @change="updateUnits(1)"
                      dense
                      label="Unit Measure(FROM)"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      v-model="formData.multiply"
                      :rules="[formRules.required]"
                      color="header"
                      label="Multiplier"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="formData.To"
                      :items="unitmeasures2"
                      @change="updateUnits(2)"
                      item-text="UnitMeasureDescription"
                      item-value="UnitMeasureID"
                      color="header"
                      dense
                      label="Unit Measure(TO)"
                    >
                  </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="cancel()"
              >
                Cancel
              </v-btn>
              <v-btn v-if="!editItemId"
                color="green darken-1"
                text
                @click="save"
                :loading="isLoading"
              >
                Save
              </v-btn>
              <v-btn
              v-else
                color="red darken-1"
                text
                @click="update"
                :loading="isLoading"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    data: () => ({
      searchText: '',
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
      options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      formData: {
        description: null,
        factor: null,
        From: null,
        multiply: null,
        To: null,
      },
      Dialog: false,
      unitConversionList: [],
      headers: [
        {
          text: 'Description',
          align: 'start',
          sortable: false,
          value: 'description',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Factor',
          align: 'center',
          sortable: false,
          value: 'factor',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'From',
          align: 'center',
          sortable: false,
          value: 'um_from',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Multiply',
          align: 'center',
          sortable: false,
          value: 'multiply',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'To',
          align: 'center',
          sortable: false,
          value: 'um_to',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', sortable: false, width: '100', class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      unitmeasures: "",
      unitmeasures1: [],
      unitmeasures2: [],
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
    },
    mounted(){
      this.getUnitMeasures();
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      initialize () {
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
         data.append("searchText", this.searchText);
              this.axiosCall(
              '/getUnitConversion',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.unitConversionList = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      },
    editItem(data) {
      this.editItemId = data.id;
      this.formData.description = data.description;
      this.formData.factor = data.factor;
      this.formData.From = data.UnitMeasureID_from;
      this.formData.multiply = data.multiply;
      this.formData.To = data.UnitMeasureID_to;
      this.updateUnits(1);
      this.updateUnits(2);
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate()){
             
             this.isLoading = true;
             let data = new FormData();
              data.append('description', this.formData.description);
              data.append('factor', this.formData.factor);
              data.append('UnitMeasureID_from', this.formData.From);
              data.append('multiply', this.formData.multiply);
              data.append('UnitMeasureID_to', this.formData.To);
                         this.axiosCall(
                        '/unitconversion/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Unit Conversion was successfully added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
              this.isLoading = true;
              let data = new FormData();
              data.append('id', this.editItemId);
              data.append('description', this.formData.description);
              data.append('factor', this.formData.factor);
              data.append('UnitMeasureID_from', this.formData.From);
              data.append('multiply', this.formData.multiply);
              data.append('UnitMeasureID_to', this.formData.To);
                         this.axiosCall(
                        '/unitconversion/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Unit Conversion was successfully updated!!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      getUnitMeasures() {
      this.axiosCall("/getCategory", "GET").then((res) => {
        this.unitmeasures = JSON.stringify(res.data.data.unitmeasure);
        this.unitmeasures1 = res.data.data.unitmeasure;
        this.unitmeasures2 = res.data.data.unitmeasure;
      });
    },
    updateUnits(num){
      if(num == 1){
        this.unitmeasures2 = JSON.parse(this.unitmeasures);
        this.unitmeasures2.forEach(item => {
          if(item.UnitMeasureID == this.formData.From){
            item.disabled = true;
          }
        });
      }
      else if(num == 2){
        this.unitmeasures1 = JSON.parse(this.unitmeasures);
        this.unitmeasures1.forEach(item => {
          if(item.UnitMeasureID == this.formData.To){
            item.disabled = true;
          }
        });
      }
    }
    }
  }
</script>