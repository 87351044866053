<template>
<div>
  <div>
    <v-autocomplete
          :items="mrtypes"
          label="Select MR Type"
          outlined
          item-text="desc"
          item-value="id"
          v-model="mrtype"
          color="header"
        ></v-autocomplete>
  </div>
  <div class="flex w-full h-screen items-center justify-center text-center">
  <div class="pa-12 dd-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <input type="file" multiple name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" accept=".csv, .txt" />
  
    <label for="assetsFieldHandle">
      <div>
        Drag and drop your 3 csv or txt files 
        or <span class="text-decoration-underline font-weight-bold">click here</span> to upload the files
      </div>
    </label>
    <div class="mt-4 text-start" v-if="filelist.length > 0">
        <v-list
          subheader
          three-line
        >
          <v-subheader>File List:</v-subheader>

          <v-list-item v-for="file in filelist" :key="file.name">
            <v-list-item-content>
              <v-list-item-title>{{file.name}}</v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="file.size < 1024">
                  {{formatQty(parseFloat(file.size).toFixed(2))}} byte(s)
                </span>
                <span v-else-if="file.size < 1048576">
                  {{(formatQty(parseFloat(file.size) / 1024).toFixed(2))}} KB(s)
                </span>
                <span v-else>
                  {{(formatQty(parseFloat(file.size) / 1048576).toFixed(2))}} MB(s)
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="red" small dark @click="remove(filelist.indexOf(file))">
                remove
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
    </div>
  </div>
</div>
  <div v-if="mrtype == 1">
        <v-row>
      <v-col cols="12">
        <v-card v-if="csv_ics.length > 0" class="my-5">
      <v-card-title>
        Preview ICS
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          color="header"
          label="Search"
          single-line
          outlined
          dense
          class="mx-2"
          hide-details
        ></v-text-field>
        <v-btn color="header" dark @click="upload()"><v-icon>mdi-check-circle</v-icon>submit</v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers_ics"
        :items="csv_ics"
        :search="search"
        :expanded.sync="expanded_ics"
        item-key="ICSID"
        show-expand
        :single-expand="true"
      >
      <template v-slot:[`item.FundID`]="{ item }">
        {{item.FundID ? getUtility(item.FundID, 1) : "N/A"}}
      </template>
      <template v-slot:[`item.SpecificFundSourceID`]="{ item }">
        {{item.SpecificFundSourceID ? getUtility(item.SpecificFundSourceID, 2) : "N/A"}}
      </template>
      <template v-slot:[`item.UserID`]="{ item }">
        {{item.UserID ? getUtility(item.UserID, 3) : "N/A"}}
      </template>
      <template v-slot:[`item.DispenseTypeID`]="{ item }">
        {{item.DispenseTypeID ? getUtility(item.DispenseTypeID, 4) : "N/A"}}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-5">
          <v-data-table
            :headers="headers2"
            :items="item.items"
            :expanded.sync="expanded_ics_2"
            item-key="ICSItemID"
            show-expand
            :single-expand="true"
          >
          <template v-slot:[`item.UnitCost`]="{ item }">
            <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
          </template>
          <template v-slot:[`item.Amount`]="{ item }">
            <span class="text-no-wrap">₱ {{ formatPrice(item.Amount) }}</span>
          </template>
          <template v-slot:[`item.UnitMeasureID`]="{ item }">
            {{item.UnitMeasureID ? getUtility(item.UnitMeasureID, 5) : "N/A"}}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-5">
              <v-data-table
                :headers="headers3"
                :items="item.mritems"
              >
              <template v-slot:[`item.UserID`]="{ item }">
                {{item.UserID ? getUtility(item.UserID, 3) : "N/A"}}
              </template>
              </v-data-table>
            </td>
          </template>
          </v-data-table>
        </td>
      </template>
      </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-if="mrtype == 2">
    <v-row>
      <v-col cols="12">
        <v-card v-if="csv_par.length > 0" class="my-5">
      <v-card-title>
        Preview PAR
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          color="header"
          label="Search"
          outlined
          dense
          single-line
          class="mx-2"
          hide-details
        ></v-text-field>
        <v-btn color="header" dark @click="upload()">submit</v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers_par"
        :items="csv_par"
        :search="search"
        :expanded.sync="expanded_par"
        item-key="PARID"
        show-expand
        :single-expand="true"
      >
      <template v-slot:[`item.FundID`]="{ item }">
        {{item.FundID ? getUtility(item.FundID, 1) : "N/A"}}
      </template>
      <template v-slot:[`item.SpecificFundSourceID`]="{ item }">
        {{item.SpecificFundSourceID ? getUtility(item.SpecificFundSourceID, 2) : "N/A"}}
      </template>
      <template v-slot:[`item.UserID`]="{ item }">
        {{item.UserID ? getUtility(item.UserID, 3) : "N/A"}}
      </template>
      <template v-slot:[`item.DispenseTypeID`]="{ item }">
        {{item.DispenseTypeID ? getUtility(item.DispenseTypeID, 4) : "N/A"}}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-5">
          <v-data-table
            :headers="headers2"
            :items="item.items"
            :expanded.sync="expanded_par_2"
            item-key="PARItemID"
            show-expand
            :single-expand="true"
          >
          <template v-slot:[`item.UnitCost`]="{ item }">
            <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
          </template>
          <template v-slot:[`item.Amount`]="{ item }">
            <span class="text-no-wrap">₱ {{ formatPrice(item.Amount) }}</span>
          </template>
          <template v-slot:[`item.UnitMeasureID`]="{ item }">
            {{item.UnitMeasureID ? getUtility(item.UnitMeasureID, 5) : "N/A"}}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="py-5">
              <v-data-table
                :headers="headers3"
                :items="item.mritems"
              >
              <template v-slot:[`item.UserID`]="{ item }">
                {{item.UserID ? getUtility(item.UserID, 3) : "N/A"}}
              </template>
              </v-data-table>
            </td>
          </template>
          </v-data-table>
        </td>
      </template>
      </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <v-dialog v-model="dialogConfirm" persistent max-width="450px">
    <v-card>
      <v-card-title>
        <span class="subheader font-weight-bold">Confirmation</span>
        <v-spacer></v-spacer>
        <v-btn icon dark color="secondary2" @click="dialogConfirm = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
        <v-card-text>
          Are you sure you want to upload these 
          <span v-if="mrtype == 1">ICS</span>
          <span v-if="mrtype == 2">PAR</span>
          ?
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" outlined @click="dialogConfirm = false">
        Cancel
        </v-btn>
        <v-btn
          color="green darken-3"
          :loading="isLoading"
          class="white--text"
          @click="confirmUpload()"
        >
        <v-icon>mdi-check-circle</v-icon>
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
  ></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    delimiters: ['${', '}'],
    data: () => ({
      csv_ics: [],
      csv_par: [],
      csv_ics_item: [],
      csv_par_item: [],
      csv_ics_mritem: [],
      csv_par_mritem: [],
      expanded_ics: [],
      expanded_ics_2: [],
      expanded_par: [],
      expanded_par_2: [],
      search: '',
      
      headers_ics: [
        { text: 'ICS Code', value: 'ICSCode' },
        { text: 'Date Created', value: 'DateCreated' },
        { text: 'ICS Purpose', value: 'ICSPurpose' },
        { text: 'Fund Code', value: 'FundID', align: "center" },
        { text: 'Specific Fund Source', value: 'SpecificFundSourceID', align: "center" },
        { text: 'User(s)', value: 'UserID' },
        { text: 'Dispense Type', value: 'DispenseTypeID', align: "center" },
      ],
      headers_par: [
        {
          text: 'PARID',
          sortable: false,
          value: 'PARID',
        },
        { text: 'PAR Code', value: 'PARCode' },
        { text: 'Date Created', value: 'DateCreated' },
        { text: 'PAR Purpose', value: 'PARPurpose' },
        { text: 'Fund Code', value: 'FundID', align: "center" },
        { text: 'Specific Fund Source', value: 'SpecificFundSourceID', align: "center" },
        { text: 'User(s)', value: 'UserID' },
        { text: 'Dispense Type', value: 'DispenseTypeID', align: "center" },
      ],
      headers2: [
        { text: 'Stock/Property No.', value: 'StockPropertyNo' },
        { text: 'Item Name', value: 'ItemName' },
        { text: 'Item Description', value: 'ItemDescription' },
        { text: 'Unit Measure', value: 'UnitMeasureID' },
        { text: 'Qty', value: 'Qty' },
        { text: 'Unit Cost', value: 'UnitCost', align: "end" },
        { text: 'Amount', value: 'Amount', align: "end" },
        { text: 'EUL', value: 'EUL', align: "center" },
      ],
      headers3: [
        { text: 'Brand', value: 'Brand' },
        { text: 'Model', value: 'Model' },
        { text: 'SerialNo', value: 'SerialNo' },
        { text: 'User(s)', value: 'UserID' },
      ],
      isLoading: false,
      fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
      },
      dialogConfirm: false,
      mrtype: 1,
      mrtypes: [
        {id: 1, desc: "Inventory Custodian Slip"}, 
        {id: 2, desc: "Property Acknowledgement Receipt"}
      ],
      filelist: []
    }),
    watch: {
      mrtype: {
      handler() {
        this.filelist = [];
        this.$refs.file.files = null;
      },
      deep: true,
      },
      csv_ics: {
      handler() {
      },
      deep: true,
      },
      csv_ics_item: {
        handler(data) {
          if(this.csv_ics){
            this.csv_ics.forEach(item1 => {
              let result = [];
              data.forEach(item2 => {
                if(item2.ICSID == item1.ICSID){
                  result.push(item2);
                }
              });
              item1.items = result;
            });
          }
        },
        deep: true,
      },
      csv_ics_mritem: {
        handler(data) {
          if(this.csv_ics_item){
            this.csv_ics_item.forEach(item1 => {
              let result = [];
              data.forEach(item2 => {
                if(item2.ICSItemID == item1.ICSItemID){
                  result.push(item2);
                }
              });
              item1.mritems = result;
            });
          }
        },
        deep: true,
      },
      csv_par: {
      handler() {
      },
      deep: true,
      },
      csv_par_item: {
        handler(data) {
          if(this.csv_par){
            this.csv_par.forEach(item1 => {
              let result = [];
              data.forEach(item2 => {
                if(item2.PARID == item1.PARID){
                  result.push(item2);
                }
              });
              item1.items = result;
            });
          }
        },
        deep: true,
      },
      csv_par_mritem: {
        handler(data) {
          if(this.csv_par_item){
            this.csv_par_item.forEach(item1 => {
              let result = [];
              data.forEach(item2 => {
                if(item2.PARItemID == item1.PARItemID){
                  result.push(item2);
                }
              });
              item1.mritems = result;
            });
          }
        },
        deep: true,
      },
    },
    created () {
      this.getCSVUtilities();
    },
    methods: {
      onChange() {
        if(this.$refs.file.files.length > 3){
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message = "Maximum of 3 csv files are allowed.";
        }
        else{
          const csv=require('csvtojson')
          this.filelist = [...this.$refs.file.files];
          this.filelist.forEach(file => {
            if(this.mrtype == 1){
              if(file.name == 'ics.csv'){
              this.csvJSON(file, (e) => {
                csv({
                    noheader:false,})
                .fromString(e.target.result)
                .then((csvRow)=>{ 
                    this.csv_ics = csvRow;
                })
              });
              }
              else if(file.name == 'icsItem.csv'){
                this.csvJSON(file, (e) => {
                csv({
                    noheader:false,})
                .fromString(e.target.result)
                .then((csvRow)=>{ 
                    this.csv_ics_item = csvRow;
                })
              });
              }
              else if(file.name == 'icsMRItem.csv'){
                this.csvJSON(file, (e) => {
                csv({
                    noheader:false,})
                .fromString(e.target.result)
                .then((csvRow)=>{ 
                    this.csv_ics_mritem = csvRow;
                })
              });
              }
            }
            else if(this.mrtype == 2){
              if(file.name == 'par.csv'){
              this.csvJSON(file, (e) => {
                csv({
                    noheader:false,})
                .fromString(e.target.result)
                .then((csvRow)=>{ 
                    this.csv_par = csvRow;
                })
              });
              }
              else if(file.name == 'parItem.csv'){
                this.csvJSON(file, (e) => {
                csv({
                    noheader:false,})
                .fromString(e.target.result)
                .then((csvRow)=>{ 
                    this.csv_par_item = csvRow;
                })
              });
              }
              else if(file.name == 'parMRItem.csv'){
                this.csvJSON(file, (e) => {
                csv({
                    noheader:false,})
                .fromString(e.target.result)
                .then((csvRow)=>{ 
                    this.csv_par_mritem = csvRow;
                })
              });
              }
            }
          });
        }
        },
        remove(i) {
          this.filelist.splice(i, 1);
          if(i.name == "ics.csv"){
            this.csv_ics = [];
            this.csv_ics_item = [];
            this.csv_ics_mritem = [];
          }
          else if(i.name == "icsItem.csv"){
            this.csv_ics_item = [];
            this.csv_ics_mritem = [];
          }
          else if(i.name == "icsMRItem.csv"){
            this.csv_ics_mritem = [];
          }
          if(i.name == "par.csv"){
            this.csv_par = [];
            this.csv_par_item = [];
            this.csv_par_mritem = [];
          }
          else if(i.name == "parItem.csv"){
            this.csv_par_item = [];
            this.csv_par_mritem = [];
          }
          else if(i.name == "parMRItem.csv"){
            this.csv_par_mritem = [];
          }
        },
        dragover(event) {
          event.preventDefault();
          if (!event.currentTarget.classList.contains('bg-green-300')) {
            event.currentTarget.classList.remove('bg-gray-100');
            event.currentTarget.classList.add('bg-green-300');
          }
        },
        dragleave(event) {
          event.currentTarget.classList.add('bg-gray-100');
          event.currentTarget.classList.remove('bg-green-300');
        },
        drop(event) {
          event.preventDefault();
          this.$refs.file.files = event.dataTransfer.files;
          if(event.dataTransfer.files.length > 3){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Maximum of 3 csv files are allowed.";
          }
          else{
            this.onChange();
          }
          event.currentTarget.classList.add('bg-gray-100');
          event.currentTarget.classList.remove('bg-green-300');
        },
      upload(){
        this.dialogConfirm = true;
      },
      confirmUpload(){
        if(this.mrtype == 1){
          if(this.csv_ics.length > 0){
            this.isLoading = true;
            let data = new FormData();
            data.append("data", JSON.stringify(this.csv_ics));
            this.axiosCall("/csv/uploadICS", "POST", data).then((res) => {
              if(res.data.status){
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "success";
                this.fadeAwayMessage.header = "System Message";
                this.fadeAwayMessage.message = "ICS was successfully uploaded.";
                this.dialogConfirm = false;
                this.filelist = [];
                this.csv_ics = [];
                this.csv_ics_item = [];
                this.csv_ics_mritem = [];

              }
              else{
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "error";
                this.fadeAwayMessage.header = "System Message";
                this.fadeAwayMessage.message = "Something went wrong!";
              }
              this.isLoading = false;
              });
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Unable to proceed. Please check the details before submitting!";
          }
        }
        else if(this.mrtype == 2){
          if(this.csv_par.length > 0){
            this.isLoading = true;
            let data = new FormData();
            data.append("data", JSON.stringify(this.csv_par));
            this.axiosCall("/csv/uploadPAR", "POST", data).then((res) => {
              if(res.data.status){
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "success";
                this.fadeAwayMessage.header = "System Message";
                this.fadeAwayMessage.message = "PAR was successfully uploaded.";
                this.dialogConfirm = false;
                this.filelist = [];
                this.csv_ics = [];
                this.csv_ics_item = [];
                this.csv_ics_mritem = [];
              }
              else{
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "error";
                this.fadeAwayMessage.header = "System Message";
                this.fadeAwayMessage.message = "Something went wrong!";
              }
              this.isLoading = false;
              });
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Unable to proceed. Please check the details before submitting!";
          }
        }
    },
    csvJSON(file, onLoadCallback){
      var reader = new FileReader();
      reader.onload = onLoadCallback;
      reader.readAsText(file);
    },
    getCSVUtilities(){
      this.axiosCall("/csv/utilities", "GET").then((res) => {
        if(res.data.status){
          this.funds = res.data.data.funds;
          this.sfs = res.data.data.sfs;
          this.users = res.data.data.users;
          this.dispensetypes = res.data.data.dispensetypes;
          this.unitmeasures = res.data.data.unitmeasures;
        }
      });
    },
    getUtility(uid, type){
      let result = [];
      switch (type) {
        case 1:
          result = this.funds.find(({ id }) => id == uid);
          break;
        case 2:
          result = this.sfs.find(({ id }) => id == uid);
          break;
        case 3:
          result = this.getMultipleUsers(uid);
          break;
        case 4:
          result = this.dispensetypes.find(({ id }) => id == uid);
          break;
        case 5:
          result = this.unitmeasures.find(({ id }) => id == uid);
          break;
      }
      return result.description;
    },
    getMultipleUsers(uid){
      let user = uid.split(',');
      let res = {id: uid, description: ''};
      let desc = [];
      user.forEach(item => {
        let temp = this.users.find(({ id }) => id == item);
        if(temp){
          desc.push(temp.description);
        }
      });
      res.description = desc.toString();
      return res;
    }
    }
  }
</script>
<style scoped>
.dd-container {
  background-color: white !important;
  border: 3px dashed green !important;
}
</style>