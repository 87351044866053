<template>
  <div>
    <v-card class="mx-1" max-width="344" elevation="2" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Help - PR Approval</div>
          <v-list-item-title class="text-h5 mb-1">
            How to Approve PR
          </v-list-item-title>
          <v-list-item-subtitle
            >A step-by-step instruction on How to approve
            PR.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="green"
          rounded
          class="white--text items-center"
          @click="overlay = !overlay"
        >
          Learn How
          <v-icon right dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-card class="mx-1 relative" elevation="2" outlined color="white">
        <div class="scroll">
          <div
            class="
              text-overline
              px-4
              py-2
              black--text
              fixedElement
              white
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>help on Purchase Request Approval - How to approve PR</div>
            <v-card-actions>
              <v-btn
                depressed
                color="green"
                rounded
                class="white--text items-center"
                @click="overlay = !overlay"
              >
                Close
                <v-icon right dark> mdi-close </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-list-item three-line>
            <v-list-item-content class="px-4">
              <!-- <v-list-item-title class="text-h5 mb-1 black--text">
              How to Create PR
            </v-list-item-title> -->
              <v-list-item-subtitle class="black--text"
                >1. On the left side of the screen, click on the Purchase
                Request Approval Tab.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/FINANCEPR1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. Upon clicking the Purchase Request Approval Tab, a list of
                Purchase Requests of all Fund managers will show on the
                list.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/FINANCEPR2.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. On the upper part of the table, you will see tabs that can
                show you the PR’s which are for approval or
                approved.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/FINANCEPR3.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. Below the tabs, you will see the filter component for the
                details on the table. You can search for any information inside
                the table on the search bar. You can also filter the data on the
                table using “Filter by Office” Filter.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/FINANCEPR4.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >5. On the PR List table under the actions column, you will see
                3 different buttons such as “APPROVE”, “PENDING”, and “VIEW”. To
                approve a PR, click on the “APPROVE” button.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/FINANCEPR5.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >6. A pop-up modal will show and will ask you to verify your
                decision to approve the PR or cancel. To approve the PR click on
                the “YES” button or “CANCEL” to cancel the approval.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/FINANCEPR6.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >7. Then to return the PR to the user, click on “PENDING”
                button. After clicking the “PENDING” button a pop-up modal will
                show and will ask for you to input your pending remarks. Click
                continue to pending this PR or cancel to return to the PR List
                table.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/FINANCEPR7.png"
                  height="auto"
                  width="800"
                />
              </div>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/FINANCEPR8.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >8. To view the PR, click on the “VIEW” button. A pop-up modal
                will show which contains the full details of the Purchase
                Request. “PRINT”, “APPROVE” and “PENDING” buttons are also
                available on this view. To close the modal, click on the close
                button on the top right side of the
                screen.</v-list-item-subtitle
              >

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalPPMP: false,
      overlay: false,
    };
  },
  methods: {
    showPPMP() {
      this.modalPPMP = !this.modalPPMP;
    },
  },
};
</script>

<style scoped>
.papel {
  z-index: 2;
}
.scroll {
  width: 1000px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.fixedElement {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>