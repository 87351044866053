var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ppmpList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{staticClass:"mt-2",attrs:{"color":"header"},model:{value:(_vm.app_status),callback:function ($$v) {_vm.app_status=$$v},expression:"app_status"}},[_c('v-tab',[_vm._v("Pending")]),_c('v-tab',[_vm._v("Done")]),_c('v-tab',[_vm._v("Unpublished")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"mb-3 mr-5",attrs:{"append-icon":"mdi-magnify","placeholder":"Search for Budget Code","color":"green darken-2","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-autocomplete',{staticClass:"ml-5",attrs:{"item-text":"desc","item-value":"id","items":_vm.ppmptypes,"hide-details":"","color":"header","label":"Filter by Types"},model:{value:(_vm.app_type),callback:function ($$v) {_vm.app_type=$$v},expression:"app_type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-autocomplete',{staticClass:"ml-5",attrs:{"item-text":"FundCode","item-value":"FundID","items":_vm.funds,"hide-details":"","color":"header","label":"Filter by Funds"},model:{value:(_vm.app_fund),callback:function ($$v) {_vm.app_fund=$$v},expression:"app_fund"}})],1)],1)]},proxy:true},{key:"item.Amount",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.Amount || 0)))])]}},{key:"item.BudgetDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.DateStart)+" - "+_vm._s(item.DateEnd)+" ")]}},{key:"item.DateApproved",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.PresidentApprovedDate))+" ")]}},{key:"item.PPMPStatusID",fn:function(ref){
var item = ref.item;
return [(item.PPMPStatusID == 12)?_c('span',[_vm._v("Published")]):_vm._e()]}},{key:"item.PPMPTypeID",fn:function(ref){
var item = ref.item;
return [(item.PPMPTypeID == 1)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("DBM")])]):_vm._e(),(item.PPMPTypeID ==2)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("NON-DBM")])]):_vm._e(),(item.PPMPTypeID == 3)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("LIB")])]):_vm._e()]}},{key:"item.other_actions",fn:function(ref){
var item = ref.item;
return [(item.APPStatus == 1 || item.APPStatus == 3)?_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"header","dark":""},on:{"click":function($event){return _vm.setAsDone(item)}}},[_vm._v(" SET AS DONE ")]):_vm._e(),(item.APPStatus == 2)?_c('v-btn',{attrs:{"text":"","color":"header","small":""}},[_c('v-icon',{attrs:{"text":""}},[_vm._v("mdi-check")]),_vm._v(" Done ")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"blue darken-2","dark":""},on:{"click":function($event){return _vm.viewPPMP(item)}}},[_vm._v(" VIEW ")])]}}],null,true)}),_c('ppmpModlal',{attrs:{"ppmpData":_vm.ppmpItem}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDone),callback:function ($$v) {_vm.dialogDone=$$v},expression:"dialogDone"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline mt-5"},[_vm._v("Confirmation")]),_c('div',{staticClass:"mx-6"},[_c('strong',[_vm._v("Are you sure you set this PPMP as done?")]),_vm._v(" "),_c('br'),_c('b',[_vm._v("Budget Code: ")]),_vm._v(" "+_vm._s(_vm.itemForDone.BudgetCode)+" ")]),_c('v-card-actions',{staticClass:"mt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-2","outlined":""},on:{"click":function($event){_vm.dialogDone = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"header darken-2 white--text"},on:{"click":function($event){return _vm.confirmDone()}}},[_c('v-icon',[_vm._v("mdi-check")]),_vm._v(" Submit")],1)],1)],1)],1),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }