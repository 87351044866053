<template>
  <div>
    <v-card class="mx-1" max-width="344" elevation="2" outlined>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="text-overline mb-4">Help - Recommending Approval</div>
          <v-list-item-title class="text-h5 mb-1">
            How to Approve PPPMP
          </v-list-item-title>
          <v-list-item-subtitle
            >A step-by-step instruction on How to approve
            PPMP.</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-card-actions>
        <v-btn
          depressed
          color="green"
          rounded
          class="white--text items-center"
          @click="overlay = !overlay"
        >
          Learn How
          <v-icon right dark> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay :value="overlay">
      <v-card class="mx-1 relative" elevation="2" outlined color="white">
        <div class="scroll">
          <div
            class="
              text-overline
              px-4
              py-2
              black--text
              fixedElement
              white
              d-flex
              justify-space-between
              align-center
            "
          >
            <div>help on Recommending Approval - How to approve PPMP</div>
            <v-card-actions>
              <v-btn
                depressed
                color="green"
                rounded
                class="white--text items-center"
                @click="overlay = !overlay"
              >
                Close
                <v-icon right dark> mdi-close </v-icon>
              </v-btn>
            </v-card-actions>
          </div>
          <v-list-item three-line>
            <v-list-item-content class="px-4">
              <!-- <v-list-item-title class="text-h5 mb-1 black--text">
              How to Create PR
            </v-list-item-title> -->
              <v-list-item-subtitle class="black--text"
                >1. Upon Signing in, on the left side of the screen click on the
                Recommending Approval Tab.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA1.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >2. Upon clicking the Recommending Approval Tab, a list of
                PPMP’s will show per tab category as “FOR APPROVAL”, “APPROVED”,
                and “PENDING” PPMP’s.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA2.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >3. On the list, in the right side of the PPMP list, you will
                see 3 (three) buttons which are “APPROVE”, “PENDING”, and “VIEW”
                button.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA3.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >4. To view a PPMP, click on the “VIEW”
                button.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA4.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >5. Upon clicking the view button, a pop-up modal will appear
                and will show you the full details of the PPMP you want to
                approve. Click the close button located at the right top side of
                the screen after viewing.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA5.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >6. Click on the “PENDING” button if there are things needed to
                be edited in the PPMP that you needed to approve.
              </v-list-item-subtitle>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA6.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >7. Upon clicking the “PENDING” button, a pop-up will show and
                will ask you to add your remarks on the said
                PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA7.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >8. Click “RETURN PPMP” button to return the PPMP or click
                “CANCEL” to cancel the command. After clicking these button, the
                pop-up modal will close automatically.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA8.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >9. To approve a PPMP, click on the “APPROVE” button. A pop-up
                modal will appear and will ask you to re-confirm your decision
                to approve this PPMP.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA9.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >10. Upon clicking the “YES” button, the PPMP that you have
                approved will disappear from the “FOR APPROVAL” tab and will be
                available to view under the “APPROVED”
                tab.</v-list-item-subtitle
              >
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA10.png"
                  height="auto"
                  width="800"
                />
              </div>
              <div class="px-4 mt-2">
                <img
                  src="@/assets/help images/PPMPRA11.png"
                  height="auto"
                  width="800"
                />
              </div>

              <v-list-item-subtitle class="black--text mt-8"
                >11. You have successfully approved a PPMP. Repeat the process
                from item number 9 and 10 to approve another
                PPMP.</v-list-item-subtitle
              >

              <v-list-item-subtitle
                class="black--text mt-8 d-flex justify-center"
                >--- end ---</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalPPMP: false,
      overlay: false,
    };
  },
  methods: {
    showPPMP() {
      this.modalPPMP = !this.modalPPMP;
    },
  },
};
</script>

<style scoped>
.papel {
  z-index: 2;
}
.scroll {
  width: 1000px;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}
.fixedElement {
  position: sticky;
  top: 0;
  z-index: 3;
}
</style>