import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // thisVue : {},
    user: null,
    fiscalYear: null,
    theme: null,
    module: null,
    reminder: null,
    revision: [],
  },
  mutations: {
  //   setThisVue(state, payload){
  //     state.thisVue = payload;
  // },
  setUserData(state, data){
    state.user = data
  },
  setFiscalYear(state, data){
    state.fiscalYear = data
  },
  setTheme(state, data){
    state.theme = data
  },
  setModule(state, data){
    state.module = data
  },
  setReminder(state, data){
    state.reminder = data
  },
  setRevision(state, data){
    state.revision = data
  }
  },
  actions: {
    setThisVue(context, payload){
      context.commit('setThisVue', payload);
  },
    setUser(context, data){
      // let _this = context.state.thisVue;
      context.commit('setUserData', data);
    },
    setFiscalYear(context, data){
      context.commit('setFiscalYear', data);
    },
    setTheme(context, data){
      context.commit('setTheme', data);
    },
    setModule(context, data){
      context.commit('setModule', data);
    },
    setReminder(context, data){
      context.commit('setReminder', data);
    },
    setRevision(context, data){
      context.commit('setRevision', data);
    }
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})