<template>
  <div class="pa-4" style="position: relative">
    <h2 class="subheading green--text">Help for PSU Users</h2>
    <img
      src="@/assets/Consulting-rafiki.svg"
      class="bg"
      position="right"
      height="450"
      width="auto"
    />
    <HelpIAR class="larawan" />
  </div>
</template>

<script>
import HelpIAR from "@/components/Utils/Help-PSU.vue";
export default {
  components: {
    HelpIAR,
  },
  data: () => ({}),
};
</script>

<style scoped>
.bg {
  border-style: none;
  position: absolute;
  right: 10px;
  top: 160px;
  opacity: 0.5;
}
.larawan {
  opacity: 1;
}
</style>