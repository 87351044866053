<template>
  <div class="DarkWhite">
    <div>
    <v-autocomplete
          :items="mrtypes"
          label="Select MR Type"
          outlined
          item-text="desc"
          item-value="id"
          v-model="mrtype"
          dense
          color="header"
        ></v-autocomplete>
  </div>
    <v-data-table
      :headers="headers_ics"
      :items="icsList"
      :options.sync="options1"
      :server-items-length="totalCount1"
      :loading="loading1"
      :footer-props="footerProps1"
      class="elevation-1"
      v-if="mrtype == 1"
    >
    <template v-slot:top>
        <v-toolbar flat class="my-1 pt-1">
          <v-text-field
            style="max-width: 350px"
            v-model="searchText1"
            append-icon="mdi-magnify"
            placeholder="Search ICS number"
            color="green darken-2"
            dense
            hide-details
            class="mt-3"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
          color="header"
          dark
          @click="addICS()"
        >
        <v-icon class="mr-2">mdi-folder-multiple-plus</v-icon>
          Add ICS & References
        </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.FundSource`]="{ item }">
        {{ item.FundCode }} - {{ item.SpecificFundSourceDescription }}
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.totalAmount) }}</span>
      </template>
      <template v-slot:[`item.Remarks`]="{ item }">
        <span v-if="item.RenewID">Renewed</span>
        <span v-else-if="item.TransferID">Transferred</span>
        <span v-else>First</span>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.DispenseTypeID`]="{ item }">
        <span v-if="item.DispenseTypeID == 1">Distributed</span>
        <span v-if="item.DispenseTypeID == 2">Shared</span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewICS(item)"
          >
            View
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printICS(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>

    <v-data-table
      :headers="headers_par"
      :items="parList"
      :options.sync="options2"
      :server-items-length="totalCount2"
      :loading="loading2"
      :footer-props="footerProps2"
      class="elevation-1"
      v-if="mrtype == 2"
    >
    <template v-slot:top>
        <v-toolbar flat class="my-1 pt-1">
          <v-text-field
            style="max-width: 350px"
            v-model="searchText2"
            append-icon="mdi-magnify"
            placeholder="Search PAR number"
            color="green darken-2"
            dense
            hide-details
            class="mt-2"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
          color="header"
          dark
          @click="addPAR()"
        >
        <v-icon class="mr-2">mdi-folder-multiple-plus</v-icon>
          Add PAR & References
        </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.FundSource`]="{ item }">
        {{ item.FundCode }} - {{ item.SpecificFundSourceDescription }}
      </template>
      <template v-slot:[`item.Amount`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.totalAmount) }}</span>
      </template>
      <template v-slot:[`item.Remarks`]="{ item }">
        <span v-if="item.RenewID">Renewed</span>
        <span v-else-if="item.TransferID">Transferred</span>
        <span v-else>First</span>
      </template>
      <template v-slot:[`item.DateCreated`]="{ item }">
        {{ formatDate(item.DateCreated) }}
      </template>
      <template v-slot:[`item.DispenseTypeID`]="{ item }">
        <span v-if="item.DispenseTypeID == 1">Distributed</span>
        <span v-if="item.DispenseTypeID == 2">Shared</span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="viewPAR(item)"
          >
            View
          </v-btn>
          <v-btn
            color="red darken-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="printPAR(item)"
            v-if="item.IsCancelled == 0"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <!-- <v-dialog v-model="pendingDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to pending this Return Slip?
            <br /><br />
            <div>RS #: <strong>{{pendingData.RSCODE}}</strong></div>
            <div>Returned By: <strong>{{pendingData.ReturnBy}}</strong></div>
            <br />
            <v-form ref="formRef">
            <v-textarea
              color="green"
                dense
                v-model="formData.Remarks"
                :rules="[formRules.required]"
                label="Remarks"
                counter
                outlined
                maxlength="500"
                ></v-textarea>
            </v-form>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="pendingDialog = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmPendingRS()"><v-icon class="mr-1">mdi-clock-outline</v-icon>Pending</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
    <CreateRIA :riaData="riaData" />
    <ICSModal :icsData="icsData" />
    <PARModal :parData="parData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    CreateRIA: () => import("./CreateRIA.vue"),
    ICSModal: () => import("./ViewICS.vue"),
    PARModal: () => import("./ViewPAR.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText1: "",
    searchText2: "",
    searchTimeout: null,
    footerProps1: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    footerProps2: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount1: 0,
    totalCount2: 0,
    loading1: true,
    loading2: true,
    options1: {},
    options2: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    icsList: [],
    parList: [],
    icsData: [],
    parData: [],
    riaData: [],
    headers_ics: [
      {
          text: 'ICS CODE',
          align: 'start',
          sortable: false,
          value: 'ICSCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'ICSPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Dispense Type',
          align: 'center',
          sortable: false,
          value: 'DispenseTypeID',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      headers_par: [
      {
          text: 'PAR CODE',
          align: 'start',
          sortable: false,
          value: 'PARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'IAR CODE',
          align: 'start',
          sortable: false,
          value: 'IARCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RIS CODE',
          align: 'start',
          sortable: false,
          value: 'RISCODE',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'PARPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
         {
          text: 'Amount',
          align: 'end',
          sortable: false,
          value: 'Amount',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Date Created',
          align: 'start',
          sortable: false,
          value: 'DateCreated',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Dispense Type',
          align: 'center',
          sortable: false,
          value: 'DispenseTypeID',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        { text: 'Actions', value: 'Actions', align: 'center', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      mrtype: 1,
      mrtypes: [
        {id: 1, desc: "Inventory Custodian Slip"}, 
        {id: 2, desc: "Property Acknowledgement Receipt"}
      ],
  }),
  watch: {
    searchText1: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize1();
      }, 600);
    },
    searchText2: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize2();
      }, 600);
    },
    options1: {
      handler() {
        this.initialize1();
      },
      deep: true,
    },
    options2: {
      handler() {
        this.initialize2();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize1();
        this.initialize2();
      },
      deep: true,
    },
    mrtype: {
      handler(data) {
        if(data == 1){
            this.initialize1();
        }
        else if(data ==2){
            this.initialize2();
        }
        
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeViewICS", () => {
      this.initialize1();
    });
    this.eventHub.$on("closeViewPAR", () => {
      this.initialize2();
    });
    this.eventHub.$on("closeCreateRIA", () => {
      if(this.mrtype == 1){
        this.initialize1();
      }
      else if(this.mrtype == 2){
        this.initialize2();
      }
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewics");
    this.eventHub.$off("closeViewPAR");
    this.eventHub.$off("closeCreateRIA");
  },
  methods: {
    viewICS(item) {
      this.icsData = item;
    },
    printICS(item) {
      this.link = this.apiUrl + "/ics/report/" + item.ICSID;
      window.open(this.link);
    },
    viewPAR(item) {
      this.parData = item;
    },
    printPAR(item) {
      this.link = this.apiUrl + "/par/report/" + item.PARID;
      window.open(this.link);
    },
    addICS(){
      this.riaData = {type: 1};
    },
    addPAR(){
      this.riaData = {type: 2};
    },
    initialize1() {
      this.loading1 = true;
      if(this.fiscalYear != null){
        let data = new FormData();
        if (this.options1.page && this.options1.itemsPerPage) {
            data.append("page", this.options1.page);
            data.append("itemsPerPage", this.options1.itemsPerPage);
        } else {
            data.append("page", 1);
            data.append("itemsPerPage", 10);
        }
        data.append("searchText", this.searchText1);
        data.append("FiscalYearID", this.fiscalYear);
        data.append("RemarksID", 0);
        data.append("UserID", 0);
        this.axiosCall("/getICSList/type/3", "POST", data).then((res) => {
            this.loading1 = false;
            this.icsList = res.data.data.result;
            this.totalCount1 = res.data.data.total;
            if (this.totalCount1 <= 10) {
            this.options1.page = 1;
            }
        });
      }
    },
    initialize2() {
      this.loading2 = true;
      if(this.fiscalYear != null){
        let data = new FormData();
        if (this.options2.page && this.options2.itemsPerPage) {
            data.append("page", this.options2.page);
            data.append("itemsPerPage", this.options2.itemsPerPage);
        } else {
            data.append("page", 1);
            data.append("itemsPerPage", 10);
        }
        data.append("searchText", this.searchText2);
        data.append("FiscalYearID", this.fiscalYear);
        data.append("RemarksID", 0);
        data.append("UserID", 0);
        this.axiosCall("/getPARList/type/3", "POST", data).then((res) => {
            this.loading2 = false;
            this.parList = res.data.data.result;
            this.totalCount2 = res.data.data.total;
            if (this.totalCount2 <= 10) {
            this.options2.page = 1;
            }
        });
      }
    },
    approveRS(data){
      this.approveData = data;
      this.approveDialog = true;
    },
    pendingRS(data){
      this.pendingData = data;
      this.pendingDialog = true;
    },
    confirmApproveRS(){
      let data = new FormData();
      data.append("RSID", this.approveData.RSID);
      data.append("Admin", 1);
      this.axiosCall("/rs/approve", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RS was successfully approved.";
            this.fadeAwayMessage.header = "System Message";
            this.approveDialog = false;
            this.approveData = [];
            this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    confirmPendingRS(){
      if(this.$refs.formRef.validate()){
      let data = new FormData();
      data.append("RSID", this.pendingData.RSID);
      data.append("Remarks", this.formData.Remarks);
      data.append("Admin", 1);
      this.axiosCall("/rs/pending", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RS was successfully in pending status.";
            this.fadeAwayMessage.header = "System Message";
            this.pendingDialog = false;
             this.pendingData = [];
             this.initialize();
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
  },
};
</script>