<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title> {{ meiData.BudgetCode }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="meiData.CreatedBy > 0"
                :rules="[() => meiData.CreatedBy > 0]"
              >
              <span class="text-no-wrap">M&E Instrument Creation</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(meiData.DateCreated)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="meiData.SubmittedBy > 0"
                step="2"
                class="pa-3"
              >
                <span class="text-no-wrap">Fund Manager Submission</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(meiData.DateSubmitted)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h5"
                  >Monitoring & Evaluation Instrument</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Quarter:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ meiData.Quarter }}</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Budget Code:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ meiData.BudgetCode }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Fund Manager:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ meiData.Assignee }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Budget Amount:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatPrice(meiData.Amount) }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Office:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ meiData.OfficeDescription }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Per Budget Record:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatPrice(meiData.PerBudgetRecord) }}</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Remarks:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>
                <span v-if="meiData.Remarks">{{ meiData.Remarks }}</span>
                <span v-else><i>No Remarks</i></span>
                </v-subheader>
            </v-col>
          </v-row>
          </div>
          <v-row class="mt-8" no-gutters v-if="$store.state.user.roleID == 7">
            <v-col cols="3" sm="7" md="9" lg="10"><v-divider class="mt-3"></v-divider></v-col>
            <v-col cols="9" sm="5" md="3" lg="2" align="right" >
                <span class="text-no-wrap">
                <v-btn
                    outlined
                    color="grey darken-2 mr-2"
                    dark
                    small
                    @click="editMEI()"
                    class="mr-2"
                >
                    <v-icon>mdi-pencil-outline</v-icon>
                    Edit M&E
                </v-btn>
                <v-btn
                    outlined
                    color="red darken-2"
                    dark
                    small
                    @click="print(meiData)"
                    class="mr-2"
                >
                    <v-icon>print</v-icon>
                    Print
                </v-btn>
                </span>
            </v-col>
          </v-row>
          <v-row class="ma-10 mt-4">
            <v-col lg="12" class="">
                <ol>
                    <li style="padding-bottom: 10px;">Total amount of Non-DBM PPMPs&nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(meiData.AmountNDBM) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></li>
                    <li style="padding-bottom: 10px;">Total amount of all Purchase Requests for Non-DBM PPMPs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(meiData.AmountNDBM_PR) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u><br />
                        <div style="padding-top: 10px;">2.1 Percentage of the total amount of all Non-DBM PRs over the total amount of Non-DBM PPMPs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ meiData.AmountNDBM > 0 ? formatPrice((meiData.AmountNDBM_PR / meiData.AmountNDBM) * 100) : '0.00' }}%</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></div>
                    </li>
                    <li style="padding-bottom: 10px;">Total amount of Purchase Order for Non-DBM PPMPs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(meiData.AmountNDBM_PO) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u><br />
                            <div style="padding-top: 10px;">3.1 Percentage of the total amount of Non-DBM POs over the total amount of Non-DBM PRs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ meiData.AmountNDBM_PR > 0 ? formatPrice((meiData.AmountNDBM_PO / meiData.AmountNDBM_PR) * 100) : '0.00' }}%</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></div>
                    </li>
                    <li style="padding-bottom: 10px;">Total amount of Obligations for Non-DBM PPMPs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(meiData.AmountNDBM_OBL) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></li>
                    <li style="padding-bottom: 10px;">Total amount of DBM PPMPs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(meiData.AmountDBM) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></li>
                    <li style="padding-bottom: 10px;">Total amount of all Purchase Requests for DBM PPMPs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(meiData.AmountDBM_PR) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></li>
                    <li style="padding-bottom: 10px;">Total amount of all LIBs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(meiData.AmountLIB) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></li>
                    <li style="padding-bottom: 10px;">Total obligations for all LIBs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(meiData.AmountLIB_OBL) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u><br />
                            <div style="padding-top: 10px;">8.1 Percentage of the total obligations for all LIBs over the total amount of all LIBs &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ meiData.AmountLIB > 0 ? formatPrice((meiData.AmountLIB_OBL / meiData.AmountLIB) * 100) : '0.00' }}%</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></div>
                    </li>
                    <li style="padding-bottom: 10px;">Non-DBM POs + Non-DBM Obligations + LIB Obligations &nbsp;&nbsp;<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{ formatPrice(parseFloat(meiData.AmountNDBM_PO) + parseFloat(meiData.AmountNDBM_OBL) + parseFloat(meiData.AmountLIB_OBL)) }}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></li>
                    <li style="padding-bottom: 10px;">Provide justification of your current level of BURs (<u style="color: red;">Justification must be per budget code</u>):<br />
                    <div style="border: 1px solid black; width: 90%; height: 130px; margin-top: 10px; padding: 7px;">
                        {{meiData.Justification}}<br />
                    </div>
                    </li>
                    <li>Enumerate you plans to ensure 100% utilization of budget (<u style="color: red;">Catch-up plan must be per budget code</u>):<br />
                    <div style="border: 1px solid black; width: 90%; height: 130px; margin-top: 10px; padding: 7px;">
                        {{meiData.CatchUpPlan}}
                    </div>
                    </li>

                </ol>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUnpublish" max-width="500px">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <div class="mx-6">
              Are you sure you want to unpublish this Return Slip?
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogUnpublish = false">Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmUnpublish()"><v-icon>mdi-delete</v-icon> Unpublish</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-dialog
          v-model="editMEIDialog"
          persistent
          max-width="750px"
          scrollable
        >
          <v-card>
            <v-card-title>
              <span>Update Budget for M & E</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="editMEIDialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
            <v-card-text style="max-height: 900px;">
              <v-form @submit.prevent ref="MAndEUpdateFormRef">
              <v-container>
              <v-row>
                <v-col cols="12" v-if="meiData">
                  <v-card
                    class="pa-3"
                    color="white lighten-3"
                    flat
                  >
                  <v-card-text class="pa-0 ma-0 text-center">
                    <h2 v-if="meiData.Quarter">{{meiData.Quarter.toUpperCase()}} QUARTER</h2>
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-card
                    class="pa-3"
                    color="grey lighten-3"
                    flat
                  >
                  <v-card-text class="pa-0 ma-0">
                  <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <strong class="text-no-wrap">Budget Code: </strong>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <span>{{meiData.BudgetCode}}</span>
                  </v-col>
                  </v-row>
                  <v-row no-gutters>
                  <v-col cols="12" sm="4">
                    <strong class="text-no-wrap">Fund Source: </strong>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <span v-if="meiData.FundCode">{{meiData.FundCode + '-' + meiData.SpecificFundSourceDescription}}</span>
                  </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong class="text-no-wrap">Assignee: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{meiData.Assignee}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Office: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{meiData.OfficeDescription}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Purpose: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{meiData.PurposeDescription}}
                    </v-col>
                  </v-row>
                  <!-- <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Allotment Type: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{budgetDetails.AllotmentTypeDescription}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Delivery Unit: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      {{budgetDetails.DeliveryUnitDescription}}
                    </v-col>
                  </v-row> -->
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Actual Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <strong v-if="meiData.Amount">{{formatPrice(meiData.Amount) }}</strong>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="4">
                      <strong>Utilized Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="8">
                        <strong v-if="meiData.AmountNDBM_PO">{{formatPrice(parseFloat(meiData.AmountNDBM_PO) + parseFloat(meiData.AmountNDBM_OBL) + parseFloat(meiData.AmountDBM_PR) + parseFloat(meiData.AmountLIB_OBL)) }}</strong>
                    </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                      cols="12"
                    >
        <!-- <v-autocomplete
            v-model="formData.QuarterID"
            item-text="description"
            item-value="id"
            :rules="[formRules.required]"
            :items="quarters"
            dense
            outlined
            color="header"
            label="Quarter">
        </v-autocomplete> -->

        <v-text-field
          dense
          v-model="formData.PerBudgetRecord"
          :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
          @input="checkBalance(formData.PerBudgetRecord, meiData.Amount)"
          type = "number"
          color="header"
          outlined
          label="Per Budget Record">
          </v-text-field>
        <v-textarea
          dense
          v-model="formData.Remarks"
          color="header"
          outlined
            label="Remarks">
          </v-textarea>
          <div v-if="meiData.SubmittedBy > 0 && $store.state.user.roleID == 7">
            <span class="flex-items pb-5 black--text">Fund Manager Area<v-divider></v-divider></span>
          <v-textarea
          dense
          v-model="formData.Justification"
          color="header"
          outlined
            label="Justification">
          </v-textarea>
          <v-textarea
          dense
          v-model="formData.CatchUpPlan"
          color="header"
          outlined
            label="Catch Up Plan">
          </v-textarea>
          </div>
          </v-col>
              </v-row>
              </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="grey darken-1"
                outlined
                @click="editMEIDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-2"
                class="white--text"
                @click="confirmUpdate"
                :loading="isLoading"
              >
              <v-icon class="mr-1">mdi-square-edit-outline</v-icon>
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    meiData: {},
  },
  data: () => ({
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    dialogIARVersion: false,
    link: "",
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "QTY", align:"center", value: "Qty", sortable: false },
      { text: "Amount", align:"end", value: "Amount", sortable: false },
      { text: "Remarks", align:"center", value: "Remarks", sortable: false },
    ],
    headers2: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        align: "center",
        sortable: false,
      },
      { text: "Unit Cost", align:"end", value: "UnitCost", sortable: false },
      { text: "Qty", align:"center", value: "Qty", sortable: false },
      { text: "Serviceable", align:"center", value: "ReturnType", sortable: false },
    ],
    expanded1: [],
    expanded2: [],
    singleExpand: true,
    formData: {
      QuarterID: null,
      PerBudgetRecord: null,
      Remarks: null,
      Justification: null,
      CatchUpPlan: null,
    },
    itemList: [],
    itemList_filtered: [],
    tabs: null,
    submitbtn: false,
    dispenseTypes: [],
    ICSDispenseType: null,
    DSDialog: false,
    DSType: null,
    editDescription: false,
    dialogConfirmSubmit: false,
    generatedICSID : [],
    dialogUnpublish: false,
    isLoading: false,
    dialogProcess: false,
    approveData: [],
    pendingData: [],
    editMEIDialog: false,
    quarters: []
  }),
  watch: {
    meiData: {
      handler(data) {
        if(data.MEIID){
        this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    print() {
        window.open(this.apiUrl + "/mei/report/" + this.meiData.MEIID);
    },
    editMEI(){
    let data = this.meiData;
    this.formData.QuarterID = parseInt(data.QuarterID);
    this.formData.PerBudgetRecord = data.PerBudgetRecord;
    this.formData.Remarks = data.Remarks;
    this.formData.Justification = data.Justification;
    this.formData.CatchUpPlan = data.CatchUpPlan;
      this.editMEIDialog = true;
    },
    closeDialog() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeViewMAndE", false);
    },
    unpublish(){
      this.dialogUnpublish = true;
    },
    confirmUpdate(){
      if(this.$refs.MAndEUpdateFormRef.validate()){
        let data = new FormData();
        data.append("MEIID", this.meiData.MEIID);
        data.append("QuarterID", this.formData.QuarterID);
        data.append("PerBudgetRecord", this.formData.PerBudgetRecord);
        data.append("Justification", this.formData.Justification ? this.formData.Justification : '');
        data.append("CatchUpPlan", this.formData.CatchUpPlan ? this.formData.CatchUpPlan : '');
        data.append("Remarks", this.formData.Remarks ? this.formData.Remarks : '');
        this.axiosCall("/mei/edit", "POST", data).then((res) => {
          if(res.data.status){
            this.meiData.QuarterID = this.formData.QuarterID;
            this.meiData.PerBudgetRecord = this.formData.PerBudgetRecord;
            this.meiData.Remarks = this.formData.Remarks;
            this.meiData.Justification = this.formData.Justification;
            this.meiData.CatchUpPlan = this.formData.CatchUpPlan;
             this.formData = {
              QuarterID: null,
              PerBudgetRecord: null,
              Remarks: null,
              Justification: null,
              CatchUpPlan: null,
            },
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "M&E Instrument successfully updated.";
            this.editMEIDialog = false;
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Something went wrong!";
          }
          });
      }
      else{
        this.fadeAwayMessage.show = true;
        this.fadeAwayMessage.type = "error";
        this.fadeAwayMessage.header = "System Message";
        this.fadeAwayMessage.message = "Unable to proceed. Please check the details before submitting!";
      }
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("RSID", this.meiData.RSID);
      this.axiosCall("/rs/unpublish", "POST", data).then((res) => {
        if(res.data.status){
        this.closeDialog();
          this.dialogUnpublish = false;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Return Slip was successfully unpublished";
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    checkBalance(perBudget, amount) {
        if(parseFloat(parseFloat(perBudget).toFixed(2)) <= parseFloat(parseFloat(amount).toFixed(2))){
          this.amountErr2 = null;
        }
        else{
          this.amountErr2 = 'Per Budget Record must not exceed Actual Budget/Amount!';
        }
      },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>