<template>
  <div class="DarkWhite pa-4">
      <v-row>
      <v-col cols="12" sm="6" md="8" lg="10">
        <h2 class="subheading green--text">APP Management</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-autocomplete
          v-model="fiscalYear"
          :items="fiscalyears"
          auto-select-first
          item-text="fiscalYear"
          item-value="id"
          color="header"
          dense
          label="Fiscal Year"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
      </v-row>
      <APPManagement :fiscalYear="fiscalYear"/>
  </div>
</template>

<script>
import APPManagement from '../components/Utils/APPManagement.vue'
  export default {
    components: {
      APPManagement
    },
    data: () => ({
      fiscalYear: null,
      fiscalyears: [],
    }),
    watch: {
      fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
      },
      deep: true,
    },
    },
    created(){
      this.getFiscalYears()
    },
    methods: {
      getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
    }
  }
</script>

<style scoped>
</style>