var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-5"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-autocomplete',{attrs:{"item-text":"OfficeDescription","item-value":"OfficeID","items":_vm.office,"hide-details":"","color":"header","label":"Filter by Office"},model:{value:(_vm.OfficeID),callback:function ($$v) {_vm.OfficeID=$$v},expression:"OfficeID"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-autocomplete',{attrs:{"item-text":"DeliveryUnitDescription","item-value":"DeliveryUnitID","items":_vm.deliveryUnit,"hide-details":"","color":"header","label":"Filter by Delivery Unit"},model:{value:(_vm.DeliveryUnitID),callback:function ($$v) {_vm.DeliveryUnitID=$$v},expression:"DeliveryUnitID"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-autocomplete',{attrs:{"item-text":"PurposeDescription","item-value":"PurposeID","items":_vm.purpose,"hide-details":"","color":"header","label":"Filter by Program/Activity/Project"},model:{value:(_vm.PurposeID),callback:function ($$v) {_vm.PurposeID=$$v},expression:"PurposeID"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-autocomplete',{attrs:{"item-text":"SpecificFundSourceDescription","item-value":"SpecificFundSourceID","items":_vm.specificFundSource,"hide-details":"","color":"header","label":"Filter by Fund Source"},model:{value:(_vm.SpecificFundSourceID),callback:function ($$v) {_vm.SpecificFundSourceID=$$v},expression:"SpecificFundSourceID"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"menu1",attrs:{"dense":"","close-on-content-click":false,"return-value":_vm.DateRange,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.DateRange=$event},"update:return-value":function($event){_vm.DateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({staticClass:"mt-5",attrs:{"dense":"","label":"Date Range","color":"header","hide-details":"","clearable":""},model:{value:(_vm.DateRange),callback:function ($$v) {_vm.DateRange=$$v},expression:"DateRange"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":"","color":"header","max":new Date().toISOString().substr(0, 10)},model:{value:(_vm.DateRange),callback:function ($$v) {_vm.DateRange=$$v},expression:"DateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"header"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"header"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.DateRange)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ppmpList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mx-1",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","placeholder":"Search for Budget Code","color":"green darken-2","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer'),_c('vue-json-to-csv',{attrs:{"json-data":_vm.itemListForCSV,"labels":{ 
          BudgetCode: { title: 'BudgetCode' },
          PPMPDescription: { title: 'Description' },
          Amount: { title: 'Budget' },
          BudgetDate: { title: 'Budget Date' },
          PresidentApprovedDate: { title: 'Date Approved' },
          PPMPStatusID: { title: 'Status (11=Not yet Published; 12=Published)' },
          PPMPTypeID: { title: 'Type (1=DBM; 2=NDBM; 3=LIB)' },
          },"csv-title":"Approved PPMPs"}},[_c('v-btn',{attrs:{"outlined":"","color":"green darken-2","dark":"","loading":_vm.loadingCSV}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-microsoft-excel")]),_vm._v(" CSV ")],1)],1)],1)]},proxy:true},{key:"item.Amount",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.Amount || 0)))])]}},{key:"item.BudgetDate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.DateStart)+" - "+_vm._s(item.DateEnd)+" ")]}},{key:"item.DateApproved",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.PresidentApprovedDate))+" ")]}},{key:"item.PPMPStatusID",fn:function(ref){
          var item = ref.item;
return [(item.PPMPStatusID == 12)?_c('span',[_vm._v("Published")]):_vm._e()]}},{key:"item.PPMPTypeID",fn:function(ref){
          var item = ref.item;
return [(item.PPMPTypeID == 1)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("DBM")])]):_vm._e(),(item.PPMPTypeID ==2)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("NON-DBM")])]):_vm._e(),(item.PPMPTypeID == 3)?_c('span',{staticClass:"text-no-wrap"},[_c('small',[_vm._v("LIB")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"blue darken-2","dark":""},on:{"click":function($event){return _vm.viewPPMP(item)}}},[_vm._v(" VIEW ")])]}}],null,true)}),_c('ppmpModlal',{attrs:{"ppmpData":_vm.ppmpItem}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }