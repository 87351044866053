<template>
  <div class="DrkWhite pa-4">
      <h2 class="subheading green--text">Uploading of ICS and PAR</h2>
      <div class="mt-4">
        <MRUploading/>
      </div>
  </div>
</template>

<script>
import MRUploading from '@/components/Supply/MRUploading.vue'
  export default {
    components: {
      MRUploading
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
</style>