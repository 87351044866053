<template>
  <div class="DarkWhite pa-4">
    <v-row>
    <v-col cols="12" sm="6" md="8" lg="10">
        <h2 class="subheading green--text">Fund Utilization Rate</h2>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <v-autocomplete
          v-model="fiscalYear"
          :items="fiscalyears"
          auto-select-first
          item-text="fiscalYear"
          item-value="id"
          color="header"
          dense
          label="Fiscal Year"
          class="mt-2"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div>
      <v-data-table
        :headers="headers"
        :items="utilizationData"
        :options.sync="options"
        :server-items-length="totalCount"
        :loading="loading"
        :footer-props="footerProps"
        class="elevation-1"
      >
        <template v-slot:top>
            <v-row class="mx-1 align-center">
            <v-col cols="12" md="5">
            <v-text-field
              v-model="searchText"
              append-icon="mdi-magnify"
              placeholder="Search for Budget Code"
              color="green darken-2"
              class="mb-md-4"
              hide-details
            ></v-text-field>
            </v-col>
            <v-col cols="12" md="7" class="flex-items justify-md-end mb-4 mb-lg-0">
            <v-autocomplete
            v-model="FundID"
            color="green"
            item-text="FundCode"
            item-value="FundID"
            :items="funds"
            dense
            style="max-width: 180px"
            outlined
            hide-details
            class="mr-2"
            label="Fund Code">
            </v-autocomplete>
            <!-- <v-btn
              outlined
              color="red darken-2"
              dark
              @click="print()"
              class="mr-2"
            >
              <v-icon>print</v-icon>
              Print
            </v-btn> -->
            <vue-json-to-csv 
                    :json-data="utilizationData"
                    :labels="csvLabel"
                    csv-title="FundUtilizationRate">
                <v-btn
                  outlined
                  color="green darken-2"
                  dark
                >
                  <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
                  CSV
                </v-btn>
              </vue-json-to-csv>
            </v-col>
            </v-row>
        </template>
        <template v-slot:[`item.AllocatedFund`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.AllocatedFund || 0) }}</span>
        </template>
        <template v-slot:[`item.AllocatedBudget`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.AllocatedBudget || 0) }}</span>
        </template>
        <template v-slot:[`item.PRs`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.PRs || 0) }}</span>
        </template>
        <template v-slot:[`item.POs`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.POs || 0) }}</span>
        </template>
        <template v-slot:[`item.IARs`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.IARs || 0) }}</span>
        </template>
        <template v-slot:[`item.NonProc`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.NonProc || 0) }}</span>
        </template>
        <template v-slot:[`item.UnrequestedBudget`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.UnrequestedBudget || 0) }}</span>
        </template>
        <template v-slot:[`item.UnutilizedBudget`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.UnutilizedBudget || 0) }}</span>
        </template> 
        <template v-slot:[`item.ObligationBUR`]="{ item }">
          <span class="text-no-wrap">{{ formatPrice(item.ObligationBUR || 0) }}</span>
        </template>
        <template v-slot:[`item.DisbursementBUR`]="{ item }">
          <span class="text-no-wrap">{{ formatPrice(item.DisbursementBUR || 0) }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    labels_budget: [],
    value_budget: [],
    labels_ppmp: [],
    value_ppmp: [],
    total_budget_count: 0,
    total_ppmp_count: 0,
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [10, 20, 50, 100, 500, -1] },
    totalCount: 0,
    loading: true,
    options: {},
    isLoading: false,
    utilizationData: [],
    headers: [
      {
        text: "Budget Code",
        align: "start",
        sortable: false,
        value: "BudgetCode",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "User Name",
        align: "start",
        sortable: false,
        value: "FullName",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Obligation BUR",
        value: "ObligationBUR",
        align: "end",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Disbursement BUR",
        align: "end",
        sortable: false,
        value: "DisbursementBUR",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
    csvLabel: {
        BudgetCode: { title: 'Budget Code' },
        FullName: { title: 'User' },
        ObligationBUR: { title: 'Obligation BUR' },
        DisbursementBUR: { title: 'Disbursement BUR' },
        },
    fiscalYear: null,
    fiscalyears: [],
    funds: [],
    FundID: 0,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    FundID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler(data) {
        this.$store.dispatch("setFiscalYear", data);
        this.initialize();
      },
      deep: true,
    },
  },
  created(){
    this.getFundList();
  },
  mounted() {
    this.eventHub.$on("closePPMPModal", () => {
      this.ppmpItem = {};
      this.initialize();
    });
    this.getFiscalYears()
  },
  beforeDestroy() {
    this.eventHub.$off("closePPMPModal");
  },
  methods: {
    viewPPMP(item) {
      this.ppmpItem = item;
    },
    initialize() {
      this.loading = true;
      this.utilizationData = [];
      if(this.fiscalYear != null){
        this.getFundUtilization();
      }
    },
    getFundUtilization() {
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("fundID", this.FundID);
      this.axiosCall('/dashboard/fundUtilizationRate', "POST", data).then(
        (res) => {
          this.loading = false;
          this.utilizationData = res.data.data.result;
          this.totalCount = res.data.data.total;
          if (this.totalCount <= 10) {
            this.options.page = 1;
          }
        }
      );
    },
    print(){
      let searchText = this.searchText != '' ? this.searchText : 0;
      window.open(this.apiUrl + "/transaction/fundUtilizationRate/" + searchText);
    },
    getFundList() {
    this.axiosCall(
            '/get/getFundCodes',
            'GET'
        )
        .then((res) => {
          this.funds = [{FundID: 0, FundCode: "All"},...res.data.data]
        })
		},
    getFiscalYears() {
      this.axiosCall("/getAllFiscalYear", "GET").then((res) => {
        this.fiscalyears = [{id: 0, fiscalYear: "All"}, ...res.data.data]
        this.fiscalYear = this.$store.state.fiscalYear != null ? this.$store.state.fiscalYear : res.data.data[0].id
      });
    },
  },
};
</script>

<style scoped>
.Offset {
  top: -20px !important;
  position: relative !important;
}
.v-sheet--offset {
  top: -15px;
  position: relative;
}
</style>